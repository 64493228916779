import React, { Component, useEffect, useState, useRef } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import http from "../Services/httpService";
import config from "../config.json";
import Form from "./Comman/Form";
import Joi from "joi-browser";
import UploadDocument from "./Comman/UploadDocument";
import authService from "../Services/authService";
import { getAlert } from "../Services/appAlerts";
import { getCurrentUser } from "../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { addAppNewMessage, appMessageAdded, getAddedMessageStatus } from "../app/store/messages";

const AddNewMessage = (props) => {

  const { addMsgShowHide, handleAddMsgModalShowHide, runningApplication } = props;


  const [documents, setDocuments] = useState([]);
  const [msg, setMsg] = useState("");
  const [btnSaveProcess, setBtnSaveProcess] = useState(false);

  const addedMessageStatusSelector = useSelector(getAddedMessageStatus);

  const currentUser = getCurrentUser();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (!addMsgShowHide) {
      setMsg("");
      setDocuments([]);
    }
  }, [addMsgShowHide])

  // const onFileChange = async (event) => {
  //   const docs = [...documents];
  //   for (const file of event.target.files) {
  //     var doc = {
  //       name: file.name,
  //       type: file.type,
  //       file: file,
  //     };
  //     docs.push(doc);
  //   }
  //   setDocuments(docs);
  // };

  const onFileChange = async (event) => {
    const docs = [...documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        size: (file.size / 1024).toFixed(2),
        file: file,
      };
      docs.push(doc);
    }
    setDocuments(docs);
  };

  // const handleFileRemove = (item) => {
  //   const index = this.state.documents.indexOf(item);
  //   let documents = [...this.state.documents];
  //   if (index !== -1) {
  //     documents.splice(index, 1);
  //     this.setState({ documents });
  //   }
  // };
  const handleFileRemove = (item) => {
    const updatedDocuments = documents.filter((doc) => doc !== item);
    setDocuments(updatedDocuments);
  };

  const handleMessageChange = ({ currentTarget }) => {
    setMsg(currentTarget.value);
  };

  const doSubmit = () => {
    setBtnSaveProcess(true);
    const frmData = new FormData();
    frmData.append("workflowTask", JSON.stringify(runningApplication[0]));
    frmData.append("message", msg);
    frmData.append("userId", currentUser.id);
    documents.map((file, key) =>
      frmData.append("files[" + key + "]", file.file)
    );
    dispatch(addAppNewMessage(frmData));
  };

  useEffect(() => {
    if (addedMessageStatusSelector) {
      getAlert("", "Sent Successfully", "success");
      dispatch(appMessageAdded(false));
      setBtnSaveProcess(false);
      handleAddMsgModalShowHide("save");
    }
  }, [addedMessageStatusSelector])
  

  return (
    <Modal show={addMsgShowHide} size="xl">
      <Modal.Header closeButton onHide={() => handleAddMsgModalShowHide("")}>
        <Modal.Title>Add Message</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="row">
          {documents.length > 0 && (
            <div className="file-preview">
              {documents.map((file, index) => (
                <div key={index} className="file-preview-item ml-2">
                  <span>{file.name}</span>
                  <span
                    className="file-size"
                    style={{
                      backgroundColor: "#f0f0f0",
                      marginLeft: "10px",
                      padding: "2px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    {file.size} KB
                  </span>
                  <button
                    className="btn btn-sm btn-link"
                    onClick={() => handleFileRemove(file)}
                  >
                    <i className="fa fa-times text-red" aria-hidden="true"></i>
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="msg" className="form-control-label">
                Message
              </label>
              <textarea
                name="msg"
                id="msg"
                cols="2"
                rows="2"
                className="form-control"
                value={msg}
                onChange={handleMessageChange}
              ></textarea>
            </div>
          </div>
          <div className="col-12 text-center mt-3 d-flex justify-content-center">
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => fileInputRef.current.click()}
            >
              <i className="fa fa-paperclip mr-2" aria-hidden="true"></i>
              Add
            </Button>

            <Button variant="outline-dark" size="sm" onClick={doSubmit}>
              <i className="fa fa-paper-plane-o mr-2" aria-hidden="true"></i>
              Send
            </Button>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={onFileChange}
            multiple
            style={{ display: "none" }}
          />
          {/* <div className="col-md-12">
            <UploadDocument
              documents={documents}
              onFileChange={onFileChange}
              handleFileRemove={handleFileRemove}
            ></UploadDocument>
          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => handleAddMsgModalShowHide("")}
        >
          <i className="fa fa-times" aria-hidden="true"></i> Close
        </button>
        {/* <button
          className="btn btn-sm btn-outline-default"
          disabled={!msg}
          onClick={doSubmit}
        >
          <i className="fa fa-paper-plane" aria-hidden="true"></i> Send
        </button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default AddNewMessage;
