import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ApplicationInstructions from "../../../Components/ApplicationInstructions";

export default function EnrollmentSteps() {

    const [instructionShowHide,setInstructionShowHide]=useState(false);

    const location = useLocation();
    const appType = location?.pathname;

    const handleInstructionShowHide = () => {
        setInstructionShowHide(!instructionShowHide);
    }

    const allPaths=["/Onboarding","/ContractDocs","/AchInfo","/InsuranceInfo","/OnboardingStatus","/DynamicForm"];

    if(allPaths.indexOf(appType) > -1){
        return <React.Fragment>
        <div className="mt-2">
            <a
                style={{
                    cursor: "pointer",
                    color: "blue",
                    fontSize: "13px",
                }}
                onClick={handleInstructionShowHide}
            >
                <i
                    className="fa fa-hand-o-right text-red"
                    aria-hidden="true"
                ></i>{" "}
                Instructions for completing enrollment process
            </a>
        </div>
        <div className="p-3 border mt-1">
            <Link
                to="/Onboarding"
                className={
                    (appType === "/Onboarding" || appType === "/DynamicForm")
                        ? "font-weight-bold"
                        : "text-dark"
                }
            >
                Onboarding Form
                <i
                    className="fa fa-angle-double-right mr-1 ml-1"
                    aria-hidden="true"
                ></i>
            </Link>
            <Link
                to="/ContractDocs"
                className={
                    appType === "/ContractDocs"
                        ? "font-weight-bold"
                        : "text-dark"
                }
            >
                Contract Documents
                <i
                    className="fa fa-angle-double-right mr-1 ml-1"
                    aria-hidden="true"
                ></i>
            </Link>
            <Link
                to="/AchInfo"
                className={
                    appType === "/AchInfo"
                        ? "font-weight-bold"
                        : "text-dark"
                }
            >
                ACH Information
                <i
                    className="fa fa-angle-double-right mr-1 ml-1"
                    aria-hidden="true"
                ></i>
            </Link>
            <Link
                to="/InsuranceInfo"
                className={
                    appType === "/InsuranceInfo"
                        ? "font-weight-bold"
                        : "text-dark"
                }
            >
                Insurance Information
                <i
                    className="fa fa-angle-double-right mr-1 ml-1"
                    aria-hidden="true"
                ></i>
            </Link>

            <Link
                to="/OnboardingStatus"
                className={
                    appType === "/OnboardingStatus"
                        ? "font-weight-bold"
                        : "text-dark"
                }
            >
                Enrollment Status
               
            </Link>
           
        </div>
        {instructionShowHide &&
        <ApplicationInstructions
            instructionShowHide={instructionShowHide}
            handleInstructionShowHide={handleInstructionShowHide}
            entityType={""}
        ></ApplicationInstructions> }
    </React.Fragment>
    }

    return "";
    
}