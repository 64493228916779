import React from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "../Components/Comman/Form";
import Joi from "joi-browser";
import auth from "../Services/authService";
import http from "../Services/httpService";
import config from "../config.json";
import Swal from "sweetalert2";

class ChangeSecurityQuestion extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      securityQuestions1: [],
      securityQuestions2: [],
      securityQuestions3: [],
    };
  }

  async componentDidMount() {
    const { data: securityQuestions } = await http.get(
      config.GetCodemanager + "Security Question 1"
    );
    this.setState({
      securityQuestions1: securityQuestions,
      securityQuestions2: securityQuestions,
      securityQuestions3: securityQuestions,
    });
  }

  schema = {
    oldPassword: Joi.string().required().label("Password"),
    hintQuestion1: Joi.string().required().label("Security Question 1"),
    hintQuestion2: Joi.string().required().label("Security Question 2"),
    hintQuestion3: Joi.string().required().label("Security Question 3"),
    hintAnswer1: Joi.string().required().label("Answer 1"),
    hintAnswer2: Joi.string().required().label("Answer 2"),
    hintAnswer3: Joi.string().required().label("Answer 3"),
  };

  doSubmit = async () => {
    const { data } = this.state;
    const user = auth.getCurrentUser();
    data["id"] = user.id;
    data["newPassword"] = "";
    const { data: result } = await http.post(config.AddSecurityQuestion, data);
    if (result === "error") {
      this.getAlert("Password Incorrect", "", "error");
    } else {
      this.props.handleSecurityShowHide();
      this.getAlert("", "Saved Successfully", "success");
    }
  };

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Close",
      confirmButtonColor: "#d33",
    });
  }

  render() {
    let { securityQuestions1, securityQuestions2, securityQuestions3, data } =
      this.state;
    const { securityShowHide, handleSecurityShowHide } = this.props;

    if (data["hintQuestion1"] !== undefined && data["hintQuestion1"] !== "") {
      securityQuestions2 = securityQuestions2.filter(
        (x) => x.id !== parseInt(data["hintQuestion1"])
      );
      securityQuestions3 = securityQuestions3.filter(
        (x) => x.id !== parseInt(data["hintQuestion1"])
      );
    }
    if (data["hintQuestion2"] !== undefined && data["hintQuestion2"] !== "") {
      securityQuestions1 = securityQuestions1.filter(
        (x) => x.id !== parseInt(data["hintQuestion2"])
      );
      securityQuestions3 = securityQuestions3.filter(
        (x) => x.id !== parseInt(data["hintQuestion2"])
      );
    }
    if (data["hintQuestion3"] !== undefined && data["hintQuestion3"] !== "") {
      securityQuestions1 = securityQuestions1.filter(
        (x) => x.id !== parseInt(data["hintQuestion3"])
      );
      securityQuestions2 = securityQuestions2.filter(
        (x) => x.id !== parseInt(data["hintQuestion3"])
      );
    }
    return (
      <Modal show={securityShowHide} size="lg">
        <Modal.Header>
          <Modal.Title>Change Security Question</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            <div className="col-md-12">
              {this.renderInput("oldPassword", "Password", "password")}
            </div>
            <div className="col-md-12 form-group">
              <p className="text-red">
                <i className="fa fa-hand-o-right" aria-hidden="true"></i> Choose
                your security questions for recovery of your User ID, Username
                and Password
              </p>
            </div>
            <div className="col-md-12">
              {this.renderSelect(
                "hintQuestion1",
                "Security Question 1",
                securityQuestions1,
                "name",
                "id"
              )}
            </div>
            <div className="col-md-12">
              {this.renderInput("hintAnswer1", "Enter Answer 1", "password")}
            </div>
            <div className="col-md-12">
              {this.renderSelect(
                "hintQuestion2",
                "Security Question 2",
                securityQuestions2,
                "name",
                "id"
              )}
            </div>
            <div className="col-md-12">
              {this.renderInput("hintAnswer2", "Enter Answer 2", "password")}
            </div>
            <div className="col-md-12">
              {this.renderSelect(
                "hintQuestion3",
                "Security Question 3",
                securityQuestions3,
                "name",
                "id"
              )}
            </div>
            <div className="col-md-12">
              {this.renderInput("hintAnswer3", "Enter Answer 3", "password")}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleSecurityShowHide}>
            <i className="fa fa-times"></i> Close
          </Button>
          <Button
            variant="primary"
            disabled={this.validate()}
            onClick={this.handleSubmit}
          >
            <i className="fa fa-default"></i> Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ChangeSecurityQuestion;
