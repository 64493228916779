import React, { Component, useEffect, useState } from "react";
import http from "../../Services/httpService";
import config from "../../config.json";
import auth, { getCurrentUser } from "../../Services/authService";
import Moment from "react-moment";
import PageHeader from "../Comman/PageHeader";
import SubcontractorDetails from "./SubcontractorDetails";
import AddPartnerUser from "./AddPartnerUser";
import ActiveDeactiveHistory from "./ActiveDeactiveHistory";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerUsers, loadPartnerUsers } from "../../app/store/partner";
import { getLoggedUserDetails, loadUserDetails, userDetailsUpdated } from "../../app/store/auth";
import { getFormatedNumber } from "../../Services/appService";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";

const ManagePartnerUsers = () => {
  const route = [{ name: "Administration", url: "/" }];

  const [userList, setUserList] = useState([]);
  const [actionType, setActionType] = useState("action");
  const [userDetails, setUserDetails] = useState(null);
  const [logShowHide, setLogShowHide] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [selectedObj, setSelectedObj] = useState("");

  const userDetailsSelector = useSelector(getLoggedUserDetails);
  const partnerUsersSelector = useSelector(getPartnerUsers);


  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setCurrentPageTitle("Manage Users"));
    dispatch(setCurrentRoute(route))
},[])

  useEffect(() => {
    if(!userDetailsSelector)
    dispatch(loadUserDetails(currentUser.id));
  }, []);

  useEffect(() => {
    if (userDetailsSelector && userDetailsSelector?.partnerid) {
      setUserDetails(userDetailsSelector);
      dispatch(loadPartnerUsers(userDetailsSelector.partnerid))
    }
  }, [userDetailsSelector]);

  useEffect(() => {
    setUserList(partnerUsersSelector);
  }, [partnerUsersSelector]);

  const handleModalShowHide = (obj) => {
    if (obj === "save") {
      setShowHide(false);
      setSelectedObj("");
      if (userDetails)
        dispatch(loadPartnerUsers(userDetails.partnerid))
    } else {
      setShowHide(!showHide);
      setSelectedObj(obj);
    }
  };

  const handleLogShowHide = (item,type) => {
    if (item === "save") {
      setLogShowHide(false);
      setSelectedObj("");
      setActionType("action");
      if (userDetails)
        dispatch(loadPartnerUsers(userDetails.partnerid))
    } else {
      setLogShowHide(!logShowHide);
      setSelectedObj(item);
      setActionType(type);
    }
  };


  return (
    <React.Fragment>

      <div className="col-md-12 mt-3">
        <div className="card shadow-none border">
          <div className="card-header p-3">
            <div className="row">
              <div className="col-md-6">
                
                <h3 className="mb-0 mt-1">
                <i class="fa fa-user" aria-hidden="true"></i> Users List
                </h3>
              </div>
              <div className="col-md-6 text-right">
                <button
                  className="btn btn-sm btn-outline-default"
                  onClick={() => handleModalShowHide("")}
                >
                  <i class="fa fa-user-plus" aria-hidden="true"></i> Add User
                </button>
              </div>
            </div>
          </div>
          <div className="card-body p-3">
            <div className="table-responsive">
              <table className="table align-items-center table-flush table-hover table-striped mb-2 border">
                <thead className="thead-light">
                  <tr>
                    <th>ID</th>
                    <th>Date & Time</th>
                    <th>User Details</th>
                    <th>Type</th>
                    <th>State</th>
                    <th>Username</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userList.map((item) => (
                    <tr>
                      <td>{item.id}</td>
                      <td>
                        <Moment format="MMM Do YYYY">
                          {item.addedDateTime}
                        </Moment>
                        <br />
                        <Moment format="h:mm a">
                          {item.addedDateTime}
                        </Moment>
                      </td>
                      <td>
                        {item.name}
                        <br />
                        {item.email}
                        <br />
                        {item.contact && getFormatedNumber(item.contact)}
                      </td>

                      <td>{item.title}</td>
                      <td>
                        {item.state}
                      </td>
                      <td>{item.username}</td>
                      <td>
                        <React.Fragment>
                          {item.status === true && (
                            <button
                              className="btn btn-link btn-sm p-0"
                              onClick={() => handleLogShowHide(item,"action")
                              }
                            >
                              <i
                          className="fa fa-check text-success"
                          style={{ fontSize: "15px" }}
                          aria-hidden="true"
                        ></i>{" "}
                              Activated
                            </button>
                          )}

                          {item.status === false && (
                            <button
                              className="btn btn-link btn-sm p-0"
                              onClick={() => handleLogShowHide(item,"action")
                              }
                            >
                             <i
                          className="fa fa-times text-danger"
                          style={{ fontSize: "15px" }}
                          aria-hidden="true"
                        ></i> Deactivated
                            </button>
                          )}
                          {item.status === null && (
                            <button
                              className="btn btn-link btn-sm p-0"
                              onClick={() => handleLogShowHide(item,"action")
                              }
                            >
                              To Activate
                            </button>
                          )}
                          <br />
                          <button
                            className="btn btn-link btn-sm p-0"
                            onClick={() => handleLogShowHide(item, "log") }
                          >
                            Log History
                          </button>
                        </React.Fragment>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-link btn-sm p-0"
                          title="Edit User"
                          onClick={() => handleModalShowHide(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <AddPartnerUser
        showHide={showHide}
        handleModalShowHide={handleModalShowHide}
        currentUser={currentUser}
        selectedObj={selectedObj}
        userDetails={userDetails}
      ></AddPartnerUser>
      <ActiveDeactiveHistory
        logShowHide={logShowHide}
        handleLogShowHide={handleLogShowHide}
        selectedObj={selectedObj}
        type="performer"
        pageType="User"
        actionType={actionType}
      ></ActiveDeactiveHistory>
    </React.Fragment>
  );
}

export default ManagePartnerUsers;
