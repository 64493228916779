import React, { useEffect, useState } from "react"
import { getFieldData, getFormatedNumber } from "../../Services/appService"
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../store/auth";
import { getFilterAllCandidates, loadCandidatesByFilter } from "../store/candidate";

export default function CandidateInfo() {

    const [candidate, setCandidate] = useState(null);
    const [entityType, setEntityType] = useState("");

    const candidateSelector = useSelector(getFilterAllCandidates);
    const dispatch = useDispatch();
    const currentUser = getCurrentUser();

    useEffect(() => {
        getCandidateDetails(currentUser.id);
    }, []);

    const getCandidateDetails = (id) => {
        const frmBody = new FormData();
        frmBody.append("id", id);
        frmBody.append("name", "");
        frmBody.append("email", "");
        frmBody.append("entity", "");
        frmBody.append("type", "candidate");
        frmBody.append("filterType", "single");
        dispatch(loadCandidatesByFilter(frmBody));
    };

    useEffect(() => {
        if (candidateSelector.length > 0) {
            setCandidate(candidateSelector[0]);
            const entType = getFieldData(candidateSelector[0].performerData, "candidateonboardingform", "CandidateEntity");
            setEntityType(entType);
        }

    }, [candidateSelector]);

    let item = "";
    if(candidate) item=candidate;

    return <React.Fragment>
        <div className="table-responsive border border-top-0">
            <table className="table align-items-center table-flush table-hover table-striped">
                <thead className="thead-light border-top-0">
                    <tr>
                        <th className="border-top-0">Candidate #<br />IPCS ID</th>
                        <th className="border-top-0">Candidate Details</th>
                        <th className="border-top-0">Date & Time<br/>Registered</th>
                        <th className="border-top-0">Entity Type</th>
                        <th className="border-top-0">Subcontractor</th>
                        <th className="border-top-0">Agency/Client<br />MSP Mode</th>
                        <th className="border-top-0">Duration<br />(Start-End)</th>
                    </tr>
                </thead>
                <tbody>
                    {candidate && (
                        <tr>
                            <td>
                                {item.performerid}
                                <br />
                                {item.maxloans}
                            </td>
                            <td>
                               
                                <span className="text-uppercase text-dark">{item.performername} {item.lastName}</span>
                                <br />
                                {item.performeremail}
                                <br />
                                {item.contactNo && getFormatedNumber(item.contactNo)}
                            </td>
                            <td>
                                <Moment format="MMM Do YYYY">{item.addedDateTime}</Moment>
                                <br />
                                <Moment format="h:mm a">{item.addedDateTime}</Moment>
                            </td>
                            <td>
                                {entityType.replace("Basis", "")}
                                <br />
                                {entityType === "1099 Basis" && (
                                    <React.Fragment>
                                        Rate :{" "}
                                        <b>
                                            ${item.rates && item.rates.candidateRate}
                                            /Hr
                                        </b>
                                    </React.Fragment>
                                )}
                                {entityType === "W2 Basis" && (
                                    <React.Fragment>
                                        {getFieldData(
                                            item.performerData,
                                            "candidateonboardingform",
                                            "w9_Type"
                                        )}
                                        <br /> Rate :{" "}
                                        <b>
                                            $
                                            {item.rates &&
                                                parseFloat(item.rates.candidateRate).toFixed(2)}
                                            /Hr
                                        </b>

                                    </React.Fragment>
                                )}
                            </td>
                            <td>
                                {entityType === "C2C Basis" && <React.Fragment>
                                    <span>
                                        {item.subcontractor?.label}
                                    </span>
                                    <br />
                                    ID : <b>{item.validId}</b>
                                    <br />
                                    {item.subcontractor?.email}
                                </React.Fragment>}
                            </td>


                            <td>
                                <span>
                                    {getFieldData(
                                        item.performerData,
                                        "projectengagement",
                                        "AgencyName"
                                    )}
                                </span>
                                <br />
                                <span>
                                    {getFieldData(
                                        item.performerData,
                                        "candidateonboardingform",
                                        "Msp_Mode"
                                    )}
                                </span>
                            </td>
                            <td>
                                {item.duration && (
                                    <React.Fragment>
                                        Duration : {item.duration.duration} Month <br />
                                        Start :{" "}
                                        <Moment format="MMM Do YYYY">
                                            {item.duration.startDate}
                                        </Moment>
                                        <br />
                                        End :{" "}
                                       {item.duration.endDate &&  <Moment format="MMM Do YYYY">
                                            {item.duration.endDate}
                                        </Moment>}
                                    </React.Fragment>
                                )}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </React.Fragment>
}