import React, { Component, useEffect, useState } from "react";
import PageHeader from "../Comman/PageHeader";
import ChangePassword from "../ChangePassword";
import MyProfileDetails from "./MyProfileDetails";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateUserDetails, getCurrentUser, getLoggedUserDetails,
  getPicUpdatedStatus,
  getUserProfilePic,
  getUserUpdateStatus, loadUserDetails,
  loadUserProfilePic,
  updateUserProfilePic,
  userDetailsUpdated
} from "../../app/store/auth";
import { getAlert } from "../../Services/appAlerts";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

export default function Profile(props) {
  const { profileShowHide, handleProfileShowHide } = props;
  const [profileEdit, setProfileEdit] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [userDetails, setUserDetails] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [profilePicObj, setProfilePicObj] = useState(null);
  const [profilePicData, setProfilePicData] = useState(null);

  const userDetailsSelector = useSelector(getLoggedUserDetails);
  const updateStatusSelector = useSelector(getUserUpdateStatus);
  const picUpdatedStatusSelector = useSelector(getPicUpdatedStatus);
  const userProfilePicSelector = useSelector(getUserProfilePic);

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (!userDetailsSelector)
      dispatch(loadUserDetails(currentUser.id));
    if (!userProfilePicSelector)
      dispatch(loadUserProfilePic(currentUser.id));
  }, []);

  useEffect(() => {
    setUserDetails(userDetailsSelector);
  }, [userDetailsSelector]);

  useEffect(() => {
    if (userProfilePicSelector) {
      //let objectURL = 'data:image/jpeg;base64,' + userProfilePicSelector;
      setProfilePicData(userProfilePicSelector);
    }
  }, [userProfilePicSelector])

  useEffect(() => {
    if (updateStatusSelector === "success" && currentUser?.id) {
      //dispatch(loadUserDetails(currentUser.id));
      getAlert("", "Updated Successfully", "success");
    } else if (updateStatusSelector) {
      getAlert("", "Update Failed", "error");
    }
    return () => {
      dispatch(userDetailsUpdated(null));
    };
  }, [updateStatusSelector])

  const handleModalShowHide = () => {
    setShowHide(!showHide);
  };

  const handleSubmit = (profile) => {
    dispatch(UpdateUserDetails(profile));
    if (profilePicObj)
      handleUpdatePic();
  };

  const handleUpdatePic = () => {
    const frmData = new FormData();
    frmData.append("userId", currentUser.id);
    frmData.append("files[0]", profilePicObj.file);
    dispatch(updateUserProfilePic(frmData));
  }

  useEffect(() => {
    if (picUpdatedStatusSelector) {
      dispatch(loadUserProfilePic(currentUser.id));
    }
  }, [picUpdatedStatusSelector])

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setProfilePicObj({
        file: file,
        fileName: file.name,
        imagePreviewUrl: reader.result,
      })
    };

    reader.readAsDataURL(file);
  };

  const handleEditProfile = () => {
    setProfileEdit(!profileEdit);
  };

  let $imagePreview = null;
  if (profilePicObj) {
    $imagePreview = <img src={profilePicObj.imagePreviewUrl}
      alt="profile" className="img-thumbnail rounded-circle profilePrev" />
  } else {
    $imagePreview = <img
      alt="profile" className="img-thumbnail rounded-circle profilePrev"
      src={`${!profilePicData ? "../../assets/img/theme/team-4.png" : process.env.REACT_APP_API_BASEURL + "/ProfilePics/" + profilePicData}`}
    />
  }

  return (
    <React.Fragment>
      <Modal show={profileShowHide} size="xl">
        <Modal.Header closeButton onHide={handleProfileShowHide}>
          <Modal.Title>My Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border pb-0">
          <div className="row">
            <div className="col-md-12 text-right">
              {!profileEdit &&
                <button
                  className="btn btn-sm btn-outline-default"
                  onClick={handleEditProfile}
                >
                  <i className="fa fa-edit" aria-hidden="true"></i> Edit
                </button>}
            </div>
            <div className="col-md-2 d-flex justify-content-center">
              {$imagePreview}
              <br />
              <div>
                <label className="profileBtn">
                  <input
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    disabled={!profileEdit}
                  />
                  <i class="fa fa-camera" aria-hidden="true"></i>
                </label>
              </div>
            </div>

            <div className="col-md-10">
              <MyProfileDetails
                userDetails={userDetails}
                handleFormSubmit={handleSubmit}
                profileEdit={profileEdit}
                setProfileEdit={setProfileEdit}
                handleEditProfile={handleEditProfile}
              ></MyProfileDetails>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={handleProfileShowHide}>
            <i class="fa fa-times" aria-hidden="true"></i> Close
          </button>
        </Modal.Footer>
      </Modal>

    </React.Fragment>
  );
}
