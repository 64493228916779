import React from "react";
import Form from "../Comman/Form";
import ContactAdmin from "./ContactAdmin";
import ForgotUserName from "./ForgotUserName";
import http from "../../Services/httpService";
import config from "../../config.json";
import ClientHeader from "../Comman/ClientHeader";
import LoginHeader from "./LoginHeader";
import LoginFooter from "./LoginFooter";
import { Link } from "react-router-dom";

class ForgotValidID extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      forgotUser: false,
      forgotMain: true,
      thankYou: false,
      contactAdmin: false,
      clientDetails: "",
    };
  }

  async componentDidMount() {
    // const { data: clientDetails } = await http.get(config.GetClientDetails);
    // this.setState({ clientDetails });
  }

  handleUserName = () => {
    this.setState({ forgotUser: true, forgotMain: false });
  };

  handleBackUser = () => {
    this.setState({ forgotMain: true, forgotUser: false });
  };

  handleContactAdmin = () => {
    this.setState({ contactAdmin: true, forgotMain: false });
  };

  handleContactBack = () => {
    this.setState({ forgotMain: true, contactAdmin: false });
  };

  render() {
    const { forgotMain, thankYou, contactAdmin, forgotUser, clientDetails } =
      this.state;
    return (
      <React.Fragment>
        <div className="col-md-12">
          <div className="row align-items-center" style={{ height: "calc(100vh - 278px)" }}>
            <div className="col-lg-3"></div>
            <div className="col-lg-6">
              <div
                className="mrnone card shadow-none border"
                style={{ backgroundColor: "#fafafa", marginTop: "0px" }}
              >
                <h2 className="text-black text-uppercase mt-1 mb-0 text-center">
                  Forgot Valid ID?
                </h2>
                <div className="card-body p-3">
                  {forgotMain && (
                    <div className="row p-4">
                      <div className="form-group col-md-12">
                        <Link to="/Login" className="btn btn-link pl-0">
                          <i
                            className="fa fa-long-arrow-left"
                            aria-hidden="true"
                          ></i>
                          Back
                        </Link>
                      </div>
                      <div className="col-md-12 form-group">
                        <button
                          className="btn btn-danger w-100"
                          onClick={this.handleUserName}
                        >
                          I Forgot My Valid ID
                        </button>
                      </div>
                      {/* <div className="col-md-12 text-center">
                        <p>
                          <b>If you are having any other issues logging in</b>
                        </p>
                      </div>
                      <div className="col-md-12 text-center">
                        <button
                          className="btn btn-link btn-ms mb--4"
                          onClick={this.handleContactAdmin}
                        >
                          Contact WorkFINITY® Administrator
                        </button>
                      </div> */}
                    </div>
                  )}

                  {forgotUser && (
                    <ForgotUserName
                      handleBackUser={this.handleBackUser}
                      forgotType="Valid ID"
                    ></ForgotUserName>
                  )}

                  {/* {contactAdmin && (
                    <ContactAdmin
                      handleContactBack={this.handleContactBack}
                    ></ContactAdmin>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 mt-3">
              <div
                className="card shadow-none border"
                style={{ backgroundColor: "#fafafa" }}
              >
                <div className="card-body text-center p-2">
                  <p>
                    Customer Support :{" "}
                    <a href="mailto:technicalsupport@workfinity.com">
                      Support1@workfinity.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotValidID;
