import React, { Component, useEffect, useState } from "react";
import _ from "lodash";
import Moment from "react-moment";
import "jspdf-autotable";
import { useLocation, useNavigate } from "react-router-dom";
import countryISDCodes from "../Services/countryCodes.json";
import { useDispatch, useSelector } from "react-redux";
import { getAppSummary, getAppUpdates, loadAppSummary, loadAppUpdates } from "../app/store/application";
import WorkflowElement from "../app/components/WorkflowElement";
import PageHeader from "./Comman/PageHeader";

export default function ApplicationSummary() {

  const route = [{ name: "Task Management", url: "" }];
  const route1 = [{ name: "Contract Management", url: "" }];

  //const [route, setRoute] = useState([]);
  const [appSummary, setAppSummary] = useState("");
  const [wfTask, setWfTask] = useState(null);
  const [updatedStateData, setUpdatedStateData] = useState("");
  const [currentSequence, setCurrentSequence] = useState(1);
  const [decryptShowHide, setDecryptShowHide] = useState(false);
  const [codeIsVerified, setCodeIsVerified] = useState(true);
  const [viewType, setViewType] = useState("View");
  const [appUpdates, setAppUpdates] = useState([]);
  const [countryCodes, setCountryCodes] = useState(countryISDCodes)

  const appSummarySelector = useSelector(getAppSummary);
  const appUpdateSelector = useSelector(getAppUpdates);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate=useNavigate();

  useEffect(() => {
    const state = location.state;
    if (state?.task) {
      dispatch(loadAppSummary(state.task.ciid));
      dispatch(loadAppUpdates(state.task.ciid));
      //setWfTask({ formId: 0, ciid: state.task.ciid });
    }

    if (state?.viewType === "edit")
      setViewType("Edit");

  }, []);

  useEffect(() => {
    if (appSummarySelector !== "") {
      setAppSummary(appSummarySelector);
      const state = location.state;
      let task ={ formId: 0, ciid: state.task.ciid };
      const frm = appSummarySelector.applicationForms.filter(x => x.formSequence === currentSequence);
      if (frm.length > 0) {
        task.formId = frm[0].formId;
        setWfTask(task);
      }

      if (appSummarySelector?.applicationData)
        setUpdatedStateData(appSummarySelector.applicationData);
    }

    if (appUpdateSelector.length > 0) {
      setAppUpdates(appUpdateSelector);
      const lastUpdated = appUpdateSelector[appUpdateSelector.length - 1];
      setUpdatedStateData(lastUpdated.stateData);
    }
  }, [appSummarySelector, appUpdateSelector]);

  const handleNextTask = () => {
    dispatch(loadAppUpdates(wfTask.ciid))
  };

  const handleChangeForms = () => {

  }

  const handleNextForm = () => {
    const { currentSequence, appSummary } = this.state;
    if (
      currentSequence >= 1 &&
      currentSequence < appSummary.applicationForms.length
    ) {
      const nextForm = currentSequence + 1;
      const currentForm = appSummary.applicationForms[nextForm];
      //this.loadCMDropdownSubform(currentForm);
      //this.setState({ currentSequence: nextForm });
    }
  };

  const handlePreviousForm = () => {
    const { currentSequence, appSummary } = this.state;
    if (
      currentSequence > 1 &&
      currentSequence <= appSummary.applicationForms.length
    ) {
      const previousForm = currentSequence - 1;
      const currentForm = appSummary.applicationForms[previousForm];
      //this.loadCMDropdownSubform(currentForm);
      //this.setState({ currentSequence: previousForm });
    }
  };


  const handleUpdateView = (item) => {
    setUpdatedStateData(item.stateData);
  };

  const handleViewSubmitData = () => {
    if(appSummary?.applicationData) setUpdatedStateData(appSummary.applicationData);
  };

  const usrType = localStorage.getItem("userType");
  let pgTitle = " Enrollment Form";
  if (usrType === "candidate")
    pgTitle = " Onboarding Form";

  return (
    <>
      <div className="col-md-12 mt-3">
       
        <div className="card shadow-none border">
          <div className="card-body p-3">
            <div className="row">
              <div className="col-md-6">
              </div>
              <div className="col-md-6 text-right mb-2">
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={() => navigate(-1)}
                >
                  <i className="fa fa-times" aria-hidden="true"></i> Close
                </button>
              </div>
            </div>
            {wfTask?.formId > 0 && <WorkflowElement
              wfTask={wfTask}
              handleNextTask={handleNextTask}
              viewType={viewType === "View" ? true : false}
              appSummary={true}
              codeVerified={codeIsVerified}
              updatedStateData={updatedStateData}
            />
            }
          </div>
        </div>
      </div>
      
    </>
  );
}
