import React, { Component } from "react";
import Moment from "react-moment";
import PageHeader from "../Comman/PageHeader";
import http from "../../Services/httpService";
import auth from "../../Services/authService";
import config from "../../config.json";
import ChecklistItem from "../Comman/ChecklistItem";
import UploadDocPopup from "../UploadDocPopup";

class RecvedDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: [
        { url: "/", name: "Home" },
        { url: "/", name: "Documents" },
      ],
      sentDocs: [],
      appTasklist: [],
      uploadDocShowHide: false,
      runningApplication: [],
    };
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    const { data: appliedApplication } = await http.get(
      config.GetPerformerApplication + user.id
    );

    if (appliedApplication.length > 0) {
      const filterApp = appliedApplication.filter((x) => x.workflowType === 1);
      let appTasklist = [];
      filterApp.map(async (item) => {
        const { data: receivedDocuments } = await http.get(
          config.GetReceivedDocuments + user.id + "&chkId=" + item.oldWorkflowId
        );
        //console.log(receivedDocuments);
        const sentDocs =
          receivedDocuments &&
          receivedDocuments.filter(
            (x) =>
              x.type === "send" &&
              x.docType !== "internal" &&
              x.uploadType !== "Invoice"
          );

        const obj = {
          chkApp: item,
          sentDocs,
        };
        appTasklist.push(obj);
        //console.log(appTasklist);
        this.setState({ appTasklist });
      });
    }
  }

  handleDownloadFile = async (item) => {
    try {
      const { data } = await http.get(config.DownloadDocument + item.id, {
        responseType: "blob",
      });
      if (data.size > 0) {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", item.documentName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }
    } catch (error) {
      console.log(error);
    }

    // const { data } = await http.get(config.DownloadDocument + item.docId);
    // if (data !== "Fail") {
    //   this.download(item.docName.trim(), data);
    // }
  };

  download(filename, text) {
    var element = document.createElement("a");
    element.setAttribute("href", text);
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  getExtension(filename) {
    return filename.split(".").pop().toLowerCase();
  }

  handleUploadDocModalShowHide = (docs) => {
    if (docs === "save") {
      this.loadDocuments();
    }
    if (docs !== undefined && docs !== "save") {
      this.setState({
        uploadDocShowHide: !this.state.uploadDocShowHide,
        runningApplication: docs,
      });
    } else {
      this.setState({
        uploadDocShowHide: !this.state.uploadDocShowHide,
        runningApplication: [],
      });
    }
  };

  render() {
    const { route, appTasklist, uploadDocShowHide, runningApplication } =
      this.state;
    return (
      <React.Fragment>
        <PageHeader
          route={route}
          parentTitle="Additional Documents"
          headerTitle="Additional Documents"
        ></PageHeader>
        <div className="col-md-12">
          <ul className="list-group mt-3">
            {appTasklist.map((app) => (
              <React.Fragment>
                {/* <ChecklistItem
                      Checklist={app.chkApp}
                      color="#fce7a9"
                      type="Documents"
                    ></ChecklistItem> */}
                <li className="list-group-item">
                  <div className="text-right">
                    <button
                      className="btn btn-sm btn-default mb-2"
                      onClick={() =>
                        this.handleUploadDocModalShowHide(
                          app.runningApplication
                        )
                      }
                    >
                      Upload Documents
                    </button>
                  </div>
                  <div className="table-responsive">
                    <table className="table align-items-center table-flush table-hover table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>S.No.</th>
                          <th>Date and Time</th>
                          <th>Document</th>
                          {/* <th>Size</th>
                          <th>Download</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {app.sentDocs.map((item, key) => (
                          <tr>
                            <td>{key + 1}</td>
                            <td>
                              <Moment format="MMM Do YYYY, h:mm a">
                                {item.createdDateTime}
                              </Moment>
                            </td>
                            <td>
                              <button
                                className="btn btn-sm btn-link pl-0"
                                title="Download Document"
                                onClick={() => this.handleDownloadFile(item)}
                              >
                                {item.documentName}
                              </button>
                            </td>
                            {/* <td>{item.docSize}</td>
                            <td>
                              
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
        <UploadDocPopup
          uploadDocShowHide={uploadDocShowHide}
          handleUploadDocModalShowHide={this.handleUploadDocModalShowHide}
          runningApplication={runningApplication}
        ></UploadDocPopup>
      </React.Fragment>
    );
  }
}

export default RecvedDocs;
