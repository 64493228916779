import React, { Component } from "react";
import auth from "../Services/authService";
import InfoBox from "./Comman/InfoBox";

class DashboardPageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      application: 50,
      users: 53,
      documents: 63,
      wPhaseclosed: 36,
      wPhaseApplication: 32,
      wPhaseReview: 56,
      wPhaseApproved: 96,
      wPhasePending: 65,
      wPhaseWithdrawn: 69,
      latestApplication: [],
      user: {},
      showHide: false,
      userApp: {},
    };
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    let userId = user.id;

    if (user.type === "partner") {
      userId = user.linkUser;
    }
    const userDetail = await auth.getCurrentUserDetails(userId);
    this.setState({ user, userDetail });
  }

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  getNumberUnit = (labelValue) => {
    const sign = Math.sign(Number(labelValue));
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? sign * (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? sign * (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? sign * (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
      : Math.abs(Number(labelValue));
  };

  render() {
    const { userDetail } = this.state;
    const { application: latestApplication, dashboardData } = this.props;
    let usrType = localStorage.getItem("userType");
    if (usrType === "PP") {
      usrType = userDetail?.partnerType;
      if (userDetail?.partnerType === "Sub-Contractors")
        usrType = "Subcontractor";
    }
    usrType = usrType?.toLocaleLowerCase();
    return (
      <div
        className="card card-body shadow-none mb-0 pb-0 rounded-0 mt--3"
        style={{ backgroundColor: "#f0f0f0" }}
      >
        <div className="row">
          <InfoBox
            name="Candidates - Enrolled"
            count={dashboardData.allCandidate}
          ></InfoBox>
          <InfoBox
            name="Candidates - Activated/Billable"
            count={dashboardData.activeCandidate}
          ></InfoBox>
          <InfoBox
            name="Candidate - Deactivated"
            count={dashboardData.inActiveCandidate}
          ></InfoBox>
          {usrType === "subcontractor" && (
            <InfoBox
              name="Users"
              count={dashboardData.subcontractorUsers}
            ></InfoBox>
          )}
          {usrType === "recruiter" && (
            <InfoBox
              name="Users"
              count={dashboardData.recruiterUsers}
            ></InfoBox>
          )}
        </div>
      </div>
    );
  }
}

export default DashboardPageHeader;
