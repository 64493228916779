import React, { Component, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Moment from "react-moment";
import config from "../../config.json";
import http from "../../Services/httpService";
import PrintPurchaseOrder from "./PrintPurchaseOrder";
import auth from "../../Services/authService";
import { useDispatch, useSelector } from "react-redux";
import { getPartCandidatePoOrders, loadPartCandidatePoOrders } from "../../app/store/partner";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../../app/store/auth";
import { getPoOrderDocumentData, loadPoOrderdownloadDocument, poOrderDownloadDataRecevied } from "../../app/store/documents";

const PurchaseOrders = (props) => {

  const { handlePoShowHide, poShowHide, candidateObj,handleRefreshPartnerCandidates } = props;

  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [poAction, setPoAction] = useState("");
  const [genShowHide, setGenShowHide] = useState(false);
  const [selectedPoOrder, setSelectedPoOrder] = useState(null);
  const [usrType, setUsrType] = useState("");

  const loggedUserDetailsSelector = useSelector(getLoggedUserDetails)
  const partCandidatePoOrdersSelector = useSelector(getPartCandidatePoOrders);
  const poOrderDocumentDataSelector = useSelector(getPoOrderDocumentData);

  let userType = localStorage.getItem("userType");
  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    if (userType === "PP") {
      if (!loggedUserDetailsSelector)
        dispatch(loadUserDetails(currentUser.id))
    }
    else {
      setUsrType(userType);
    }
  }, [])

  useEffect(() => {
    if (candidateObj?.performerid > 0 && usrType !== "")
      dispatch(loadPartCandidatePoOrders(candidateObj.performerid, usrType));
  }, [candidateObj]);

  useEffect(() => {
    if (loggedUserDetailsSelector && candidateObj?.id) {
      let uType = loggedUserDetailsSelector.partnerType;
      if (loggedUserDetailsSelector.partnerType === "Sub-Contractors")
        uType = "subcontractor";

      setUsrType(uType);
    }
  }, [loggedUserDetailsSelector])

  useEffect(() => {
    setPurchaseOrders(partCandidatePoOrdersSelector);
  }, [partCandidatePoOrdersSelector]);

  const handleGenShowHide = (obj, action) => {
    if (obj === "save") {
      setGenShowHide(false);
      setPoAction("");
      setSelectedPoOrder("");
      if (candidateObj?.performerid > 0 && usrType !== ""){
        dispatch(loadPartCandidatePoOrders(candidateObj.performerid, usrType));
        handleRefreshPartnerCandidates()
      }
    } else {
      setGenShowHide(!genShowHide);
      setPoAction(action);
      setSelectedPoOrder(obj);
    }
  };

  const handleDownloadFile = (item, type) => {
    dispatch(loadPoOrderdownloadDocument(item.id, type));
  };

  useEffect(() => {
    if (poOrderDocumentDataSelector?.size > 0) {
      const url = window.URL.createObjectURL(poOrderDocumentDataSelector);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      dispatch(poOrderDownloadDataRecevied(null))
    }
  }, [poOrderDocumentDataSelector])


  return (
    <React.Fragment>
      <Modal show={poShowHide} size="xl">
        <Modal.Header closeButton onHide={() => handlePoShowHide("")}>
          <Modal.Title>Purchase Orders</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table align-items-center table-flush table-hover table-striped border">
                  <thead className="thead-light">
                    <tr>
                      <th>S.No.</th>
                      <th>Date & Time</th>
                      <th>Sent By Ipcs</th>
                      <th>Signed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purchaseOrders.map((item, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>
                          <Moment format="MMM Do YYYY, h:mm a">
                            {item.addedDateTime}
                          </Moment>
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-link p-0"
                            onClick={() => handleDownloadFile(item, "unSign")}
                          >
                            Download/View
                          </button>
                        </td>
                        <td>
                          {item.partnerSign === null && (
                            <button
                              className="btn btn-sm btn-link p-0"
                              onClick={() => handleGenShowHide(item, "generate")}
                            >
                              Pending
                            </button>
                          )}
                          {item.partnerSign && (
                            <button
                              className="btn btn-sm btn-link p-0"
                              onClick={() => handleDownloadFile(item, "signed")}
                            >
                              Download/View
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handlePoShowHide("")}>
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </button>
        </Modal.Footer>
      </Modal>
      {genShowHide && candidateObj?.performerid > 0 && (
        <PrintPurchaseOrder
          genShowHide={genShowHide}
          handleGenShowHide={handleGenShowHide}
          candidateObj={candidateObj}
          poOrder={selectedPoOrder}
          poAction={poAction}
        ></PrintPurchaseOrder>
      )}
    </React.Fragment>
  );
}

export default PurchaseOrders;
