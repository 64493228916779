import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

class Progress extends Component {
  renderTime = ({ remainingTime }) => {
    const { processId, formProcess } = this.props;
    const processItems = formProcess.filter(
      (x) => x.formProcessId === parseInt(processId)
    );
    //console.log(processItems);
    if (processItems.length > 0) {
      //console.log(remainingTime);
      let index = Math.round(remainingTime / 2) - 1;
      if (index === formProcess.length && formProcess.length < 3)
        index = index - 1;
      //console.log(processItems[index]);
      // console.log(index);
      return (
        <div className="timer">
          {processItems[index] === undefined
            ? processItems[0].processItem
            : processItems[index].processItem}
        </div>
      );
    } else {
      if (remainingTime === 5 || remainingTime == 6) {
        return <div className="timer">Saving Data...</div>;
      }
      if (remainingTime === 3 || remainingTime == 4) {
        return <div className="timer">Submitting...</div>;
      }
      if (remainingTime === 1 || remainingTime == 2 || remainingTime === 0) {
        return <div className="timer">Processing...</div>;
      }
    }
  };

  render() {
    const { showHide, formProcess } = this.props;
    return (
      <Modal show={showHide} size={"xs"} centered>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="timer-wrapper">
                {formProcess !== undefined && formProcess.length > 0 ? (
                  <CountdownCircleTimer
                    isPlaying
                    duration={
                      formProcess.length > 3 ? formProcess.length * 2 : 6
                    }
                    colors={[
                      ["#A30000", 0.33],
                      ["#F7B801", 0.33],
                      ["#218380", 0.33],
                    ]}
                    onComplete={() => [false, 1000]}
                  >
                    {this.renderTime}
                  </CountdownCircleTimer>
                ) : (
                  <CountdownCircleTimer
                    isPlaying
                    duration={6}
                    colors={[
                      ["#A30000", 0.33],
                      ["#F7B801", 0.33],
                      ["#218380", 0.33],
                    ]}
                    onComplete={() => [false, 1000]}
                  >
                    {this.renderTime}
                  </CountdownCircleTimer>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default Progress;
