import React, { Component, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Moment from "react-moment";
import http from "../Services/httpService";
import config from "../config.json";
import { getAlert } from "../Services/appAlerts";
import { useDispatch, useSelector } from "react-redux";
import { acceptSheduleMeeting, getMeetingAcceptStatus, sendSheduleMeetingLinks } from "../app/store/candidate";

const MeetingOptions = (props) => {
  const { meetingShowHide, handleMeetingModalShowHide, meeting } = props;
  const [selectedOption, setSelectedOption] = useState("");

  const meetingAcceptStatusSelector = useSelector(getMeetingAcceptStatus);

  const dispatch = useDispatch();

  const handleMeetingAccept = (opt) => {
    setSelectedOption(opt)
  };

  const doSubmit = () => {
    dispatch(acceptSheduleMeeting(selectedOption));
  };

  useEffect(() => {
    if (meetingAcceptStatusSelector) {
      getAlert("", "Accepted Successfully", "success");
      if (selectedOption)
        dispatch(sendSheduleMeetingLinks(selectedOption.optionId))

      handleMeetingModalShowHide("save");
    }
  }, [meetingAcceptStatusSelector])


  return (
    <Modal show={meetingShowHide} size="xl">
      <Modal.Header closeButton onHide={handleMeetingModalShowHide}>
        <Modal.Title>Schedule Appointment Options</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-striped align-items-center table-flush border">
                <thead className="thead-light">
                  <tr>
                    <th>Select</th>
                    <th>Date & Time</th>
                  </tr>
                </thead>
                <tbody>
                  {meeting?.options?.map((o, key) => (
                    <tr>
                      <td>
                        <div className="custom-control custom-radio mb-3">
                          <input
                            name="option"
                            className="custom-control-input"
                            id={"option" + key}
                            type="radio"
                            onChange={() => handleMeetingAccept(o)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={"option" + key}
                          ></label>
                        </div>
                      </td>
                      <td>
                        <Moment format="MMM Do YYYY">
                          {o.meetingDate}
                        </Moment>{" "}
                        {o.startTime} - {o.endTime}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={handleMeetingModalShowHide}>
          <i className="fa fa-times" aria-hidden="true"></i> Close
        </button>
        <button className="btn btn-sm btn-outline-default"
          disabled={!selectedOption} onClick={doSubmit}>
          <i className="fa fa-check" aria-hidden="true"></i> Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default MeetingOptions;
