import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAlert } from "../../Services/appAlerts";
import WorkflowElement from "../../app/components/WorkflowElement";
import { addUpdateBprmFormHistory, formUpdateHistoryAdded,
   getFormUpdateHistoryStatus } from "../../app/store/bprmForm";


const ViewForm = (props) => {
  const { workflowTask, selectedForm, handleFormShowHide,
    handleFormUpdate, allowEdit } = props;
    
  const [wfTask, setWfTask] = useState(null);
  const [saveBtnProcessStatus, setSaveBtnProcessStatus] = useState(true);
  const [updatedStateData, setUpdatedStateData] = useState(null);

  const formUpdateHistoryStatusSelector = useSelector(getFormUpdateHistoryStatus)

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedForm?.id && workflowTask?.ciid)
      setWfTask({ formId: selectedForm.formId, ciid: workflowTask.ciid });
  }, [selectedForm]);

  const handleNextTask = (frmData) => {
    setSaveBtnProcessStatus(true);
    frmData.append("formLibrary", JSON.stringify(selectedForm));
    dispatch(addUpdateBprmFormHistory(frmData));
  }

  useEffect(() => {
    if (formUpdateHistoryStatusSelector) {
      getAlert("", "Updated Successfully", "success");
      setSaveBtnProcessStatus(false);
      if (handleFormShowHide) handleFormShowHide("save");
      if(handleFormUpdate) handleFormUpdate();
      dispatch(formUpdateHistoryAdded(false));
    }
  }, [formUpdateHistoryStatusSelector]);

  return (
    <div>
      {wfTask?.formId > 0 && <WorkflowElement
        wfTask={wfTask}
        handleNextTask={handleNextTask}
        viewType={!allowEdit}
        appSummary={false}
        codeVerified={true}
        wfTaskType={"viewForm"}
        saveBtnProcessStatus={saveBtnProcessStatus}
        setSaveBtnProcessStatus={setSaveBtnProcessStatus}
        updatedStateData={updatedStateData}
      />}
    </div>
  );
}

export default ViewForm;
