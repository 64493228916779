import React from "react";
import { Modal } from "react-bootstrap";
import ViewForm from "../../Components/ApplicantProcessing/ViewForm";

const ViewFormModal = (props) => {
    const { handleViewForm, viewFormShowHide, 
        workflowTask, selectedForm,handleFormUpdate,allowEdit } = props;
    return (
        <Modal show={viewFormShowHide} size="xl">
            <Modal.Header closeButton onHide={handleViewForm}>
                <Modal.Title>View Form</Modal.Title>
            </Modal.Header>
            <Modal.Body className="border">
                <div className="row" style={{ height: "500px", overflow: "auto" }}>
                    <div className="col-md-12">
                    <ViewForm
                        formShowHide={viewFormShowHide}
                        handleFormShowHide={handleViewForm}
                        workflowTask={workflowTask}
                        selectedForm={selectedForm}
                        handleFormUpdate={handleFormUpdate}
                        allowEdit={allowEdit}
                    ></ViewForm>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={handleViewForm}
                >
                   <i className="fa fa-times" aria-hidden="true"></i> Close
                </button>
            </Modal.Footer>
        </Modal>
    );

}

export default ViewFormModal;