import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import ClientHeader from "../../../Components/Comman/ClientHeader";
import PageHeader from "../../../Components/Comman/PageHeader";
import { useSelector } from "react-redux";
import { getPageRoute, getPageTitle } from "../../store/layoutUI";
import Menu from "../../../Components/Navigation/Menu";
import Menu2 from "../../components/Header/Menu";
import CandidateInfo from "../../components/CandidateInfo";
import PartnerInfo from "../../components/PartnerInfo";
import OnboardingSteps from "../../components/Onboarding/OnboardingSteps";
import EnrollmentSteps from "../../components/Onboarding/EnrollmentSteps";
import TopMenu from "../../components/TopMenu";

const Layout = () => {

    const usrType = localStorage.getItem("userType");
    const [scriptLoadingState, setScriptLoadingState] = useState('IDLE');

    const [route, setRoute] = useState([]);
    const [pageTitle, setPageTitle] = useState("");

    const pageTitleSelector = useSelector(getPageTitle);
    const pageRouteSelector = useSelector(getPageRoute);

    useEffect(() => {
        setPageTitle(pageTitleSelector);
    }, [pageTitleSelector]);

    useEffect(() => {
        setRoute(pageRouteSelector);
    }, [pageRouteSelector]);


    useEffect(() => {
        var script = document.createElement('script');
        script.async = true;
        script.type = 'text/javascript';
        script.src = '/assets/js/argon.js';
        script.onload = function () {
            setScriptLoadingState('LOADED');
        };
        script.onerror = function () {
            setScriptLoadingState('FAILED');
        };
        document.body.appendChild(script);
    }, []);

    return <>
        <div className="col-md-12">
            <TopMenu />
        </div>
            <Menu />

        <div className="main-content h-100" id="panel">
            <div
                style={{
                    height: "79vh", 
                    overflowY: "auto", 
                    paddingRight: "15px", 
                }}
            >

                <div className="col-md-12">
                    {usrType === "candidate" ? <CandidateInfo /> : <PartnerInfo />}
                </div>
                <div className="col-md-12">
                    {usrType === "candidate" ? <OnboardingSteps /> : <EnrollmentSteps />}
                </div>

                <Outlet />

                <br />
                <br />
                <br />
            </div>
        </div>
    </>

}


export default Layout;
