import React, { useEffect, useState } from "react"
import PageHeader from "../../Components/Comman/PageHeader"
import Moment from "react-moment";
import { getFieldData, getFormatedNumber } from "../../Services/appService";
import { useNavigate } from "react-router-dom";
import PurchaseOrders from "../../Components/ApplicantProcessing/PurchaseOrders";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../store/auth";
import { getPartnerAllCandidate, loadPartnerAllCandidate } from "../store/partner";
import PartnerInfo from "./PartnerInfo";
import { setCurrentPageTitle, setCurrentRoute } from "../store/layoutUI";
import { getComponentUserStatus, getToolsCount, UpdateComponentsUserStatus } from "../store/tools";

export default function PartnerCandidates() {

    const route = [{ name: "Contract Management", url: "" }];

    const [candidateList, setCandidateList] = useState([]);
    const [candidateObj, setCandidateObj] = useState(null);
    const [poShowHide, setPoShowHide] = useState(false);
    const [userDetails, setUserDetails] = useState(false);

    const userDetailsSelector = useSelector(getLoggedUserDetails);
    const partnerAllCandidateSelector = useSelector(getPartnerAllCandidate);
    const componentUserStatusSelector = useSelector(getComponentUserStatus);
    const toolsCountSelector = useSelector(getToolsCount);

    const navigate = useNavigate();
    const currentUser = getCurrentUser();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCurrentPageTitle("List of Candidates"));
        dispatch(setCurrentRoute(route))
    }, [])

    useEffect(() => {
        if (!userDetailsSelector)
            dispatch(loadUserDetails(currentUser.id));
    }, []);

    useEffect(() => {
        if (userDetailsSelector && userDetailsSelector?.partnerid) {
            setUserDetails(userDetailsSelector);
            dispatch(loadPartnerAllCandidate(userDetailsSelector.partnerid, 0))
        }
    }, [userDetailsSelector]);

    useEffect(() => {
        if (partnerAllCandidateSelector.length > 0) {
            setCandidateList(partnerAllCandidateSelector);
            let candItems = "";
            partnerAllCandidateSelector.map((x, key) => {
                if (key > 0) candItems += ",";
                candItems += x.performerid;
            });

            const appObj = toolsCountSelector.filter(x => x.key === 0);
            let appCiid = 0;
            if (appObj.length > 0)
                appCiid = appObj[0].value;

            handleUpdateComponent(candItems, appCiid);
        }
    }, [partnerAllCandidateSelector]);

    const usrType = localStorage.getItem("userType");

    const handleUploadDoc = (item) => {
        navigate("/CandidateWorkingHub", { state: item });
    }

    const handlePoShowHide = (obj) => {
        setPoShowHide(!poShowHide);
        setCandidateObj(obj);
    };

    const handleUpdateComponent = (compItems, ciid) => {
        const comp = componentUserStatusSelector.filter(x => x.compId === 19);
        let updCompItems = "";
        if (comp.length > 0) {
            updCompItems = comp[0].compItems;
        }
        const compObj = {
            compName: "Partner Candidate",
            performerId: currentUser.id,
            compItems: compItems,
            ciid: ciid,
            wiid: 0,
            iiid: 0,
            compId: 19
        }
        if (compItems !== "" && compItems !== updCompItems) {
            dispatch(UpdateComponentsUserStatus(compObj));
        }
    }

    const getPoCount = (obj) => {
        let poType = "recruiter";
        if (userDetails?.partnerType === "Sub-Contractors") {
            poType = "subcontractor";
            //return getReadUnreadBgColor(50)
        }
        else {
            //return getReadUnreadBgColor(51)
        }

        let pcount = obj.poTypes.filter(x => x.type === poType && x.partnerSign !== true).length;
        if (pcount === 0) {
            pcount = obj.poTypes.filter(x => x.type === poType).length;
            return <span className="badge badge-pill badge-success position-absolute mt--2">
                {pcount}
            </span>
        } else {
            return <span className="badge badge-pill badge-danger position-absolute mt--2">
                {pcount}
            </span>
        }
    }

    const handleRefreshPartnerCandidates=()=>{
        if (userDetailsSelector && userDetailsSelector?.partnerid) {
            setUserDetails(userDetailsSelector);
            dispatch(loadPartnerAllCandidate(userDetailsSelector.partnerid, 0))
        }
    }

    return <React.Fragment>
        <div className="col-md-12 mt-2">
            <div className="card shadow-none border">
                <div className="card-header p-3">
                <i className="fa fa-square wf-phase5-box mr-2" aria-hidden="true"></i>
                <span className="h3">
                List of Candidates
                </span>
                   
                    <p className="text-red">
                        Listed below are your candidate's engaged on the
                        project.
                    </p>
                </div>
                <div className="card-body p-3">
                    <div className="table-responsive">
                        <table className="table align-items-center table-flush table-hover table-striped border">
                            <thead className="thead-light">
                                <tr>
                                    <th>Candidate #<br />IPCS ID</th>
                                    <th>Details</th>
                                    <th>Agency/Client</th>
                                    <th>Duration<br />(Start-End)</th>
                                    <th>Onboarding<br />Status</th>
                                    <th>Purchase Orders</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {candidateList.map((item) => (
                                    <tr>
                                        <td>
                                            {item.performerid}
                                            <br />
                                            {item.maxloans}
                                        </td>
                                        <td>
                                            <span className="text-uppercase font-weight-bold">
                                                {item.performername} {item.lastName}
                                            </span>
                                            <br />
                                            {item.performeremail}
                                            <br />
                                            {item.contactNo && getFormatedNumber(item.contactNo)}
                                            <br />
                                            {getFieldData(
                                                item.performerData,
                                                "projectengagement",
                                                "PositionTitle"
                                            )}
                                        </td>
                                        <td>
                                            {getFieldData(
                                                item.performerData,
                                                "projectengagement",
                                                "AgencyName"
                                            )}
                                        </td>
                                        <td>
                                            {item.duration && (
                                                <React.Fragment>
                                                    {item.duration.duration} Months
                                                    <br />
                                                    Start :{" "}
                                                    <Moment format="MMM Do YYYY">
                                                        {item.duration.startDate}
                                                    </Moment>
                                                    <br />
                                                    End :{" "}
                                                    <Moment format="MMM Do YYYY">
                                                        {item.duration.endDate}
                                                    </Moment>
                                                </React.Fragment>
                                            )}
                                        </td>
                                        <td>
                                            {item.appSubmit && (
                                                <span>Complete</span>
                                            )}
                                            {!item.appSubmit && (
                                                <span>Pending</span>
                                            )}
                                        </td>
                                        <td>
                                            {usrType === "subcontractor" &&
                                                <button
                                                    className="btn btn-sm btn-link p-0"
                                                    onClick={() => handlePoShowHide(item)}
                                                >
                                                    View
                                                    {getPoCount(item)}
                                                </button>}
                                        </td>
                                        <td>
                                            <button
                                                onClick={() => handleUploadDoc(item)}
                                                className="btn btn-sm btn-link p-0"
                                            >
                                                Upload Documents
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <PurchaseOrders
            poShowHide={poShowHide}
            handlePoShowHide={handlePoShowHide}
            candidateObj={candidateObj}
            handleRefreshPartnerCandidates={handleRefreshPartnerCandidates}
        ></PurchaseOrders>
    </React.Fragment>
}