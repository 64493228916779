import React, { useEffect, useState } from "react";
import { getFormatedNumber } from "../../Services/appService";
import Moment from "react-moment";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getPartnerDetails, loadPartnerDetails } from "../store/partner";

export default function PartnerInfo() {

    const [partnerDetails, setPartnerDetails] = useState(null);

    const userDetailsSelector = useSelector(getLoggedUserDetails);
    const partnerDetailsSelector = useSelector(getPartnerDetails);

    const currentUser = getCurrentUser();
    const dispatch = useDispatch();

    useEffect(() => {
        if(!userDetailsSelector)
        dispatch(loadUserDetails(currentUser.id));
    }, []);

    useEffect(() => {
        if (userDetailsSelector && userDetailsSelector?.partnerid) {
            dispatch(loadPartnerDetails(userDetailsSelector.partnerid))
        }
    }, [userDetailsSelector]);

    useEffect(() => {
        setPartnerDetails(partnerDetailsSelector);
    }, [partnerDetailsSelector]);

    const usrType = localStorage.getItem("userType");

    let item = "";
    if (partnerDetails)
        item = partnerDetails;

    return <React.Fragment>
        <div className="table-responsive border border-top-0">
            <table className="table align-items-center table-flush table-hover table-striped mb-2">
                <thead className="thead-light">
                    <tr>
                        <th className="border-top-0">ID</th>
                        <th className="border-top-0">{usrType}</th>
                        <th className="border-top-0">Date & Time</th>
                        <th className="border-top-0">Address</th>
                        <th className="border-top-0">Key Contact</th>
                        <th className="border-top-0">Active Users</th>
                    </tr>
                </thead>
                <tbody>
                    {item !== "" && (
                        <tr>
                            <td>{item.fuid}</td>
                            <td>
                                <span className="text-uppercase">{item.label}</span>
                                <br />
                                <span>{item.email}</span>
                                <br />
                                {item.contact1?.contactNo && getFormatedNumber(item.contact1.contactNo)}
                            </td>
                            <td>
                                <Moment format="MMM Do YYYY">{item.createdDate}</Moment>
                                <br />
                                <Moment format="h:mm a">{item.createdDate}</Moment>
                            </td>
                            <td>
                                {item.portalUrl} {item.state} <br />
                                {item.city} {item.zip}
                            </td>
                            <td>
                                {item.contact1 && (
                                    <React.Fragment>
                                        <span className="text-uppercase">
                                            {item.contact1.performername}
                                        </span>
                                        <br />
                                        <span>
                                            {item.contact1.performeremail}
                                        </span>
                                        <br />
                                        {item.contact1.contactNo && getFormatedNumber(item.contact1.contactNo)}
                                    </React.Fragment>
                                )}
                            </td>
                            <td>
                            {item.activeUsers}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </React.Fragment>
}