import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Moment from "react-moment";
import { getTotalHours } from "../../Services/appService";

const CandidatePay = (props) => {

  const { selectedObj, candidateObj, handlePayShowHide, payShowHide } = props;
  
  const [invAmount, setInvAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);

  useEffect(() => {
    if (selectedObj && candidateObj) {
      const invAmt = candidateObj?.rates?.subcontractorRate * getTotalHours(selectedObj.workingHrs);
      let fAmt = invAmt;

      if (selectedObj.adjustmentMinues)
        fAmt = fAmt - selectedObj.adjustmentMinues;

      if (selectedObj.adjustmentPlus)
        fAmt = fAmt + selectedObj.adjustmentPlus;

      setInvAmount(invAmt);
      setFinalAmount(fAmt);
    }
  }, [selectedObj]);

  return (
    <Modal show={payShowHide} size="xl">
      <Modal.Header closeButton onHide={() => handlePayShowHide("")}>
        <Modal.Title>Payment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="" className="form-control-label">
              Gross Pay
            </label>
            <label className="form-control">
              {invAmount}
            </label>
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="form-control-label">
              Date Paid
            </label>
            <label className="form-control">
              <Moment format="MMM Do YYYY">{selectedObj?.addedDatetime}</Moment>
            </label>
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="form-control-label">
              Adjustment Plus (+) Amount ($)
            </label>
            <label className="form-control">
              {selectedObj?.adjustmentPlus}
            </label>
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="form-control-label">
              Adjustment Minus (-) Amount ($)
            </label>
            <label className="form-control">
              {selectedObj?.adjustmentMinues}
            </label>
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="form-control-label">
              Net Pay
            </label>
            <label className="form-control">
              {finalAmount}
            </label>
          </div>
          <div className="col-md-6">
            <label htmlFor="" className="form-control-label">
              Payment Mode
            </label>
            <label className="form-control">
              {selectedObj?.paymentMode}
            </label>
          </div>
          <div className="col-md-12">
            <label htmlFor="" className="form-control-label">
              Payment Mode
            </label>
            <textarea className="form-control">
              {selectedObj?.payNotes}
            </textarea>
          </div>

        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={() => handlePayShowHide("")}>
          <i className="fa fa-times mr-2"></i>Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CandidatePay;
