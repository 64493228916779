import React, { Component } from "react";
import { Link } from "react-router-dom";
import Form from "../Comman/Form";
import LoginFooter from "./LoginFooter";
import LoginHeader from "./LoginHeader";
import Swal from "sweetalert2";
import Joi from "joi-browser";
import http from "../../Services/httpService";
import config from "../../config.json";

class ResetPassword extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
    };
  }

  schema = {
    username: Joi.string().required().label("Username"),
    validId: Joi.string().required().label("Valid ID"),
    newPassword: Joi.string().required().min(8).max(14).label("New Password"),
    confirmPassword: Joi.string()
      .required()
      .valid(Joi.ref("newPassword"))
      .options({ language: { any: { allowOnly: "must match new password" } } })
      .label("Confirm Password"),
  };

  doSubmit = async () => {
    const { data } = this.state;
    const passResult = this.password_validate(data["newPassword"]);
    if (passResult) {
      const { data: result } = await http.post(config.ResetPassword, data);
      if (result === "error") {
        this.getAlert("Invalid Details", "", "error");
      } else {
        this.getAlert("", "Saved Successfully", "success");
      }
    } else {
      this.getAlert(
        "Password Strength",
        "Please enter strong password",
        "error"
      );
    }
  };

  password_validate(password) {
    var re = {
      capital: /[A-Z]/,
      digit: /[0-9]/,
      except: /[aeiou]/,
      full: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][A-Za-z0-9]/,
    };
    //console.log("capital", re.capital.test(password));
    //console.log("digit", re.digit.test(password));
    //console.log("except", re.except.test(password));
    //console.log("full", re.full.test(password));
    return (
      re.capital.test(password) &&
      re.digit.test(password) &&
      re.except.test(password) &&
      re.full.test(password)
    );
  }

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Close",
      confirmButtonColor: "#d33",
      onClose: () => {
        if (type === "success") {
          window.location = "/login";
        }
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        {/* <LoginHeader></LoginHeader> */}
        <div className="col-md-12">
          <div className="row align-items-center" style={{ height: "calc(100vh - 278px)" }}>
            <div className="col-md-3"></div>
            <div className="col-lg-6">
              <div
                className="mrnone card shadow-none border p-1"
                style={{ backgroundColor: "#fafafa", marginTop: "0px" }}
              >
                <h1 className="text-black text-uppercase text-center mt-2 mb-0">
                  Reset Your Password
                </h1>
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-md-12">
                      <Link
                        //to={`/Login${search}`}
                        to={{
                          pathname: "Login",
                        }}
                        className="btn btn-link pl-0"
                      >
                        <i
                          className="fa fa-long-arrow-left"
                          aria-hidden="true"
                        ></i>
                        Back
                      </Link>
                    </div>
                    <div className="col-md-12 form-group">
                      <p className="text-red">
                        <i
                          className="fa fa-hand-o-right"
                          aria-hidden="true"
                        ></i>{" "}
                        New password should contain at least 8 characters (and
                        up to 50 characters) with a mixture of both uppercase
                        and lowercase letters. A mixture of letters and numbers
                        inclusion of at least one special character, e.g., ! @ #
                        ? [no spaces allowed!].
                      </p>
                    </div>
                    <div className="col-md-6">
                      {this.renderInput("validId", "Enter Your Valid ID")}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput("username", "Enter Your Username")}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput(
                        "newPassword",
                        "Enter New Password",
                        "password"
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput(
                        "confirmPassword",
                        "Confirm Passowrd",
                        "password"
                      )}
                    </div>
                    <div className="col-md-12 form-group">
                      <button
                        type="button"
                        className="btn btn-danger w-100"
                        disabled={this.validate()}
                        onClick={this.handleSubmit}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-3"></div>
            <div className="col-lg-6 mt-3">
              <div
                className="card shadow-none border"
                style={{ backgroundColor: "#fafafa" }}
              >
                <div className="card-body text-center p-2">
                  <p>
                    Customer Support :{" "}
                    <a href="mailto:support@workfinity.com">
                      Support1@workfinity.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <LoginFooter></LoginFooter> */}
      </React.Fragment>
    );
  }
}

export default ResetPassword;
