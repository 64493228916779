import React, { Component, useEffect, useState } from "react";
import Moment from "react-moment";
import PageHeader from "../Comman/PageHeader";
import AddNewMessage from "../AddNewMessage";
import MessagingPopup from "../MessagingPopup";
import http from "../../Services/httpService";
import auth from "../../Services/authService";
import config from "../../config.json";
import ChecklistItem from "../Comman/ChecklistItem";
import CandidateInfo from "../../app/components/CandidateInfo";
import { getFormatedNumber } from "../../Services/appService";
import { getCurrentUser } from "../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getAppMessages, getAppTasks, getCandidateApps,
   loadAppMessages, loadAppRuningTasks, loadCandidateApps } from "../../app/store/candidate";
import { getAlert } from "../../Services/appAlerts";
import PartnerInfo from "../../app/components/PartnerInfo";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
import { getComponentUserStatus, UpdateComponentsUserStatus } from "../../app/store/tools";

export default function IndividualMsg() {

  const route = [{ url: "", name: "Task Management" }];
  const route1 = [{ name: "Contract Management", url: "" }];

  const usrType = localStorage.getItem("userType");

  const [appTasklist, setAppTasklist] = useState([]);
  const [appMessages, setAppMessage] = useState([]);
  const [wfId, setWfId] = useState(0);
  const [msgShowHide, setMsgShowHide] = useState(false);
  const [addMsgShowHide, setAddMsgShowHide] = useState(false);
  const [msgObj, setMsgObj] = useState("");
  const [wfTask, setWfTask] = useState(null);

  const candidateAppsSelector = useSelector(getCandidateApps);
  const appMessagesSelector = useSelector(getAppMessages);
  const appTasksSelector = useSelector(getAppTasks);

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    dispatch(setCurrentPageTitle("Message(s)"));
    dispatch(setCurrentRoute(usrType === "candidate" ? route : route1))
  }, [])

  useEffect(() => {
    if (candidateAppsSelector.length === 0)
      dispatch(loadCandidateApps(currentUser.id))
  }, []);

  useEffect(() => {
    if (candidateAppsSelector.length > 0) {
      const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
      if (mainWorkflow.length > 0) {
        const wf = mainWorkflow[0]
        setWfId(wf.oldWorkflowId);
        if (appMessagesSelector.length === 0)
          dispatch(loadAppMessages(wf.oldWorkflowId, currentUser.id));
        if (appTasksSelector.length === 0)
          dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
      }
    }
  }, [candidateAppsSelector]);

  useEffect(() => {
    if (appMessagesSelector.length > 0) {
      setAppMessage(appMessagesSelector);
      // let msgItems = "";
      // appMessagesSelector.map((x, key) => {
      //   if (key > 0) msgItems += ",";
      //   msgItems += x.id;
      // })
      // handleUpdateComponent(msgItems, appMessagesSelector[0].ciid);
    }
  }, [appMessagesSelector]);

  

  useEffect(() => {
    if (appTasksSelector.length > 0) {
      const filterTasks = appTasksSelector.filter(x => x.taskType === 1 && x.wiid > 0);
      if (filterTasks.length > 0) {
        setAppTasklist(filterTasks);
      }
    }
  }, [appTasksSelector])

  const handleAddNewMessagePopup = (app) => {
    if (app === "save") {
      setAddMsgShowHide(false);
      dispatch(loadAppMessages(wfId, currentUser.id));
    }
    else {
      const reviewApp = appTasklist.filter((x) => x.registrationType === "WP");
      if (reviewApp.length > 0) {
        const lastReviewer = reviewApp[reviewApp.length - 1];
        setWfTask([lastReviewer]);
      } else {
        getAlert("", "No reviewer found", "error");
      }
      setAddMsgShowHide(!addMsgShowHide);
    }
  };

  const handleMessagePopup = (obj) => {
    setMsgShowHide(!msgShowHide);
    setMsgObj(obj);
  };

  const handleSendMessage = () => {
    if (wfId > 0)
      dispatch(loadAppMessages(wfId, currentUser.id));
  }

  return (
    <React.Fragment>
      <div className="col-md-12 mt-2">
        <div className="card shadow-none border">
          <div className="card-header p-3">
            <div className="row">
              <div className="col-md-6">
              <i className="fa fa-square wf-phase-box mr-2" aria-hidden="true"></i>
                <span className="h3">
                  Message(s)
                </span>
                <p className="text-red">Sent and Received Messages</p>
              </div>
              <div className="col-md-6 text-right">
                <button
                  className="btn btn-sm btn-outline-default mt-2"
                  onClick={() => handleAddNewMessagePopup("")}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> Add Message
                </button>
              </div>
            </div>
          </div>
          <div className="card-body p-3">
            <div className="table-responsive">
              <table className="table align-items-center table-flush table-hover table-striped border">
                <thead className="thead-light">
                  <tr>
                    <th>S.No.</th>
                    <th>Date & Time</th>
                    <th>Review Officer</th>
                    <th>Messages</th>
                  </tr>
                </thead>
                <tbody>
                  {appMessages.map((item, key) => (
                    <tr>
                      <td>{key + 1}</td>
                      <td>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.addedDateTime}
                        </Moment>
                      </td>
                      <td>
                        {item.receiverId === parseInt(currentUser.id)
                          ? item.sender
                          : item.receiver}
                        <br />
                        {item.role}
                        {item.role && <br />}
                        {item.email}
                        <br />
                        {item.contact && getFormatedNumber(item.contact)}
                      </td>
                      <td>
                        <button
                          className="btn btn-link btn-sm p-0"
                          onClick={() => handleMessagePopup(item)}
                        >
                          View
                          {item.unreadCount > 0 &&
                            <span className="badge badge-pill badge-danger position-absolute mt--1 ml-0">
                              {item.unreadCount}
                            </span>}
                          {item.readCount > 0 && item.unreadCount === 0 &&
                            <span className="badge badge-pill badge-success text-dark position-absolute mt--1 ml-0">
                              {item.readCount}
                            </span>}

                        </button>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

      <AddNewMessage
        addMsgShowHide={addMsgShowHide}
        handleAddMsgModalShowHide={handleAddNewMessagePopup}
        runningApplication={wfTask}
      ></AddNewMessage>

      <MessagingPopup
        msgShowHide={msgShowHide}
        message={msgObj}
        handleMsgModalShowHide={handleMessagePopup}
        handleSendMessage={handleSendMessage}
      ></MessagingPopup>

    </React.Fragment>
  );
}
