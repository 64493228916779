import React, { Component, useEffect, useState } from "react";
import Form from "../Comman/Form";
import http from "../../Services/httpService";
import auth from "../../Services/authService";
import config from "../../config.json";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Joi from "joi-browser";
import useForm from "../../app/hooks/useForm";
import useControls from "../../app/hooks/useControls";
import { useDispatch, useSelector } from "react-redux";
import { addUserSecurityQuestion, getSecQuestions, getSecQuestionUpdatedStatus, loadSecQuestions, secQuestionsUpdated } from "../../app/store/users";
import { getAlert } from "../../Services/appAlerts";

const AddSecurityQuestion = (props) => {
  const { securityShowHide, handleSecurityShowHide, userDetails } = props;

  const state = {
    data: {},
    errors: {},
    securityQuestions1: [],
    securityQuestions2: [],
    securityQuestions3: [],
  };

  const [secQuestion1, setSecQuestion1] = useState([]);
  const [secQuestion2, setSecQuestion2] = useState([]);
  const [secQuestion3, setSecQuestion3] = useState([]);
  const [allSecQuestion, setAllSecQuestion] = useState([]);
  const [btnSaveProcess, setBtnSaveProcess] = useState(false);

  const secQuestionsSelector = useSelector(getSecQuestions);
  const secQuestionUpdatedStatusSelector = useSelector(getSecQuestionUpdatedStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSecQuestions("Security Question 1"));
  }, []);

  useEffect(() => {
    setSecQuestion1(secQuestionsSelector);
    setSecQuestion2(secQuestionsSelector);
    setSecQuestion3(secQuestionsSelector);
    setAllSecQuestion(secQuestionsSelector);
  }, [secQuestionsSelector]);

  const schema = {
    oldPassword: Joi.string().required().label("Login Password"),
    hintQuestion1: Joi.string().required().label("Security Question 1"),
    hintQuestion2: Joi.string().required().label("Security Question 2"),
    hintQuestion3: Joi.string().required().label("Security Question 3"),
    hintAnswer1: Joi.string().required().label("Answer 1"),
    hintAnswer2: Joi.string().required().label("Answer 2"),
    hintAnswer3: Joi.string().required().label("Answer 3"),
  };

  const doSubmit = () => {
    setBtnSaveProcess(true);
    const newData = { ...stateData };
    newData["id"] = userDetails.performerid;
    dispatch(addUserSecurityQuestion(newData));
  };

  useEffect(() => {
    if (secQuestionUpdatedStatusSelector) {
      getAlert("", "Saved Successfully", "success");
      handleSecurityShowHide();
    }
    else if (secQuestionUpdatedStatusSelector === false) {
      getAlert("", "Login Password is incorrect", "error");
    }
    dispatch(secQuestionsUpdated(null));
    setBtnSaveProcess(false);
  }, [secQuestionUpdatedStatusSelector]);

  const { handleChange, stateData, setStateData, stateErrors, setStateErrors, handleSubmit, validate } = useForm(schema, doSubmit);

  const { renderSelect, renderInput } = useControls(stateData, stateErrors, handleChange, schema);

  let question1 = [...secQuestion1];
  let question2 = [...secQuestion2];
  let question3 = [...secQuestion3];
  if (stateData["hintQuestion1"]) {
    question2 = question2.filter(
      (x) => x.id !== parseInt(stateData["hintQuestion1"])
    );
    question3 = question3.filter(
      (x) => x.id !== parseInt(stateData["hintQuestion1"])
    );
  }
  if (stateData["hintQuestion2"]) {
    question1 = question1.filter(
      (x) => x.id !== parseInt(stateData["hintQuestion2"])
    );
    question3 = question3.filter(
      (x) => x.id !== parseInt(stateData["hintQuestion2"])
    );
  }
  if (stateData["hintQuestion3"]) {
    question1 = question1.filter(
      (x) => x.id !== parseInt(stateData["hintQuestion3"])
    );
    question2 = question2.filter(
      (x) => x.id !== parseInt(stateData["hintQuestion3"])
    );
  }

  return (
    <Modal show={securityShowHide} size="xl">
      <Modal.Header closeButton onHide={handleSecurityShowHide}>
        <Modal.Title>Change Security Questions</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border pb-0">
        <div className="row">
          {/* <div className="col-md-12">
              <p className="text-red">
                <i className="fa fa-hand-o-right" aria-hidden="true"></i> Change
                your system generated password, new password should contain at
                least 8 characters (and up to 50 characters) with a mixture of
                both uppercase and lowercase letters. A mixture of letters and
                numbers inclusion of at least one special character, e.g., ! @ #
                ? [no spaces allowed!].
              </p>
            </div> */}

          <div className="col-md-12 mb-2">
            <p className="text-red">
              <i className="fa fa-hand-o-right" aria-hidden="true"></i> Choose
              your security questions for recovery of your User ID, Username
              and Password
            </p>
          </div>
          <div className="col-md-12">
            {renderInput("oldPassword", "Password", "password")}
          </div>
          <div className="col-md-6">
            {renderSelect(
              "hintQuestion1",
              "Security Question 1",
              question1,
              "name",
              "id"
            )}
          </div>
          <div className="col-md-6">
            {renderInput("hintAnswer1", "Enter Answer 1", "password")}
          </div>
          <div className="col-md-6">
            {renderSelect(
              "hintQuestion2",
              "Security Question 2",
              question2,
              "name",
              "id"
            )}
          </div>
          <div className="col-md-6">
            {renderInput("hintAnswer2", "Enter Answer 2", "password")}
          </div>
          <div className="col-md-6">
            {renderSelect(
              "hintQuestion3",
              "Security Question 3",
              question3,
              "name",
              "id"
            )}
          </div>
          <div className="col-md-6">
            {renderInput("hintAnswer3", "Enter Answer 3", "password")}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={handleSecurityShowHide}>
          <i class="fa fa-times" aria-hidden="true"></i> Close
        </button>
        <button
          className="btn btn-sm btn-outline-default"
          disabled={btnSaveProcess || validate()}
          onClick={handleSubmit}
        >
          {btnSaveProcess ? <i className="fa fa-spinner fa-spin mr-1"></i> : <i className="fa fa-check mr-1" aria-hidden="true"></i>}
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddSecurityQuestion;
