import React, { Component } from "react";

class InfoBox extends Component {
  render() {
    const { name, count, fontSize, otherInfo } = this.props;
    return (
      <div className="col-xl-3 col-md-6">
        <div className="card card-stats shadow-none border">
          <div className="card-body p-2">
            <div className="row">
              <div className="col d-flex align-items-center">
                <h5 className="card-title text-uppercase mb-0">
                  {name}

                  {otherInfo && otherInfo.length > 0 && (
                    <h6 className="mb-0">
                      {otherInfo.map((item, key) => (
                        <React.Fragment>
                          {key > 0 && ", "} {item.name.replace("Basis", "")} :{" "}
                          <span className="font-weight-bolder">
                            {item.count}
                          </span>
                        </React.Fragment>
                      ))}
                    </h6>
                  )}
                </h5>
              </div>
              <div className="col-auto">
                <div className="icon icon-shape bg-yellow border rounded-circle">
                  <span
                    className={`${
                      fontSize !== undefined ? fontSize : "h2"
                    } font-weight-bold mb-0`}
                  >
                    {count}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InfoBox;
