import React from "react";

export default function Label(props) {

    const { controleId, comp, stateData: data } = props;
    const attStyle = comp?.styleCss?.textalign;

    let textContent = "";
    if (comp.formIcons) {
        textContent = <div className={comp?.text && "d-flex"}>
            <div>
                <span>
                    <i className={`${comp.formIcons} p-1`} aria-hidden="true"></i>
                </span>
            </div>
            <div>
                {comp.text?.split("&lt;br&gt;").map((item) => (
                    <React.Fragment>
                        {item} <br></br>
                    </React.Fragment>
                ))}
            </div>
        </div>
    }
    else {
        textContent = <div>
            {comp.text?.split("&lt;br&gt;").map((item) => (
                <React.Fragment>
                    {item} <br></br>
                </React.Fragment>
            ))}
        </div>
    }

    if (comp?.attributeDataTypes) {
        return <div
            className={`${attStyle !== "" ? "text-" + attStyle + " " : ""} ${comp?.cssclass ? comp.cssclass : ""
                } form-group`}
        >
            {comp.attributeDataTypes.type === "ol" && (
                <ol type={comp.orderedListType}>
                    {comp.text?.split("&lt;br&gt;").map((p) => (
                        <li>{p}</li>
                    ))}
                </ol>
            )}
            {comp.attributeDataTypes.type === "ul" && (
                <ul>
                    {comp.text?.split("&lt;br&gt;").map((p) => (
                        <li>{p}</li>
                    ))}
                </ul>
            )}
            {comp.attributeDataTypes.type === "paragraph" && (
                <p>{controleId !== "" ? data[controleId] : textContent}</p>
            )}
            {comp.attributeDataTypes.type === "label" && (
                <label>
                    {controleId !== "" ? data[controleId] : textContent}
                </label>
            )}
            {comp.attributeDataTypes.type === "h1" && (
                <h1 className="mb-0">
                    {controleId !== "" ? data[controleId] : textContent}
                </h1>
            )}
            {comp.attributeDataTypes.type === "h2" && (
                <h2 className="mb-0">
                    {controleId !== "" ? data[controleId] : textContent}
                </h2>
            )}
            {comp.attributeDataTypes.type === "h3" && (
                <h3 className="mb-0">
                    {controleId !== "" ? data[controleId] : textContent}
                </h3>
            )}
            {comp.attributeDataTypes.type === "h4" && (
                <h4 className="mb-0">
                    {controleId !== "" ? data[controleId] : textContent}
                </h4>
            )}
            {comp.attributeDataTypes.type === "h5" && (
                <h5 className="mb-0">
                    {controleId !== "" ? data[controleId] : textContent}
                </h5>
            )}
        </div>
    }
    else {
        return <label
            className={`${attStyle !== "" ? "text-" + attStyle + " " : ""} ${comp?.cssclass ? comp.cssclass : ""
                } form-group`}
        >
            {controleId !== "" ? (
                <span className="ml-1">{data[controleId]}</span>
            ) : (
                textContent
            )}
        </label>
    }
}