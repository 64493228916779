import { combineReducers } from "@reduxjs/toolkit";
//import workflowRoleReducers from "./workflowRole"
//import subcontractorsReducers from "./subcontractor";
//import recruiterReducers from "./recruiter";
import candidateReducers from "./candidate";
import toolsReducers from "./tools";
import bprmFormReducers from "./bprmForm";
import applicationReducers from "./application";
import workflowEngineReducers from "./WorkflowEngine";
import timeLogReducers from "./timeLog";
import timeLogInvoiceReducers from "./timeLogInvoice";
import partnerReducers from "./partner";
import layoutUIReducers from "./layoutUI";
import documentsReducers from "./documents";
import supportReducers from "./support";
import formsReducers from "./forms";
import reportReducers from "./report";
import messagesReducers from "./messages";
import reviewNotesReducer from "./reviewNotes";
import usersReducers from "./users";

export default combineReducers({
    //workflowRoles: workflowRoleReducers,
    //subcontractors: subcontractorsReducers,
    //recruiters: recruiterReducers,
    candidates:candidateReducers,
    tools:toolsReducers,
    bprmForm:bprmFormReducers,
    application:applicationReducers,
    workflowEngine:workflowEngineReducers,
    timeLog:timeLogReducers,
    timeLogInvoice:timeLogInvoiceReducers,
    partners:partnerReducers,
    layoutUI:layoutUIReducers,
    documents: documentsReducers,
    support: supportReducers,
    forms: formsReducers,
    reports: reportReducers,
    messages: messagesReducers,
    reviewNotes:reviewNotesReducer,
    users:usersReducers,
});