import React from "react";
import ReactDOM from "react-dom";
import App from "./App"
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Switch from "react-bootstrap/esm/Switch";
import loadjs from "loadjs";
import Dictionary from "./Components/Comman/Dictionary";
import Logout from "./Components/Logout";
import PartnerRegistration from "./Components/PartnerRegistration";
import Login from "./Components/Login";
import Home from "./Components/Home";
import Registration from "./Components/Registration";
import ClientHeader from "./Components/Comman/ClientHeader";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ForgotValidID from "./Components/Auth/ForgotValidID";
import ResetPassword from "./Components/Auth/ResetPassword";
import ValidateUser from "./Components/Auth/ValidateUser";
import Dashboard from "./Components/Dashboard";
import ProtectedRoute from "./Components/Comman/ProtectedRoute";

import { Provider } from "react-redux";
import store, { persistor } from "./app/store/configureStore";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        { <Route path="/Registration" element={<Registration />} />
        /*<Route path="/PartnerRegistration" element={<PartnerRegistration />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Logout" element={<Logout />} />
        <Route path="/Dictionary" element={<Dictionary />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/ForgotValidID" element={<ForgotValidID />} />
        <Route path="/ResetPassword" element={<ResetPassword />} />
        <Route path="/ValidateUser" element={<ValidateUser />} />
        <Route path="/Dashboard" element={<Dashboard />} /> */}
        <Route path="/*" element={ <App />} />
        
      </Routes>
     
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
