import React from "react"

const Header = () => {
    return <React.Fragment>
        <div
            className="col-md-12"
            style={{
                backgroundColor: "white",
                textAlign: "left",
                borderBottom: "2px solid #ccc",
                backgroundColor: "white",
                paddingTop: "5px",
                paddingBottom: "5px",
            }}
        >
            <img style={{ height: "70px" }} src={`/assets/Img/logo.png`} />
        </div>
        <div className="col-md-12 d-flex align-items-center justify-content-center">
            <h3 className="mb-0 text-black text-uppercase p-1">
                HUMAN RESOURCES ONBOARDING AND CONTRACT CASE MANAGEMENT SYSTEM
            </h3>
        </div>
        <div
            className="col-md-12"
            style={{
                backgroundImage: `URL("/assets/Img/slide3.jpg")`,
                backgroundPosition: "center center",
                borderTop: "2px solid white",
                backgroundSize: "100%",
                color: "white",
                height: "70px",
            }}
        >
            <h1 className="text-white d-flex align-items-center justify-content-center mb-0 mt-2">
                Welcome to <img className="mb-1 ml-2" src="/assets/Img/logo_ft.png" />
            </h1>
            <p className="text-white d-flex align-items-center justify-content-center mt--1 mr-4">
                <b>Subcontractor, Recruiter and Candidate Portal</b>
            </p>
        </div>
    </React.Fragment>
}

export default Header;