import React from "react";
import ReactDOM from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Joi from "joi-browser";
import http from "../Services/httpService";
import config from "../config.json";
import Form from "./Comman/Form";
import geo from "../Services/geoService";
import ApplicationHeader from "./Comman/ApplicationHeader";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import Progress from "../Components/Comman/Progress";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import oxford from "oxford-dictionaries-api";
import Dictionary from "./Comman/Dictionary";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import useScreenReader from "react-screenreader";
import ScreenReaderText from "./Comman/ScreenReaderText";
import TextReader from "./Comman/TextReader";
import Speech from "speak-tts";
import ClientHeader from "./Comman/ClientHeader";
import { Link } from "react-router-dom";
import auth from "../Services/authService";

class Registration extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      hintQuestions: [],
      errors: [],
      applications: [],
      stateList: [],
      countyList: [],
      cityList: [],
      titles: [
        {
          id: "1",
          name: "Dr.",
        },
        {
          id: "2",
          name: "Miss",
        },
        {
          id: "3",
          name: "Mr.",
        },
        {
          id: "4",
          name: "Mrs.",
        },
        {
          id: "5",
          name: "Mx.",
        },
      ],
      alert: null,
      aWorkfinityApp: {},
      aWorkfinityAppEntity: {},
      entityName: "",
      showHide: false,
      process: false,
      formProcess: [],
      processId: 0,
      layoutName: "default",
      input: "",
      selectedInput: "",
      dictionaryShowHide: false,
      selectedText: "",
      scrollPosition: 0,
      voiceTest: "",
      bdyFontSize: "13",
      whiteCursor: false,
      blackCursor: false,
      voice: {},
      textReader: false,
      selectedVoiceLang: "en-GB",
      selectedVoice: "Google UK English Male",
      userTypes: [
        {
          id: 1,
          type: "Applicant",
          regType: "applicant",
          ddType: "applicantregistration",
        },
        {
          id: 2,
          type: "Partner - Mortgage Broker",
          regType: "Mortgage Broker",
          ddType: "partnerregistration",
        },
        {
          id: 3,
          type: "Partner- Mortgage Lender",
          regType: "Mortgage Lender",
          ddType: "partnerregistration",
        },
        {
          id: 4,
          type: "Partner - Title Company",
          regType: "Title Company",
          ddType: "partnerregistration",
        },
        {
          id: 5,
          type: "Partner - Property Inspection Company",
          regType: "Property Inspection Company",
          ddType: "partnerregistration",
        },
        {
          id: 6,
          type: "Partner - Government Agency",
          regType: "Government Agency",
          ddType: "partnerregistration",
        },
        // {
        //   id: 6,
        //   type: "Pharmacist",
        //   regType: "partner",
        //   ddType: "pharmacistregistration",
        // },
        // {
        //   id: 7,
        //   type: "Manufacturer",
        //   regType: "partner",
        // },
        // {
        //   id: 8,
        //   type: "Distributor",
        //   regType: "partner",
        // },
        // {
        //   id: 9,
        //   type: "Labs",
        //   regType: "partner",
        // },
      ],
      workflowUserTypes: [],
      category: [],
      subCategory: [],
      isDisableForm: false,
    };
  }

  schema = {
    workflowId: Joi.string().required().label("Application"),
    First_Name: Joi.string().required().label("Your First Name"),
    Email: Joi.string().email().required().label("Email Address"),
    question: Joi.string().required().label("Password Hint Question"),
    answer: Joi.string().required().label("Password Hint Answer"),
    message: Joi.string().required().label("Options"),
    Mobile: Joi.number(),
    ReCAPTCHA: Joi.string().required().label("Re-Captcha"),
  };

  async componentDidMount() {
    //  this.myInputRef.focus();
    //   var focussableElements =
    //   'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
    //   if (document.activeElement && document.activeElement.form) {
    //     var focussable = Array.prototype.filter.call(document.activeElement.form.querySelectorAll(focussableElements),
    //     function (element) {
    //         //check for visibility while always include the current activeElement
    //         return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
    //     });
    //     console.log(focussable);
    //     var index = focussable.indexOf(document.activeElement);
    //     console.log(index);
    //     if(index > -1) {
    //        var nextElement = focussable[index + 1] || focussable[0];
    //        nextElement.focus();
    //     }
    // }
    // this.setState({ scrollPosition: window.pageYOffset });
    // this.handleVoiceCommand();
    // this.handleTextReader();
    const { state } = this.props.location;
    const { data } = this.state;
    const stateList = await geo.getStateList();
    this.setState({ stateList });
    let isDisableForm = false;
    if (state !== undefined) {
      const { data: registration } = await http.get(
        config.GetRegistrationData + state.userId
      );

      const aWorkfinityApp = JSON.parse(registration.message);
      const appShortName = Object.keys(aWorkfinityApp)[1];
      const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
      Object.keys(aWorkfinityAppEntity).forEach(function (keyValue) {
        if (
          keyValue !== "@xmlns:xsd" &&
          keyValue !== "@xmlns:xsi" &&
          keyValue !== "EntityId"
        ) {
          Object.keys(aWorkfinityAppEntity[keyValue]).forEach(function (
            keyValue1
          ) {
            if (
              keyValue1 !== "EntityId" &&
              keyValue === "applicantregistration"
            ) {
              data[keyValue1.replace("@", "")] =
                aWorkfinityAppEntity[keyValue][keyValue1];
            }
          });
        }
      });

      data["workflowId"] = registration.workflowId;
      data["userType"] = registration.type;
      this.handleOnChangeTrigger("userType");
      data["userType"] = registration.type;
      data["question"] = registration.question;
      this.handleOnChangeTrigger("US_State");
      this.handleOnChangeTrigger("Organisation_State");
      isDisableForm = true;
    }

    const { data: hintQuestions } = await http.get(
      config.GetCodemanager + "Security Question 1"
    );
    const { data: workflowUserTypes } = await http.get(
      config.GetCodemanager + "Workflow User Type"
    );
    const { data: category } = await http.get(config.GetCodemanager + "SC");
    const { data: subCategory } = await http.get(config.GetCodemanager + "LT");

    this.setState({
      hintQuestions,
      workflowUserTypes,
      stateList,
      category,
      subCategory,
      data,
      isDisableForm,
    });
  }

  handleOnChangeTrigger(id) {
    var element = document.getElementById(id);
    var event = new Event("change", { bubbles: true });
    element.dispatchEvent(event);
  }

  handleStateChanged = async ({ target: input }) => {
    const selectedOption = input.selectedOptions[0];
    //console.log(selectedOption);
    this.setState({
      selectedState: selectedOption.text,
      selectedStateId: selectedOption.value,
    });

    this.updateState(input.name, selectedOption.text);
    if (input.nodeName === "SELECT") this.handleDropdwonSpeech(input);
    await this.bindCountyDropdown(selectedOption.value);
  };

  handleCountyChanged = async ({ target: input }) => {
    const selectedOption = input.selectedOptions[0];
    //console.log(selectedOption);
    this.setState({
      selectedCounty: selectedOption.text,
      selectedCountyId: selectedOption.value,
    });

    this.updateState(input.name, selectedOption.text);
    if (input.nodeName === "SELECT") this.handleDropdwonSpeech(input);
    await this.bindCityDropdown(selectedOption.value);
  };

  updateState(name, value) {
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  }

  async bindCountyDropdown(geonameId) {
    const countyList = geonameId > 0 ? await geo.getGeoData(geonameId) : [];
    this.setState({ countyList });
    this.handleOnChangeTrigger("US_County");
  }

  async bindCityDropdown(geonameId) {
    const cityList = geonameId > 0 ? await geo.getGeoData(geonameId) : [];
    this.setState({ cityList });
  }

  onCaptchaChange = (value) => {
    const { data } = this.state;
    data["ReCAPTCHA"] = value;
    this.setState({ data });
  };

  doSubmit = async () => {
    const {
      data,
      aWorkfinityApp,
      aWorkfinityAppEntity,
      entityName,
      userTypes,
    } = this.state;

    this.setState({ showHide: true });

    const applicantRegistration = {};

    Object.keys(data).forEach(function (keyValue, index, map) {
      applicantRegistration["@" + keyValue] = data[keyValue];
    });

    const guid = uuidv4();
    applicantRegistration["EntityId"] = guid;
    data["EntityId"] = guid;
    const usrType = data["userType"];
    const regType = userTypes.filter((x) => x.regType === usrType);
    data["type"] = usrType;
    const usrDdtype = "applicantregistration"; //regType[0].ddType;
    aWorkfinityAppEntity[usrDdtype] = applicantRegistration;
    aWorkfinityApp[entityName] = aWorkfinityAppEntity;
    const formData = new FormData();
    formData.append("appEntity", JSON.stringify(aWorkfinityApp));
    formData.append("registration", JSON.stringify(data));
    const response = await http.post(config.Registration, formData);
    this.handleModalShowHide();

    if (response.data === "success") {
      this.setState({
        alert: this.getAlert(
          "Registration Successfull",
          "Registration Successfull",
          "success"
        ),
      });
    } else {
      this.setState({
        alert: this.getAlert(
          "Registration Unsuccessfull",
          response.data,
          "error"
        ),
      });
    }
  };

  getAlert(title, message, type) {
    return Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: "Ok",
      onClose: () => {
        if (type === "success") {
          window.location = "/login";
        }
      },
    });
  }

  handleApplicationChange = async ({ target: input }) => {
    const selectedOption = input.selectedOptions[0];
    const data = { ...this.state.data };
    data[input.name] = selectedOption.value;
    data["Application_Type"] = selectedOption.text;
    const application = this.state.applications.filter(
      (x) => x.chkID === parseInt(selectedOption.value)
    );
    if (application.length > 0) {
      const { data: aWorkfinityApp } = await http.get(
        config.GetWorkfinityAppEntity + application[0].applicationId
      );
      // console.log(aWorkfinityApp);
      //const entityName = application[0].description + "Entity";
      //const aWorkfinityAppEntity = aWorkfinityApp[entityName];
      const appShortName = Object.keys(aWorkfinityApp)[1];
      const aWorkfinityAppEntity = aWorkfinityApp[appShortName];
      //console.log(aWorkfinityAppEntity);
      this.setState({
        data,
        aWorkfinityApp,
        aWorkfinityAppEntity,
        entityName: appShortName,
      });
    }
    if (input.nodeName === "SELECT") this.handleDropdwonSpeech(input);
  };

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  onChange = (input) => {
    const { selectedInput } = this.state;
    const data = { ...this.state };
    data[selectedInput] = input;
    this.setState({ data });
    //console.log("Input changed", input);
  };

  onKeyPress = (button) => {
    console.log("Button pressed", button);
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };

  handleShift = () => {
    const layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default",
    });
  };

  onInputChanged = (data) => {
    //this.setState({ input: data });
  };

  onInputSubmitted = (data) => {
    //console.log("Input submitted:", data);
  };

  onChangeInput = (event) => {
    //const input = event.target.value;
    //this.setState({ input });
    //this.keyboard.setInput(input);
  };

  handleVoiceCommand = () => {
    // const vr = document.body.classList.contains("INDvoicecmd");
    // if (vr) {
    //SpeechRecognition.startListening({ continuous: true });
    SpeechRecognition.getRecognition().onresult = (event) => {
      let current = event.resultIndex;
      let transcript = event.results[current][0].transcript;
      //console.log(transcript);
      this.setState({ voiceTest: transcript });
      this.handleSpeechCommand();
    };
    //}
  };

  handleTextReader = () => {
    const vr = document.body.classList.contains("INDtextreader");
    const { selectedVoiceLang, selectedVoice } = this.state;
    //if (vr) {
    const speech = new Speech();
    speech
      .init({
        volume: 1,
        lang: selectedVoiceLang,
        rate: 1,
        pitch: 1,
        voice: selectedVoice,
        splitSentences: true,
        listeners: {
          onvoiceschanged: (voices) => {
            console.log("Event voiceschanged");
          },
        },
      })
      .then((data) => {
        this.setState({ voice: data });
      })
      .catch((e) => {
        console.error("An error occured while initializing : ", e);
      });
    //this.setState({ textReader: vr });
    //}
  };

  handleMouseUp = () => {
    const vr = document.body.classList.contains("INDdictionary");
    //console.log(vr);
    if (vr) {
      const selectedText = window.getSelection().toString();
      console.log(`Selected text: ${selectedText}`);
      if (selectedText !== "") {
        this.setState({
          dictionaryShowHide: !this.state.dictionaryShowHide,
          selectedText,
        });
      }
    }
  };

  handledictionaryShowHide = () => {
    this.setState({
      dictionaryShowHide: !this.state.dictionaryShowHide,
    });
  };

  handleScrollUp = () => {
    const { scrollPosition } = this.state;
    this.setState({ scrollPosition: scrollPosition + 50 });
    window.scrollTo({
      top: scrollPosition,
      behavior: "smooth",
    });
  };

  handleScrollDown = () => {
    const { scrollPosition } = this.state;
    console.log(scrollPosition);
    if (scrollPosition > 0) {
      this.setState({ scrollPosition: scrollPosition - 50 });
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    } else {
      this.setState({ scrollPosition: 0 });
      window.scrollTo({
        top: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  handleSpeechCommand = () => {
    const { voiceTest } = this.state;
    if (voiceTest === "close popup" || voiceTest === " close popop") {
      this.props.handleVoiceShowHide();
    }

    if (voiceTest === "scroll down" || voiceTest === " scroll down") {
      this.handleScrollDown();
    }

    if (voiceTest === "scroll up" || voiceTest === " scroll up") {
      this.handleScrollUp();
    }

    if (voiceTest === "next" || voiceTest === " next") {
      this.handleNextElementFocus();
      // var nextElement = document.activeElement.nextSibling;
      // // nextElement.focus();
      // console.log(nextElement);
      // if (nextElement !== null) {
      //   nextElement.lastElementChild.focus();
      // }
    }

    if (voiceTest === "previous" || voiceTest === " previous") {
      this.handlePreviousElementFocus();
      // var prevElement = document.activeElement.previousElementSibling;
      // //prevElement.focus();
      // console.log(prevElement);
      // if (prevElement !== null) {
      //   prevElement.focus();
      // }
    }

    if (voiceTest === "pause" || voiceTest === " pause") {
      SpeechRecognition.stopListening();
      this.setState({ voiceTest: "" });
    }

    if (
      voiceTest === "increase font size" ||
      voiceTest === " increase font size"
    ) {
      this.handleIncreaseFontSize();
    }

    if (
      voiceTest === "decrease font size" ||
      voiceTest === " decrease font size"
    ) {
      this.handleDecreaseFontSize();
    }

    if (
      voiceTest === "large white cursor" ||
      voiceTest === " large white cursor"
    ) {
      this.handleWhiteCursor();
    }

    if (
      voiceTest === "large black cursor" ||
      voiceTest === " large black cursor"
    ) {
      this.handleBlackCursor();
    }

    if (
      voiceTest === "bright high contrast" ||
      voiceTest === " bright high contrast"
    ) {
      this.handleBrightHighContrast();
    }

    if (
      voiceTest === "dark high contrast" ||
      voiceTest === " dark high contrast"
    ) {
      this.handleDarkHighContrast();
    }
  };

  handleNextElementFocus = () => {
    var focussableElements =
      'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
    console.log(focussableElements);
    if (document.activeElement && document.activeElement.form) {
      var focussable = Array.prototype.filter.call(
        document.activeElement.form.querySelectorAll(focussableElements),
        function (element) {
          //check for visibility while always include the current activeElement
          return (
            element.offsetWidth > 0 ||
            element.offsetHeight > 0 ||
            element === document.activeElement
          );
        }
      );
      var index = focussable.indexOf(document.activeElement);
      if (index > -1) {
        var nextElement = focussable[index + 1] || focussable[0];
        nextElement.focus();
      }
    }
  };

  handlePreviousElementFocus = () => {
    var focussableElements =
      'a:not([disabled]),select:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
    console.log(focussableElements);
    if (document.activeElement && document.activeElement.form) {
      var focussable = Array.prototype.filter.call(
        document.activeElement.form.querySelectorAll(focussableElements),
        function (element) {
          //check for visibility while always include the current activeElement
          return (
            element.offsetWidth > 0 ||
            element.offsetHeight > 0 ||
            element === document.activeElement
          );
        }
      );
      var index = focussable.indexOf(document.activeElement);
      if (index > -1) {
        var nextElement = focussable[index - 1] || focussable[0];
        nextElement.focus();
        return;
      }
    }
  };

  handleWhiteCursor = () => {
    //const { whiteCursor } = this.state;
    //if (whiteCursor) {
    //document.body.classList.remove("INDbigcursor");
    //document.body.classList.remove("INDwhitecursor");
    //} else {
    document.body.classList.add("INDbigcursor");
    document.body.classList.add("INDwhitecursor");
    //}
    //this.setState({ whiteCursor: !this.state.whiteCursor });
  };

  handleBlackCursor = () => {
    //const { blackCursor } = this.state;
    //if (blackCursor) {
    //document.body.classList.remove("INDbigcursor");
    //document.body.classList.remove("INDblackcursor");
    //} else {
    document.body.classList.add("INDbigcursor");
    document.body.classList.add("INDblackcursor");
    //}
    //this.setState({ blackCursor: !this.state.blackCursor });
  };

  handleDarkHighContrast = () => {
    document.body.classList.add("INDcolorscheme");
    document.body.classList.add("INDblackwhite");
  };

  handleBrightHighContrast = () => {
    document.body.classList.add("INDcolorscheme");
    document.body.classList.add("INDwhiteblack");
  };

  handleIncreaseFontSize = () => {
    const { bdyFontSize } = this.state;
    //console.log(bdyFontSize);
    if (bdyFontSize < 19) {
      document.body.style.fontSize = parseInt(bdyFontSize) + 2 + "px";
      this.setState({ bdyFontSize: parseInt(bdyFontSize) + 2 });
    }
  };

  handleDecreaseFontSize = () => {
    const { bdyFontSize } = this.state;
    //console.log(bdyFontSize);
    if (bdyFontSize > 13) {
      document.body.style.fontSize = parseInt(bdyFontSize) - 2 + "px";
      this.setState({ bdyFontSize: parseInt(bdyFontSize) - 2 });
    }
  };

  handleVoiceChange = ({ target: input }) => {
    const { options, value } = input;
    //console.log(options[options.selectedIndex].text);
    //console.log(value);
    this.setState({
      selectedVoiceLang: value,
      selectedVoice: options[options.selectedIndex].text,
    });
    //this.handleTextReader();
  };

  handleHeadingFocus = () => {
    //console.log(document.activeElement.tagName);
    const vr = document.body.classList.contains("INDtextreader");
    if (vr) {
      const spch = new Speech();
      spch.speak({
        text:
          document.activeElement.textContent +
          "Heading Level " +
          document.activeElement.tagName.replace("H", ""),
      });
    }
  };

  handleParagraphFocus = () => {
    //console.log(document.activeElement);
    const vr = document.body.classList.contains("INDtextreader");
    if (vr) {
      const spch = new Speech();
      spch.speak({
        text: document.activeElement.textContent,
      });
    }
  };

  handleUserTypeChanged = async ({ currentTarget }) => {
    const { data } = this.state;
    data[currentTarget.name] = currentTarget.value;
    this.setState({ data });
    const { data: applications } = await http.get(config.GetChecklist);
    // console.log(applications);
    const filterApplications = applications.filter((x) => x.chkState === 1);
    this.setState({ applications: filterApplications });
  };

  render() {
    const {
      data,
      hintQuestions,
      applications,
      stateList,
      countyList,
      cityList,
      titles,
      showHide,
      processId,
      formProcess,
      dictionaryShowHide,
      selectedText,
      voiceTest,
      voice,
      textReader,
      selectedVoiceLang,
      userTypes,
      workflowUserTypes,
      orginationTypes,
      category,
      subCategory,
      isDisableForm,
    } = this.state;
    if (auth.getCurrentUser()) return <Link to="/" />;
    const stateId =
      data["US_State"] !== undefined &&
      stateList.length > 0 &&
      stateList.filter((x) => x.name === data["US_State"]).length > 0 &&
      stateList.filter((x) => x.name === data["US_State"])[0].geonameId;

    const countyId =
      data["US_County"] !== undefined &&
      countyList.length > 0 &&
      countyList.filter((x) => x.name === data["US_County"]).length > 0 &&
      countyList.filter((x) => x.name === data["US_County"])[0].geonameId;

    const orgStateId =
      data["Organisation_State"] !== undefined &&
      stateList.length > 0 &&
      stateList.filter((x) => x.name === data["Organisation_State"]).length >
        0 &&
      stateList.filter((x) => x.name === data["Organisation_State"])[0]
        .geonameId;

    const orgCountyId =
      data["Organisation_County"] !== undefined &&
      countyList.length > 0 &&
      countyList.filter((x) => x.name === data["Organisation_County"]).length >
        0 &&
      countyList.filter((x) => x.name === data["Organisation_County"])[0]
        .geonameId;

    const filterCategory = category.filter((x) => x.parentCode === 53);
    const selectedCategory =
      data["Grant_Program"] !== undefined
        ? filterCategory.filter((x) => x.name === data["Grant_Program"])
        : [];
    const filterSubCategory =
      selectedCategory.length > 0
        ? subCategory.filter(
            (x) => x.parentCode === parseInt(selectedCategory[0].code)
          )
        : [];
    const subCate =
      filterSubCategory.length > 0
        ? filterSubCategory[0].name.split(",").map((item, key) => {
            return { id: key + 1, name: item };
          })
        : [];

    return (
      <div className="d-flex justify-content-center container">
        <form onSubmit={this.handleSubmit}>
          <div className="border mb-5" onMouseUp={this.handleMouseUp}>
            <ClientHeader />
            <div
              className="col-md-12 p-2 mt-2 text-center form-group"
              style={{ backgroundColor: "#2b62a1" }}
            >
              <h2
                className="mb-0 text-white"
                tabIndex="-1"
                onFocus={this.handleHeadingFocus}
              >
                REGISTRATION FORM - SUB - CONTRACTORS
              </h2>
            </div>
            {isDisableForm && (
              <div className="col-md-12 mb-3 text-right">
                <a href="/" className="btn btn-default">
                  Back
                </a>
              </div>
            )}
            <fieldset disabled={isDisableForm}>
              <div className="col-md-12">
                <div
                  className="card card-body p-3 shadow-none"
                  style={{
                    border: "1px solid #a8ce98",
                    backgroundColor: "#E5F8E5",
                  }}
                >
                  <p onFocus={this.handleParagraphFocus} tabIndex="-1">
                    Please complete the registration form below. You will be
                    required to upload an electronic copy of your
                    government-issued photo ID (state ID, driver’s license) and
                    a copy of your professional license(s) as applicable.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="card card-body pl-3 pr-3 pt-3 pb-0 shadow-none"
                  style={{
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #ccc",
                  }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      {this.renderSelect(
                        "Grant_Program",
                        "Select the grant program",
                        filterCategory,
                        "name",
                        "name"
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.renderSelect(
                        "Grant_Program_Subtype",
                        "Select the grant program type",
                        subCate,
                        "name",
                        "name"
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput(
                        "Organisation_Name",
                        "Organization Name"
                      )}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          htmlFor="workflowId"
                          className="form-control-label"
                        >
                          Organization Type
                        </label>
                        <select
                          className="form-control"
                          name="userType"
                          id="userType"
                          value={data["userType"]}
                          onChange={this.handleUserTypeChanged}
                        >
                          <option value="0">Select</option>
                          {workflowUserTypes.map((s) => (
                            <option key={s.id} value={s.name}>
                              {s.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label
                          htmlFor="workflowId"
                          className="form-control-label"
                        >
                          Select the Application you are applying for
                        </label>
                        <select
                          className="form-control"
                          name="workflowId"
                          id="workflowId"
                          value={data["workflowId"]}
                          onChange={this.handleApplicationChange}
                          aria-label="Select the Application you are applying for"
                        >
                          <option value="0">Select</option>
                          {applications.map((s) => (
                            <option key={s.chkID} value={s.chkID}>
                              {s.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="card card-body pl-3 pr-3 pt-3 pb-0"
                  style={{
                    backgroundColor: "#D9EFFC",
                    border: "1px solid #92d8e1",
                  }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      {this.renderInput(
                        "Organisation_Address1",
                        "Organisation Address Line 1"
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput(
                        "Organisation_Address2",
                        "Organisation Address Line 2"
                      )}
                    </div>
                    <div className="col-md-3">
                      {this.renderInput(
                        "Organisation_City",
                        "Organisation US City"
                      )}
                      {/* {this.renderSelect(
                        "Organisation_City",
                        "Organisation US City",
                        cityList,
                        "name",
                        "name"
                      )} */}
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          htmlFor="Organisation_State"
                          className="form-control-label"
                        >
                          Organisation US State
                        </label>
                        <select
                          className="form-control"
                          name="Organisation_State"
                          id="Organisation_State"
                          value={orgStateId}
                          onChange={this.handleStateChanged}
                          //onFocus={this.handleDropdwonFocus}
                          aria-label="US State"
                        >
                          <option value="0">Select</option>
                          {stateList.map((s) => (
                            <option key={s.geonameId} value={s.geonameId}>
                              {s.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      {this.renderInput(
                        "Organisation_Zip",
                        "Organisation Zip Code"
                      )}
                    </div>
                    <div className="col-md-3">
                      {this.renderInput(
                        "Organisation_County",
                        "Organisation US County"
                      )}
                      {/* <div className="form-group">
                        <label
                          htmlFor="Organisation_County"
                          className="form-control-label"
                        >
                          Organisation US County
                        </label>
                        <select
                          className="form-control"
                          name="Organisation_County"
                          id="Organisation_County"
                          value={orgCountyId}
                          onChange={this.handleCountyChanged}
                          //onFocus={this.handleDropdwonFocus}
                          aria-label="US County"
                        >
                          <option value="0">Select</option>
                          {countyList.map((s) => (
                            <option key={s.geonameId} value={s.geonameId}>
                              {s.name}
                            </option>
                          ))}
                        </select>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="card card-body pl-3 pr-3 pt-3 pb-0"
                  style={{
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #ccc",
                  }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      {this.renderInput(
                        "Duns",
                        "Data Universal Number System (DUNS) #"
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput("Federal_Ein", "Federal EIN")}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput(
                        "Organisation_Contact_Name",
                        "Organization’s Contact Name"
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput("Contact_Title", "Contact Title")}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput(
                        "Contact_Phone_Number",
                        "Contact Phone Number"
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput("Contact_Email", "Contact Email")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <h4 className="text-red">
                  <i className="fa fa-hand-o-right" aria-hidden="true"></i>{" "}
                  Individual filing the application
                </h4>
              </div>
              <div className="col-md-12">
                <div
                  className="card card-body pl-3 pr-3 pt-3 pb-0"
                  style={{
                    backgroundColor: "#D9EFFC",
                    border: "1px solid #92d8e1",
                  }}
                >
                  <div className="row">
                    <div className="col-md-2">
                      {this.renderSelect(
                        "Title",
                        "Title",
                        titles,
                        "name",
                        "name"
                      )}
                    </div>
                    <div className="col-md-3">
                      {this.renderInput(
                        "First_Name",
                        `${
                          data["userType"] !== "applicant"
                            ? "Your First Name"
                            : "Your First Name"
                        }`
                      )}
                    </div>
                    <div className="col-md-3">
                      {this.renderInput("Middle_Name", "Middle Name")}
                    </div>
                    <div className="col-md-4">
                      {this.renderInput("Last_Name", "Last Name")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="card card-body pl-3 pr-3 pt-3 pb-0"
                  style={{
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #ccc",
                  }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      {this.renderInput("Address_Line1", "Address Line 1")}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput("Address_Line2", "Address Line 2")}
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-3">
                      {this.renderInput("US_City", "US City")}
                      {/* {this.renderSelect(
                        "US_City",
                        "US City",
                        cityList,
                        "name",
                        "name"
                      )} */}
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <label
                          htmlFor="US_State"
                          className="form-control-label"
                        >
                          US State
                        </label>
                        <select
                          className="form-control"
                          name="US_State"
                          id="US_State"
                          value={stateId}
                          onChange={this.handleStateChanged}
                          onFocus={this.handleDropdwonFocus}
                          aria-label="US State"
                        >
                          <option value="0">Select</option>
                          {stateList.map((s) => (
                            <option key={s.geonameId} value={s.geonameId}>
                              {s.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-3">
                      {this.renderInput("Zip_Code", "Zip Code")}
                    </div>
                    <div className="col-md-3">
                      {this.renderInput("US_County", "US County")}
                      {/* <div className="form-group">
                        <label
                          htmlFor="US_County"
                          className="form-control-label"
                        >
                          US County
                        </label>
                        <select
                          className="form-control"
                          name="US_County"
                          id="US_County"
                          value={countyId}
                          onChange={this.handleCountyChanged}
                          onFocus={this.handleDropdwonFocus}
                          aria-label="US County"
                        >
                          <option value="0">Select</option>
                          {countyList.map((s) => (
                            <option key={s.geonameId} value={s.geonameId}>
                              {s.name}
                            </option>
                          ))}
                        </select>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="card card-body pl-3 pr-3 pt-3 pb-0"
                  style={{
                    backgroundColor: "#D9EFFC",
                    border: "1px solid #92d8e1",
                  }}
                >
                  <div className="row">
                    <div className="col-md-6">
                      {this.renderInput("Email", "Email Address")}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput("Mobile", "Mobile No.")}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {this.renderSelect(
                        "question",
                        "Password Hint Question",
                        hintQuestions,
                        "name",
                        "id"
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.renderInput("answer", "Password Hint Answer")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="card card-body p-3"
                  style={{
                    backgroundColor: "#f5f5f5",
                    border: "1px solid #ccc",
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <label className="form-control-label">
                        Choose options to receive the User Credentials
                      </label>
                    </div>
                    <div className="col-md-12">
                      <input
                        type="radio"
                        name="message"
                        id="bymobile"
                        value="mobile"
                        onChange={this.handleChange}
                      />
                      <label
                        htmlFor="bymobile"
                        className="form-control-label ml-2 mr-2"
                      >
                        Mobile (SMS)
                      </label>
                      <label id="phonenumber"> </label>
                      <input
                        type="radio"
                        name="message"
                        id="byemail"
                        value="email"
                        onChange={this.handleChange}
                      />
                      <label
                        htmlFor="byemail"
                        className="form-control-label ml-2"
                      >
                        Email
                      </label>
                    </div>
                    <div className="col-md-12">
                      <ReCAPTCHA
                        sitekey="6Le08xsUAAAAAKC6Oglhe-haRVGzA8KTLUZ-jmEK"
                        onChange={this.onCaptchaChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div
                  className="card card-body p-3"
                  style={{
                    backgroundColor: "#D9EFFC",
                    border: "1px solid #92d8e1",
                  }}
                >
                  <div className="row">
                    <div className="col-md-12 text-center">
                      {this.renderButton("Register")}
                      <a href="/Login" id="btnExit" className="btn btn-danger">
                        Exit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          {voiceTest !== "" && (
            <div className="col-md-12">
              <div
                className="card card-body border"
                style={{ position: "fixed", bottom: "-30px", width: "84%" }}
              >
                <div className="row">
                  <div className="col-md-12 d-flex">
                    <label htmlFor="voiceText" className="form-control-label">
                      Voice Command :
                    </label>
                    <input
                      type="text"
                      name="voiceText"
                      id="voiceText"
                      value={voiceTest}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <Progress
            showHide={showHide}
            handleModalShowHide={this.handleModalShowHide}
            formProcess={formProcess}
            processId={processId}
          ></Progress>

          <div
            style={{
              display: `none`,
              position: "sticky",
              bottom: "0px",
              boxShadow: "#c8c8c8 0px 0px 15px 5px",
              border: "20px solid #000000",
            }}
          >
            <Keyboard
              keyboardRef={(r) => (this.keyboard = r)}
              layoutName={this.state.layoutName}
              onChange={this.onChange}
              onKeyPress={this.onKeyPress}
            />
            <Dictionary
              dictionaryShowHide={dictionaryShowHide}
              handledictionaryShowHide={this.handledictionaryShowHide}
              selectedText={selectedText}
            ></Dictionary>
          </div>
        </form>
      </div>
    );
  }
}

export default Registration;
