import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import Moment from "react-moment";
class WeekLogView extends Component {
  render() {
    const { weekShowHide, handleWeekShowHide, SelectedObj } = this.props;
    const wt = SelectedObj !== "" ? JSON.parse(SelectedObj.weekTimes) : [];
    return (
      <Modal show={weekShowHide}>
        <Modal.Header closeButton onClick={() => handleWeekShowHide("")}>
          <Modal.Title>View Week Log</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            <div className="col-lg-12">
              <table className="table table-boardred bg-white">
                <thead className="thead-light">
                  <tr>
                    <th>Day</th>
                    <th>Date</th>
                    <th>Hour</th>
                  </tr>
                </thead>
                <tbody>
                  {wt.map((item) => (
                    <tr>
                      <td>{item.Day}</td>
                      <td>
                        {item.Date !== null && (
                          <Moment format="MMM Do YYYY">{item.Date}</Moment>
                        )}
                      </td>
                      <td>{item.Hour}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleWeekShowHide("")}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default WeekLogView;
