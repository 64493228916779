import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "tools",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        documents: [],
        reqDocuments: [],
        emailTemplates: [],
        letterTemplates: [],
        toolsCount: [],
        appMessages: [],
        appComments: [],
        compUpdateStatus: false,
        componentUserStatus: []
    },
    reducers: {
        emailTemplateReceived: (tools, action) => {
            tools.emailTemplates = action.payload;
        },
        letterTemplateReceived: (tools, action) => {
            tools.letterTemplates = action.payload;
        },
        toolsCountReceived: (tools, action) => {
            tools.toolsCount = action.payload;
        },
        componentUserStatusReceived: (tools, action) => {
            tools.componentUserStatus = action.payload;
            tools.compUpdateStatus = false;
        },
        componentCountUpdated: (tools, action) => {
            tools.compUpdateStatus = action.payload;
        },

    }
});

export const {
    emailTemplateReceived, letterTemplateReceived,
    toolsCountReceived, componentCountUpdated,
    componentUserStatusReceived } = slice.actions;

export default slice.reducer;

export const loadLetterTemplates = (type) => apiCallBegan({
    url: config.GetFormsByType + type,
    onSuccess: letterTemplateReceived.type
});

export const loadEmailTempates = (type) => apiCallBegan({
    url: config.GetFormsByType + type,
    onSuccess: emailTemplateReceived.type
});

export const loadToolsCount = (id) => apiCallBegan({
    url: config.GetPortalToolCount + id,
    onSuccess: toolsCountReceived.type
});

export const loadComponentUserStatus = (id, ciid) => apiCallBegan({
    url: config.GetComponentUserStatus + id + "&ciid=" + ciid,
    onSuccess: componentUserStatusReceived.type
});

export const UpdateComponentsUserStatus = (data) => apiCallBegan({
    url: config.UpdateComponentStatus,
    method: "post",
    data: data,
    onSuccess: componentCountUpdated.type
});

export const getEmailTemplates = createSelector(
    state => state.entities.tools,
    tools => tools.emailTemplates
);

export const getLetterTemplates = createSelector(
    state => state.entities.tools,
    tools => tools.letterTemplates
);

export const getToolsCount = createSelector(
    state => state.entities.tools,
    tools => tools.toolsCount
);

export const getComponentUserStatus = createSelector(
    state => state.entities.tools,
    tools => tools.componentUserStatus
);

export const getComponentUpdateStatus = createSelector(
    state => state.entities.tools,
    tools => tools.compUpdateStatus
);
