import React, { useEffect, useState } from "react"
import PageHeader from "../../../Components/Comman/PageHeader"
import OnboardingSteps from "./OnboardingSteps";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import {
    getAppDocuments, getAppDocusignDocs, getAppFormLibrary, getAppTasks,
    getCandidateApps, loadAppDocuments, loadAppDocusignDocs,
    loadAppFormLibrary,
    loadAppRuningTasks, loadCandidateApps
} from "../../store/candidate";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails, sign } from "../../store/auth";
import { useNavigate } from "react-router-dom";
import CandidateInfo from "../CandidateInfo";
import { getFieldData } from "../../../Services/appService";
import UploadDocPopup from "../../../Components/UploadDocPopup";
import { getAlert } from "../../../Services/appAlerts";
import PartnerInfo from "../PartnerInfo";
import EnrollmentSteps from "./EnrollmentSteps";
import { setCurrentPageTitle, setCurrentRoute } from "../../store/layoutUI";
import ViewForm from "../../../Components/ApplicantProcessing/ViewForm";
import { getReqDocuments, getSignedDocumentData, loadReqDocuments, loadSignedDocument, signedDocumentReceived } from "../../store/documents";
import useDocDownload from "../../hooks/useDocDownload";
import { getComponentUserStatus, UpdateComponentsUserStatus } from "../../store/tools";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function ContractDocs() {

    const usrType = localStorage.getItem("userType");

    const [appTasklist, setAppTasklist] = useState([]);
    const [entityType, setEntityType] = useState("");
    const [signDocs, setSignDocs] = useState([]);
    const [sentDocs, setSentDocs] = useState([]);
    const [receivedDocs, setReceivedDocs] = useState([]);
    const [appFormLibrary, setAppFormLibrary] = useState([]);
    const [reqDocuments, setReqDocuments] = useState([]);
    const [docLoading, setDocLoading] = useState(false);
    const [docLoadingId, setDocLoadingId] = useState(0);
    const [signLoading, setSignLoading] = useState(false);
    const [uploadDocShowHide, setUploadDocShowHide] = useState(false);
    const [selectedSignedDoc, setSelectedSignedDoc] = useState(null);
    const [userDetails, setUserDetails] = useState(null);
    const [docType, setDocType] = useState("");
    const [wfTask, setWfTask] = useState(null);
    const [wfId, setWfId] = useState(0);
    const [formShowHide, setFormShowHide] = useState(false);
    const [formObj, setFormObj] = useState(null);

    const candidateAppsSelector = useSelector(getCandidateApps);
    const appTasksSelector = useSelector(getAppTasks);
    const appDocumentsSelector = useSelector(getAppDocuments);
    const userDetailsSelector = useSelector(getLoggedUserDetails);
    const appFormLibrarySelector = useSelector(getAppFormLibrary);
    const reqDocumentsSelector = useSelector(getReqDocuments);
    const appDocusignDocSelector = useSelector(getAppDocusignDocs);
    const signedDocumentDataSelector = useSelector(getSignedDocumentData);
    const componentUserStatusSelector = useSelector(getComponentUserStatus);

    const [handleDownloadFile] = useDocDownload();

    const dispatch = useDispatch();
    const currentUser = getCurrentUser();
    const navigate = useNavigate();

    const route = [
        { name: "Task Management", url: "" },
        { name: "Onboarding Steps", url: "" },
    ];
    const route1 = [
        { name: "Contract Management", url: "" },
        { name: "Enrollment Steps", url: "" },
    ];

    useEffect(() => {
        dispatch(setCurrentPageTitle("STEP-2 : Contract Documents"));
        dispatch(setCurrentRoute(usrType === "candidate" ? route : route1))
    }, [])

    useEffect(() => {
        if (!userDetailsSelector)
            dispatch(loadUserDetails(currentUser.id));
        if (candidateAppsSelector?.length === 0)
            dispatch(loadCandidateApps(currentUser.id));
        if (appDocusignDocSelector?.length === 0)
            dispatch(loadAppDocusignDocs(currentUser.id));
    }, []);

    useEffect(() => {
        setUserDetails(userDetailsSelector);
        setSignDocs(appDocusignDocSelector);
    }, [userDetailsSelector, appDocusignDocSelector]);

    useEffect(() => {
        if (candidateAppsSelector.length > 0) {
            const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
            if (mainWorkflow.length > 0) {
                const wf = mainWorkflow[0];
                setWfId(wf.oldWorkflowId);
                dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
                dispatch(loadAppFormLibrary(wf.oldWorkflowId, currentUser.id));
                dispatch(loadAppDocuments(wf.oldWorkflowId, currentUser.id));
            }
        }
    }, [candidateAppsSelector]);

    useEffect(() => {
        if (appTasksSelector.length > 0) {
            const filterTasks = appTasksSelector.filter(x => x.taskType === 1 && x.wiid > 0);
            setAppTasklist(filterTasks);

            if (filterTasks.length > 0) {
                dispatch(loadReqDocuments(filterTasks[0].ciid));
            }

            if (appTasksSelector.length > 0) {
                const entType = getFieldData(
                    appTasksSelector[0].stateData,
                    "candidateonboardingform",
                    "CandidateEntity"
                );
                setEntityType(entType);
            }
        }
    }, [appTasksSelector]);

    useEffect(() => {
        if (appDocumentsSelector.length > 0) {
            const appDocs = appDocumentsSelector;

            let receiveDoc = appDocs.filter(
                (x) =>
                    x.type === "received" &&
                    x.uploadType !== "Invoice" &&
                    x.uploadType !== "reporting" &&
                    x.uploadType !== "client" &&
                    x.uploadType !== "project" &&
                    x.docType !== "internal" &&
                    x.docType !== "Messaging" &&
                    x.docModifiedName !== "esign" &&
                    //!x.docType?.includes("_c2c") &&
                    x.sendStatus === true &&
                    x.deleteFlag !== true
            );
            let sentDocs = appDocs.filter(
                (x) =>
                    x.type === "send" &&
                    x.uploadType !== "Invoice" &&
                    x.docType !== "internal" &&
                    x.docType !== "Messaging" &&
                    x.uploadType !== "Messaging" &&
                    x.uploadType !== "monthly_time_sheet" &&
                    x.uploadType?.substring(0, 3) !== "ach" &&
                    x.uploadType?.substring(0, 3) !== "req" &&
                    x.uploadType?.substring(0, 9) !== "insurance"

            );
            setReceivedDocs(receiveDoc);
            setSentDocs(sentDocs);

            let docItems = "";
            receiveDoc.map((x, key) => {
                if (key > 0) docItems += ",";
                docItems += x.id;
            })

            sentDocs.map((x, key) => {
                if (key > 0) docItems += ",";
                docItems += x.id;
            })

            let appCiid = 0;
            if (receiveDoc.length > 0)
                appCiid = receiveDoc[0].ciid;

            if (sentDocs.length > 0)
                appCiid = sentDocs[0].ciid;

            handleUpdateComponent(docItems, appCiid);
        }

    }, [appDocumentsSelector]);

    useEffect(() => {
        if (appFormLibrarySelector.length > 0) {
            const frmLibrary = appFormLibrarySelector.filter(x => x.formType !== "ACH Information"
                && x.formType !== "Insurance Information");
            setAppFormLibrary(frmLibrary);
        }
    }, [appFormLibrarySelector]);

    useEffect(() => {
        if (reqDocumentsSelector.length > 0) {
            const reqDocs = reqDocumentsSelector.filter(x => x.sendStatus === true);
            setReqDocuments(reqDocs);

            if (reqDocs.length > 0) {
                let docItems = "";
                receivedDocs.map((x, key) => {
                    if (key > 0) docItems += ",";
                    docItems += x.id;
                })

                sentDocs.map((x, key) => {
                    if (key > 0 || docItems !== "") docItems += ",";
                    docItems += x.id;
                })

                reqDocs.map((x, key) => {
                    if (key > 0 || docItems !== "") docItems += ",";
                    docItems += x.id;
                })

                let appCiid = 0;
                if (receivedDocs.length > 0)
                    appCiid = receivedDocs[0].ciid;

                if (sentDocs.length > 0)
                    appCiid = sentDocs[0].ciid;

                handleUpdateComponent(docItems, appCiid);
            }
        }
    }, [reqDocumentsSelector])

    const handleViewSignedDoc = (item) => {
        setSelectedSignedDoc(item);
        setSignLoading(true);
        dispatch(loadSignedDocument(item.id));
    }

    useEffect(() => {
        if (signedDocumentDataSelector && selectedSignedDoc) {
            const url = window.URL.createObjectURL(signedDocumentDataSelector);
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";

            if (selectedSignedDoc?.docName)
                link.setAttribute("download", selectedSignedDoc.docName);
            else if (selectedSignedDoc?.documentName)
                link.setAttribute("download", selectedSignedDoc.documentName);
            else
                link.setAttribute("download", "ipcs_document");

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setSelectedSignedDoc(null);
            setSignLoading(false);
            dispatch(signedDocumentReceived(null));
        }
    }, [signedDocumentDataSelector])

    const handleUploadShowHide = (type) => {
        if (type === "save") {
            setUploadDocShowHide(false);
            dispatch(loadAppDocuments(wfId, currentUser.id));
        }
        else {
            const reviewApp = appTasklist.filter((x) => x.registrationType === "WP");
            if (reviewApp.length > 0) {
                const lastReviewer = reviewApp[0];
                setWfTask([lastReviewer]);
            } else {
                getAlert("", "No reviewer found", "error");
            }
            setUploadDocShowHide(!uploadDocShowHide);
            setDocType(type);
        }
    };

    const handleFormShowHide = (frm) => {
        if (frm === "save") {
            setFormShowHide(false);
            setFormObj("");
        } else {
            setFormShowHide(!formShowHide);
            setFormObj(frm);
        }
    };

    const getReqUploadedDocs = (item) => {
        const achDocs = appDocumentsSelector.filter(
            (x) => x.docType === `req_${item.id}`
        );

        if (achDocs.length === 0) {
            return <>
                <td>
                    {item.docType?.includes('_c2c_') && "Contract"}
                    {(item.docType !== "sharing" && !item.docType?.includes('_c2c_')) && "Pending"}
                </td>
                <td>
                    {item.docType !== "sharing" && !item.docType?.includes('_c2c_') &&
                        <button
                            className="btn btn-outline-default btn-sm btn-min-width"
                            onClick={() => handleUploadShowHide(`req_${item.id}`)}
                        >
                            <i className="fa fa-upload" aria-hidden="true"></i> Upload
                        </button>
                    }
                </td>

            </>
        }

        if (achDocs.length > 0) {
            const doc = achDocs[achDocs.length - 1];
            return <>
                <td>Uploaded</td>
                <td>
                    <button
                        className="btn btn-link btn-sm p-0"
                        title="Download Document"
                        onClick={() => handleDownloadFile(doc)
                        }
                    >
                        {doc.documentName}
                    </button>

                    {getPopover(doc.notes)}
                </td>

            </>
        }
    }

    const getReqRefDocs = (item) => {
        if (item.refDocId > 0) {
            const findDocs = appDocumentsSelector.filter(x => x.uploadId === item.refDocId);
            if (findDocs.length > 0) {
                return <>
                    {findDocs.map(doc =>
                        <td>
                            <button
                                className="btn btn-link btn-sm p-0"
                                title="Download Document"
                                onClick={() => handleDownloadFile(doc)
                                }
                            >
                                {doc.documentName}
                            </button>
                            <br />
                            {item.documnetName}
                        </td>
                    )}
                </>
            }
        }

        return <td>{item.documnetName}</td>

    }

    const getUnsignedDoc = (item) => {
        const signDoc = appDocumentsSelector.filter(x => x.docData === item.docData);
        if (signDoc.length === 0) {
            return <>
                <td>{item.documentName}</td>
            </>
        }

        if (signDoc.length > 0) {
            const doc = signDoc[0];
            return <>
                <td>
                    <button
                        className="btn btn-link btn-sm p-0"
                        title="Download Document"
                        onClick={() => handleDownloadFile(doc)}
                    >
                        {doc.documentName}
                    </button>
                </td>
            </>
        }
    }

    const handleUpdateComponent = (compItems, ciid) => {
        const comp = componentUserStatusSelector.filter(x => x.compId === 3);
        let updCompItems = "";
        if (comp.length > 0) {
            updCompItems = comp[0].compItems;
        }
        const compObj = {
            compName: "Contract Document",
            performerId: currentUser.id,
            compItems: compItems,
            ciid: ciid,
            wiid: 0,
            iiid: 0,
            compId: 3
        }
        if (compItems !== "" && compItems !== updCompItems) {
            dispatch(UpdateComponentsUserStatus(compObj));
        }
    }

    const getPopover = (notes) => {
        const popover = (
            <Popover id="popover-basic">
                <Popover.Title as="h4">Notes</Popover.Title>
                <Popover.Content>{notes}</Popover.Content>
            </Popover>
        );
        return (
            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                <i className="fa fa-info-circle btn btn-sm" style={{ cursor: "pointer" }}></i>
            </OverlayTrigger>
        );
    };

    const getPopover1 = (notes) => {
        const popover = (
            <Popover id="popover-basic">
                <Popover.Title as="h4">Notes</Popover.Title>
                <Popover.Content>{notes}</Popover.Content>
            </Popover>
        );
        return (
            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                <button className="btn btn-sm btn-link"> View</button>
            </OverlayTrigger>
        );
    };

    return <React.Fragment>

        <div className="col-md-12 mt-2 form-group">
            <div className="card border shadow-none">
                <div className="card-header p-3">
                    <div className="row">
                        <div className="col-md-8">
                            <i className="fa fa-square wf-phase2-box mr-2" aria-hidden="true"></i>
                            <span className="h3">Contract and other documents</span>
                            <p className="text-red">
                                Download, complete, sign and upload documents as PDF.
                                In this section you will also see the final executed
                                contract documents.
                            </p>
                        </div>
                        <div className="col-md-4 text-right">
                            <button
                                className="btn btn-sm btn-outline-default mt-2 btn-min-width"
                                title="Use this feature to upload any documents as may be required."
                                onClick={() => handleUploadShowHide("")}
                            >
                                <i className="fa fa-upload" aria-hidden="true"></i> Upload
                            </button>
                        </div>

                    </div>
                </div>
                <div className="card-body p-3">
                    <div className="table-responsive">
                        <p className="text-red mb-2">
                            (Open password protected document : First 3 letters of
                            your first name and starting 3 charactors of the your ID
                            in lower case) Example - (abc123).
                        </p>
                        <table className="table align-items-center table-flush table-hover table-striped border">
                            <thead className="thead-light">
                                <tr>
                                    <th>S.No.</th>
                                    <th>Requirement</th>
                                    <th>Description</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reqDocuments.map((item, key) =>
                                    <tr>
                                        <td>{key + 1}</td>
                                        {getReqRefDocs(item)}
                                        <td>{getPopover1(item.notes)}</td>
                                        {getReqUploadedDocs(item)}
                                    </tr>
                                )}
                                {receivedDocs.map((item, key) =>
                                    <tr>
                                        <td>{key + 1 + reqDocuments.length}

                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-sm btn-link pl-0"
                                                title="Download Document"
                                                onClick={() => handleDownloadFile(item)}
                                            >

                                                {item.documentName}
                                            </button>
                                        </td>
                                        <td>{getPopover1(item.notes)}</td>
                                        {getReqUploadedDocs(item)}
                                    </tr>
                                )}
                                {signDocs.map((item, key) =>
                                    <tr>
                                        <td>{key + 1 + reqDocuments.length + receivedDocs.length}

                                        </td>
                                        {getUnsignedDoc(item)}
                                        <td>{getPopover1(item.remarks)}</td>
                                        <td>
                                            {item.status ? "Signed" : "Pending"}
                                        </td>
                                        <td>
                                            {item.status ? (
                                                <button
                                                    className="btn btn-link btn-sm p-0"
                                                    onClick={() => handleViewSignedDoc(item)
                                                    }
                                                >
                                                    {item.documentName}
                                                </button>
                                            ) : (
                                                <a
                                                    className="btn btn-outline-dark btn-sm btn-min-width"
                                                    target="_blank"
                                                    href={`https://wf-esign.azurewebsites.us/${item.id}`}
                                                >

                                                    Complete and Sign
                                                </a>
                                            )}
                                        </td>
                                    </tr>
                                )}
                                {sentDocs.map((item, key) =>
                                    <tr>
                                        <td>{key + 1 + reqDocuments.length + receivedDocs.length + signDocs.length}

                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-sm btn-link pl-0"
                                                title="Download Document"
                                                disabled={docLoading && item.id === docLoadingId}
                                                onClick={() => handleDownloadFile(item)}
                                            >
                                                {docLoading &&
                                                    item.id === docLoadingId && (
                                                        <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
                                                    )}
                                                {item.documentName}
                                            </button>
                                        </td>
                                        <td>{getPopover1(item.notes)}</td>
                                        <td>Sent</td>
                                        <td></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>


        {uploadDocShowHide &&
            <UploadDocPopup
                uploadDocShowHide={uploadDocShowHide}
                handleUploadDocModalShowHide={handleUploadShowHide}
                runningApplication={wfTask}
                selectedDocType={docType}
            ></UploadDocPopup>}
        {formShowHide && <ViewForm
            formShowHide={formShowHide}
            handleFormShowHide={handleFormShowHide}
            workflowTask={wfTask}
            selectedForm={formObj}
        ></ViewForm>}
    </React.Fragment>
}