import http from "./httpService"
import config from "../config.json";
import JwtDecode from "jwt-decode";

const apiEndPoint = "/account/";
const apiEndPoint1 = "https://iam.qa.premierit.host" + "/oauth/token";
const tokenKey = "token";
//const key=getJwt();
//console.log(key);
//http.setJwt(key);

export const getAuthToken = async (username, password) => {
  const formData = new FormData();
  formData.append("grant_type", "password");
  formData.append("username", username);
  formData.append("password", password);
  formData.append("response_type", "token");

  const obj = {
    grant_type: "password",
    username: username,
    password: password,
    response_type: "token",
  };

  const { data } = await http.post(apiEndPoint1, formData, {
    auth: {
      username: "cent-dev",
      password: "Altapiakayns",
    },
  });
  //console.log(data);
  return data;
};

export async function login(username, password) {
  const userType = "applicant";
  const { data: user } = await http.post(apiEndPoint + "authenticate", {
    username,
    password,
    userType,
  });
  localStorage.setItem("currentUser", JSON.stringify(user));
}

export async function changePassword(oldPassword, newPassword, userId) {
  const { data } = await http.post(apiEndPoint + "ChangePassword", {
    oldPassword,
    newPassword,
    userId
  });
  //console.log(data);
}

export async function changeEmail(email, userId) {
  const { data } = await http.post(apiEndPoint + "ChangeEmail", {
    email,
    userId,
  });
  //console.log(data);
  return data;
}

export async function verifyUser(frmData) {
  const { data } = await http.post(apiEndPoint + "VerifyUser", frmData);
  return data;
}

export async function forgotPassword(frmData) {
  const { data } = await http.post(apiEndPoint + "ForgotPassoword", frmData);
  return data;
}

export async function logout() {
  const user = getCurrentUser();
  localStorage.removeItem(tokenKey);
  const { data } = await http.get(
    config.UpdateLoginLogoutStatus + user.id + "&type=logout"
  );
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey);
    return JwtDecode(jwt);
  } catch (error) {
    return null;
  }
}

export async function getCurrentUserDetails(performerId) {
  const { data } = await http.get(apiEndPoint + "GetUser?performerId=" + performerId);
  return data;
}

export function getJwt() {
  const token = localStorage.getItem("token");
  if (token === null) return "";
  return token;
}

export async function validateUser(userId, email, pwd) {
  const userType = "applicant";
  const { data: user } = await http.post(apiEndPoint + "ValidateUser", { userId, email, userType, password: pwd });
  localStorage.setItem(tokenKey, user.token);
}

export function getLoginUser() {
  return localStorage.getItem("currentUser");
}

export default {
  login,
  logout,
  getCurrentUser,
  getJwt,
  getCurrentUserDetails,
  changePassword,
  changeEmail,
  verifyUser,
  forgotPassword,
  getAuthToken,
  validateUser,
  getLoginUser
};
