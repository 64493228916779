import React, { Component } from "react";
import { Link } from "react-router-dom";

class ThankYou extends Component {
  render() {
    const { forgotType, urlData } = this.props;

    return (
      <div className="row">
        <div className="col-md-12 form-group text-center">
          <h2 className="mb-0">Thank you, Your request has been accepted.</h2>
        </div>
        <div className="col-md-12 form-group text-center">
          <p>
            If the information entered is correct, your{" "}
            {forgotType === "pwd" ? "Reset Password Link" : forgotType} has been
            sent to the email address specified.
          </p>
          <p>Please check your email address and return to the sign in page.</p>
        </div>

        <div className="col-md-12 text-center">
          <Link
            to={{
              pathname: "/Login",
              data: urlData,
            }}
            className="btn btn-danger w-100"
          >
            RETURN TO SIGN IN PAGE
          </Link>
        </div>
      </div>
    );
  }
}

export default ThankYou;
