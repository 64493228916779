import React, { useEffect, useState } from "react";
import Joi from "joi-browser";
import Form from "./Comman/Form";
import auth from "../Services/authService";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import ForgetPassword from "../Components/ForgetPassword";
import ClientHeader from "./Comman/ClientHeader";
import LoginStep1 from "./Auth/LoginStep1";
import LoginStep2 from "./Auth/LoginStep2";
import http from "../Services/httpService";
import config from "../config.json";
import Moment from "react-moment";
import ProgramDetailsPopup from "./ProgramDetailsPopup";
import LoginHeader from "./Auth/LoginHeader";
import LoginFooter from "./Auth/LoginFooter";
import { getCurrentUser, getLoggedUser, getLoginFailedStatus, login, loginFailed, sign } from "../app/store/auth";
import { useDispatch, useSelector } from "react-redux";

const Login = () => {
  
  const [validId, setValidId] = useState("");
  const [userName, setUserName] = useState("");
  const [userPwd, setUserPwd] = useState("");
  const [stateErrors, setStateErrors] = useState("");
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);

  const usrType = localStorage.getItem("userType");

  const loggedUserSeletor=useSelector(getLoggedUser);
  const loginFailedStatusSelector=useSelector(getLoginFailedStatus);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    const state = location.state;
    if (state?.validId) {
      setValidId(state.validId);
      setStep1(false);
      setStep2(true);
    }
  }, []);

  useEffect(()=>{
    if(loggedUserSeletor.user && !loggedUserSeletor.token){
      localStorage.setItem("tempPwd", userPwd);
      navigate("/ValidateUser");
    }
    else{
      if(currentUser){
        navigate("/");
      }
    }
    if(currentUser){
      navigate("/");
    }
  },[loggedUserSeletor]);

  useEffect(()=>{
    if(loginFailedStatusSelector !== ""){
      setStateErrors("Username or password is incorrect");
      dispatch(loginFailed(""))
    }
  },[loginFailedStatusSelector])

  const handleStep1 = (step1Data) => {
    if (step1Data?.validId) {
      setStep1(false);
      setStep2(true);
      setValidId(step1Data.validId);
    }
  };

  const doSubmit = async (step2Data) => {
    dispatch(sign(step2Data.username,step2Data.password));
    setUserName(step2Data.username);
    setUserPwd(step2Data.password);
  };

  const handleBack = () => {
    setStep1(true);
    setStep2(false);
    setValidId("");
    localStorage.removeItem("userType");
  };

  const handleForgotPwd = () => {
    navigate("/ForgotPassword", { state: { validId } });
  }

  const supportFooter = () => {
    return <div className="card shadow-none border">
      <div className="card-body p-2 text-center">
        <p>
          Customer Support :{" "}
          <a href="mailto:Support1@workfinity.com">
            Support1@workfinity.com
          </a>
        </p>
      </div>
    </div>
  }

  return (
    <React.Fragment>
      {step1 && <div className="col-md-12 d-flex align-items-center " style={{ height: "calc(100vh - 278px)" }}>
        <div className="row d-flex justify-content-center align-items-center" style={{ height: "calc(100vh - 278px)!important" }}>
          <div className="col-md-5 d-flex align-items-stretch pr-0 pl-0">
            <div
              className="card shadow-none border rounded-0"
              style={{
                backgroundColor: "#eceff1",
                marginTop: "30px",
                height: "300px",
              }}
            >
              <div className="paddd-1 card-body d-flex align-items-center">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h1>
                      Achieve agility and strategic business advantage
                      with WorkFINITY® technology framework
                    </h1>
                  </div>
                  <div className="col-md-12 text-center">
                    <h4>
                      Delivering next-generation, robust and scalable
                      digital service delivery platforms
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 pl-0 pr-0">
            <div
              className="card shadow-none border rounded-0"
              style={{
                backgroundColor: "#fafafa",
                marginTop: "30px",
              }}
            >
              <div className="padd-1 card-body">
                <div className="text-center text-white mb-2">
                  <h1 className="text-black">SIGN IN</h1>
                </div>
                <LoginStep1
                  handleStep1={handleStep1}
                  userError={stateErrors}
                  type="admin"
                ></LoginStep1>
                <Link
                  to="/ForgotValidID"
                  className="btn btn-link btn-sm"
                  style={{ float: "right" }}
                >
                  Forgot Your ID?
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="col-md-10 pl-0 pr-0">
            {supportFooter()}
          </div> */}
        </div>
      </div>}

      {step2 && <div className="col-md-12"  style={{ height: "calc(100vh - 278px)" }}>
        <div className="row d-flex justify-content-center align-items-center" style={{ height: "calc(100vh - 278px)" }}>
          <div className="col-md-5">
            <div
              className="cust-card-pp shadow-none border rounded-0"
              style={{
                backgroundColor: "#fafafa",
                marginTop: "0px",
              }}
            >
              <div className="pp-1 card-body">
                <div className="text-center text-white mb-2">
                  <h2 className="text-black">SIGN IN</h2>
                </div>
                <div>
                  <button
                    className="btn btn-link btn-sm"
                    onClick={handleBack}
                  >
                    <i
                      className="fa fa-long-arrow-left"
                      aria-hidden="true"
                    ></i>
                    Back
                  </button>
                </div>
                <div>
                  <label className="form-control-label">
                    User Type : {usrType}, Valid ID : {validId}
                  </label>
                </div>
                <LoginStep2
                  handleDoSubmit={doSubmit}
                  userError={stateErrors}
                  handleForgotPwd={handleForgotPwd}
                  type="admin"
                ></LoginStep2>
      
              </div>
            </div>
          </div>
          {/* <div className="col-md-7">
            {supportFooter()}
          </div> */}
        </div>
      </div>}
    </React.Fragment>
  );
}

export default Login;
