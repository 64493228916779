import React from "react";

const FileUpload = (props) => {
    const {
        controleId,
        comp,
        stateData: data,
        stateErrors: errors,
        isDisabled,
        onFileChange,
    } = props;

    return <>
        <div className={`form-group ${comp.isRequired && "required"}`}>
            <label className="form-control-label" htmlFor={controleId}>
                {comp.label}
            </label>
            <br />
            <div className="custom-file">
                <input
                    type="file"
                    className="custom-file-input"
                    id={controleId}
                    name={controleId}
                    disabled={isDisabled}
                    aria-label={comp.label}
                    onChange={onFileChange}
                />
                <label className="custom-file-label" htmlFor={controleId}>
                    {data[controleId] === undefined
                        ? "Select file"
                        : data[controleId]}
                </label>
            </div>
        </div>
    </>
}

export default FileUpload;