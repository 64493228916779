import React, { Component, useEffect, useState } from "react";
import PageHeader from "../Comman/PageHeader";
import AddNewTimeLog from "./AddNewTimeLog";
import CandidateDetails from "./CandidateDetails";
import auth from "../../Services/authService";
import http from "../../Services/httpService";
import config from "../../config.json";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../../app/store/auth";
import { getAppTasks, getCandidateApps, getFilterAllCandidates, loadAppRuningTasks, loadCandidateApps, loadCandidatesByFilter } from "../../app/store/candidate";
import { getFieldData, getFormatedNumber } from "../../Services/appService";
import OnboardingSteps from "../../app/components/Onboarding/OnboardingSteps";
import CandidateInfo from "../../app/components/CandidateInfo";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";

export default function ReportingDetails() {
  const route = [
    { name: "Task Management", url: "" },
    { name: "Onboarding Steps", url: "" },
  ];

  const [appTasklist, setAppTasklist] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [rptDocs, setRptDocs] = useState([]);
  const [wfTask, setWfTask] = useState(null);
  const [entityType, setEntityType] = useState("");
  const [candidate, setCandidate] = useState(null);

  const candidateSelector = useSelector(getFilterAllCandidates);
  const userDetailsSelector = useSelector(getLoggedUserDetails);
  const candidateAppsSelector = useSelector(getCandidateApps);
  const appTasksSelector = useSelector(getAppTasks);

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    dispatch(setCurrentRoute(route))
  }, [])

  useEffect(() => {
    if (!userDetailsSelector)
      dispatch(loadUserDetails(currentUser.id));
    getCandidateDetails(currentUser.id);
    dispatch(loadCandidateApps(currentUser.id));
  }, []);

  const getCandidateDetails = (id) => {
    const frmBody = new FormData();
    frmBody.append("id", id);
    frmBody.append("name", "");
    frmBody.append("email", "");
    frmBody.append("entity", "");
    frmBody.append("type", "candidate");
    frmBody.append("filterType", "single");
    dispatch(loadCandidatesByFilter(frmBody));
  };

  useEffect(() => {
    console.log(candidateSelector)
    if (candidateSelector.length > 0) {
      setCandidate(candidateSelector[0]);
      const entType = getFieldData(candidateSelector[0].performerData, "candidateonboardingform", "CandidateEntity");
      
      setEntityType(entType);
      let count = entityType !== "C2C Basis" ? "5" : "4";
      dispatch(setCurrentPageTitle(`STEP-${count} : Background Check`));
    }

  }, [candidateSelector]);

  useEffect(() => {
    if (userDetailsSelector) {
      const entityType = getFieldData(
        item.stateData,
        "candidateonboardingform",
        "CandidateEntity"
      );
     
      setUserDetails(userDetailsSelector);
      setEntityType(entityType);
      let count = entityType !== "C2C Basis" ? "5" : "4";
      dispatch(setCurrentPageTitle(`STEP-${count} : Background Check`));
    }

  }, [userDetailsSelector])

  useEffect(() => {
    if (candidateAppsSelector.length > 0) {
      const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
      if (mainWorkflow.length > 0) {
        const wf = mainWorkflow[0]
        dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
      }
    }
  }, [candidateAppsSelector]);

  useEffect(() => {
    if (appTasksSelector.length > 0) {
      const filterTasks = appTasksSelector.filter(x => x.taskType === 1 && x.wiid > 0);
      if (filterTasks.length > 0) {
        console.log(JSON.parse(filterTasks[0].stateData))
        setWfTask(filterTasks[0]);
      }
    }
  }, [appTasksSelector])


  let item = "";
  if (candidate)
    item = candidate;

  let count = entityType !== "C2C Basis" ? "5" : "4";
  return (
    <React.Fragment>

      {wfTask && <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-12">
            <div className="card shadow-none border">
              <div className="card-header p-3">
              <i className="fa fa-square wf-phase3-box mr-2" aria-hidden="true"></i>
              <span className="h3">
                  Reporting Details
                </span>
              </div>
              <div className="card-body p-3">
                <table className="table align-items-center table-hover table-striped border">
                  <thead className="thead-light">
                    <tr>
                      <th style={{ width: "20%" }}>Agency Reporting To</th>
                      <td style={{ whiteSpace: "pre-wrap" }}>
                        {getFieldData(
                          item.performerData,
                          "projectengagement",
                          "AgencyName"
                        )}
                        {", "}
                        {getFieldData(
                          item.performerData,
                          "projectengagement",
                          "PositionLocation"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Position Title</th>
                      <td>
                        {getFieldData(
                          item.performerData,
                          "projectengagement",
                          "PositionTitle"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Start Date</th>
                      <td>
                        {item.duration?.startDate && <Moment format="MMM Do YYYY">
                          {item.duration.startDate}
                        </Moment>}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>End Date</th>
                      <td>
                        {item.duration?.endDate && <Moment format="MMM Do YYYY">
                          {item.duration.endDate}
                        </Moment>}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Duration</th>
                      <td>
                        {item.duration?.duration} Months

                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>
                        Reporting Time/Work Schedule
                      </th>
                      <td>
                        {getFieldData(
                          wfTask.stateData,
                          "reportingdetails",
                          "ReportingTime"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Dress Code</th>
                      <td style={{ whiteSpace: "pre-wrap" }}>
                        {getFieldData(
                          wfTask.stateData,
                          "reportingdetails",
                          "DressCode"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Parking</th>
                      <td>
                        {getFieldData(
                          wfTask.stateData,
                          "reportingdetails",
                          "Parking"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Notes</th>
                      <td style={{ whiteSpace: "pre-wrap" }}>
                        {getFieldData(
                          wfTask.stateData,
                          "reportingdetails",
                          "Notes"
                        )}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
          {/* <div className="col-md-4">
              <label htmlFor="" className="form-control-label">
                Documents
              </label>
              <ul className="list-group">
                {rptDocs.map((doc) => (
                  <li className="list-group-item p-1">
                    <span className="p-2">
                      <Moment format="MMM Do YYYY">
                        {doc.createdDateTime}
                      </Moment>
                    </span>
                    <button
                      className="btn btn-sm btn-link"
                      onClick={() => this.handleDownloadFile(doc)}
                    >
                      {doc.documentName}
                    </button>
                  </li>
                ))}
              </ul>
            </div> */}
          <div className="col-md-5">
            <div className="card shadow-none border">
              <div className="card-header p-3">
                <h4 className="mb-0">
                  <i className="fa fa-hand-o-right" aria-hidden="true"></i>{" "}
                  REPORTING WORK LOCATION ADDRESS
                </h4>
              </div>
              <div className="card-body p-2">
                <table className="table align-items-center table-hover table-striped border">
                  <thead className="thead-light">
                    <tr>
                      <th style={{ width: "20%" }}>Address Line 1</th>
                      <td>
                        {getFieldData(
                          wfTask.stateData,
                          "worklocationaddress",
                          "AddressLine1"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Address Line 2</th>
                      <td>
                        {getFieldData(
                          wfTask.stateData,
                          "worklocationaddress",
                          "AddressLine2"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>City</th>
                      <td>
                        {getFieldData(
                          wfTask.stateData,
                          "worklocationaddress",
                          "City"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>State</th>
                      <td>
                        {getFieldData(
                          wfTask.stateData,
                          "worklocationaddress",
                          "State"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Zip Code</th>
                      <td>
                        {getFieldData(
                          wfTask.stateData,
                          "worklocationaddress",
                          "Zip"
                        )}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="card shadow-none border">
              <div className="card-header p-3">
                <h4 className="mb-0">
                  <i className="fa fa-hand-o-right" aria-hidden="true"></i>{" "}
                  REPORTING TO
                </h4>
              </div>
              <div className="card-body p-2">
                <table className="table align-items-center table-hover table-striped border">
                  <thead className="thead-light">
                    <tr>
                      <th style={{ width: "20%" }}>Title</th>
                      <td>
                        {getFieldData(
                          wfTask.stateData,
                          "reportingto",
                          "Title"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Name</th>
                      <td>
                        {getFieldData(
                          wfTask.stateData,
                          "reportingto",
                          "Name"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Phone</th>
                      <td>
                        {getFormatedNumber(getFieldData(
                          wfTask.stateData,
                          "reportingto",
                          "Phone"
                        ))}

                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Email</th>
                      <td>
                        {getFieldData(
                          wfTask.stateData,
                          "reportingto",
                          "Email"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th style={{ width: "20%" }}>Location</th>
                      <td>
                        {getFieldData(
                          wfTask.stateData,
                          "reportingto",
                          "Location"
                        )}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </React.Fragment>
  );

}
