import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getActivateDeactivateUpdateStatus, getUserAccessHistory,
  loadUserAccessLogHistory, updateUserActiveDeactive
} from "../../app/store/users";
import { getAlert, getConfirmAlert } from "../../Services/appAlerts";
import { getCurrentUser } from "../../app/store/auth";

const ActiveDeactiveHistory = (props) => {

  const { logShowHide, handleLogShowHide, actionType, selectedObj, type, pageType } = props;

  const [status, setStatus] = useState(null);
  const [notes, setNotes] = useState("");
  const [accessHistory, setAccessHistory] = useState([]);

  const userAccessHistorySelector = useSelector(getUserAccessHistory);
  const activateDeactivateUpdateStatusSelector = useSelector(getActivateDeactivateUpdateStatus);

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    if (selectedObj) {
      if (actionType === "log")
        dispatch(loadUserAccessLogHistory(selectedObj.id, type));

      setStatus(selectedObj.status);
      setNotes("");
    }
    else {
      setNotes("");
    }
  }, [selectedObj]);

  useEffect(() => {
    setAccessHistory(userAccessHistorySelector);
  }, [userAccessHistorySelector]);

  const handleSubmit = async () => {
    let title = "Activate";
    if (!status) title = "Deactivate";
    const result = await getConfirmAlert("Warning", `Are you sure you want to ${title} the ${pageType}?`, "warning");
    if (result.isConfirmed) {
      const data = {
        addedBy: currentUser.id,
        status: status,
        notes: notes,
        performerId: selectedObj.id,
        partnerId: selectedObj.partnerId
      }
      dispatch(updateUserActiveDeactive(data))
    }
  }

  useEffect(() => {
    if (activateDeactivateUpdateStatusSelector) {
      let title = "Activate";
      if (!status) title = "Deactivate";
      getAlert("", `${title}d Successfully`, "success");

      handleLogShowHide("save");
    }
  }, [activateDeactivateUpdateStatusSelector]);

  return (
    <Modal show={logShowHide} size="xl">
      <Modal.Header closeButton onHide={() => handleLogShowHide("", "")}>
        <Modal.Title>
          {actionType === "action" && "Activate/Deactivate Status"}
          {actionType === "log" && "Activated/Deactivated Log History"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="row">

          {actionType === "action" && (
            <React.Fragment>
              <div className="col-md-12 form-group form-inline">
                <div className="custom-control custom-radio mb-3">
                  <input
                    name="status"
                    className="custom-control-input"
                    id="activate"
                    type="radio"
                    checked={status}
                    placeholder="Notes"
                    onChange={() => setStatus(true)}
                  />
                  <label className="custom-control-label" htmlFor="activate">
                    Activate
                  </label>
                </div>
                <div className="custom-control custom-radio mb-3 ml-3">
                  <input
                    name="status"
                    className="custom-control-input"
                    id="deactivate"
                    value="false"
                    type="radio"
                    checked={status === false}
                    onChange={() => setStatus(false)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="deactivate"
                  >
                    Deactivate
                  </label>
                </div>
              </div>
              <div className="col-md-12 form-group">
                <label htmlFor="notes">Notes</label>
                <textarea
                  name="notes"
                  id="notes"
                  cols="2"
                  rows="2"
                  value={notes}
                  className="form-control"
                  onChange={({ currentTarget }) => setNotes(currentTarget.value)}
                ></textarea>
              </div>
            </React.Fragment>
          )}
          {actionType === "log" && (
            <div className="col-md-12">
              <table className="table table-bordered bg-white">
                <thead className="thead-light">
                  <tr>
                    <th>S.No.</th>
                    <th>Date & Time</th>
                    <th>Notes</th>
                    <th>Action By</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {accessHistory.map((item, key) => (
                    <tr>
                      <td>{key + 1}</td>
                      <td>
                        <Moment format="MMM DD YYYY, h:mm a">
                          {item.addedDateTime}
                        </Moment>
                      </td>

                      <td>{item.notes}</td>
                      <td>{item.performerName}</td>
                      <td>
                        {item.status && (
                          <span>
                            <i
                              className="fa fa-check text-success"
                              aria-hidden="true"
                            ></i>{" "}
                            Activated
                          </span>
                        )}
                        {!item.status && (
                          <span>
                            <i
                              className="fa fa-times text-danger"
                              aria-hidden="true"
                            ></i>{" "}
                            Deactivated
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={() => handleLogShowHide("", "")}>
          <i class="fa fa-times" aria-hidden="true"></i> Close
        </button>
        {actionType === "action" && (
          <button className="btn btn-sm btn-outline-default" onClick={handleSubmit}>
            <i class="fa fa-check" aria-hidden="true"></i> Save
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ActiveDeactiveHistory;
