import React, { Component, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "../Comman/Form";
import config from "../../config.json";
import auth, { getCurrentUser } from "../../Services/authService";
import http from "../../Services/httpService";
import Swal from "sweetalert2";
import SignaturePad from "react-signature-canvas";
import Moment from "react-moment";
import publicIp from "react-public-ip"
import { getFieldData, getTotalHours } from "../../Services/appService";
import { useDispatch, useSelector } from "react-redux";
import { getLoggedUserDetails, loadUserDetails } from "../../app/store/auth";
import { getGenerateBulkInvoiceStatus, getPartnerCandidatesReport, loadPartnerAllCandidateRpt, partnerCandidateGenerateBulkInv, partnerCandidatesInvGenerated } from "../../app/store/partner";
import { generatedInvoiePdfReceived, getGeneratedInvPdf, printGeneratedInvoice } from "../../app/store/timeLogInvoice";
import { getAppTasks, getCandidateApps, loadAppRuningTasks, loadCandidateApps } from "../../app/store/candidate";
import { getAlert } from "../../Services/appAlerts";

const GenerateInvoice = (props) => {
  const { invoiceNumber, notes, genShowHide,
    handleGenShowHide, candidateObj, selectedObj,wfTask } = props;
  const [ipAddress, setIpAddress] = useState("");
  const [ddName, setDdName] = useState("");
  const [rate, setRate] = useState(0);
  const [partnerId, setPartnerId] = useState(0);
  const [esignName, setEsignName] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [candidateList, setCandidateList] = useState([]);
  const [pdfBtnProccess, setPdfBtnProccess] = useState(false);
  const [saveBtnProccess, setSaveBtnProccess] = useState(false);
  const [generateCount,setGenerateCount]=useState(0);

  const userDetailsSelector = useSelector(getLoggedUserDetails);
  const partnerCandidatesReportSelector = useSelector(getPartnerCandidatesReport);
  const generatedInvPdfSelector = useSelector(getGeneratedInvPdf);
  const generateBulkInvoiceStatusSelector = useSelector(getGenerateBulkInvoiceStatus);

  const usrType = localStorage.getItem("userType");

  const printBodyRef = useRef();

  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  useEffect(()=>{
    if(!genShowHide){
      setEsignName("");
    }
  },[genShowHide])

  useEffect(() => {
    const loadIpAddress = async () => {
      const ipv4 = await publicIp.v4() || "";
      setIpAddress(ipv4);

    }
    loadIpAddress();
    if (!userDetailsSelector)
      dispatch(loadUserDetails(currentUser.id));
  }, []);

  useEffect(() => {
    setUserDetails(userDetailsSelector);
  }, [userDetailsSelector]);


  useEffect(() => {
    if (candidateObj) {
      let ddName = "subcontractorenrollmentform";
      let rate = 0;
      let partnerId = 0;
      if (usrType === "recruiter") {
        ddName = "recruiterenrollmentform";
        if (candidateObj.rates?.recruiterRate > 0)
          rate = candidateObj.rates?.recruiterRate

        if (parseInt(candidateObj.activeloans) > 0)
          partnerId = candidateObj.activeloans;

      } else {

        if (candidateObj.rates?.subcontractorRate > 0)
          rate = candidateObj.rates?.subcontractorRate;

        if (parseInt(candidateObj.loanamountlimit) > 0)
          partnerId = candidateObj.loanamountlimit;
      }

      setRate(rate);
      setPartnerId(partnerId);
      setDdName(ddName);
    }
  }, [candidateObj]);

  useEffect(() => {
    if (selectedObj) {
      dispatch(loadPartnerAllCandidateRpt(selectedObj.year, selectedObj.month,
        partnerId, usrType, selectedObj.candidateId))
    }
  }, [selectedObj]);

  useEffect(() => {
    setCandidateList(partnerCandidatesReportSelector);
  }, [partnerCandidatesReportSelector])

  const doSubmit = () => {
    handlePrintPdf();
  };

  const handleSaveGenerate = () => {
    setSaveBtnProccess(true);

    let htmlBody = printBodyRef.current.innerHTML;
    candidateList.map(async (item, key) => {
      const cObj={...item};
      if (invoiceNumber)
        cObj.invoiceNumber = invoiceNumber;

      const frmData = new FormData();
      frmData.append("html", htmlBody);
      frmData.append("candidate", JSON.stringify(cObj));
      frmData.append("sign", "");
      frmData.append("remarks", "Invoice");
      frmData.append("userId", currentUser.id);
      frmData.append("workflowTask", JSON.stringify(wfTask));

      dispatch(partnerCandidateGenerateBulkInv(frmData));
      setGenerateCount(key + 1);
    });

  };

  useEffect(()=>{
    if(generateBulkInvoiceStatusSelector && generateCount === candidateList.length){
      setSaveBtnProccess(false);
      getAlert("", "Generated and Sent Successfully", "success");
      setGenerateCount(0);
      dispatch(partnerCandidatesInvGenerated(false));
      setCandidateList([]);
      handleGenShowHide("save");
    }
  },[generateBulkInvoiceStatusSelector])

  const handlePrintPdf = () => {
    setPdfBtnProccess(true)
    let htmlBody = printBodyRef.current.innerHTML;
    const frmData = new FormData();
    frmData.append("html", htmlBody);
    dispatch(printGeneratedInvoice(frmData));
  };

  useEffect(() => {
    if (generatedInvPdfSelector?.size > 0) {
      setPdfBtnProccess(false);
      dispatch(generatedInvoiePdfReceived(null))
      const url = window.URL.createObjectURL(generatedInvPdfSelector);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }, [generatedInvPdfSelector])

  const getEmergencyFoundImg = () => {
    var c = document.createElement("canvas");
    var img = document.getElementById("Img1");
    c.height = img.naturalHeight;
    c.width = img.naturalWidth;
    var ctx = c.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var base64String = c.toDataURL();
    let img2 = document.createElement("img");
    img2.height = img.naturalHeight;
    img2.width = img.naturalWidth;
    img2.src = base64String;
    return img2;
  };

  const getMonthlyPeriod = (obj) => {
    if (!obj?.workingHrs || obj.workingHrs === "") return "";
    const wt = JSON.parse(obj.workingHrs);
    const sortWt = wt.sort((a, b) => new Date(a.start) - new Date(b.start));
    if (sortWt.length > 0) {
      return (
        <p>
          {new Date(sortWt[0].start).toLocaleDateString()} -{" "}
          {new Date(sortWt[sortWt.length - 1].start).toLocaleDateString()}
        </p>
      );
    } else {
      return "";
    }
  };

  const getFinalAmout = (item, rate) => {
    let fAmt = getTotalHours(item.workingHrs) * rate;
    if (item.adjustmentMinues > 0)
      fAmt = fAmt - item.adjustmentMinues;
    if (item.adjustmentPlus > 0)
      fAmt = fAmt + item.adjustmentPlus;
    return parseFloat(fAmt).toFixed(2);
  };


  return (
    <Modal show={genShowHide} size="xl">
      <Modal.Header closeButton onHide={() => handleGenShowHide("")}>
        <Modal.Title>Generate Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="col-md-12" style={{ maxHeight: "500px", overflow: "auto" }}>
          <img id="Img1" src={`/assets/Img/logo.png`} />
          <div id="printBody" ref={printBodyRef}>
            <table
              width="100%"
              cellPadding="5"
              cellSpacing="0"
              style={{ borderBottom: "1px solid #ccc", marginBottom: "25px" }}
            >
              <tr>
                <td colSpan={4} align="right">
                  <p
                    style={{
                      fontSize: "40px",
                      fontWeight: "bold",
                      padding: "2px",
                    }}
                  >
                    INVOICE
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55%"></td>
                <td>Invoice Number</td>
                <td width="1%">:</td>
                <td align="right">
                  {candidateObj && partnerId}
                  {candidateObj && candidateObj.performerid}
                  {selectedObj && selectedObj.id}
                  {selectedObj && selectedObj.month}
                  {selectedObj && selectedObj.year}
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Invoice Date</td>
                <td>:</td>
                <td align="right">{new Date().toLocaleDateString()}</td>
              </tr>
              <tr>
                <td></td>
                <td>Payment Term</td>
                <td>:</td>
                <td align="right"> Net 30 Days</td>
              </tr>
              <tr>
                <td colSpan={4} style={{ paddingTop: "10px" }}>
                  <p>&nbsp;&nbsp;</p>
                </td>
              </tr>
            </table>
            <table
              cellPadding="5"
              cellSpacing="0"
              width="100%"
              border="0"
              style={{ marginBottom: "25px" }}
            >
              <tbody>
                <tr>
                  <td
                    style={{ fontSize: "25px", fontWeight: "bold" }}
                    width="51%"
                  >
                    {userDetails && getFieldData(
                      userDetails.performerData,
                      ddName,
                      "Company_Name"
                    )}
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      backgroundColor: "#f0f0f0",
                      border: "1px solid #ccc",
                    }}
                  >
                    Bill To/Ship To
                  </td>
                </tr>
                <tr>
                  <td>
                    {userDetails && (
                      <React.Fragment>
                        <p>
                          {getFieldData(
                            userDetails.performerData,
                            ddName,
                            "Company_Address_Line1"
                          )}
                        </p>
                        <p>
                          {getFieldData(
                            userDetails.performerData,
                            ddName,
                            "Company_City"
                          )}
                          ,{" "}
                          {getFieldData(
                            userDetails.performerData,
                            ddName,
                            "Company_State"
                          )}{" "}
                          {getFieldData(
                            userDetails.performerData,
                            ddName,
                            "Company_Zip_Code"
                          )}
                        </p>

                        <p>
                          Federal ID :{" "}
                          {getFieldData(
                            userDetails.performerData,
                            ddName,
                            "Federal_ID"
                          )}
                        </p>
                        <p>
                          Phone :{" "}
                          {getFieldData(
                            userDetails.performerData,
                            ddName,
                            "Company_Phone_Code"
                          )}{" "}
                          {getFieldData(
                            userDetails.performerData,
                            ddName,
                            "Company_Phone"
                          )}
                        </p>
                        <p>
                          Email ID :{" "}
                          {getFieldData(
                            userDetails.performerData,
                            ddName,
                            "Company_Email"
                          )}
                        </p>
                        <p>ID : {partnerId}</p>
                      </React.Fragment>
                    )}
                  </td>
                  <td style={{ border: "1px solid #ccc" }} valign="top">
                    <p>
                      International Projects Consultancy Services (IPCS) Inc.
                    </p>

                    <p>600 South Highway</p>
                    <p>169 Metropoint Suite</p>
                    <p>1595 Minneapolis, MN 55426 USA</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              cellPadding="5"
              cellSpacing="0"
              width="100%"
              style={{
                border: "1px solid #ccc",
                borderBottom: "0px",
                borderLeft: "0px",
                marginBottom: "25px",
              }}
            >
              <thead style={{ backgroundColor: "#f0f0f0" }}>
                <tr>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    Service
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    Candidate
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    Period (Month/Year)
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    Hours
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    Rate/hr ($)
                  </th>
                  <th
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    Total Amount ($)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    width="15%"
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    As per IT consulting services agreement and purchase order
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    {candidateObj && candidateObj.performername}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    {selectedObj && getMonthlyPeriod(selectedObj)}
                  </td>
                  <td
                    align="right"
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    {selectedObj && getTotalHours(selectedObj.workingHrs)}
                  </td>
                  <td
                    align="right"
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    {rate}
                  </td>
                  <td
                    align="right"
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    {selectedObj && getFinalAmout(selectedObj, rate)}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={5}
                    align="right"
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    SubTotal
                  </td>
                  <td
                    align="right"
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    {selectedObj && getFinalAmout(selectedObj, rate)}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={5}
                    align="right"
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    Total
                  </td>
                  <td
                    align="right"
                    style={{
                      border: "1px solid #ccc",
                      borderTop: "0px",
                      borderRight: "0px",
                    }}
                  >
                    {selectedObj && getFinalAmout(selectedObj, rate)}
                  </td>
                </tr>
              </tbody>
            </table>
            <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>
              Signature
            </p>
            <p style={{
              fontStyle: "italic", fontWeight: "bold",
              fontSize: "14px", marginBottom: "7px"
            }}>{esignName}</p>
            <p>{new Date().toUTCString()}</p>
            <p>IP Address : {ipAddress}</p>
            <p>
              Name : {userDetails?.performername} {userDetails?.lastName}
            </p>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <label htmlFor="esignName" className="form-control-label">Sign Digitally</label>
              <textarea cols="2" rows={2}
                id="esignName"
                name="esignName"
                className="form-control"
                value={esignName}
                placeholder="Sign Digitally"
                onChange={({ currentTarget }) => setEsignName(currentTarget.value)}
              ></textarea>
            </div>
            <div className="col-md-6">
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={() => handleGenShowHide("")}>
          <i className="fa fa-times" aria-hidden="true"></i> Close
        </button>
        <button className="btn btn-sm btn-outline-dark"
        disabled={saveBtnProccess}
        onClick={handleSaveGenerate}>
          {saveBtnProccess ? <i className="fa fa-spinner fa-spin"></i> :
          <i className="fa fa-paper-plane" aria-hidden="true"></i>} Generate & Send
        </button>
        <button className="btn btn-sm btn-outline-default"
          disabled={pdfBtnProccess}
          onClick={doSubmit}>
          {pdfBtnProccess ? <i className="fa fa-spinner fa-spin"></i> :
            <i className="fa fa-print" aria-hidden="true"></i>} Print PDF
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default GenerateInvoice;
