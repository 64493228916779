import React, { Component } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";

class ProgramDetailsPopup extends Component {
  render() {
    const { handleProgShowHide, progShowHide } = this.props;
    return (
      <Modal show={progShowHide} size="lg">
        <Modal.Header closeButton onClick={handleProgShowHide}>
          <Modal.Title>Program Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary pb-2">
          <div className="row">
            <div className="col-md-12">
              <h3>Grant Program Details</h3>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleProgShowHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ProgramDetailsPopup;
