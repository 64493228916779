import React, { Component, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "./Comman/Form";
import UploadDocument from "./Comman/UploadDocument";
import Joi from "joi-browser";
import http from "../Services/httpService";
import config from "../config.json";
import auth from "../Services/authService";
import Swal from "sweetalert2";
import { getAlert } from "../Services/appAlerts";
import { useDispatch, useSelector } from "react-redux";
import { appDocumentUploaded, getAppDocUploadStatus, uploadAppDocuments } from "../app/store/documents";
import { getCurrentUser } from "../app/store/auth";

const UploadDocPopup = (props) => {

  const { uploadDocShowHide, handleUploadDocModalShowHide,
    selectedDocType, runningApplication, messageAlert } = props;

  console.log(runningApplication);

  const [documents, setDocuments] = useState([]);
  const [notes, setNotes] = useState("");
  const [btnProcess, setBtnProcess] = useState(false);

  const appDocUploadStatusSelector = useSelector(getAppDocUploadStatus);

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();
  const fileInputRef = useRef(null);


  useEffect(() => {
    if (!uploadDocShowHide) {
      setDocuments([]);
      setNotes("");
    }
  }, [uploadDocShowHide]);

  const doSubmit = () => {
    if (documents.length > 0) {
      setBtnProcess(true);
      const frmData = new FormData();
      frmData.append("message", notes);
      frmData.append("workflowTask", JSON.stringify(runningApplication[0]));
      frmData.append("userId", currentUser.id);
      frmData.append("docType", !selectedDocType ? "" : selectedDocType);
      documents.map((file, key) =>
        frmData.append("files[" + key + "]", file.file)
      );
      dispatch(uploadAppDocuments(frmData));
    }
    else {
      getAlert("", "Document not found", "error");
    }
  };

  useEffect(() => {
    if (appDocUploadStatusSelector > 0) {
      setBtnProcess(false);
      if (messageAlert && messageAlert !== "")
        getAlert("", messageAlert, "success");
      else
        getAlert("", "Sent Successfully", "success");

      dispatch(appDocumentUploaded(null));
      handleUploadDocModalShowHide("save", "");
    }
  }, [appDocUploadStatusSelector])

  const onFileChange = async (event) => {
    const docs = [...documents];
    for (const file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        size: (file.size / 1024).toFixed(2),
        file: file,
      };
      docs.push(doc);
    }
    setDocuments(docs);
  };

  // const handleFileRemove = (item) => {
  //   const index = documents.indexOf(item);
  //   let docs = [...documents];
  //   if (index !== -1) {
  //     docs.splice(index, 1);
  //     setDocuments(docs)
  //   }
  // };
  const handleFileRemove = (item) => {
    const updatedDocuments = documents.filter((doc) => doc !== item);
    setDocuments(updatedDocuments);
  };

  return (
    <Modal show={uploadDocShowHide} size="xl">
      <Modal.Header closeButton onHide={() => handleUploadDocModalShowHide("", "")}>
        <Modal.Title>Upload Document</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="row">
          {documents.length > 0 && (
            <div className="file-preview">
              {documents.map((file, index) => (
                <div key={index} className="file-preview-item ml-2">
                  <span>{file.name}</span>
                  <span
                    className="file-size"
                    style={{
                      backgroundColor: "#f0f0f0",
                      marginLeft: "10px",
                      padding: "2px 5px",
                      borderRadius: "5px",
                    }}
                  >
                    {file.size} KB
                  </span>
                  <button
                    className="btn btn-sm btn-link"
                    onClick={() => handleFileRemove(file)}
                  >
                    <i className="fa fa-times text-red" aria-hidden="true"></i>
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="message" className="form-control-label">
                Message
              </label>
              <textarea
                name="message"
                id="message"
                cols="2"
                rows="2"
                className="form-control"
                value={notes}
                onChange={({ currentTarget }) => setNotes(currentTarget.value)}
              ></textarea>
            </div>
          </div>
          <div className="col-12 text-center mt-3 d-flex justify-content-center">
            <Button
              variant="outline-dark"
              size="sm"
              onClick={() => fileInputRef.current.click()}
            >
              <i className="fa fa-paperclip mr-2" aria-hidden="true"></i>
              Add
            </Button>

            <Button variant="outline-dark" size="sm" onClick={doSubmit}>
              <i className="fa fa-paper-plane-o mr-2" aria-hidden="true"></i>
              Send
            </Button>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            onChange={onFileChange}
            multiple
            style={{ display: "none" }}
          />
          <div className="col-md-12">
            {/* <UploadDocument
              documents={documents}
              onFileChange={onFileChange}
              handleFileRemove={handleFileRemove}
            ></UploadDocument> */}

          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={() => handleUploadDocModalShowHide("", "")}
        >
          <i className="fa fa-times" aria-hidden="true"></i> Close
        </button>
        {/* <button
          className="btn btn-sm btn-outline-default"
          onClick={doSubmit}
          disabled={btnProcess || documents.length === 0}
        >
          {btnProcess ? <i className="fa fa-spinner fa-spin mr-1"></i> : <i className="fa fa-paper-plane mr-1" aria-hidden="true"></i>}
           Send
        </button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default UploadDocPopup;
