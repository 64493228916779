import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import auth from "../../Services/authService";
import http from "../../Services/httpService";
import config from "../../config.json";
import ChangePassword from "../ChangePassword";
import CandidateDetails from "../ApplicantProcessing/CandidateDetails";
import SubcontractorDetails from "../ApplicantProcessing/SubcontractorDetails";
import Help from "../Support/Help";
import SupportPupup from "../Support/SupportPopup";
import ChangeSecurityQuestion from "../ChangeSecurityQuestion";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";

export default function PageHeader(props){
  const { type, parentTitle,route } = props;

  const [userDetails,setUserDetails]=useState(null);
  const [showHide,setShowHide]=useState(false);
  const [securityShowHide,setSecurityShowHide]=useState(false);
  const [helpShowHide,setHelpShowHide]=useState(false);
  const [issueShowHide,setIssueShowHide]=useState(false);
  const currentUser=getCurrentUser();

  const userDetailSelector=useSelector(getLoggedUserDetails);

  const dispatch=useDispatch();

  useEffect(()=>{
    let userId=currentUser.id;
    if (currentUser.type === "partner") {
      userId = currentUser.linkUser;
    }
    //dispatch(loadUserDetails(userId));
  },[])

  useEffect(()=>{
    if(userDetailSelector)
      setUserDetails(userDetailSelector);
  },[userDetailSelector])


 const handleModalShowHide = () => {
    setShowHide(!showHide);
  };

 const handleHelpShowHide = () => {
    setHelpShowHide(!helpShowHide);
  };

 const handleIssueShowHide = () => {
    setIssueShowHide(!issueShowHide);
  };

  const  handleSecurityShowHide = () => {
    setSecurityShowHide(!securityShowHide);
  };

 const handleWorkflowTask = (data) => {
    // this.props.history.push({
    //   pathname: "/DynamicForm",
    //   state: { workflowTask: data },
    // });
  };

 const handleCurrentTask = async (wiid) => {
    // const { data: workflowTask } = await http.get(
    //   config.GetWorkflowTask + wiid
    // );
    // if (workflowTask.formId > 0) {
    //   if (workflowTask.formType === "CF") {
    //     this.props.history.push({
    //       pathname: "/" + workflowTask.formName,
    //       state: { workflowTask },
    //     });
    //   } else {
    //     this.props.history.push({
    //       pathname: "/DynamicForm",
    //       state: { workflowTask },
    //     });
    //   }
    // }
  };

 
  
  let usrType = localStorage.getItem("userType");

  if (usrType === "PP") {
    usrType = userDetails?.partnerType;
    if (userDetails?.partnerType === "Sub-Contractors")
      usrType = "Subcontractor";
  }


  return (
    <React.Fragment>
      <div
        className="header"
        style={{
          //backgroundImage: `URL("/assets/Img/slide3.jpg")`,
          backgroundPosition: "center center",
          borderTop: "1px solid #ccc",
          backgroundSize: "100%",
          color: "white",
          height: "70px",
        }}
      >
        <div className="container-fluid">
          <div className="header-body">
            <div className="row align-items-center py-2">
              <div
                className="col-lg-8 col-7"
                style={{
                  display: parentTitle === "MyProfile" ? "none" : "",
                }}
              >
                <h6 className="h2 text-dark d-inline-block">{parentTitle}</h6>
              </div>
              <div className="col-lg-4 d-flex justify-content-end">
                <nav className="navbar navbar-top navbar-expand navbar-dark p-0">
                  <div className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >

                    <ul className="navbar-nav align-items-center ml-auto ml-md-0">
                      <li className="nav-item dropdown mt--1">
                        <a
                          className="nav-link pr-0"
                          href="#"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="media align-items-center">
                            <span className="avatar avatar-sm rounded-circle">
                              <img
                                alt="Image placeholder"
                                src="../../assets/img/theme/team-4.png"
                              />
                            </span>
                            {currentUser && <div className="media-body ml-2 d-none d-lg-block">
                              <span className="mb-0 text-sm font-weight-bold text-dark text-uppercase mt--1">
                                <b>{currentUser.name}</b>
                              </span>
                              <h5 className="text-dark mt--1">
                                Reg ID :{" "}
                                {currentUser.type === "partner"
                                  ? currentUser.linkUser
                                  : currentUser.id}

                                {currentUser.type === "partner" &&
                                  `"Partner ID :" ${currentUser.id} `}
                                <br />
                                Role :{" "}
                                <span className="text-capitalize">
                                  {usrType}
                                </span>
                              </h5>
                            </div>}
                          </div>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right">
                          <a href="/Profile" className="dropdown-item">
                            <i className="ni ni-single-02" />
                            <span>My Profile</span>
                          </a>
                          <button
                            className="dropdown-item"
                            onClick={handleModalShowHide}
                          >
                            <i className="ni ni-settings-gear-65" />
                            <span>Change Password</span>
                          </button>
                          <button
                            className="dropdown-item"
                            onClick={handleSecurityShowHide}
                          >
                            <i className="fa fa-shield" />
                            <span>Change Security Questions</span>
                          </button>
                          <button
                            className="dropdown-item"
                            onClick={handleHelpShowHide}
                          >
                            <i className="ni ni-support-16" />
                            <span>Help</span>
                          </button>

                          <div className="dropdown-divider" />
                          <a href="/logout" className="dropdown-item">
                            <i className="ni ni-user-run" />
                            <span>Logout</span>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {type !== "portalForm" && (
        <div className="pl-0 pt-2 pb-2 mb-3 border col-md-12">
          <div className="row">
            <div className="col-lg-8">
              <nav
                aria-label="breadcrumb"
                className="d-none d-md-inline-block ml-md-4 mt-1"
              >
                <ol className="breadcrumb breadcrumb-links">
                  <li className="breadcrumb-item">
                    {usrType !== "candidate" ? (
                      <a href="/Dashboard">
                        <i className="fa fa-home" />
                      </a>
                    ) : (
                      <a href="/">
                        <i className="fa fa-home" />
                      </a>
                    )}
                  </li>
                  
                  {usrType !== "candidate" && (
                    <li className="breadcrumb-item">
                      <a href="/Dashboard">Dashboard</a>
                    </li>
                  )}
                  {route && route.map((obj) => (
                    <li key={obj.name} className="breadcrumb-item">
                      {obj.url === "" ? (
                        <span className="text-blue">{obj.name}</span>
                      ) : (
                        <Link
                          to={{ pathname: obj.url, state: obj.state }}
                        >
                          {obj.name}
                        </Link>
                      )}
                    </li>
                  ))}
                  {parentTitle !== "" &&
                    <li className="breadcrumb-item" aria-current="page">
                      {parentTitle}
                    </li>
                  }
                </ol>
              </nav>
            </div>
            <div className="col-lg-4 text-right">
              {/* <button
                onClick={handleIssueShowHide}
                className="btn btn-sm btn-danger m-0 mr-2"
                title="Any defects/issues found in the Portal can be registered in the Customer Support Portal."
              >
                <i className="fa fa-support"></i> Access to Customer Support
                Portal
              </button> */}
              {window.location.pathname === "/Dashboard" && (
                <a
                  href="/"
                  className="btn btn-sm btn-primary m-0 ml-2"
                >
                  <i
                    className="fa fa-angle-double-right mr-1"
                    aria-hidden="true"
                  ></i>
                  Continue
                </a>
              )}
            </div>
          </div>
        </div>
      )}

      <ChangePassword
        handleModalShowHide={handleModalShowHide}
        showHide={showHide}
      ></ChangePassword>
      <ChangeSecurityQuestion
        securityShowHide={securityShowHide}
        handleSecurityShowHide={handleSecurityShowHide}
      ></ChangeSecurityQuestion>
      <Help
        helpShowHide={helpShowHide}
        handleHelpShowHide={handleHelpShowHide}
        module={parentTitle}
      ></Help>
      <SupportPupup
        handleIssueShowHide={handleIssueShowHide}
        issueShowHide={issueShowHide}
        handleCurrentTask={handleCurrentTask}
        handleWorkflowTask={handleWorkflowTask}
      ></SupportPupup>
    </React.Fragment>
  );
  
}
