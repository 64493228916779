import http from "./httpService";
import config from "../config.json";
import countries from "./allCountries.json";
import countriesStates from "./allCountryStates.json";

const countryId = 6252001;
const ALLCOUNTRY = "allCountry";
const ALLUSSTATE = "allUSState";

export async function getGeoData(geonameId) {
  if (geonameId == countryId) {
    const stateList = localStorage.getItem(ALLUSSTATE);
    if (stateList === null) {
      //const { data } = await http.get(config.GetGeoData + geonameId);
      const data = countriesStates.filter(x => x.countryGeonameId == geonameId);
      localStorage.setItem(ALLUSSTATE, JSON.stringify(data));
      return data;
    }
    return JSON.parse(stateList);
  } else {
    //const { data } = await http.get(config.GetGeoData + geonameId);
    const data = countriesStates.filter(x => x.countryGeonameId == geonameId);
    return data;
  }

}

export async function getStateList() {
  return await getGeoData(countryId);
}

export async function getAllCountries() {
  const countryList = localStorage.getItem(ALLCOUNTRY);
  if (countryList === null) {
    //const { data } = await http.get(config.GetGeoCountryData);
    localStorage.setItem(ALLCOUNTRY, JSON.stringify(countries));
    var us = countries.filter(x => x.geonameId === 6252001);
    if (us.length > 0) {
      countries.splice(0, 0, us[0]);
    }
    return countries;
  }
  const countryJs = JSON.parse(countryList);
  var us = countryJs.filter(x => x.geonameId === 6252001);
  if (us.length > 0) {
    countryJs.splice(0, 0, us[0]);
  }
  return countryJs;
}

export default {
  getGeoData,
  getStateList,
  getAllCountries,
};
