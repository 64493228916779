import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom";
import ApplicationInstructions from "../../../Components/ApplicationInstructions";
import { getFieldData } from "../../../Services/appService";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../../store/auth";
import { useDispatch, useSelector } from "react-redux";

export default function OnboardingSteps(props) {
    //const { entityType } = props;

    const [instructionShowHide, setInstructionShowHide] = useState("");
    const [entityType, setEntityType] = useState("");

    const usrType = localStorage.getItem("userType");
    const location = useLocation();
    const appType = location?.pathname;


    const dispatch = useDispatch();
    const currentUser = getCurrentUser();
    const userDetailsSelector = useSelector(getLoggedUserDetails)

    useEffect(() => {
        if (!userDetailsSelector)
            dispatch(loadUserDetails(currentUser.id));
    }, []);

    useEffect(() => {
        if (userDetailsSelector) {
            const entType = getFieldData(
                userDetailsSelector.performerData,
                "candidateonboardingform",
                "CandidateEntity"
            );
            setEntityType(entType);
        }
    }, [userDetailsSelector])

    const handleInstructionShowHide = () => {
        setInstructionShowHide(!instructionShowHide);
    }

    const allPaths = ["/Onboarding", "/ContractDocs", "/AchInfo", "/BackgroundChecks", "/ReportingDetails",
     "/OnboardingStatus","/DynamicForm"];

    if (allPaths.indexOf(appType) > -1) {
        return <React.Fragment>
            <div className="mt-2">
                <button
                    className="btn btn-sm btn-link text-underline"
                    onClick={handleInstructionShowHide}
                >
                    <i
                        className="fa fa-hand-o-right text-red"
                        aria-hidden="true"
                    ></i>{" "}
                    Instructions for completing onboarding process
                </button>
            </div>
            <div className="p-3 border mt-1">
                <Link
                    to="/Onboarding"
                    className={(appType === "/Onboarding" || appType === "/DynamicForm") ? "font-weight-bold" : "text-dark"}
                >
                    Onboarding Form
                    <i
                        className="fa fa-angle-double-right mr-1 ml-1"
                        aria-hidden="true"
                    ></i>
                </Link>

                <Link
                    to="/ContractDocs"
                    className={
                        appType === "/ContractDocs" ? "font-weight-bold" : "text-dark"
                    }
                >
                    Contract Documents
                    <i
                        className="fa fa-angle-double-right mr-1 ml-1"
                        aria-hidden="true"
                    ></i>
                </Link>
                {entityType !== "C2C Basis" && (
                    <Link
                        to="/AchInfo"
                        className={
                            appType === "/AchInformation"
                                ? "font-weight-bold"
                                : "text-dark"
                        }
                    >
                        ACH Information
                        <i
                            className="fa fa-angle-double-right mr-1 ml-1"
                            aria-hidden="true"
                        ></i>
                    </Link>
                )}
                <Link
                    to="/BackgroundChecks"
                    className={
                        appType === "/BackgroundChecks"
                            ? "font-weight-bold"
                            : "text-dark"
                    }
                >
                    Background Check
                    <i
                        className="fa fa-angle-double-right mr-1 ml-1"
                        aria-hidden="true"
                    ></i>
                </Link>
                <Link
                    to="/OnboardingStatus"
                    className={
                        appType === "/OnboardingStatus"
                            ? "font-weight-bold"
                            : "text-dark"
                    }
                >
                    Onboarding Status
                    <i
                        className="fa fa-angle-double-right mr-1 ml-1"
                        aria-hidden="true"
                    ></i>
                </Link>

                <Link
                    to="/ReportingDetails"
                    className={
                        appType === "/ReportingDetails"
                            ? "font-weight-bold"
                            : "text-dark"
                    }
                >
                    Reporting Details
                </Link>
                
            </div>
            {instructionShowHide && <ApplicationInstructions
                instructionShowHide={instructionShowHide}
                handleInstructionShowHide={handleInstructionShowHide}
                entityType={entityType}
            ></ApplicationInstructions>}

        </React.Fragment>
    }

    return "";

}