import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./Input";
import Select from "./Select";
import TextArea from "./TextArea";
import Speech from "speak-tts";
import PasswordInput from "./PasswordInput";
import PhoneInput from "./PhoneInput";

class Form extends Component {
  state = {
    data: {},
    errors: {},
    passwordType: "password",
  };

  validate = () => {
    const options = { abortEarly: false, allowUnknown: true };
    const result = Joi.validate(this.state.data, this.schema, options);
    //console.log(result);
    if (!result.error) return null;
    const errors = {};
    for (let item of result.error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    if (this.schema[name] !== undefined) {
      const obj = { [name]: value };
      const schema = { [name]: this.schema[name] };
      const { error } = Joi.validate(obj, schema);
      return error ? error.details[0].message : null;
    }
    return null;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    //console.log(input.nodeName);
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data, errors });
    if (input.nodeName === "SELECT") this.handleDropdwonSpeech(input);
    else this.handleSpeech(input.value[input.value.length - 1]);
  };

  renderButton(label) {
    return (
      <button disabled={this.validate()} className="btn btn-primary">
        {label}
      </button>
    );
  }

  //let viewEye = "close";
  handleEyeButton = (item) => {
    const { currentItem } = this.state;
    if (currentItem.name === item && currentItem.eye) {
      this.setState({ currentItem: { name: "", eye: false } });
    } else {
      this.setState({ currentItem: { name: item, eye: true } });
    }
  };

  renderInput(name, label, type = "text") {
    const { data, errors } = this.state;
    if (type === "password") {
      return (
        <PasswordInput
          name={name}
          value={data[name]}
          label={label}
          onChange={this.handleChange}
          error={errors[name]}
          req={this.schema[name]}
        />
      );
    } else if (type === "phone") {
      return (
        <PhoneInput
          name={name}
          value={data[name]}
          label={label}
          onChange={this.handleChange}
          error={errors[name]}
          req={this.schema[name]}
        />
      );
    } else {
      return (
        <Input
          type={type}
          name={name}
          value={data[name]}
          label={label}
          onChange={this.handleChange}
          error={errors[name]}
          onFocus={this.handleFocus}
          req={this.schema[name]}
        ></Input>
      );
    }
  }

  renderSelect(name, label, options, optName, optValue) {
    const { data, errors } = this.state;
    return (
      <Select
        name={name}
        value={data[name]}
        label={label}
        options={options}
        onChange={this.handleChange}
        error={errors[name]}
        optName={optName}
        optValue={optValue}
        onFocus={this.handleDropdwonFocus}
        aria-label={name}
      />
    );
  }

  handleFocus = (event) => {
    this.handleSpeech(event.currentTarget.name + " Edit Text");
    const { selectedInput } = this.state;
    if (
      event.currentTarget.name !== selectedInput &&
      this.keyboard !== undefined
    )
      this.keyboard.setInput("");
    this.setState({ selectedInput: event.currentTarget.name });
  };

  handleDropdwonFocus = ({ currentTarget: input }) => {
    this.handleDropdwonSpeech(input);
  };

  handleSpeech = (text) => {
    const vr = document.body.classList.contains("INDtextreader");
    if (vr) {
      const spch = new Speech();
      spch
        .speak({
          text: text,
          queue: true, // current speech will be interrupted,
          listeners: {
            onstart: () => {
              console.log("Start utterance");
            },
            onend: () => {
              console.log("End utterance");
            },
            onresume: () => {
              console.log("Resume utterance");
            },
            onboundary: (event) => {
              console.log(
                event.name +
                  " boundary reached after " +
                  event.elapsedTime +
                  " milliseconds."
              );
            },
          },
        })
        .then(() => {
          console.log("Success !");
        })
        .catch((e) => {
          console.error("An error occurred :", e);
        });
    }
  };

  handleDropdwonSpeech = (input) => {
    const { options, value } = input;
    const index = options.selectedIndex + 1;
    this.handleSpeech(input.ariaLabel);
    this.handleSpeech(options[options.selectedIndex].text);
    this.handleSpeech(index + " of " + options.length);
  };

  renderTextArea(name, label) {
    const { data, errors } = this.state;
    return (
      <TextArea
        name={name}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      ></TextArea>
    );
  }
}

export default Form;
