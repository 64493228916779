import React from "react";
import Joi from "joi-browser";
import ReCAPTCHA from "react-google-recaptcha";
import Form from "../Comman/Form";

class LoginStep2 extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      documents: {},
    };
  }

  schema = {
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
    ReCAPTCHA: Joi.string().required().label("Re-Captcha"),
  };

  doSubmit = () => {
    const { data } = this.state;
    this.props.handleDoSubmit(data);
  };

  componentWillReceiveProps = (nextProps) => {
    const { userError } = nextProps;
    if (userError !== undefined) {
      const { errors } = this.state;
      errors["username"] = userError;
      this.setState({ errors });
    }
  };

  onCaptchaChange = (value) => {
    const { data } = this.state;
    data["ReCAPTCHA"] = value;
    this.setState({ data });
  };

  render() {
    const { type } = this.props;
    return (
      <React.Fragment>
        {this.renderInput("username", "Enter Your Username")}
        {this.renderInput("password", "Enter Your Password", "password")}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReCAPTCHA
            sitekey="6Le08xsUAAAAAKC6Oglhe-haRVGzA8KTLUZ-jmEK"
            onChange={this.onCaptchaChange}
          />
        </div>
        <button
          className="btn w-100 mt-1"
          style={{
            backgroundColor: `${type === "admin" ? "#298607" : "#2b62a1"}`,
            color: "white",
          }}
          disabled={this.validate()}
          onClick={this.handleSubmit}
        >
          SIGN IN
        </button>
        <div className="d-flex justify-content-end">
          <button
            onClick={this.props.handleForgotPwd}
            className="btn btn-link btn-sm"
          >
            Forgot Username/Password?
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginStep2;
