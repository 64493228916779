import React, { Component, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import Form from "../Comman/Form";
import { Modal, Button } from "react-bootstrap";
import http from "../../Services/httpService";
import config from "../../config.json";
import Joi from "joi-browser";
import SignaturePad from "react-signature-canvas";
import Swal from "sweetalert2";
import publicIp from "react-public-ip"
import { getAlert } from "../../Services/appAlerts";
import { saveBprmForm } from "../../app/store/bprmForm";
import { getAllMonths, getAllYears, getFieldData, getMonthName } from "../../Services/appService";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getGenerateBulkInvoiceStatus, getPartnerCandidatesReport, loadPartnerAllCandidateRpt, partnerCandidateGenerateBulkInv, partnerCandidatesInvGenerated } from "../../app/store/partner";
import { generatedInvoiePdfReceived, getGeneratedInvPdf, printGeneratedInvoice } from "../../app/store/timeLogInvoice";
import { getAppTasks, getCandidateApps, loadAppRuningTasks, loadCandidateApps } from "../../app/store/candidate";

const BulkInvoice = (props) => {

  const { bulkShowHide, handleBulkShowHide } = props;

  const [partType, setPartType] = useState("recruiter");
  const [ddName, setDdName] = useState("recruiterenrollmentform");
  const [ipAddress, setIpAddress] = useState("");
  const [rate, setRate] = useState(0);
  const [partnerId, setPartnerId] = useState(0);
  const [esignName, setEsignName] = useState("");
  const [candidateObj, setCandidateObj] = useState(null);
  const [candidateList, setCandidateList] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [isGenerated, setIsGenerated] = useState(false);
  const [pdfBtnProccess, setPdfBtnProccess] = useState(false);
  const [saveBtnProccess, setSaveBtnProccess] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [generateCount,setGenerateCount]=useState(0);
  const [wfTask, setWfTask] = useState(null);

  const months = getAllMonths();
  const years = getAllYears();

  const candidateAppsSelector = useSelector(getCandidateApps);
  const appTasksSelector = useSelector(getAppTasks);
  const userDetailsSelector = useSelector(getLoggedUserDetails);
  const partnerCandidatesReportSelector = useSelector(getPartnerCandidatesReport);
  const generatedInvPdfSelector = useSelector(getGeneratedInvPdf);
  const generateBulkInvoiceStatusSelector = useSelector(getGenerateBulkInvoiceStatus);

  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  const printBodyRef = useRef();
  const printFooterRef = useRef();

  useEffect(() => {
    if (!bulkShowHide) {
      setSelectedYear("");
      setSelectedMonth("");
      setCandidateList([]);
    }
  }, [bulkShowHide]);

  useEffect(() => {
    if (!userDetailsSelector)
      dispatch(loadUserDetails(currentUser.id));

    const loadIpAddress = async () => {
      const ipv4 = await publicIp.v4() || "";
      setIpAddress(ipv4);

    }
    loadIpAddress();
  }, [])

  useEffect(() => {
    if (userDetailsSelector) {
      if (userDetailsSelector?.partnerType === "Sub-Contractors") {
        setPartType("subcontractor");
        setDdName("subcontractorenrollmentform");
      }
      setPartnerId(userDetailsSelector.partnerid);
      setUserDetails(userDetailsSelector);
    }
  }, [userDetailsSelector]);


  useEffect(() => {
    if (candidateAppsSelector?.length === 0)
      dispatch(loadCandidateApps(currentUser.id));
  }, []);

  useEffect(() => {
    if (candidateAppsSelector.length > 0) {
      const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
      if (mainWorkflow.length > 0) {
        const wf = mainWorkflow[0];
        if (appTasksSelector.length === 0)
          dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
      }
    }
  }, [candidateAppsSelector]);

  useEffect(() => {
    if (appTasksSelector.length > 0) {
      const filterTasks = appTasksSelector.filter(x => x.taskType === 1 && x.wiid > 0);
      if (filterTasks.length > 0) {
        const reviewTasks = filterTasks.filter(x => x.registrationType === "WP");
        if (reviewTasks.length > 0)
          setWfTask(reviewTasks[reviewTasks.length - 1]);
        else
          setWfTask(filterTasks[0]);
      }
    }
  }, [appTasksSelector]);

  const getMonthlyPeriod = (item) => {
    if (item.timeLog !== null) {
      const obj = item.timeLog;
      if (obj.workingHrs === null) return "";
      const wt = JSON.parse(obj.workingHrs);
      const sortWt = wt.sort((a, b) => new Date(a.start) - new Date(b.start));
      if (sortWt.length > 0) {
        return (
          <p>
            {new Date(sortWt[0].start).toLocaleDateString()} -{" "}
            {new Date(sortWt[sortWt.length - 1].start).toLocaleDateString()}
          </p>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const getFinalAmout = (item, usrType) => {
    let rate = getCandidateRate(item, usrType);
    let fAmt = getTotalHours(item) * rate;
    return parseFloat(fAmt).toFixed(2);
  };

  const getTotalHours = (item) => {
    if (item.timeLog !== null) {
      const obj = item.timeLog;
      if (obj.workingHrs === null) return 0;
      const wt = JSON.parse(obj.workingHrs);
      let totalHours = 0;
      let totalMinutes = 0;
      wt.filter((x) => x.title !== null).map((t) => {
        const hrs = t.title.toString().split(".");
        totalHours += parseInt(hrs[0]);
        if (hrs.length > 1) {
          totalMinutes += parseInt(hrs[1]);
        }
      });
      totalHours += parseInt(totalMinutes / 60);
      return totalHours + "." + (totalMinutes % 60).toString();
    } else {
      return 0;
    }
  };

  const getCandidateRate = (item, type) => {
    let rate = 0;
    if (type === "subcontractor") rate = item?.rate?.subcontractorRate;
    if (type === "recruiter") rate = item?.rate?.recruiterRate;
    return rate;
  };

  const handleInvGenerate = () => {
    if (isGenerated) {
      setSelectedCandidates([]);
      setIsGenerated(false);
    } else {
      setIsGenerated(true);
    }
  };

  const doSubmit = () => {
    dispatch(loadPartnerAllCandidateRpt(selectedYear, selectedMonth,partnerId, partType, 0))
  };

  useEffect(() => {
    setCandidateList(partnerCandidatesReportSelector);
    if(partnerCandidatesReportSelector.length > 0){
      setCandidateObj(partnerCandidatesReportSelector[0]);
    }
  }, [partnerCandidatesReportSelector]);

  const handleSelectCandidates = (item) => {
    const obj = selectedCandidates.filter((x) => x.id === item.id);
    const candList = [...selectedCandidates];
    if (obj.length > 0) {
      const filterCandidates = selectedCandidates.filter(
        (x) => x.id !== item.id
      );
      setSelectedCandidates(filterCandidates);
    } else {
      candList.push(item);
      setSelectedCandidates(candList)
    }
  };

  const getSubTotals = (selectedCandidates, usrType) => {
    let total = 0;
    selectedCandidates.map((item) => {
      const fAmt = getFinalAmout(item, usrType);
      total += fAmt;
    });

    return parseFloat(total).toFixed(2);
  };

  const handlePrintPdf = () => {
    setPdfBtnProccess(true)
    let htmlBody = printBodyRef.current.innerHTML;
    htmlBody += printFooterRef.current.innerHTML;
    const frmData = new FormData(); 
    frmData.append("html", htmlBody);
    dispatch(printGeneratedInvoice(frmData));
  };

  useEffect(() => {
    if (generatedInvPdfSelector?.size > 0) {
      setPdfBtnProccess(false);
      dispatch(generatedInvoiePdfReceived(null))
      const url = window.URL.createObjectURL(generatedInvPdfSelector);
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  }, [generatedInvPdfSelector])

  const handleSaveGenerate = () => {
    setSaveBtnProccess(true);
    let newFilter = [...candidateList];
    selectedCandidates.map((item,key) => {
      newFilter = newFilter.filter(x => x.id !== item.id);
      const newList = [item];
      let htmlBody = getUpdatedHtml(newList);
      htmlBody += printFooterRef.current.innerHTML;
      const frmData = new FormData();
      frmData.append("html", htmlBody);
      frmData.append("candidate", JSON.stringify(item));
      frmData.append("sign", "");
      frmData.append("remarks", "Invoice");
      frmData.append("workflowTask", JSON.stringify(wfTask));
      // const { data: result } = await http.post(
      //   config.GenerateBulkInvoice,
      //   frmData
      // );
      dispatch(partnerCandidateGenerateBulkInv(frmData));
      setGenerateCount(key + 1);
    });
    // this.setState({ saveBtnProccess: false });
    // getAlert("", "Generated Successfully", "success");
    // this.setState({ selectedCandidates: [], isGenerated: false, candidateList: newFilter });
  };

  useEffect(()=>{
    if(generateBulkInvoiceStatusSelector && generateCount === candidateList.length){
      setSaveBtnProccess(false);
      getAlert("", "Generated and Sent Successfully", "success");
      setGenerateCount(0);
      dispatch(partnerCandidatesInvGenerated(false));
      setCandidateList([]);
      setIsGenerated(false);
      setEsignName("");
    }
  },[generateBulkInvoiceStatusSelector])

  const getUpdatedHtml = (newList) => {
    const inv1 = getInvoiceHeader(newList[0]);
    const inv2 = getInvoiceInfo();
    const inv3 = getInvoiceMain(newList);
    const invHtml1 = ReactDOMServer.renderToStaticMarkup(inv1);
    const invHtml2 = ReactDOMServer.renderToStaticMarkup(inv2);
    const invHtml3 = ReactDOMServer.renderToStaticMarkup(inv3);
    const htmlBody = invHtml1 + invHtml2 + invHtml3;
    return htmlBody;
  };

  const getInvoiceBody = (selectedCandidates) => {
  
    const borderCss = {
      border: "1px solid #ccc",
      borderTop: "0px",
      borderRight: "0px",
    };

    const tbdy = (
      <tbody>
        {selectedCandidates.map((item) => (
          <tr>
            <td style={borderCss}>{item.name}</td>
            <td style={borderCss}>{getMonthlyPeriod(item)}</td>
            <td align="right" style={borderCss}>
              {getTotalHours(item)}
            </td>
            <td align="right" style={borderCss}>
              {getCandidateRate(item, partType)}
            </td>
            <td align="right" style={borderCss}>
              {getFinalAmout(item, partType)}
            </td>
          </tr>
        ))}
      </tbody>
    );

    return tbdy;
  };

  const getInvoiceHeader = (obj) => {
    const canObj = selectedCandidates.filter(x => x.id === obj.id)[0];
    return (
      <table
        width="100%"
        cellPadding="5"
        cellSpacing="0"
        style={{
          marginBottom: "20px",
          borderBottom: "1px solid #ccc",
        }}
      >
        <tr>
          <td colSpan={4} align="right">
            <p
              style={{
                fontSize: "40px",
                fontWeight: "bold",
                padding: "2px",
              }}
            >
              INVOICE
            </p>
          </td>
        </tr>
        <tr>
          <td width="55%"></td>
          <td>Invoice Number</td>
          <td width="1%">:</td>
          <td align="right">{canObj?.invoiceNumber}</td>
        </tr>
        <tr>
          <td></td>
          <td>Invoice Date</td>
          <td>:</td>
          <td align="right">{new Date().toLocaleDateString()}</td>
        </tr>
        <tr>
          <td></td>
          <td>Payment Term</td>
          <td>:</td>
          <td align="right">Net 30 Days</td>
        </tr>
        <tr>
          <td colSpan={4} style={{ height: "20px" }}>
            {" "}
          </td>
        </tr>
      </table>
    );
  };

  const getInvoiceInfo = () => {
    return (
      <table
        cellPadding="5"
        cellSpacing="0"
        width="100%"
        border="0"
        style={{ marginBottom: "25px" }}
      >
        <tbody>
          <tr>
            <td style={{ fontSize: "25px", fontWeight: "bold" }} width="51%">
              {userDetails && getFieldData(
                  userDetails.performerData,
                  ddName,
                  "Company_Name"
                )}
            </td>
            <td
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                backgroundColor: "#f0f0f0",
                border: "1px solid #ccc",
              }}
            >
              Bill To/Ship To
            </td>
          </tr>
          <tr>
            <td>
              {candidateObj && userDetails && (
                <React.Fragment>
                  <p>
                    {getFieldData(
                      userDetails.performerData,
                      ddName,
                      "Company_Address_Line1"
                    )}
                  </p>
                  <p>
                    {getFieldData(
                      userDetails.performerData,
                      ddName,
                      "Company_City"
                    )}
                    ,{" "}
                    {getFieldData(
                      userDetails.performerData,
                      ddName,
                      "Company_State"
                    )}{" "}
                    {getFieldData(
                      userDetails.performerData,
                      ddName,
                      "Company_Zip_Code"
                    )}
                  </p>
                  <p>
                    Phone :{" "}
                    {getFieldData(
                      userDetails.performerData,
                      ddName,
                      "Company_Phone_Code"
                    )}{" "}
                    {getFieldData(
                      userDetails.performerData,
                      ddName,
                      "Company_Phone"
                    )}
                  </p>
                  <p>
                    Email ID :{" "}
                    {getFieldData(
                      userDetails.performerData,
                      ddName,
                      "Company_Email"
                    )}
                  </p>
                  <p>ID : {partnerId}</p>
                </React.Fragment>
              )}
            </td>
            <td style={{ border: "1px solid #ccc" }} valign="top">
              <p>International Projects Consultancy Services (IPCS) Inc.</p>
              <p>600 South Highway 169</p>
              <p>Metropoint, Suite 1595</p>
              <p>Minneapolis, MN 55426</p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const getBankDetails = () => {
    
    return (
      <table
        cellPadding="5"
        cellSpacing="0"
        width="100%"
        border="0"
        style={{ marginBottom: "25px" }}
      >
        <tbody>
          <tr>
            <td
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                backgroundColor: "#f0f0f0",
                border: "1px solid #ccc",
              }}
            >
              Make Payment To
            </td>
          </tr>
          <tr>
            <td style={{ border: "1px solid #ccc" }} valign="top">
              <p>
                Bank Name :{" "}
                {getFieldData(
                  candidateObj.partnerStateData,
                  "achinfo",
                  "Bank_Name"
                )}
              </p>
              <p>
                Routing Number :{" "}
                {getFieldData(
                  candidateObj.partnerStateData,
                  "achinfo",
                  "Routing"
                )}
              </p>
              <p>
                Account Number :{" "}
                {getFieldData(
                  candidateObj.partnerStateData,
                  "achinfo",
                  "Account_No"
                )}
              </p>
              <p>
                Email Notification To :{" "}
                {getFieldData(
                  candidateObj.partnerStateData,
                  "achinfo",
                  "Email"
                )}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const getInvoiceFotter = () => {
    return (
      <div>
        <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>Signature</p>
        <p style={{
          fontStyle: "italic", fontWeight: "bold",
          fontSize: "14px", marginBottom: "7px"
        }}>{esignName}</p>
        <p>{new Date().toUTCString()}</p>
        <p>IP Address : {ipAddress}</p>
        <p>
          Name : {userDetails?.performername} {userDetails?.lastName}
        </p>
        <p>Date : {new Date().toLocaleDateString()}</p>
      </div>
    );
  };

  const getInvoiceMain = (selectedCandidates) => {
    
    const borderCss = {
      border: "1px solid #ccc",
      borderTop: "0px",
      borderRight: "0px",
    };
    return (
      <React.Fragment>
        <p style={{ paddingBottom: "20px" }}>
          Services : As per IT consulting services agreement and purchase order
        </p>
        <table
          cellPadding="5"
          cellSpacing="0"
          width="100%"
          style={{
            marginBottom: "25px",
            border: "1px solid #ccc",
            borderLeft: "0px",
            borderBottom: "0px",
          }}
        >
          <thead style={{ backgroundColor: "#f0f0f0" }}>
            <tr>
              <th style={borderCss}>Candidate</th>
              <th style={borderCss}>Period (Month/Year)</th>
              <th style={borderCss}>Hours</th>
              <th style={borderCss}>Rate/hr ($)</th>
              <th style={borderCss}>Total Amount ($)</th>
            </tr>
          </thead>
          {getInvoiceBody(selectedCandidates)}
          <tbody>
            <tr>
              <td colSpan={4} align="right" style={borderCss}>
                SubTotal
              </td>
              <td align="right" style={borderCss}>
                {getSubTotals(selectedCandidates, partType)}
              </td>
            </tr>
            <tr>
              <td colSpan={4} align="right" style={borderCss}>
                Total
              </td>
              <td align="right" style={borderCss}>
                {getSubTotals(selectedCandidates, partType)}
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment>
    );
  };

  const handleSelect = ({ currentTarget }) => {
    const id = currentTarget.dataset.obj;
    const obj = candidateList.filter((x) => x.id === parseInt(id))[0];
    if (currentTarget.checked) {
      if (!obj.invoiceNumber) {
        getAlert("", "Please Enter Invoice Number", "error");
        currentTarget.checked = false;
      } else handleSelectCandidates(obj);
    } else {
      handleSelectCandidates(obj);
    }
  };

  const handleChangeInvNumber = ({ currentTarget }) => {
    const id = currentTarget.dataset.obj;
    const obj = { ...candidateList.filter((x) => x.id === parseInt(id))[0] };
    const index = candidateList.findIndex(x => x.id === parseInt(id));
    const candList = [...candidateList];
    if (obj) {
      obj.invoiceNumber = currentTarget.value;
      candList[index] = obj;
      setCandidateList(candList);
    }
  }

  return (
    <React.Fragment>
      <Modal show={bulkShowHide} size="xl">
        <Modal.Header closeButton onHide={() => handleBulkShowHide("")}>
          <Modal.Title>Generate Bulk Invoices</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            {!isGenerated && (
              <React.Fragment>
                <div className="col-md-4">
                  <label htmlFor="year" className="form-control-label">Invoice Year</label>
                  <select name="year" id="year"
                    className="form-control"
                    value={selectedYear}
                    onChange={({ currentTarget }) => setSelectedYear(currentTarget.value)}
                  >
                    <option value="">Year</option>
                    {years.map(y =>
                      <option value={y.name}>{y.name}</option>
                    )}
                  </select>

                </div>
                <div className="col-md-4">
                  <label htmlFor="month" className="form-control-label">Invoice Month</label>
                  <select name="month" id="month"
                    className="form-control"
                    value={selectedMonth}
                    onChange={({ currentTarget }) => setSelectedMonth(currentTarget.value)}
                  >
                    <option value="">Month</option>
                    {months.map(m =>
                      <option value={m.id}>{m.name}</option>
                    )}
                  </select>
                 
                </div>
                <div className="col-md-4 d-flex align-items-end justify-content-start mb-1">
                  <button
                    className="btn btn-outline-default"
                    disabled={!selectedYear || !selectedMonth}
                    onClick={doSubmit}
                  >
                    Load Candidates
                  </button>
                </div>
                <div className="col-md-12 form-group mt-2">
                  <div className="table-responsive">
                    <table className="table align-items-center table-flush table-hover table-striped border">
                      <thead className="thead-light">
                        <tr>
                          <th>Select</th>
                          <th>Enter Invoice Number</th>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {candidateList.map((item) => (
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                defaultChecked={item.isSelected}
                                onChange={handleSelect}
                                data-obj={item.id}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                value={item.invoiceNumber}
                                onChange={handleChangeInvNumber}
                                data-obj={item.id}
                              />
                            </td>
                            <td>{item.id}</td>
                            <td>
                              <span className="text-uppercase font-weight-bold">
                                {item.name}
                              </span>
                            </td>
                            <td>{item.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="col-md-12 text-center">
                  <button
                    className="btn btn-sm btn-outline-default"
                    disabled={selectedCandidates.length <= 0}
                    onClick={handleInvGenerate}
                  >
                    <i className="fa fa-cog" aria-hidden="true"></i> Generate Invoice
                  </button>
                </div>
              </React.Fragment>
            )}
            {isGenerated && (
              <React.Fragment>
                <div className="col-md-12">
                  <div id="printBody" ref={printBodyRef}>
                    {candidateObj && getInvoiceHeader(candidateObj)}
                    {getInvoiceInfo()}
                    {selectedCandidates.length > 0 && getBankDetails()}
                    {selectedCandidates.length > 0 &&
                      getInvoiceMain(selectedCandidates)}
                  </div>
                  <div id="printFooter" ref={printFooterRef}>
                    {selectedCandidates.length > 0 && getInvoiceFotter()}
                  </div>
                </div>
                <div className="col-md-12 mt-3 form-group">
                  <label htmlFor="esignName" className="form-control-label">Sign Digitally</label>
                  <textarea cols="2" rows={2}
                    id="esignName"
                    name="esignName"
                    className="form-control"
                    value={esignName}
                    placeholder="Sign Digitally"
                    onChange={({ currentTarget }) => setEsignName(currentTarget.value)}
                  ></textarea>
                </div>
                <div className="col-md-12 text-center">
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={handleInvGenerate}
                  >
                    <i className="fa fa-times" aria-hidden="true"></i> Close
                  </button>
                  <button
                    className="btn btn-sm btn-outline-default"
                    onClick={handleSaveGenerate}
                  >
                    {saveBtnProccess ? <i className="fa fa-spinner fa-spin mr-2"></i> :
                      <i className="fa fa-check mr-2" aria-hidden="true"></i>}Generate & Save
                  </button>
                  <button
                    className="btn btn-sm btn-outline-default"
                    onClick={handlePrintPdf}
                  >
                    {pdfBtnProccess ? <i className="fa fa-spinner fa-spin mr-2"></i> :
                      <i className="fa fa-print mr-2" aria-hidden="true"></i>}Print PDF
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleBulkShowHide("")}>
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default BulkInvoice;
