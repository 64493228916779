import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from "./reducer";
import api from "./middleware/api";
//import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// const persistConfig = {
//   key: 'root',
//   storage,
// }

// const persistedReducer = persistReducer(persistConfig, reducer)

const store = configureStore({
  reducer: reducer,
  middleware: [
    ...getDefaultMiddleware(),
    api,
  ]
});
//export const persistor = persistStore(store)
export default store;


