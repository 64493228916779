import React, { Component } from "react";
import Speech from "speak-tts";

class TextReader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.myInputRef.focus();
  }

  handleHeadingFocus = () => {
    //console.log(document.activeElement.tagName);
    const spch = new Speech();
    spch.speak({
      text:
        document.activeElement.textContent +
        "Heading Level " +
        document.activeElement.tagName.replace("H", ""),
    });
  };

  handleInputFocus = ({ currentTarget }) => {
    //console.log(document.activeElement.tagName);
    const spch = new Speech();
    spch.speak({
      text: currentTarget.ariaLabel + " Edit Text",
    });
  };

  handleDropdwonFocus = ({ currentTarget: input }) => {
    //console.log(currentTarget.options);
    const { options, value } = input;
    const index = options.selectedIndex + 1;
    const spch = new Speech();
    spch.speak({
      text:
        input.name +
        options[options.selectedIndex].text +
        " combo box " +
        index +
        " of " +
        options.length,
    });
  };

  render() {
    return (
      <div className="col-md-12 form-group">
        <h3
          tabindex="-1"
          onFocus={this.handleHeadingFocus}
          className="text-center"
        >
          VETERAN ONLINE HOME LOAN ORIGINATION SYSTEM WITH MULTI LENDER AND
          MULTI BROKER PARTICIPATION
        </h3>
        <select
          className="form-control"
          id="Title"
          name="Title"
          onFocus={this.handleDropdwonFocus}
        >
          <option value>Select</option>
          <option value="Dr.">Dr.</option>
          <option value="Miss">Miss</option>
          <option value="Mr.">Mr.</option>
          <option value="Mrs.">Mrs.</option>
          <option value="Mx.">Mx.</option>
        </select>

        <input
          type="text"
          //value={data["testInput"]}
          //onChange={this.onChangeInput}
          placeholder="First Name"
          className="form-control"
          onFocus={this.handleInputFocus}
          name="firstName"
          id="firtName"
          aria-label="First Name"
        />
        <br></br>
        <input
          type="text"
          //value={data["testInput"]}
          //onChange={this.onChangeInput}
          placeholder="Last Name"
          className="form-control"
          onFocus={this.handleInputFocus}
          name="lastName"
          id="lastName"
          aria-label="Last Name"
        />
      </div>
    );
  }
}

export default TextReader;
