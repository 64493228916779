import React, { useEffect, useState } from "react";
import OnboardingSteps from "./OnboardingSteps";
import { getCurrentUser } from "../../store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getAppFormLibrary, getAppTasks, getCandidateApps, loadAppFormLibrary, loadAppRuningTasks, loadCandidateApps } from "../../store/candidate";
import { getFieldData, getWfPhasesColor } from "../../../Services/appService";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import CandidateInfo from "../CandidateInfo";
import PartnerInfo from "../PartnerInfo";
import EnrollmentSteps from "./EnrollmentSteps";
import { setCurrentPageTitle, setCurrentRoute } from "../../store/layoutUI";
import { getWorkflowTask, loadWorkflowTask, workflowTaskReceived } from "../../store/WorkflowEngine";
import { addFormLibrary, formLibraryAdded, getAppFormLibraryAddedStatus } from "../../store/forms";
import { loadToolsCount } from "../../store/tools";

export default function Onboarding() {

    const usrType = localStorage.getItem("userType");

    const route = [
        { name: "Task Management", url: "" },
        { name: "Onboarding Steps", url: "" },
    ];  
    const route1 = [
        { name: "Contract Management", url: "" },
        { name: "Enrollment Steps", url: "" },
    ];

    let appType = "Enrollment Form";
    if (usrType === "candidate")
        appType = "Onboarding Form";

    const [appTasklist, setAppTasklist] = useState([]);
    const [entityType, setEntityType] = useState("");
    const [count, setCount] = useState(0);
    const [appSubmitStatus, setAppSubmitStatus] = useState(false);
    const [startBtnProccess, setStartBtnProccess] = useState(false);
    const [wfId, setWfId] = useState(null);
    const [wfTask, setWfTask] = useState(null);

    const candidateAppsSelector = useSelector(getCandidateApps);
    const appTasksSelector = useSelector(getAppTasks);
    const workflowTaskSelector = useSelector(getWorkflowTask);
    const appFormLibrarySelector = useSelector(getAppFormLibrary);
    const appFormLibraryAddedStatusSelector = useSelector(getAppFormLibraryAddedStatus);

    const dispatch = useDispatch();
    const currentUser = getCurrentUser();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setCurrentPageTitle(`STEP-1 : ${appType}`));
        dispatch(setCurrentRoute(usrType === "candidate" ? route : route1))
    }, [])

    useEffect(() => {
        if (candidateAppsSelector.length === 0)
            dispatch(loadCandidateApps(currentUser.id));
    }, []);

    useEffect(() => {
        if (candidateAppsSelector.length > 0) {
            const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
            if (mainWorkflow.length > 0) {
                const wf = mainWorkflow[0];
                setWfId(wf.oldWorkflowId);
                if (appTasksSelector.length === 0)
                    dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
            }
        }
    }, [candidateAppsSelector]);

    useEffect(() => {
        if (appTasksSelector.length > 0) {
            const filterTasks = appTasksSelector.filter(x => x.taskType === 1 && x.wiid > 0);
            let firstAppTasks = [];
            if (filterTasks.length > 0) {
                firstAppTasks = [filterTasks[0]];
                setAppTasklist(firstAppTasks);
                setAppSubmitStatus(firstAppTasks[0].appSubmit);
                const entType = getFieldData(
                    filterTasks[0].stateData,
                    "candidateonboardingform",
                    "CandidateEntity"
                );
                setEntityType(entType);
                const tsk = filterTasks[0];
                setWfTask({
                    ciid: tsk.ciid,
                    wiid: tsk.wiid,
                    iiid: tsk.iiid,
                    applicantId: tsk.performerId,
                    performerId: 1,
                });
                if (wfId && entType !== "C2C Basis")
                    dispatch(loadAppFormLibrary(wfId, currentUser.id));
            }
        }
    }, [appTasksSelector]);

    const handleCurrentTask = (wiid) => {
        setStartBtnProccess(true);
        dispatch(loadWorkflowTask(wiid));
    }

    useEffect(() => {
        if (workflowTaskSelector && startBtnProccess) {
            setStartBtnProccess(false);
            if (workflowTaskSelector.formType === "CF")
                navigate("/" + workflowTaskSelector.formName, { state: { workflowTask: workflowTaskSelector } });
            else
                navigate("/DynamicForm", { state: { workflowTask: workflowTaskSelector } });

            dispatch(workflowTaskReceived(""));
        }
    }, [workflowTaskSelector]);

    const handleViewAppSummary = (item, type) => {
        navigate("/ApplicationSummary", { state: { task: item, viewType: type } });
    }

    useEffect(() => {
        const achObj = {
            formId: 47,
            formName: "ACH Payment Information",
            remarks:
                "This information is captured for making Payments to your company by ACH",
            formType: "ACH Information",
        };

        const insObj = {
            formId: 22,
            formName: "Insurance Details",
            remarks:
                "Upload your company's insurance certificate(s) as per the contract requirements",
            formType: "Insurance Information",
        };

        if (appFormLibrarySelector.length > 0) {
            const achForm = appFormLibrarySelector.filter(x => x.formType === "ACH Information");
            if (achForm.length === 0) {
                addReqFormLibrary(wfTask, achObj)
            }
            const insForm = appFormLibrarySelector.filter(x => x.formType === "Insurance Information");
            if (insForm.length === 0) {
                addReqFormLibrary(wfTask, insObj)
            }
        } else if(wfTask && entityType !== "C2C Basis") {
            addReqFormLibrary(wfTask, achObj);
            addReqFormLibrary(wfTask, insObj);
        }
      }, [appFormLibrarySelector]);

    const addReqFormLibrary = async (currentTask, frmObj) => {
        const frmData = new FormData();
        frmData.append("workflowTask", JSON.stringify(currentTask));
        frmData.append("formId", frmObj.formId);
        frmData.append("formName", frmObj.formName);
        frmData.append("remarks", frmObj.remarks);
        frmData.append("id", "");
        frmData.append("formType", frmObj.formType);
        frmData.append("status", "1");
        dispatch(addFormLibrary(frmData));
    };

    useEffect(() => {
        if (appFormLibraryAddedStatusSelector) {
            dispatch(loadToolsCount(currentUser.id));
            dispatch(formLibraryAdded(false))
        }
    }, [appFormLibraryAddedStatusSelector])

    return <React.Fragment>

        <div className="col-md-12 mt-2 form-group">
            <div className="card shadow-none border">
                <div className="card-header p-3">
                
                <i className="fa fa-square wf-phase2-box mr-2" aria-hidden="true"></i>
                    <span className="h3">
                    Onbording/Enrollment Form</span>
                    {!appSubmitStatus && (
                        <p className="text-red">
                            Click on START button below to complete your {appType}
                        </p>
                    )}
                </div>
                <div className="card-body p-3">
                    <div className="table-responsive border border-top-0">
                        <table className="table align-items-center table-flush table-hover table-striped">
                            <thead className="thead-light">
                                <tr>
                                    <th>ID</th>
                                    {appSubmitStatus && <>
                                        <th>Date & Time Submitted</th>
                                        <th>Submitted</th>
                                    </>}
                                    {!appSubmitStatus &&
                                        <th>Click on below button</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {appTasklist.map(item =>
                                    <tr>
                                        <td>{item.ciid}</td>

                                        {appSubmitStatus &&
                                            <>
                                                <td>
                                                    <Moment format="MMM Do YYYY h:mm a">
                                                        {item.startDateTime}
                                                    </Moment>
                                                </td>
                                                <td>
                                                   
                                                    <button
                                                        onClick={() => handleViewAppSummary(item, "view")}
                                                        className="btn btn-link btn-sm pl-0"
                                                    >
                                                       View
                                                    </button>
                                                    <br />
                                                    <button
                                                        onClick={() => handleViewAppSummary(item, "edit")}
                                                        className="btn btn-link btn-sm pl-0 mt-1"
                                                    >
                                                        Edit/Update
                                                    </button>
                                                </td>
                                            </>
                                        }

                                        {!appSubmitStatus &&
                                            <>
                                            <td>
                                                <button
                                                    onClick={() => handleCurrentTask(item.wiid)}
                                                    disabled={item.performerId !== parseInt(currentUser.id) || startBtnProccess}
                                                    className={`btn btn-sm btn-outline-default`}
                                                >
                                                {startBtnProccess && <i className="fa fa-spinner fa-spin mr-2"></i>}
                                                  Start {item.taskName}
                                                </button>
                                            </td>
                                            </>
                                        }
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}