import _ from "lodash";

export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items).slice(startIndex).take(pageSize).value();
}

export function calculateIndex(page, startIndex, endIndex) {
  if (endIndex === page) {
    return { startIndex: page - 1, endIndex: page + 4 };
  }

  if (startIndex === page) {
    return { startIndex: page - 4, endIndex: page + 1 };
  } else {
    if (startIndex === page - 1 && page > 4) {
      return { startIndex: page - 5, endIndex: page };
    }
  }

  return { startIndex, endIndex };
}

