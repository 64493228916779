import React, { Component, useEffect, useState } from "react";
import auth from "../../Services/authService";
import geo from "../../Services/geoService";
import Form from "./Form";
import useForm from "../../app/hooks/useForm";
import useControls from "../../app/hooks/useControls";

const MyProfileDetails=(props)=> {
  const { userDetails,profileEdit,setProfileEdit,handleFormSubmit } = props;

  const state = {
    user: {},
    currentUser: {},
    stateList: [],
    countyList: [],
    selectedStateId: "",
    selectedState: "",
    selectedCountyId: "",
    selectedCounty: "",
    data: {},
    errors: {},
  };

  const [usStateList, setUsStateList] = useState([]);

  const schema = {};

  useEffect(() => {
    const loadUsStates = async () => {
      const allStates = await geo.getStateList();
      setUsStateList(allStates);
    }
    loadUsStates();
  }, [])

  useEffect(() => {
    if (userDetails) {
      let userName = userDetails.userName;
      let email = userDetails.performeremail;
      let name = userDetails.performername;
      let id = userDetails.performerid;
      let contactNum = userDetails.contactNo;
      let lastname = userDetails.lastName;
      let zipCode = userDetails.zipCode;

      const obj = {
        performerid: id,
        performername: name,
        performeremail: email,
        userName: userName,
        contactNo: contactNum,
        location: userDetails.location,
        state: userDetails.state,
        county: userDetails.county,
        lastName: lastname,
        zipCode: zipCode,
      };
      setStateData(obj);
    }
  }, [userDetails])

 
  const doSubmit = () => {
    handleFormSubmit(stateData);
    setProfileEdit(false)
  };

  const { handleChange, stateData, setStateData, stateErrors, handleSubmit } = useForm(schema, doSubmit);

  const { renderSelect, renderInput } = useControls(stateData, stateErrors, handleChange, schema);

    return (
      <React.Fragment>
        <fieldset disabled={!profileEdit}>
          <div className="row">
            <div className="col-lg-6">
              {renderInput("performername", "First Name")}
            </div>
            <div className="col-lg-6">
              {renderInput("lastName", "Last Name")}
            </div>
            <div className="col-lg-6">
              {renderInput("contactNo", "Phone No.", "phone")}
            </div>
            <div className="col-lg-6">
              {renderInput("performeremail", "Email")}
            </div>
            <div className="col-lg-12 form-group">
            <label className="form-control-label mr-2">
                Choose a way to verify
              </label>
              (<span className="text-red">You'll need to confirm that it's yours. 
                Select how you'd like to get a verification code.</span>)
                <div className="d-flex">
              <div className="custom-control custom-checkbox">
                <input
                  className="custom-control-input"
                  id="verifyByEmail"
                  type="checkbox"
                />
                <label className="custom-control-label mr-3" htmlFor="verifyByEmail">
                  Email
                </label>
              </div>
              <div className="custom-control custom-checkbox">
              <input
                  className="custom-control-input"
                  id="verifyByPhone"
                  type="checkbox"
                />
                <label className="custom-control-label" htmlFor="verifyByPhone">
                Phone No.
                </label>
              </div></div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {renderInput("location", "Address")}
            </div>

            <div className="col-md-4">
              {renderSelect(
                "state",
                "State",
                usStateList,
                "name",
                "name"
              )}
            </div>
            <div className="col-md-4">
              {renderInput("county", "City")}
            </div>
            <div className="col-md-4">
              {renderInput("zipCode", "Zip Code")}
            </div>
          </div>
          <div className="col-md-12 text-center form-group">
            {profileEdit && (
              <button
                type="button"
                className="btn btn-sm btn-outline-default"
                onClick={handleSubmit}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Update
              </button>
            )}
          </div>
        </fieldset>
      </React.Fragment>
    );
}

export default MyProfileDetails;
