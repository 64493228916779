import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import PageHeader from "../Comman/PageHeader";
import http from "../../Services/httpService";
import auth from "../../Services/authService";
import config from "../../config.json";
import ChecklistItem from "../Comman/ChecklistItem";

class SurveyFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: [{ url: "", name: "Task Management" }],
      route1: [{ url: "", name: "Contract Management" }],
      appTasklist: [],
      user: {},
    };
  }

  // async componentDidMount() {
  //   let user = auth.getCurrentUser();
  //   const { data: appliedApplication } = await http.get(
  //     config.GetPerformerApplication + user.id
  //   );

  //   if (appliedApplication.length > 0) {
  //     const filterApp = appliedApplication.filter(
  //       (x) => x.workflowType === 2 && x.type !== "Background Check"
  //     );
  //     let appTasklist = [];
  //     filterApp.map(async (item) => {
  //       const { data: runningApplication } = await http.get(
  //         config.GetRunningTask + item.workflowId + "&performerId=" + user.id
  //       );
  //       const obj = {
  //         chkApp: item,
  //         runningApplication,
  //       };
  //       appTasklist.push(obj);
  //       this.setState({ appTasklist, user });
  //     });
  //   }
  // }

  handleCurrentTask = async (wiid) => {
    const { data: workflowTask } = await http.get(
      config.GetWorkflowTask + wiid
    );
    if (workflowTask.formId > 0) {
      if (workflowTask.formType === "CF") {
        this.props.history.push({
          pathname: "/" + workflowTask.formName,
          state: { workflowTask },
        });
      } else {
        this.props.history.push({
          pathname: "/DynamicForm",
          state: { workflowTask },
        });
      }
    }
  };

  render() {
    const { route, appTasklist, user, route1 } = this.state;
    const usrType = localStorage.getItem("userType");
    return (
      <React.Fragment>
        {/* <PageHeader
          route={usrType === "candidate" ? route : route1}
          parentTitle="Surveys"
          headerTitle="Surveys"
        ></PageHeader> */}
        <div className="col-md-12 form-group mt-2">
          <ul className="list-group">
            <li className="list-group-item">
              <button className="btn btn-success btn-sm">
                Complete Your Surveys Comming Soon....
              </button>
            </li>
          </ul>
          <ul className="list-group mt-3">
            {appTasklist.map((app) => (
              <React.Fragment>
                {/* <ChecklistItem
                  Checklist={app.chkApp}
                  color="#fce7a9"
                  type="Survey"
                ></ChecklistItem> */}
                <li className="list-group-item">
                  <h4 className="mb-0">Complete Survey(s)</h4>
                </li>
                <li className="list-group-item">
                  <div className="table-responsive">
                    <table className="table align-items-center table-flush table-hover table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>S.No.</th>
                          <th>Date</th>
                          <th>Application Workflow Status</th>
                          <th>Review Officer</th>
                        </tr>
                      </thead>
                      <tbody>
                        {app.runningApplication.map((item, key) => (
                          <tr>
                            <td>{key + 1}</td>
                            <td>
                              <Moment format="MMM Do YYYY">
                                {item.startDateTime}
                              </Moment>
                              <br></br>
                              <Moment format="h:mm A">
                                {item.startDateTime}
                              </Moment>
                            </td>
                            <td>
                              {parseInt(user.id) === item.performerId ? (
                                <Link
                                  onClick={() =>
                                    this.handleCurrentTask(item.wiid)
                                  }
                                >
                                  {item.taskName}
                                </Link>
                              ) : (
                                item.taskName
                              )}
                            </td>
                            <td>
                              {parseInt(user.id) !== item.performerId ? (
                                <React.Fragment>
                                  {item.performer !== null &&
                                    item.performer.toUpperCase()}
                                  <br></br>
                                  {item.email !== null &&
                                    "Email : " + item.email}
                                  <br></br>
                                  {item.contact !== null &&
                                    "Contact : " + item.contact}
                                </React.Fragment>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default SurveyFeedback;
