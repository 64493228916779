import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";

class WelcomePage extends Component {
  render() {
    const { welShowHide, handleWelShowHide } = this.props;
    return (
      <Modal show={welShowHide} size="lg">
        <Modal.Header closeButton onClick={handleWelShowHide}>
          <Modal.Title>Welcome to IPCS!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary pb-2">
          <div className="row">
            <div className="col-md-12 form-group">
              As you all are aware, COVID-19 pandemic has impacted everyone all
              across the globe. Businesses like ours are working to refine
              business models and redesigning ways to work more efficiently with
              customers, partners and staff. The need to innovative and to
              collaborate digitally has become the need of the hour.
            </div>
            <div className="col-md-12 form-group">
              Accordingly, in our ongoing effort to maximize digital interaction
              beginning 1st March 2022 all our contract operations will be
              collaboratively managed in this portal. This includes candidate
              onboarding, subcontractor enrollment, time log management,
              invoicing and contract management.
            </div>
            <div className="col-md-12 form-group">
              We therefore welcome you to our portal.
            </div>
            <div className="col-md-12 form-group">
              We are certain you will find the experience of working with us
              digitally as innovative and bringing high degree of business
              process automation in our joint business relationship.
            </div>

            <div className="col-md-12 form-group">Thank you.</div>
            <div className="col-md-12 form-group">Sincerely,</div>
            <div className="col-md-12 form-group">
              Kuldeep Dhar
              <br />
              CEO
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleWelShowHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default WelcomePage;
