import React, { Component, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "../Comman/Form";
import config from "../../config.json";
import auth, { getCurrentUser } from "../../Services/authService";
import http from "../../Services/httpService";
import Swal from "sweetalert2";
import GenerateInvoice from "./GenerateInvoice";
import { getAlert } from "../../Services/appAlerts";
import { useDispatch, useSelector } from "react-redux";
import { getAppTasks, getCandidateApps, loadAppRuningTasks, loadCandidateApps } from "../../app/store/candidate";
import { appDocumentUploaded, getAppDocUploadStatus, uploadAppDocuments } from "../../app/store/documents";
import { getTimeLogUpdateStatus, updateTimeLogInvoice } from "../../app/store/timeLogInvoice";

const UploadInvoice = (props) => {

  const { invShowHide, handleInvShowHide, candidateObj, selectedObj } = props;

  const [invOption, setInvOption] = useState(null);
  const [btnProess, setBtnProess] = useState(false);
  const [genShowHide, setGenShowHide] = useState(false);
  const [invNumber, setInvNumber] = useState("");
  const [notes, setNotes] = useState("");
  const [documents, setDocuments] = useState([]);
  const [wfTask, setWfTask] = useState(null);
  const [saveInvDocs, setSaveInvDocs] = useState(false);

  const candidateAppsSelector = useSelector(getCandidateApps);
  const appTasksSelector = useSelector(getAppTasks);
  const appDocUploadStatusSelector = useSelector(getAppDocUploadStatus);
  const timeLogUpdateStatusSelector = useSelector(getTimeLogUpdateStatus);

  const usrType = localStorage.getItem("userType");

  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  useEffect(()=>{
    if(!invShowHide){
      setInvNumber("");
      setNotes("");
      setInvOption(null)
    }
  },[invShowHide])

  useEffect(() => {
    if (candidateAppsSelector?.length === 0)
      dispatch(loadCandidateApps(currentUser.id));
  }, []);

  useEffect(() => {
    if (candidateAppsSelector.length > 0) {
      const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
      if (mainWorkflow.length > 0) {
        const wf = mainWorkflow[0];
        if (appTasksSelector.length === 0)
          dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
      }
    }
  }, [candidateAppsSelector]);

  useEffect(() => {
    if (appTasksSelector.length > 0) {
      const filterTasks = appTasksSelector.filter(x => x.taskType === 1 && x.wiid > 0);
      if (filterTasks.length > 0) {
        const reviewTasks = filterTasks.filter(x => x.registrationType === "WP");
        if (reviewTasks.length > 0)
          setWfTask(reviewTasks[reviewTasks.length - 1]);
        else
          setWfTask(filterTasks[0]);
      }
    }
  }, [appTasksSelector]);

  const doSubmit = () => {
    setBtnProess(true);
    if (documents.length > 0) {
      const frmData = new FormData();
      frmData.append("message", notes);
      frmData.append("workflowTask", JSON.stringify(wfTask));
      frmData.append("userId", currentUser.id);
      frmData.append("docType", "Invoice");
      documents.map((file, key) =>
        frmData.append("files[" + key + "]", file.file)
      );
      dispatch(uploadAppDocuments(frmData));
      setSaveInvDocs(true);
    }

  };

  useEffect(() => {
    if (appDocUploadStatusSelector > 0 && saveInvDocs) {
      setSaveInvDocs(false);
      dispatch(appDocumentUploaded(null));
      const tmObj = {
        id: selectedObj.id,
        invoiceDocId: appDocUploadStatusSelector,
        notes: notes,
        remarks: invNumber,
        docUploadType: usrType,
      };
      dispatch(updateTimeLogInvoice(tmObj));

    } else if (saveInvDocs) {
      getAlert("", "Invoice not found", "error");
    }
  }, [appDocUploadStatusSelector]);

  useEffect(() => {
    if (timeLogUpdateStatusSelector) {
      setBtnProess(false);
      getAlert("Sent Successfully", "", "success");
      handleInvShowHide("save");
    }
  }, [timeLogUpdateStatusSelector])

  const onFileChange = (event) => {
    const docs = [];
    for (let file of event.target.files) {
      var doc = {
        name: file.name,
        type: file.type,
        file: file,
      };
      docs.push(doc);
    }
    setDocuments(docs);
  };

  const handleGenShowHide = (type) => {
    setGenShowHide(!genShowHide);
    if (type === "save") {
      handleInvShowHide("save");
    }
  };

  return (
    <React.Fragment>
      <Modal show={invShowHide} size="xl">
        <Modal.Header closeButton onHide={handleInvShowHide}>
          <Modal.Title>Upload Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            <div className="col-md-12 form-group form-inline">
              <label
                htmlFor="invOption"
                className="form-control-label mb-3 mr-3"
              >
                Select Invoice Option
              </label>
              <div className="custom-control custom-radio mb-3">
                <input
                  name="invOption"
                  className="custom-control-input"
                  id="manual"
                  type="radio"
                  value="manual"
                  defaultChecked={invOption === "manual"}
                  onChange={() => setInvOption("manual")}
                />
                <label className="custom-control-label" htmlFor="manual">
                  Manual Upload Invoice
                </label>
              </div>
              <div className="custom-control custom-radio mb-3 ml-3">
                <input
                  name="invOption"
                  className="custom-control-input"
                  id="automatic"
                  value="automatic"
                  type="radio"
                  defaultChecked={invOption === "automatic"}
                  onChange={() => setInvOption("automatic")}
                />
                <label className="custom-control-label" htmlFor="automatic">
                  Generate Automatic Invoice
                </label>
              </div>
            </div>

            {invOption && <>
              <div className="col-md-12 form-group">
                <label htmlFor="invNumber" className="form-control-label">Invoice Number</label>
                <input type="text"
                  className="form-control"
                  value={invNumber}
                  id="invNumber"
                  name="invNumber"
                  placeholder="Invoice Number"
                  onChange={({ currentTarget }) => setInvNumber(currentTarget.value)}
                />
              </div>
              <div className="col-md-12 form-group">
                <label htmlFor="notes" className="form-control-label">Notes</label>
                <textarea cols={2} rows={2}
                  name="notes"
                  id="notes"
                  value={notes}
                  className="form-control"
                  placeholder="Notes"
                  onChange={({ currentTarget }) => setNotes(currentTarget.value)}
                ></textarea>
              </div>
            </>}

            {invOption === "manual" && (
              <React.Fragment>
                <div className="col-md-12">
                  <label htmlFor="invfile" className="form-control-label">
                    Upload Invoice File
                  </label>
                  <input
                    type="file"
                    name="invfile"
                    id="invfile"
                    className="form-control"
                    onChange={onFileChange}
                  />
                </div>
              </React.Fragment>
            )}
            {invOption === "automatic" && (
              <React.Fragment>
                <div className="col-md-12">
                  <button
                    className="btn btn-sm btn-outline-default"
                    onClick={handleGenShowHide}
                  >
                    <i className="fa fa-cog"></i> Generate
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleInvShowHide("")}>
            <i className="fa fa-times"></i> Close
          </button>
          <button className="btn btn-sm btn-outline-dark"
            disabled={btnProess}
            onClick={doSubmit}
          >
            {btnProess ? <i className="fa fa-spinner fa-spin mr-2"></i> : <i className="fa fa-paper-plane mr-1"></i>}
            Send
          </button>
        </Modal.Footer>
      </Modal>

      <GenerateInvoice
        genShowHide={genShowHide}
        handleGenShowHide={handleGenShowHide}
        selectedObj={selectedObj}
        candidateObj={candidateObj}
        invoiceNumber={invNumber}
        notes={notes}
        wfTask={wfTask}
      ></GenerateInvoice>
    </React.Fragment>
  );
}

export default UploadInvoice;
