import React, { Component } from "react";

class Other_textbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      encryptDecrypt: true,
      encryptValue: "",
      decryptValue: "",
      valueArray: [],
      selectedTextStartIndex: 0,
      selectedTextEndIndex: 0,
    };
  }

  handleEncryptOnChange = (input) => {
    const { currentTarget } = input;
    let filterValue = currentTarget.value; //.replace(/[^\d]/g, "");
    const {
      encryptDecrypt,
      valueArray,
      encryptValue,
      decryptValue,
      selectedTextStartIndex,
      selectedTextEndIndex,
    } = this.state;
    //console.log(filterValue);
    if (encryptDecrypt) {
      let newValue = filterValue;
      let decrypt = "";
      for (let index = 0; index < newValue.length; index++) {
        decrypt += "*";
      }
      filterValue = filterValue.replace(/\*/g, "");
      if (filterValue === "") {
        let calcIndex1 = input.target.selectionStart;
        //console.log(calcIndex1);
        if (selectedTextStartIndex !== selectedTextEndIndex) {
          for (
            let index = selectedTextEndIndex;
            index > selectedTextStartIndex;
            index--
          ) {
            let nindex = index;
            valueArray.splice(nindex, 1);
          }
        } else {
          valueArray.splice(calcIndex1, 1);
        }
      } else {
        let calcIndex = input.target.selectionStart;
        filterValue = filterValue.replace(/\*/g, "");
        for (let index = 0; index < filterValue.length; index++) {
          const element = filterValue[index];
          calcIndex += index;
          valueArray.splice(calcIndex - 1, 0, element);
        }
      }
      this.setState({ valueArray });
      if (valueArray.length > 0) {
        this.setState({ decryptValue: decrypt });
      } else {
        this.setState({ decryptValue: "" });
      }
    } else {
      let newArray = [];
      for (let index = 0; index < filterValue.length; index++) {
        newArray.push(filterValue[index]);
      }
      this.setState({ valueArray: newArray });
      this.setState({ decryptValue: filterValue });
    }
  };

  handleEncryptValue = () => {
    const { valueArray } = this.state;
    let decValue = "";
    for (let index = 0; index < valueArray.length; index++) {
      decValue += "*";
    }
    this.setState({ decryptValue: decValue, encryptDecrypt: true });
  };

  handleDcryptValue = () => {
    const { valueArray } = this.state;
    let decValue = "";
    for (let index = 0; index < valueArray.length; index++) {
      const element = valueArray[index];
      decValue += element;
    }
    this.setState({ decryptValue: decValue, encryptDecrypt: false });
  };

  handleMouseUp = ({ currentTarget }) => {
    this.setState({
      selectedTextStartIndex: currentTarget.selectionStart,
      selectedTextEndIndex: currentTarget.selectionEnd,
    });
  };

  componentDidMount() {
    const { value } = this.props;
    if (value !== "" && value !== undefined) {
      let valueArray = [];
      let decValue = "";
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        valueArray.push(element);
        decValue += "*";
      }
      this.setState({ decryptValue: decValue, valueArray });
    } else {
      this.setState({ valueArray: [], decryptValue: "" });
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { value } = nextProps;
    if (value !== "" && value !== undefined) {
      let valueArray = [];
      let decValue = "";
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        valueArray.push(element);
        decValue += "*";
      }
      this.setState({ decryptValue: decValue, valueArray });
    } else {
      this.setState({ valueArray: [], decryptValue: "" });
    }
  };

  render() {
    const { encryptDecrypt, decryptValue } = this.state;
    const { label, name, isDisabled } = this.props;
    return (
      <>
        <div className="d-flex">
          <input
            type="text"
            className="form-control"
            name={name}
            value={decryptValue}
            onChange={this.handleEncryptOnChange}
            onMouseUp={this.handleMouseUp}
            placeholder={label}
            disabled={isDisabled}
          ></input>
          {!encryptDecrypt && (
            <button
              className="btn btn-secondary btn-sm ml-2"
              onClick={this.handleEncryptValue}
            >
              <i className="fa fa-eye-slash" aria-hidden="true"></i>
            </button>
          )}
          {encryptDecrypt && (
            <button
              className="btn btn-secondary btn-sm ml-2"
              onClick={this.handleDcryptValue}
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>
          )}
        </div>
      </>
    );
  }
}

export default Other_textbox;
