import React from "react";
import Date_textbox from "../../../BprmControls/Date_textbox";

export default function Date(props) {
    const { controleId, 
        comp, 
        stateData: data,
        stateErrors: errors, 
        codeIsVerified,
        isDisabled,
        handleChange
    } = props;

    const handleDateEncryptDecryptedOnChange = (name, valueArray) => {
        let decValue = "";
        for (let index = 0; index < valueArray.length; index++) {
            const element = valueArray[index];
            decValue += element;
        }
        let dt = "";
        if (valueArray.length > 0) {
            dt = `${decValue.slice(0, 2)}-${decValue.slice(2, 4)}-${decValue.slice(
                4
            )}`;
        }

        const input = { name: name, value: dt };
        handleChange(input);
    };

    return (
        <div className={`form-group ${comp.isRequired ? "required" : ""}`}>
            <label className="form-control-label" htmlFor={controleId}>
                {comp.label}
            </label>
            <Date_textbox
                value={data[controleId]}
                label={comp.label}
                isDisabled={isDisabled}
                name={controleId}
                handleOnChange={handleDateEncryptDecryptedOnChange}
                codeIsVerified={codeIsVerified}
            />
            {errors[controleId] && (
                <div className="alert alert-danger">{errors[controleId]}</div>
            )}
        </div>
    );
}