import React, { Component, useEffect, useState } from "react";
import DynamicHtml from "./Comman/DynamicHtml";
import { useLocation, useNavigate } from "react-router-dom";

const DynamicForm=()=> {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     workItem: {},
  //     currentChecklist: {},
  //   };
  // }

  const [workItem,setWorkItem]=useState(null);

  const location=useLocation();
  const navigate=useNavigate();

  useEffect(()=>{
    if(location.state?.workflowTask)
      setWorkItem(location.state?.workflowTask);
  },[]);

  // async componentDidMount() {
  //   console.log(this.props);
  //  // const { workflowTask: workItem } = this.props.location.state;
  //   // console.log(workItem);
  //  // this.setState({ workItem });
  // }

  const handleNextTask = (nextWorkItem) => {
    // if (nextWorkItem !== undefined) {
    //   if (nextWorkItem.formType === "CF") {
    //     console.log(nextWorkItem);
    //     this.props.history.push({
    //       pathname: "/" + nextWorkItem.formName,
    //       state: { workflowTask: nextWorkItem },
    //     });
    //   } else {
    //     this.setState({ workItem: nextWorkItem });
    //   }
    // }
  };

  const handleBack = () => {
    //this.props.history.goBack();
    navigate(-1);
  };

  //const { workItem } = this.state;
    return (
      <DynamicHtml
        workItem={workItem}
        handleNextTask={handleNextTask}
        handleBack={handleBack}
      ></DynamicHtml>
    );

}

export default DynamicForm;
