import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "support",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        msgSendStatus:false,
    },
    reducers: {
        supportTicketReceived: (support, action) => {
            support.list=action.payload;
        },
        supportTicketMessageSent: (support, action) => {
            support.msgSendStatus = action.payload;
        },
    }
});

export const { supportTicketReceived,supportTicketMessageSent } = slice.actions;
export default slice.reducer;

export const loadSupportTickets = (id, pageSize, pageNumber) => apiCallBegan({
    url: config.GetIssueDefectList + id + "&pmId=" + config.WorkflowIds.SupportPmId + 
                "&pageSize=" + pageSize + "&pageNumber=" + pageNumber,
    onSuccess: supportTicketReceived.type
});

export const sendSupportTicketsMessage = (data) => apiCallBegan({
    url: config.SendSupportMessage,
    method:"post",
    data:data,
    onSuccess: supportTicketMessageSent.type
});

export const getSupportTickets = createSelector(
    state => state.entities.support,
    support => support.list
);



