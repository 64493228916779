import React, { Component, useEffect, useState } from "react";
import Form from "../Comman/Form";
import { Modal, Button } from "react-bootstrap";
import Joi from "joi-browser";
import { getAlert } from "../../Services/appAlerts";

const AddWorkingHrs = (props) => {
  const { showHide, handleShowHide,
    handleSelect, allEvents, selectedObj } = props;

  const [hour, setHour] = useState("");
  const [minutes, setMinutes] = useState("");

  useEffect(() => {
    if (selectedObj?.title) {
      const wrkHr = selectedObj.title.toString().split(".");
      setHour(wrkHr[0]);
      if (wrkHr.length > 1) setMinutes(wrkHr[1]);
    }
    else {
      setHour("");
      setMinutes("");
    }
  }, [selectedObj])

  const doSubmit = () => {
    let wrkHr = 0;
    if (hour) wrkHr = hour;
    if (minutes) {
      wrkHr += "." + minutes;
    } else {
      wrkHr = parseInt(wrkHr);
    }
    handleSelect(wrkHr);
  };

  const handleWorkingHrs = ({ currentTarget }) => {
    const hrs = parseInt(currentTarget.value);
    if (hrs < 0 || hrs > 23) {
      getAlert("", "Hours should be between 0 and 23.", "error");
      return;
    }
    setHour(hrs);
  }

  const handleWorkingHrsMin = ({ currentTarget }) => {
    const min = parseInt(currentTarget.value);
    if (min < 0 || min > 59) {
      getAlert("", "Minutes should be between 0 and 59.", "error");
      return;
    }
    setMinutes(min);
  }


  return (
    <Modal show={showHide} size="xl">
      <Modal.Header closeButton onHide={() => handleShowHide("")}>
        <Modal.Title>Add/Edit Hours and Minutes</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="hour" className="form-control-label">Enter Hour</label>
            <input type="number"
              id="hour"
              name="hour"
              className="form-control"
              placeholder="Enter Hour"
              value={hour}
              onChange={handleWorkingHrs}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="minutes" className="form-control-label">Enter Minutes</label>
            <input type="number"
              id="minutes"
              name="minutes"
              className="form-control"
              placeholder="Enter Minutes"
              value={minutes}
              onChange={handleWorkingHrsMin}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={() => handleShowHide("")}>
          <i class="fa fa-times" aria-hidden="true"></i> Close
        </button>
        <button
          className="btn btn-sm btn-outline-default"
          disabled={!hour}
          onClick={doSubmit}
        >
          <i class="fa fa-check" aria-hidden="true"></i> Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddWorkingHrs;
