import React from "react";
import Select from "../../Components/Comman/Select";
import PasswordInput from "../../Components/Comman/PasswordInput"
import PhoneInput from "../../Components/Comman/PhoneInput";
import Input from "../../Components/Comman/Input";
import TextArea from "../../Components/Comman/TextArea";

const useControls = (data, errors, handleChange, schema,handleFocus) => {

    const renderInput = (name, label, type = "text", size) => {
        if (type === "password") {
            return (
                <PasswordInput
                    name={name}
                    value={data[name] || ""}
                    label={label}
                    onChange={handleChange}
                    error={errors[name]}
                    req={schema[name]}
                />
            );
        } else if (type === "phone") {
            return (
                <PhoneInput
                    name={name}
                    value={data[name] || ""}
                    label={label}
                    onChange={handleChange}
                    error={errors[name]}
                    req={schema[name]}
                />
            );
        } else {
            return (
                <Input
                    type={type}
                    name={name}
                    value={data[name] || ""}
                    label={label}
                    onChange={handleChange}
                    error={errors[name]}
                    onFocus={handleFocus}
                    req={schema[name]}
                    size={size}
                ></Input>
            );
        }
    }

    const renderSelect = (name, label, options, optName, optValue) => {
        return (
            <Select
                name={name}
                value={data[name] || ""}
                label={label}
                options={options}
                onChange={handleChange}
                error={errors[name]}
                optName={optName}
                optValue={optValue}
                req={schema[name]}
            />
        );
    }

    const renderTextArea=(name, label) =>{
        return (
          <TextArea
            name={name}
            value={data[name] || ""}
            label={label}
            onChange={handleChange}
            error={errors[name]}
            req={schema[name]}
          ></TextArea>
        );
      }

    return {
        renderSelect,
        renderInput,
        renderTextArea
    }

}

export default useControls;