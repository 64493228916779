import React from "react";

const Footer = () => {

    const supportFooter = () => {
        return (
            <div className="cust-card shadow-none border">
                <div className="card-body p-0 text-center">
                    <p style={{ fontWeight: "bold" }}>
                        Customer Support:{" "}
                        <a href="mailto:Support1@workfinity.com" style={{ fontWeight: "bold" }}>
                            Support1@workfinity.com
                        </a>
                    </p>
                </div>
            </div>
        );
    };
    
    return <div
        className="col-md-12"
        style={{
            backgroundColor: "#eceff1",
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
        }}
    >
        <div className="d-flex justify-content-center p-3">

            <img className="mt-1 mr-3" src="/assets/Img/A-LIGN-SOC.png" style={{ height: "60px" }} />
            <div className="text-black">
                <img className="ml--1" src="/assets/Img/wflogoblack.png" />
                <br />
                Delivered by IPCS &reg;
            </div>
            <div className="text-black text-center mr-3 ml-3">
                This software is licensed to the user. Use of the software
                constitutes acceptance of the terms and conditions of the License
                Agreement
                <br />
                @IPCS®. @WorkFinity®.
                <br />
                All rights reserved. Under US Patent Protection

            </div>
            <div className="d-flex justify-content-center ">
                {supportFooter()}
            </div>
        </div>
    </div>
}

export default Footer;