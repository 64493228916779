import React, { Component, createRef } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import ReactDOM from "react-dom";

class Date_textbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      encryptDecrypt: true,
      encryptValue: "",
      decryptValue: "",
      valueArray: [],
      selectedTextStartIndex: 0,
      selectedTextEndIndex: 0,
      showCalendar: false,
    };
  }

  handleEncryptOnChange = (input) => {
    //const { currentTarget } = input;
    const { name } = this.props;
    //let filterValue = currentTarget.value; //.replace(/[^\d]/g, "");
    const dt = new Date(input);
    let day = dt.getDate();
    if (day < 10) day = "0" + day;
    let mn = dt.getMonth() + 1;
    if (mn < 10) mn = "0" + mn;
    let yr = dt.getFullYear();
    let filterValue = mn + "" + day + "" + yr;
    //console.log(filterValue);
    const {
      encryptDecrypt,
      encryptValue,
      decryptValue,
      selectedTextStartIndex,
      selectedTextEndIndex,
    } = this.state;
    //console.log(valueArray);
    const valueArray = [];
    if (encryptDecrypt) {
      let newValue = filterValue.replace(/\-/g, "");
      let decrypt = "";
      for (let index = 0; index < newValue.length; index++) {
        decrypt += "*";
      }
      filterValue = filterValue.replace(/[^\d]/g, "");
      if (filterValue === "") {
        let calcIndex1 = 0; //input.target.selectionStart;
        if (calcIndex1 > 5) {
          calcIndex1 = calcIndex1 - 2;
        } else {
          if (calcIndex1 > 2 && calcIndex1 < 6) calcIndex1 = calcIndex1 - 1;
        }
        if (selectedTextStartIndex !== selectedTextEndIndex) {
          for (
            let index = selectedTextEndIndex;
            index > selectedTextStartIndex;
            index--
          ) {
            let nindex = index;
            if (nindex > 5) {
              nindex = nindex - 2;
            } else {
              if (nindex > 2 && nindex < 6) nindex = nindex - 1;
            }
            valueArray.splice(nindex, 1);
          }
        } else {
          valueArray.splice(calcIndex1, 1);
        }
      } else {
        let calcIndex = 0; //input.target.selectionStart;
        if (calcIndex > 3 && calcIndex < 6) {
          calcIndex = calcIndex - 1;
        } else {
          if (calcIndex > 6) calcIndex = calcIndex - 2;
        }
        for (let index = 0; index < filterValue.length; index++) {
          const element = filterValue[index];
          calcIndex += index;
          valueArray.splice(calcIndex, 0, element);
        }
      }

      this.setState({ valueArray, showCalendar: false });
      let checkIsNumber = filterValue.replace(/\*/g, "");
      if (valueArray.length > 0) {
        this.setState({ decryptValue: decrypt });
      } else {
        this.setState({ decryptValue: "" });
      }
      this.props.handleOnChange(name, valueArray);
    } else {
      filterValue = filterValue.replace(/[^\d]/g, "");
      let newArray = [];
      for (let index = 0; index < filterValue.length; index++) {
        newArray.push(filterValue[index]);
      }
      this.setState({ valueArray: newArray, showCalendar: false });
      this.setState({ decryptValue: filterValue });
      this.props.handleOnChange(name, newArray);
    }
  };

  handleDecryptOnChange = (input) => {
    const { encryptValue } = this.state;
    const { currentTarget } = input;
    let decrypt = "";
    for (let index = 0; index < currentTarget.value.length; index++) {
      decrypt += "*";
    }
    this.setState({ decryptValue: decrypt });
    input.currentTarget.value = currentTarget.value.replace(/[^\d]/g, "");
    this.handleEncryptOnChange(input);
  };

  handleEncryptValue = () => {
    const { valueArray } = this.state;
    let decValue = "";
    for (let index = 0; index < valueArray.length; index++) {
      decValue += "*";
    }
    this.setState({ decryptValue: decValue, encryptDecrypt: true });
  };

  handleDcryptValue = () => {
    const { valueArray } = this.state;
    let decValue = "";
    for (let index = 0; index < valueArray.length; index++) {
      const element = valueArray[index];
      decValue += element;
    }
    this.setState({ decryptValue: decValue, encryptDecrypt: false });
  };

  formatSSNNumber(value) {
    if (!value) return value;
    let phoneNumber = value.replace(/\-/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 3) return phoneNumber;
    if (phoneNumberLength < 5) {
      return `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(2)}`;
    }
    return `${phoneNumber.slice(0, 2)}-${phoneNumber.slice(
      2,
      4
    )}-${phoneNumber.slice(4)}`;
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const { value } = this.props;
    //console.log(value);
    if (value !== "" && value !== undefined) {
      const { encryptDecrypt } = this.state;
      let valueArray = [];
      let decValue = "";
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        valueArray.push(element);
        decValue += "*";
      }

      if (encryptDecrypt) this.setState({ decryptValue: decValue, valueArray });
      else this.setState({ decryptValue: value, valueArray });
    } else {
      this.setState({ valueArray: [], decryptValue: "" });
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { value } = nextProps;
    //console.log(value);
    if (value !== "" && value !== undefined) {
      const { encryptDecrypt } = this.state;
      let valueArray = [];
      let decValue = "";
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        valueArray.push(element);
        decValue += "*";
      }

      if (encryptDecrypt) this.setState({ decryptValue: decValue, valueArray });
      else this.setState({ decryptValue: value, valueArray });
    } else {
      this.setState({ valueArray: [], decryptValue: "" });
    }
  };

  handleChange = (value) => {
    const dt = new Date(value);
    //console.log(dt);
    let day = dt.getDate();
    if (day < 10) day = "0" + day;
    let mn = dt.getMonth() + 1;
    if (mn < 10) mn = "0" + mn;
    let yr = dt.getFullYear();
    const dtValue = day + "" + mn + "" + yr;
    //setDate(day + "" + mn + "" + yr);
    //setShowCalendar(false);
    //this.setState({ decryptValue: dtValue, showCalendar: false });
    return dtValue;
  };

  handleOnFocus = () => {
    this.setState({ showCalendar: true });
  };

  wrapperRef = createRef();

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.setState({ showCalendar: false });
    }
  };

  render() {
    const { encryptDecrypt, decryptValue, showCalendar, valueArray } =
      this.state;
    const { label, name, isDisabled, value } = this.props;
    let dtVal = new Date(decryptValue);
    if (isNaN(dtVal.getDate())) dtVal = new Date();
    return (
      <section ref={this.wrapperRef}>
        <div className="d-flex">
          <input
            type="text"
            className="form-control"
            name={name}
            value={this.formatSSNNumber(decryptValue)}
            onFocus={this.handleOnFocus}
            placeholder={"MM-DD-YYYY"}
            disabled={isDisabled}
          ></input>

          {!encryptDecrypt && (
            <button
              className="btn btn-secondary btn-sm ml-2"
              onClick={this.handleEncryptValue}
            >
              <i className="fa fa-eye-slash" aria-hidden="true"></i>
            </button>
          )}
          {encryptDecrypt && (
            <button
              className="btn btn-secondary btn-sm ml-2"
              onClick={this.handleDcryptValue}
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>
          )}
        </div>
        <Calendar
          className={showCalendar ? "" : "hide"}
          value={dtVal}
          onChange={this.handleEncryptOnChange}
        />
      </section>
    );
  }
}

export default Date_textbox;
