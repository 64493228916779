import React from "react";

const Select = ({ name, label, options, error, optValue,optName, ...rest }) => {
  return (
    <div className="form-group">
      <label className="form-control-label" htmlFor={name}>
        {label}
      </label>
      <select className="form-control" id={name} name={name} {...rest}>
        <option value="">Select</option>
        {options.map((option) => (
          <option key={option[optValue]} value={option[optValue]}>
            {option[optName]}
          </option>
        ))}
      </select>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}; 

export default Select;
