import React, { Component, useEffect, useState } from "react";
import Joi from "joi-browser";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";
import http from "../../Services/httpService";
import geo from "../../Services/geoService";
import config from "../../config.json";
import Form from "../Comman/Form";
import { getAlert } from "../../Services/appAlerts";
import useForm from "../../app/hooks/useForm";
import useControls from "../../app/hooks/useControls";
import { useDispatch, useSelector } from "react-redux";
import { addPartnerUser, getPartRoleTypes, getPartUserAddedStatus, loadPartnerUsers, loadPartRoleTypes } from "../../app/store/partner";

const AddPartnerUser = (props) => {

  const { showHide, handleModalShowHide, selectedObj, userDetails } = props;

  const state = {
    data: {},
    errors: {},
    stateList: [],
    countyList: [],
    selectedStateId: "",
    selectedState: "",
    selectedCountyId: "",
    selectedCounty: "",
    modalShow: "",
    partnerUserTypes: [],
  };

  const [partUserTypes, setPartUserTypes] = useState([]);
  const [usStateList, setUsStateList] = useState([]);

  const partRoleTypesSelector = useSelector(getPartRoleTypes);
  const partUserAddedStatusSelector = useSelector(getPartUserAddedStatus);

  const dispatch = useDispatch();

  useEffect(() => {
    const loadUsStates = async () => {
      const allStates = await geo.getStateList();
      setUsStateList(allStates);
    }
    loadUsStates();
    dispatch(loadPartRoleTypes("Partner User Role"))
  }, []);

  useEffect(() => {
    setPartUserTypes(partRoleTypesSelector);
  }, [partRoleTypesSelector])

  useEffect(() => {
    if (selectedObj) {
      setStateData(selectedObj);
    }
    else{
      setStateData({});
      setStateErrors({});
    }
  }, [selectedObj])

  const schema = {
    name: Joi.string().required().label("Name"),
    email: Joi.string().email().required().label("Email"),
    contact: Joi.string()
      .length(10)
      .options({
        language: {
          string: {
            length: "Mobile no must be 10 digits.",
          },
        },
      })
      .options({})
      .regex(/^[0-9]+$/)
      .options({
        language: {
          string: {
            regex: {
              base: "This entry can only contain numbers",
            },
          },
        },
      })
      .label("Mobile No."),
    //username: Joi.string(),
  };

  const doSubmit = () => {
    const updStateData = { ...stateData };
    updStateData["partnerId"] = userDetails.partnerid;
    dispatch(addPartnerUser(updStateData));
  };

  useEffect(() => {
    if (partUserAddedStatusSelector) {
      if (selectedObj?.id > 0)
        getAlert("", "Updated Successfully", "success");
      else
        getAlert("", "Added Successfully", "success");

      handleModalShowHide("save");
    }
  }, [partUserAddedStatusSelector])


  const { handleChange, stateData, setStateData, stateErrors,setStateErrors, handleSubmit, validate } = useForm(schema, doSubmit);

  const { renderSelect, renderInput } = useControls(stateData, stateErrors, handleChange, schema);

  return (
    <Modal show={showHide} size="xl">
      <Modal.Header closeButton onHide={handleModalShowHide}>
        <Modal.Title>Add/Edit Key Contacts/Users</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="row">
          <div className="col-lg-12">
            {renderSelect(
              "title",
              "Select User Type",
              partUserTypes,
              "name",
              "name"
            )}
          </div>
          <div className="col-md-12">
            {renderInput("title", "Title")}
          </div>
          <div className="col-lg-6">{renderInput("name", "Name")}</div>
          <div className="col-lg-6">{renderInput("email", "Email")}</div>

          <div className="col-lg-6">
            {renderInput("contact", "Mobile No.", "phone")}
          </div>
          <div className="col-lg-6">
            {renderSelect(
              "state",
              "State",
              usStateList,
              "name",
              "name"
            )}
          </div>
          <div className="col-md-4">
            {renderInput("county", "City")}
          </div>
       
        </div>

      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={handleModalShowHide}>
          <i class="fa fa-times" aria-hidden="true"></i> Close
        </button>
        <button
          className="btn btn-sm btn-outline-default"
          disabled={validate()}
          onClick={handleSubmit}
        >
          <i class="fa fa-check" aria-hidden="true"></i> Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddPartnerUser;
