import React, { useEffect, useState } from "react";
import PageHeader from "../../../Components/Comman/PageHeader";
import OnboardingSteps from "./OnboardingSteps";
import Moment from "react-moment";
import { Link, useNavigate } from "react-router-dom";
import { getFieldData, getFormatedNumber, getWfPhasesColor } from "../../../Services/appService";
import { getCurrentUser } from "../../store/auth";
import { getAppTasks, getCandidateApps, loadAppRuningTasks, loadCandidateApps } from "../../store/candidate";
import { useDispatch, useSelector } from "react-redux";
import TimerPopup from "../../../Components/TimerPopup";
import AppMessages from "../../../Components/AppMessages";
import CandidateInfo from "../CandidateInfo";
import PartnerInfo from "../PartnerInfo";
import EnrollmentSteps from "./EnrollmentSteps";
import { setCurrentPageTitle, setCurrentRoute } from "../../store/layoutUI";

export default function OnboardingStatus() {

    const usrType = localStorage.getItem("userType");
    const [entityType, setEntityType] = useState("");
    const [appTasklist, setAppTasklist] = useState([]);
    const [appSubmitStatus, setAppSubmitStatus] = useState(false);
    const [timerShowHide, setTimerShowHide] = useState(false);
    const [msgShowHide, setMsgShowHide] = useState(false);
    const [wfId, setWfId] = useState(0);
    const [currentApp, setCurrentApp] = useState("");

    const candidateAppsSelector = useSelector(getCandidateApps);
    const appTasksSelector = useSelector(getAppTasks);

    const dispatch = useDispatch();
    const currentUser = getCurrentUser();
    const navigate = useNavigate();

    const route = [
        { name: "Task Management", url: "" },
        { name: "Onboarding", url: "" },
    ];
    const route1 = [
        { name: "Contract Management", url: "" },
        { name: "Onboarding", url: "" },
    ];

    let appType = "Enrollment";
    if (usrType === "candidate")
        appType = "Onboarding";

    let count = entityType !== "C2C Basis" ? "6" : "5";

    if (usrType !== "candidate")
        count = "5";


    useEffect(() => {
        dispatch(setCurrentPageTitle(`STEP-${count} : ${appType}`));
        dispatch(setCurrentRoute(usrType === "candidate" ? route : route1))
    }, [])

    useEffect(() => {
        if (candidateAppsSelector.length === 0)
            dispatch(loadCandidateApps(currentUser.id))
    }, []);

    useEffect(() => {
        if (candidateAppsSelector.length > 0) {
            const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
            if (mainWorkflow.length > 0) {
                const wf = mainWorkflow[0];
                setWfId(wf.oldWorkflowId);
                if (appTasksSelector.length === 0)
                    dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
            }
        }
    }, [candidateAppsSelector]);

    useEffect(() => {
        if (appTasksSelector.length > 0) {
            const filterTasks = appTasksSelector.filter(x => x.taskType === 1 && x.wiid > 0 
                && (x.wfPhase === "Review" || x.wfPhase === "Approved" || x.wfPhase === "Rejected"));
           
            let firstAppTasks = [];
            if (filterTasks.length > 0) {
                firstAppTasks = [filterTasks[filterTasks.length - 1]];
                setAppTasklist(firstAppTasks);
                setAppSubmitStatus(filterTasks[0].appSubmit);
            }
        }
    }, [appTasksSelector]);

    const handleViewAppSummary = (item, type) => {
        navigate("/ApplicationSummary", { state: { task: item, viewType: type } });
    }

    const handleCurrentTask = () => {
        // const { data: workflowTask } = await http.get(
        //     config.GetWorkflowTask + wiid
        //   );
        //   if (workflowTask.formId > 0) {
        //     if (workflowTask.formType === "CF") {
        //       this.props.history.push({
        //         pathname: "/" + workflowTask.formName,
        //         state: { workflowTask },
        //       });
        //     } else {
        //       this.props.history.push({
        //         pathname: "/DynamicForm",
        //         state: { workflowTask },
        //       });
        //     }
        //   }
    }

    const handleTimerModalShowHide = () => {
        setTimerShowHide(!timerShowHide);
    };

    const handleMsgShowHide = (app) => {
        setMsgShowHide(!msgShowHide);
        setCurrentApp(app);
    };


    return <React.Fragment>

        <div className="col-md-12  mt-2 form-group">
            <div className="card shadow-none border">
                <div className="card-header p-3">
                <i className="fa fa-square wf-phase2-box mr-2" aria-hidden="true"></i>
                <span className="h3">Onbording/Enrollment Status</span>
                    {usrType !== "candidate" && (
                        <p className="text-red">
                            This Section provides status on your firm's enrollment and
                            includes any messages / alerts.
                        </p>
                    )}
                </div>
                <div className="card-body p-3">
                    <div className="table-responsive border border-top-0">
                        <table className="table align-items-center table-flush table-hover table-striped">
                            <thead className="thead-light">
                                <tr>
                                    <th>ID</th>
                                    <th>Date & Time Submitted</th>
                                    <th>{appType} Form</th>
                                    <th>IPCS Reviewer</th>
                                    <th>Status</th>
                                    <th>Messages</th>
                                    <th>Alerts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {appTasklist.map((item, key) =>
                                    <tr>
                                        <td>{item.ciid}</td>
                                        <td>
                                            <Moment format="MMM Do YYYY h:mm a">
                                                {item.startDateTime}
                                            </Moment>
                                        </td>
                                        <td>
                                           
                                            <button
                                                onClick={() => handleViewAppSummary(item, "view")}
                                                className="btn btn-link btn-sm pl-0"
                                            >
                                               View
                                            </button>
                                            <br />
                                            <button
                                                onClick={() => handleViewAppSummary(item, "edit")}
                                                className="btn btn-link btn-sm pl-0 mt-1"
                                            >
                                                Edit/Update
                                            </button>
                                        </td>
                                        <td>
                                            {item.performerId !== parseInt(currentUser.id) && <React.Fragment>
                                                {item.performer?.toUpperCase()}
                                                <br />
                                                {item.role}
                                                {item.department}
                                                {item.partner}
                                                {item.registrationType !== "WP" &&
                                                    item.registrationType !== "PP" &&
                                                    item.registrationType}
                                                <br />
                                                {item.email}
                                                <br />
                                                {getFormatedNumber(item.contact)}
                                            </React.Fragment>}
                                        </td>
                                        <td style={{ whiteSpace: "pre-wrap" }}>
                                            
                                            {item.performerId === parseInt(currentUser.id) ?
                                            <button
                                                className="btn btn-sm btn-link p-0"
                                                onClick={() => handleCurrentTask(item.wiid)}
                                            >
                                                {item.wfPhase}
                                            </button> : item.wfPhase}

                                           
                                        </td>
                                        <td>
                                            {item.taskDoneStatus !== 0 && item.wfPhase !== "Application" && (
                                                <button
                                                    className="btn btn-link btn-sm p-0"
                                                    onClick={() => handleMsgShowHide(item)
                                                    }
                                                >

                                                    View
                                                    <span
                                                        className="badge badge-pill badge-danger position-absolute mt--1 ml-0"
                                                    >
                                                        {item.unreadMessage}
                                                    </span>
                                                </button>
                                            )}
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-sm btn-link p-0"
                                                onClick={handleTimerModalShowHide}
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        {timerShowHide && <TimerPopup
            timerShowHide={timerShowHide}
            handleTimerModalShowHide={handleTimerModalShowHide}
        ></TimerPopup>}
        {msgShowHide &&
            <AppMessages
                msgShowHide={msgShowHide}
                handleMsgShowHide={handleMsgShowHide}
                currentApp={currentApp}
                workflowId={wfId}
            ></AppMessages>}
    </React.Fragment>
}