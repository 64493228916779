import React, { Component, useEffect, useState } from "react";
import Form from "../Comman/Form";
import { Modal, Button } from "react-bootstrap";
import config from "../../config.json";
import http from "../../Services/httpService";
import auth from "../../Services/authService";
import Moment from "react-moment";
import Swal from "sweetalert2";
import Joi from "joi-browser";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../app/store/auth";
import { addReviewNotes, getAllNotes, getNotesAddedStatus, loadAllReviewNotes, reviewNotesAdded } from "../../app/store/reviewNotes";
import { formLibraryEditRequestSent, getFormLibraryEditReqStatus, sendEditReqFormLibrary } from "../../app/store/forms";
import { getAlert, getConfirmAlert } from "../../Services/appAlerts";

const AddEditNotes=(props)=>{
  
  const { noteShowHide, handleNoteShowHide, notesType,
    candidateObj, timeLogObj, selectedObj } = props;

  const [allNotes, setAllNotes] = useState([]);
  const [notes, setNotes] = useState("");
  const [btnSaveProcess, setBtnSaveProcess] = useState(false);

  const allNotesSelector = useSelector(getAllNotes);
  const notesAddedStatusSelector = useSelector(getNotesAddedStatus);
  const formLibraryEditReqStatusSelector = useSelector(getFormLibraryEditReqStatus);

  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (candidateObj?.performerid) {
      dispatch(loadAllReviewNotes(notesType, candidateObj.performerid))
    }
  }, [candidateObj]);

  useEffect(() => {
    if (allNotesSelector.length > 0) {
      let filterNotes = allNotesSelector;

      if (timeLogObj?.id > 0)
        filterNotes = filterNotes.filter((x) => x.ciid === timeLogObj.id);

      setAllNotes(filterNotes);
      setNotes("");
    }
  }, [allNotesSelector, timeLogObj]);


  const doSubmit =async () => {
    if (notesType === "formEditReq") {
      const result = await getConfirmAlert("Warning", "Are you sure you want to send edit request for this form?", "warning");
      if (result.isConfirmed) {
        setBtnSaveProcess(true);
        dispatch(sendEditReqFormLibrary(selectedObj.id, notes));
      }
    } else {
      setBtnSaveProcess(true);
      const frmData = new FormData();
      frmData.append("comment", notes);
      frmData.append("type", notesType);
      frmData.append("userId", currentUser.id);
      frmData.append("performerId", candidateObj.performerid);
      if (timeLogObj?.id > 0)
        frmData.append("ciid", timeLogObj.id);

      dispatch(addReviewNotes(frmData));
    }
  };

  useEffect(() => {
    if (notesAddedStatusSelector) {
      getAlert("", "Saved Successfully", "success");
      setBtnSaveProcess(false);
      handleNoteShowHide("save", "");
      dispatch(reviewNotesAdded(false));
    }
  }, [notesAddedStatusSelector]);

  useEffect(() => {
    if (formLibraryEditReqStatusSelector) {
      setBtnSaveProcess(false);
      getAlert("", "Request Sent Successfully", "success");
      dispatch(formLibraryEditRequestSent(false));
      handleNoteShowHide("save", "");
    }
  }, [formLibraryEditReqStatusSelector]);

  return (
    <Modal show={noteShowHide} size="xl">
      <Modal.Header closeButton onHide={() => handleNoteShowHide("", "")}>
        <Modal.Title>
          {notesType === "formEditReq" ? "Form Edit Request": "Notes from IPCS"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        <div className="row">
          {notesType !== "formEditReq" && (
            <div className="col-md-12" style={{maxHeight:"350px",overflow:"auto"}}>
              <div className="table-responsive">
                <table className="table table-striped align-items-center table-flush border bg-white">
                  <thead className="thead-light">
                    <tr>
                      <th>S.No.</th>
                      <th>Date & Time</th>
                      <th>Notes</th>
                      <th>Added By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allNotes.map((item, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>
                          <Moment format="MMM Do YYYY, h:mm a">
                            {item.dateTime}
                          </Moment>
                        </td>
                        <td>{item.comments}</td>
                        <td>
                          {item.performer}
                          <br />
                          {item.email}
                          <br />
                          {item.role}
                          {item.department}
                          {item.partner}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {notesType === "formEditReq" && (
           <div className="col-md-12">
           <label htmlFor="notes" className="form-control-label">Notes</label>
           <textarea cols={2} rows={2}
             className="form-control"
             id="notes"
             name="notes"
             placeholder="Notes"
             value={notes}
             onChange={({ currentTarget }) => setNotes(currentTarget.value)}
           ></textarea>
         </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-danger" onClick={() => handleNoteShowHide("", "")}>
        <i className="fa fa-times"></i> Close
        </button>
        {notesType === "formEditReq" && (
          <button
            className="btn btn-sm btn-outline-default"
            disabled={!notes || btnSaveProcess}
            onClick={doSubmit}
          >
             {btnSaveProcess ? <i className="fa fa-spinner fa-spin mr-1"></i> : <i className="fa fa-paper-plane mr-1" aria-hidden="true"></i>}
             Send
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default AddEditNotes;
