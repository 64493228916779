import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "candidates",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        filterList: [],
        reminder: "",
        apps:[],
        appTasks:[],
        appDocuments:[],
        appDocusignDocs:[],
        backgroundChecks:[],
        appMessages:[],
        scheduleMeetings:[],
        appFormLibrary:[],
        meetingAcceptStatus:false,
        meetingLinkSendStatus:false
    },
    reducers: {
        candidateAdded: (candidates, action) => {
            candidates.list.push(action.payload);
        },
        candidatesReceived: (candidates, action) => {
            candidates.list = action.payload;
            candidates.reminder="";
        },
        filterCandidatesReceived: (candidates, action) => {
            candidates.filterList = action.payload;
            candidates.reminder="";
        },
        reminderSent: (candidates, action) => {
            candidates.reminder = action.payload;
        },
        candidateAppsReceiving:(candidates,action)=>{
            candidates.apps=[];
        },
        candidateAppsReceived:(candidates,action)=>{
            const filterApp=action.payload?.filter(x=>x.pmId !== config.WorkflowIds.SupportPmId);
            candidates.apps=filterApp;
        },
        candidateParentAppsReceived:(candidates,action)=>{
            candidates.parentApps=action.payload;
        },
        appTasksReceived:(candidates,action)=>{
            candidates.appTasks=action.payload;
        },
        appDocumentReceived:(candidates,action)=>{
            candidates.appDocuments=action.payload;
        },
        appDocusignDocsReceived:(candidates,action)=>{
            candidates.appDocusignDocs=action.payload;
        },
        backgroundChecksReceived:(candidates,action)=>{
            candidates.backgroundChecks=action.payload;
        },
        appMessagesRecevied:(candidates,action)=>{
            candidates.appMessages=action.payload;
        },
        appFormLibraryRecevied:(candidates,action)=>{
            candidates.appFormLibrary=action.payload;
        },
        meetingRecevied:(candidates,action)=>{
            candidates.scheduleMeetings=action.payload;
            candidates.meetingAcceptStatus=false;
            candidates.meetingLinkSendStatus=false;
        },
        scheduleMeetingAccepted:(candidates,action)=>{
            candidates.meetingAcceptStatus=action.payload;
        },
        scheduleMeetingLinkSent:(candidates,action)=>{
            candidates.meetingLinkSendStatus=action.payload;
        }
    }
});

export const { candidateAdded, candidatesReceived, 
    filterCandidatesReceived, 
    reminderSent,
    candidateAppsReceiving,
    candidateAppsReceived,
    candidateParentAppsReceived,
    appTasksReceived,
    appDocumentReceived,
    appDocusignDocsReceived,
    backgroundChecksReceived,
    appMessagesRecevied,
    meetingRecevied,
    appFormLibraryRecevied,
    scheduleMeetingAccepted,
    scheduleMeetingLinkSent
 } = slice.actions;
export default slice.reducer;


export const SendReminder = (id) => apiCallBegan({
    url: config.SendRemainderToApplicant + id,
    onSuccess: reminderSent.type
});

export const loadCandidatesByFilter = (data) => apiCallBegan({
    url: config.GetApplicantsByFilter,
    method: "post",
    data: data,
    onSuccess: filterCandidatesReceived.type
});

export const loadCandidateApps = (id) => apiCallBegan({
    url: config.GetPerformerApplication + id,
    onSuccess: candidateAppsReceived.type
});

export const loadAppRuningTasks = (wfId, userId) => apiCallBegan({
    url: config.GetRunningTask + wfId + "&performerId=" + userId,
    onSuccess: appTasksReceived.type
});

export const loadAppDocuments = (wfId, userId) => apiCallBegan({
    url: config.GetReceivedDocuments + userId + "&chkId=" + wfId,
    onSuccess: appDocumentReceived.type
});

export const loadAppDocusignDocs = (userId) => apiCallBegan({
    url: config.GetDocusingDocuments + userId,
    onSuccess: appDocusignDocsReceived.type
});

export const loadBackgroundChecks = (userId) => apiCallBegan({
    url: config.GetBackgroundChecks + userId,
    onSuccess: backgroundChecksReceived.type
});

export const loadSheduleMeeting = (userId) => apiCallBegan({
    url: config.GetSheduleMeeting + userId,
    onSuccess: meetingRecevied.type
});

export const acceptSheduleMeeting = (option) => apiCallBegan({
    url: config.AcceptMeeting,
    method:"post",
    data:option,
    onSuccess: scheduleMeetingAccepted.type
});
export const sendSheduleMeetingLinks = (option) => apiCallBegan({
    url: config.SendMeetingDetails + option,
    onSuccess: scheduleMeetingLinkSent.type
});

export const loadAppMessages = (wfId, userId) => apiCallBegan({
    url: config.GetPortalCommunication + wfId + "&performerId=" + userId,
    onSuccess: appMessagesRecevied.type
});

export const loadAppFormLibrary = (wfId, userId) => apiCallBegan({
    url: config.GetApplicantFormsLibrary + wfId + "&perfId=" + userId,
    onSuccess: appFormLibraryRecevied.type
});

export const getReminderStatus = createSelector(
    state => state.entities.candidates,
    candidates => candidates.reminder
)

export const getFilterAllCandidates = createSelector(
    state => state.entities.candidates,
    candidates => candidates.filterList
);

export const getCandidateApps = createSelector(
    state => state.entities.candidates,
    candidates => candidates.apps
)

export const getAppTasks = createSelector(
    state => state.entities.candidates,
    candidates => candidates.appTasks
)

export const getAppDocuments = createSelector(
    state => state.entities.candidates,
    candidates => candidates.appDocuments
)
export const getAppFormLibrary = createSelector(
    state => state.entities.candidates,
    candidates => candidates.appFormLibrary
)

export const getAppDocusignDocs = createSelector(
    state => state.entities.candidates,
    candidates => candidates.appDocusignDocs
)

export const getBackgroundChecks = createSelector(
    state => state.entities.candidates,
    candidates => candidates.backgroundChecks
)

export const getAppMessages = createSelector(
    state => state.entities.candidates,
    candidates => candidates.appMessages
)

export const getAppScheduleMeetings = createSelector(
    state => state.entities.candidates,
    candidates => candidates.scheduleMeetings
)

export const getMeetingAcceptStatus = createSelector(
    state => state.entities.candidates,
    candidates => candidates.meetingAcceptStatus
)