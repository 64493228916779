import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Login from "./Components/Login";
import PublicLayout from "./app/Layout/Public/Layout";
import PrivateLayout from "./app/Layout/Private/Layout";
import ForgotValidID from "./Components/Auth/ForgotValidID";
import ForgotPassword from "./Components/Auth/ForgotPassword";
import ValidateUser from "./Components/Auth/ValidateUser";
import LandingPage from "./app/components/LandingPage";
import { useSelector } from "react-redux";
import { getCurrentUser, getLoggedUser, getLoggedUserToken } from "./app/store/auth";
import Onboarding from "./app/components/Onboarding/Onboarding";
import BackgroundChecks from "./Components/ApplicantProcessing/BackgroundChecks";
import ReportingDetails from "./Components/ApplicantProcessing/ReportingDetails";
import OnboardingStatus from "./app/components/Onboarding/OnboardingStatus";
import AchInfo from "./app/components/Onboarding/AchInfo";
import ApplicationSummary from "./Components/ApplicationSummary";
import Compliance from "./app/components/Compliance";
import TimeLogs from "./Components/ApplicantProcessing/TimeLogs";
import IndividualMsg from "./Components/ApplicantProcessing/IndividualMsg";
import SentDocs from "./Components/ApplicantProcessing/SentDocs";
import RecvedDocs from "./Components/ApplicantProcessing/RecvedDocs";
import ScheduleMeeting from "./Components/ApplicantProcessing/ScheduleMeeting";
import SurveyFeedback from "./Components/ApplicantProcessing/SurveyFeedback";
import Profile from "./Components/Comman/Profile";
import PartnerCandidates from "./app/components/PartnerCandidates";
import PartnerInvoices from "./app/components/PartnerInvoices";
import ContractDocs from "./app/components/Onboarding/ContractDocs";
import InsuranceInfo from "./app/components/Onboarding/InsuranceInfo";
import ComplianceRpt from "./Components/ComplianceRpt";
import CustomReport from "./Components/ApplicantProcessing/CustomReport";
import CandidateWorkingHub from "./Components/ApplicantProcessing/CandidateWorkingHub";
import CandidateInvoice from "./Components/ApplicantProcessing/CandidateInvoice";
import ManagePartnerUsers from "./Components/ApplicantProcessing/ManagePartnerUsers";
import Dashboard from "./Components/Dashboard";
import Logout from "./Components/Logout";
import RequireAuth from "./app/components/Auth/RequireAuth";
import "./App.css";
import FormLibrary from "./Components/ApplicantProcessing/FormLibrary";
import DynamicForm from "./Components/DynamicForm";
import ResetPassword from "./Components/Auth/ResetPassword";

function App() {
    return <Routes>
        <Route element={<PublicLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/ForgotValidID" element={<ForgotValidID />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/ValidateUser" element={<ValidateUser />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
        </Route>
        <Route element={<RequireAuth />}>
        <Route path="/" element={<PrivateLayout />}>
            <Route index element={<LandingPage />} />
            <Route path="/Logout" element={<Logout />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Onboarding" element={<Onboarding />} />
            <Route path="/ContractDocs" element={<ContractDocs />} />
            <Route path="/BackgroundChecks" element={<BackgroundChecks />} />
            <Route path="/ReportingDetails" element={<ReportingDetails />} />
            <Route path="/OnboardingStatus" element={<OnboardingStatus />} />
            <Route path="/AchInfo" element={<AchInfo />} />
            <Route path="/InsuranceInfo" element={<InsuranceInfo />} />
            <Route path="/ApplicationSummary" element={<ApplicationSummary />} />
            <Route path="/Compliance" element={<Compliance />} />
            <Route path="/TimeLogs" element={<TimeLogs />} />
            <Route path="/IndividualMsg" element={<IndividualMsg />} />
            <Route path="/SentDocs" element={<SentDocs />} />
            <Route path="/RecvedDocs" element={<RecvedDocs />} />
            <Route path="/ScheduleMeeting" element={<ScheduleMeeting />} />
            <Route path="/SurveyFeedback" element={<SurveyFeedback />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/PartnerCandidates" element={<PartnerCandidates />} />
            <Route path="/PartnerInvoices" element={<PartnerInvoices />} />
            <Route path="/ComplianceRpt" element={<ComplianceRpt />} />
            <Route path="/CustomReport" element={<CustomReport />} />
            <Route path="/CandidateWorkingHub" element={<CandidateWorkingHub />} />
            <Route path="/CandidateInvoice" element={<CandidateInvoice />} />
            <Route path="/ManagePartnerUsers" element={<ManagePartnerUsers />} />
            <Route path="/FormLibrary" element={<FormLibrary />} />
            <Route path="/DynamicForm" element={<DynamicForm />} />
        </Route>
        </Route>
    </Routes>
}

export default App;