import React, { Component } from "react";
import http from "../../Services/httpService";
import config from "../../config.json";
import auth from "../../Services/authService";
import Moment from "react-moment";
import WelcomePage from "../WelcomePage";
import { Link } from "react-router-dom";
import ApplicationInstructions from "../ApplicationInstructions";
import { getFieldData, getFormatedNumber } from "../../Services/appService";

class CandidateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidate: "",
      welShowHide: false,
      instructionShowHide: false,
    };
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    this.getCandidateDetails(user.id);
  }

  getCandidateDetails = async (candidateId) => {
    const frmBody = new FormData();
    frmBody.append("id", candidateId);
    frmBody.append("name", "");
    frmBody.append("email", "");
    frmBody.append("entity", "");
    frmBody.append("type", "candidate");
    frmBody.append("filterType", "single");
    const { data: allCond } = await http.post(
      config.GetApplicantsByFilter,
      frmBody
    );
    this.setState({ candidate: allCond[0] });
  };

  getFieldData = (aWorkfinityApp, attribue, filedName) => {
    const appEntity = JSON.parse(aWorkfinityApp);
    const appShortName = Object.keys(appEntity)[1];
    const aWorkfinityAppEntity = appEntity[appShortName];
    const obj = aWorkfinityAppEntity[attribue];
    if (obj === undefined) return "";
    return obj["@" + filedName] === undefined ? "" : obj["@" + filedName];
  };

  handleWelShowHide = () => {
    this.setState({ welShowHide: !this.state.welShowHide });
  };

  handleInstructionShowHide = () => {
    this.setState({ instructionShowHide: !this.state.instructionShowHide });
  };

  render() {
    const { candidate: item, welShowHide, instructionShowHide } = this.state;
    const appType = window.location.pathname;
    const usrType = localStorage.getItem("userType");
    const entityType =
      item &&
      item !== "" &&
      this.getFieldData(
        item.performerData,
        "candidateonboardingform",
        "CandidateEntity"
      );
    //console.log(entityType);
    return (
      <React.Fragment>
        <div className="text-right mt--3">
          <button
            className="btn btn-link btn-sm m-0 p-0"
            onClick={this.handleWelShowHide}
          >
            Welcome Letter !
          </button>
        </div>

        <div className="table-responsive border">
          <table className="table align-items-center table-flush table-hover table-striped">
            <thead className="thead-light-orange">
              <tr>
                <th>Candidate #<br />IPCS ID</th>
                <th>Candidate Details</th>
                <th>Added Date & Time</th>
                <th>Entity Type</th>
                <th>Subcontractor</th>
                <th>Agency/Client<br />MSP Mode</th>
                <th>Duration<br />(Start-End)</th>
              </tr>
            </thead>
            <tbody>
              {item !== "" && (
                <tr>
                  <td>
                    {item.performerid}
                    <br />
                    {item.maxloans}
                  </td>
                  <td>
                    <button
                      className="btn btn-link btn-sm p-0 m-0 text-underline"
                      onClick={() => this.handleViewShowHide(item, "candidate")}
                    >
                      {item.performername} {item.lastName}
                    </button>
                    <br />
                    {item.performeremail}
                    <br />
                    {item.contactNo && getFormatedNumber(item.contactNo)}
                  </td>
                  <td>
                    <Moment format="MMM Do YYYY">{item.addedDateTime}</Moment>
                    <br />
                    <Moment format="h:mm a">{item.addedDateTime}</Moment>
                  </td>
                  <td>
                    {entityType.replace("Basis", "")}
                    <br />
                    {entityType === "1099 Basis" && (
                      <React.Fragment>
                        Rate :{" "}
                        <b>
                          ${item.rates && item.rates.candidateRate}/Hr
                        </b>
                      </React.Fragment>
                    )}
                    {entityType === "W2 Basis" && (
                      <React.Fragment>
                        {getFieldData(
                          item.performerData,
                          "candidateonboardingform",
                          "w9_Type"
                        )}
                        <br /> Rate :{" "}
                        <b>
                          ${item.rates && parseFloat(item.rates.candidateRate).toFixed(2)}/Hr
                        </b>
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    {entityType === "C2C Basis" && <React.Fragment>
                      <button className="btn btn-link btn-sm p-0 m-0 text-underline">
                        {item.subcontractor?.label}
                      </button>
                      <br />
                      ID : <b>{item.validId}</b>
                      <br />
                      {item.subcontractor?.email}
                    </React.Fragment>}
                  </td>
                  <td>
                    <button className="btn btn-link btn-sm p-0 m-0 text-underline">
                      {getFieldData(
                        item.performerData,
                        "projectengagement",
                        "AgencyName"
                      )}
                    </button>
                    <br />
                    <button className="btn btn-link btn-sm p-0 m-0 text-underline mt-2">
                      {getFieldData(
                        item.performerData,
                        "candidateonboardingform",
                        "Msp_Mode"
                      )}
                    </button>
                  </td>
                  <td>
                    {item.duration !== null && (
                      <React.Fragment>
                        Duration : {item.duration.duration} Month <br />
                        Start :{" "}
                        <Moment format="MMM Do YYYY">
                          {item.duration.startDate}
                        </Moment>
                        <br />
                        End :{" "}
                        <Moment format="MMM Do YYYY">
                          {item.duration.endDate}
                        </Moment>
                      </React.Fragment>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
       

        <WelcomePage
          welShowHide={welShowHide}
          handleWelShowHide={this.handleWelShowHide}
        ></WelcomePage>

       
      </React.Fragment>
    );
  }
}

export default CandidateDetails;
