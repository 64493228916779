import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class BarChart extends Component {
  componentDidMount() {}

  generateColor() {
    return "#" + Math.random().toString(16).substr(-6);
  }

  render() {
    const { graphData, label } = this.props;
    let dtObj = [];
    let dtlbl = [];
    if (graphData.length > 0) {
      graphData.map((item) => {
        dtlbl = item.label;
        const clr = this.generateColor();
        const obj = {
          label: item.type,
          backgroundColor: clr,
          borderColor: clr,
          borderWidth: 1,
          hoverBackgroundColor: "rgba(255,99,132,0.4)",
          hoverBorderColor: "rgba(255,99,132,1)",
          data: item.data,
        };
        dtObj.push(obj);
      });
    }
    const data = {
      labels: dtlbl,
      datasets: dtObj,
    };

    return (
      <div className="chart">
        <Bar
          data={data}
          width={100}
          height={50}
          options={{ maintainAspectRatio: false }}
        />
      </div>
    );
  }
}

export default BarChart;
