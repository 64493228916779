import React, { Component } from "react";

class LoginFooter extends Component {
  render() {
    return (
      <div
        className="col-md-12"
        style={{
          backgroundColor: "#eceff1",
        }}
      >
        <div className="d-flex justify-content-center p-3">
          <div className="text-black">
            <img className="ml--1" src="/assets/Img/wflogoblack.png" />
            <br />
            Delivered by IPCS &reg;
          </div>
          <div className="text-black text-center mr-3 ml-3">
            This software is licensed to the user. Use of the software
            constitutes acceptance of the terms and conditions of the License
            Agreement
            <br />
            @IPCS®. @WorkFinity®.
            <br />
            All rights reserved.
            <br />
            Under US Patent Protection
          </div>
        </div>
      </div>
    );
  }
}

export default LoginFooter;
