import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import jwt from "jwt-decode";
import config from "../../config.json";

const tokenKey = "token";

const slice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    loading: false,
    userDetails: null,
    updateStatus: null,
    emailOtpSentStatus: "",
    verifyOtpStatus: "",
    validateStatus:false,
    loginFailedStatus:"",
    picUpdatedStatus:false,
    profilePic:null,
    profilePicTwo:null,
  },
  reducers: {
    login: (auth, action) => {
      auth.user = action.payload;
      auth.token = null;
    },
    loginFailed: (auth, action) => {
      auth.loginFailedStatus=action.payload;
      auth.user = null;
      auth.token = null;
    },
    emailOtpReceived: (auth, action) => {
      auth.emailOtpSentStatus = action.payload;
      auth.verifyOtpStatus = "";
    },
    verifiedOtp: (auth, action) => {
      auth.verifyOtpStatus = action.payload;
      auth.emailOtpSentStatus = "";
    },
    verifiedOtpFailed: (auth, action) => {
      auth.verifyOtpStatus = "";
      auth.emailOtpSentStatus = "";
    },
    validateUser: (auth, action) => {
      auth.user=action.payload;
      auth.token = action.payload?.token;
      auth.validateStatus=true;
      localStorage.setItem(tokenKey,  action.payload?.token);
    },
    logout: (auth, action) => {
      auth.user = null;
      auth.token = null;
      auth.validateStatus=false
    },
    refresh: (auth, action) => {
      const { user, token } = action.payload;
      auth.user = user;
      auth.token = token;
    },
    setLoginUserType: (auth, action) => {
      auth.user = { ...auth.user, ...action.payload };
    },
    loggedUserDetailsReceived: (auth, action) => {
      auth.userDetails = action.payload;
      auth.updateStatus = null;
    },
    userDetailsUpdated: (auth, action) => {
      auth.updateStatus = action.payload;
      auth.userDetails = null;
    },
    userProfilePicUpdated: (auth, action) => {
      auth.picUpdatedStatus = action.payload;
    },
    profilePicReceived: (auth, action) => {
      auth.profilePic = action.payload;
      auth.picUpdatedStatus = false;
    },
    profilePicReceivedTwo: (auth, action) => {
      auth.profilePicTwo = action.payload;
      auth.picUpdatedStatus = false;
    }
  },
});

export const { login, logout, refresh, setLoginUserType,
  loggedUserDetailsReceived, userDetailsUpdated,
  emailOtpReceived, verifiedOtp,validateUser,verifiedOtpFailed,
  loginFailed,userProfilePicUpdated,profilePicReceived, profilePicReceivedTwo} = slice.actions;
export default slice.reducer;

export const sign = (email, password) =>
  apiCallBegan({
    url: config.Login,
    method: "post",
    data: {
      username: email,
      password: password,
      userType: "applicant",
      partnerId:0
    },
    onSuccess: login.type,
    onError:loginFailed.type,
    withCredentials: true,
  });

export const validate = (userId, email, pwd) =>
  apiCallBegan({
    url: config.ValidateUser,
    method: "post",
    data: {
      userId: userId,
      email: email,
      userType: "applicant",
      password: pwd
    },
    onSuccess: validateUser.type,
    withCredentials: true,
  });

export const getRefreshToken = () =>
  apiCallBegan({
    url: "auth/refresh",
    onSuccess: refresh.type,
    withCredentials: true,
    onError: logout.type,
  });

export const loadUserDetails = (id) => apiCallBegan({
  url: `${config.GetUser}${id}`,
  onSuccess: loggedUserDetailsReceived.type
})

export const loadUserProfilePic = (id) => apiCallBegan({
  url: `${config.GetProfilePicData}${id}`,
  onSuccess: profilePicReceived.type
})

export const loadUserProfilePicTwo = (id) => apiCallBegan({
  url: `${config.GetProfilePicData}${id}`,
  onSuccess: profilePicReceivedTwo.type
})

export const UpdateUserDetails = (data) => apiCallBegan({
  url: config.UpdateProfileData,
  method: "post",
  data: data,
  onSuccess: userDetailsUpdated.type
});

export const updateUserProfilePic = (data) => apiCallBegan({
  url: config.UploadProfilePic,
  method: "post",
  data: data,
  onSuccess: userProfilePicUpdated.type
});

export const SendOTPEmail = (username, email) => apiCallBegan({
  url: config.SendSecurityCodeToEmail + username + "&email=" + email,
  onSuccess: emailOtpReceived.type
});

export const VerifyOTPEmail = (email, code) => apiCallBegan({
  url: config.VerifySecurityCode + email + "&code=" + code + "&type=user",
  onSuccess: verifiedOtp.type
});

export const getLoggedUser = createSelector(
  (state) => state.auth,
  (auth) => auth
);

export const getLoggedUserDetails = createSelector(
  (state) => state.auth,
  (auth) => auth.userDetails
);

export const getLoggedUserToken = createSelector(
  (state) => state.auth,
  (auth) => auth.token
);

export const getUserUpdateStatus = createSelector(
  (state) => state.auth,
  (auth) => auth.updateStatus
);

export const getOtpSentStatus = createSelector(
  (state) => state.auth,
  (auth) => auth.emailOtpSentStatus
);

export const getOtpVerifyStatus = createSelector(
  (state) => state.auth,
  (auth) => auth.verifyOtpStatus
);

export const getValidateStatus = createSelector(
  (state) => state.auth,
  (auth) => auth.validateStatus
);

export const getLoginFailedStatus = createSelector(
  (state) => state.auth,
  (auth) => auth.loginFailedStatus
);

export const getPicUpdatedStatus = createSelector(
  (state) => state.auth,
  (auth) => auth.picUpdatedStatus
);
export const getUserProfilePic = createSelector(
  (state) => state.auth,
  (auth) => auth.profilePic
);

export const getUserProfilePicTwo = createSelector(
  (state) => state.auth,
  (auth) => auth.profilePicTwo
);

export const getCurrentUser = () => {
  try {
    const tk = localStorage.getItem(tokenKey);
    const decode = jwt(tk);
    const logType = localStorage.getItem("loginType");
    if (logType === "ad") {
      decode.id = 1;
      decode.type = "admin";
    }
    return decode;
  } catch (error) {
    return null;
  }
}


