import React, { Component } from "react";

class LoginHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientDetails: "",
    };
  }

  async componentDidMount() {
    // const { data: clientDetails } = await http.get(config.GetClientDetails);
    // this.setState({ clientDetails });
  }

  render() {
    const { clientDetails } = this.state;
    return (
      <React.Fragment>
        <div
          className="col-md-12"
          style={{
            backgroundColor: "white",
            textAlign: "left",
            borderBottom: "2px solid #ccc",
            backgroundColor: "white",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <img style={{ height: "70px" }} src={`/assets/Img/logo.png`} />
        </div>
        <div className="col-md-12 d-flex align-items-center justify-content-center">
          <h3 className="mb-0 text-black text-uppercase p-1">
            HUMAN RESOURCES ONBOARDING AND CONTRACT CASE MANAGEMENT SYSTEM
          </h3>
        </div>
        <div
          className="col-md-12 d-flex align-items-center justify-content-center"
          style={{
            backgroundImage: `URL("/assets/Img/slide3.jpg")`,
            backgroundPosition: "center center",
            borderTop: "2px solid white",
            backgroundSize: "100%",
            color: "white",
            height: "70px",
          }}
        >
          <h1 className="text-white mb-0">
            Welcome to <img className="mb-2" src="/assets/Img/logo_ft.png" />
          </h1>
        </div>
      </React.Fragment>
    );
  }
}

export default LoginHeader;
