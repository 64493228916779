import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";

class ApplicationInstructions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getFieldData = (aWorkfinityApp, attribue, filedName) => {
    const appEntity = JSON.parse(aWorkfinityApp);
    const appShortName = Object.keys(appEntity)[1];
    const aWorkfinityAppEntity = appEntity[appShortName];
    const obj = aWorkfinityAppEntity[attribue];
    if (obj === undefined) return "";
    return obj["@" + filedName] === undefined ? "" : obj["@" + filedName];
  };

  render() {
    const {
      instructionShowHide,
      handleInstructionShowHide,
      userDetails,
      entityType,
    } = this.props;
    //console.log(userDetails);
    const usrType = localStorage.getItem("userType");
    // let entityType = "";
    // if (
    //   userDetails !== "" &&
    //   userDetails !== undefined &&
    //   userDetails.performerData !== "" &&
    //   userDetails.performerData !== undefined &&
    //   userDetails.performerData !== null
    // ) {
    //   entityType = this.getFieldData(
    //     userDetails.performerData,
    //     "candidateonboardingform",
    //     "CandidateEntity"
    //   );
    // }
    return (
      <Modal show={instructionShowHide} size="xl">
        <Modal.Header closeButton onHide={handleInstructionShowHide}>
          <Modal.Title>
            Instructions for completing the onboarding process
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            <div className="col-12 form-group text-red">
              <div className="d-flex">
                <div>
                  <span>
                    <i className="fa fa-hand-o-right p-1" aria-hidden="true" />
                  </span>
                </div>
                <div>
                  {usrType === "candidate"
                    ? "Onboarding Process Steps : "
                    : "Enrollment Process Steps : "}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="text-false  backgroundBlue form-group">
                <li>Update Your Profile</li>
                <br />
                {usrType === "candidate" ? (
                  <ol type={1}>
                    <li>
                      <b>Onboarding Form</b> - Complete the missing fields
                      “Onboarding Application Form”. All data fields are
                      mandatory.
                    </li>
                    <li>
                      <b>Contract Documents</b> - Upload the documents and/or
                      complete the forms as listed.
                      <br />
                      Some documents will be executed using DocuSign.
                    </li>
                    {entityType !== "C2C Basis" && (
                      <li>
                        <b>ACH Information</b> - Click on this link to complete
                        the ACH Form. You are also required to upload completed
                        signed form along with a copy of the Voided Check
                      </li>
                    )}
                    <li>
                      <b>Background Check</b> - You will be required to undergo
                      Background Checks as specific to each client. Once the
                      background check results are received, you will be updated
                      of the results.
                    </li>

                    <li>
                      <b>Reporting Details</b> : Click on this link to check
                      your reporting details for the project engagement
                    </li>
                    <li>
                      <b>Onboarding Status</b> - Click on this link to check
                      your onboarding status{" "}
                    </li>
                  </ol>
                ) : (
                  <ol type={1}>
                    <li>
                      <b>Enrollment Form</b> - Complete the missing fields in
                      the Enrollment Application Form. All data fields are
                      mandatory.
                    </li>
                    <li>
                      <b>Contract Documents</b> - Download and upload required
                      contract documents and/or complete the forms as listed.
                      Some documents will be executed using DocuSign.
                    </li>
                    <li>
                      <b>ACH Information</b> - Click on this link to complete
                      the ACH Form. You are also required to upload completed
                      form signed by an authorized key management executive who
                      has the signatory powers on behalf of your company, along
                      with a copy of the Voided Check.
                    </li>
                    <li>
                      <b>Insurance Information</b> - Click on this link to enter
                      your Company’s Insurance Coverage details. Upload copy of
                      your Certificate of Insurance(s).
                    </li>
                    <li>
                      <b>Enrollment Status</b> - Click on this link to check
                      your enrollment status.
                    </li>
                  </ol>
                )}
              </div>
            </div>
            <div className="col-md-12 form-group">
              {usrType === "candidate" ? (
                <b>Time Log Management</b>
              ) : (
                <b>Time Log Management and Invoicing</b>
              )}
            </div>
            <div className="col-md-12 form-group">
              {usrType === "candidate" ? (
                <span>
                  You will access ‘Time Log Management’ to register your weekly
                  hours logged on the project, and upload signed weekly and/or
                  monthly timesheets. You do not have to resubmit your weekly
                  hours and time sheets to your employer/recruiter as they will
                  automatically see the copy of hours entered and signed time
                  sheets automatically. No repeat work required. This is a
                  ‘one-stop’ entry for your time log entry.
                  <br />
                  Please note you still have to enter time in client-specific
                  portals as appropriate to the client where you are engaged on
                  the project.
                </span>
              ) : (
                <span>
                  Click on this link to view work hours logged by your
                  candidate(s) each week or monthly. Once your candidate(s)
                  enters hours in our time log system you will automatically see
                  the hours and accompanied time sheets. You don’t have to ask
                  your candidates to email or fax their time sheets anymore! All
                  candidates are having access to our system and collaborating
                  in real-time.
                </span>
              )}
            </div>
            <div className="col-12 form-group">
              {usrType === "candidate" ? (
                <span>
                  Additionally, you have other features available to
                  electronically communicate with IPCS example using messages
                  and alerts or sending and receiving any documents in regard to
                  your project engagement.
                </span>
              ) : (
                <span>
                  In regard to monthly invoicing to IPCS you are not required to
                  attach the time-sheets. Just upload your invoices only. Ensure
                  invoices are raised on time (net 30 days) for timely payments.
                </span>
              )}
            </div>
            <div className="col-12">
              {usrType === "candidate" ? (
                <span>
                  Your employer / recruiter is also having access to our system
                  specific to their part of the work and all of us are
                  collaborating electronically in a real-time.
                </span>
              ) : (
                <span>
                  In our portal you have other features available to
                  electronically communicate with IPCS example using messages
                  and alerts or sending /receiving any documents in regard to
                  contracts, purchase orders, contract extensions, ACH,
                  insurance certificates etc.
                </span>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={handleInstructionShowHide}>
          <i className="fa fa-times" aria-hidden="true"></i> Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ApplicationInstructions;
