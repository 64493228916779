import React, { Component } from "react";
import ChecklistItem from "../Comman/ChecklistItem";
import http from "../../Services/httpService";
import config from "../../config.json";
import Moment from "react-moment";
import PageHeader from "./PageHeader";
import { Link } from "react-router-dom";

class ApplicationHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bredCrumb: [],
      showHide: false,
      keyboardShowHide: false,
      layoutName: "default",
      input: "",
      selectedInput: "",
      smartNav: false,
    };
  }

  async componentDidMount() {
    const { workItem } = this.props;
    //await this.loadBredCrumb(workItem);
  }

  async componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    if (nextProps != undefined) {
      const { workItem } = nextProps;
      this.loadBredCrumb(workItem);
    } else {
      const { workItem } = this.props;
      await this.loadBredCrumb(workItem);
    }
  }

  loadBredCrumb = async (workItem) => {
    if (workItem === undefined) return null;
    const { data: bredCrumb } = await http.post(
      config.GetApplicationBreadcrumb,
      workItem
    );
    this.setState({ bredCrumb });
  };

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  render() {
    const { workItem, handleHistoryTask } = this.props;
    const { bredCrumb } = this.state;
    //console.log(bredCrumb);
    let usrType = localStorage.getItem("userType");
    //console.log(usrType);
    return (
      <React.Fragment>
        <div className="col-md-12 p-0">
          <div className="col-md-12 p-0 mb-3">
            <ul className="list-group">
              <li className="list-group-item pt-2 pb-2 pl-3 pr-3 font-weight-thin rounded-0">

                {bredCrumb !== undefined &&
                  bredCrumb.length > 0 &&
                  bredCrumb.map((b, key) => {
                    const i = key + 1;
                    if (
                      (b.status === true && b.wiid > 0) ||
                      (b.status === false && b.wiid > 0)
                    ) {
                      return (
                        <a
                          style={{ cursor: "pointer" }}
                          className={
                            `font-weight-normal ` +
                            (b.formId === workItem.formId
                              ? "text-red"
                              : "text-green")
                          }
                          onClick={() => handleHistoryTask(b)}
                        >
                          {b.formName}
                          {bredCrumb.length !== i && (
                            <i
                              className="fa fa-angle-double-right ml-2 mr-2"
                              aria-hidden="true"
                            ></i>
                          )}
                        </a>
                      );
                    } else {
                      return (
                        <label
                          className={
                            `font-weight-normal ` +
                            (workItem.formId === b.formId && b.wiid > 0
                              ? "text-orange"
                              : "text-dark")
                          }
                        >
                          {b.formName}
                          {bredCrumb.length !== i && (
                            <i
                              className="fa fa-angle-double-right ml-2 mr-2"
                              aria-hidden="true"
                            ></i>
                          )}
                        </label>
                      );
                    }
                  })}
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ApplicationHeader;
