import axios from "axios";
import { getJwt, logout } from "./authService";
//import test from "./test";

//console.log(auth.getJwt());
//axios.defaults.headers.comman["x-auth-token"] = auth.getJwt();

axios.defaults.headers.common["Authorization"] = getJwt();
axios.defaults.headers.common["Portal"] = "customer";
//axios.defaults.baseURL = "https://localhost:44334/api/";
axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL + "/api/"
//this.setJwt(auth.getJwt());

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    console.log("Logging the error", error);
  }
  else {
    if (error.response.status === 401) {
      logout();
      if (error.response.data.message === "Unauthorized") {
        alert(
          "Your access to the portal is deactivated, hence your session is being terminated"
        );
      } else {
        alert(error.response.data.message);
      }
      window.location = "/Login";
    }

  }

  return Promise.reject(error);
});

function setJwt(jwt) {
  //console.log(jwt);
  axios.defaults.headers.comman["Authorization"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  baseURL: axios.defaults.baseURL
};
