import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "reviewNotes",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        addedStatus:false,
        allNotes:[]
    },
    reducers: {
        allNotesReceived: (reviewNotes, action) => {
            reviewNotes.allNotes=action.payload;
        },
        reviewNotesAdded: (reviewNotes, action) => {
            reviewNotes.addedStatus=action.payload;
        }
    }
});

export const { allNotesReceived,reviewNotesAdded } = slice.actions;
export default slice.reducer;

export const loadAllReviewNotes = (type, id) => apiCallBegan({
    url: config.GetReviewNotes + type + "&id=" + id,
    onSuccess: allNotesReceived.type
});

export const addReviewNotes = (data) => apiCallBegan({
    url: config.AddReviewNotes,
    method: "post",
    data: data,
    onSuccess: reviewNotesAdded.type
});

export const getAllNotes = createSelector(
    state => state.entities.reviewNotes,
    reviewNotes => reviewNotes.allNotes
);

export const getNotesAddedStatus = createSelector(
    state => state.entities.reviewNotes,
    reviewNotes => reviewNotes.addedStatus
);

