import config from "../config.json";
import axios from "axios";

const apiEndPoint = config.DictionaryApi + "/define";

export async function define(term) {
  var options = {
    method: "GET",
    url: apiEndPoint, //"https://mashape-community-urban-dictionary.p.rapidapi.com/define",
    params: { term: term },
    headers: {
      "x-rapidapi-key": "9f787575damshb92f190b54fdd3ep178282jsnd7752405d56b",
      "x-rapidapi-host": "mashape-community-urban-dictionary.p.rapidapi.com",
    },
  };

  return axios
    .request(options)
    .then(function (response) {
      //console.log(response.data);
      return response;
    })
    .catch(function (error) {
      console.error(error);
    });
}

export default {
  define,
};
