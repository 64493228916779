import React, { Component } from "react";
import { Link } from "react-router-dom";
import http from "../../Services/httpService";
import config from "../../config.json";
import auth from "../../Services/authService";
import Progress from "./Progress";

class ChecklistItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workItem: {},
      showHide: false,
      formProcess: [
        {
          id: 1,
          formProcessId: 1,
          processItem: "Starting your application.",
        },
        {
          id: 2,
          formProcessId: 1,
          processItem: "Starting your application.",
        },
        {
          id: 3,
          formProcessId: 1,
          processItem: "Getting Ready.",
        },
      ],
    };
  }

  handleRunApplication = async () => {
    const { Checklist } = this.props;
    //console.log(Checklist);

    const user = auth.getCurrentUser();
    const newApplication = {
      workflowId: Checklist.workflowId,
      performerId: parseInt(user.id),
      applicationId: Checklist.applicationId,
    };

    this.setState({ showHide: true });

    const { data } = await http.post(
      config.StartNewApplication,
      newApplication
    );

    const { data: workItemTask } = await http.post(
      config.GetCurrentWorkflowTask,
      data
    );
    //console.log(workItemTask);
    if (workItemTask.ciid === null) {
      this.runApplication(data);
    } else {
      this.showTask(workItemTask);
    }
  };

  runApplication = (data) => {
    setTimeout(async () => {
      const { data: workItemTask } = await http.post(
        config.GetCurrentWorkflowTask,
        data
      );
      //console.log(workItemTask);
      if (workItemTask.ciid === null) {
        this.runApplication(data);
      } else {
        this.showTask(workItemTask);
      }
    }, 3000);
  };

  showTask = (workItemTask) => {
    this.handleModalShowHide();
    this.props.handleWorkflowTask(workItemTask);
  };

  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };

  render() {
    const { Checklist, color, type, page, appType } = this.props;
    const usrType = localStorage.getItem("userType");
    return (
      <li
        className="list-group-item p-3"
        style={{
          backgroundColor: color,
          borderLeft: `${
            type !== "header" &&
            color !== "white" &&
            page !== "portal" &&
            "5px solid #ffbf00"
          }`,
        }}
      >
        <span className="font-weight-bold" style={{ color: "black" }}>
          <i className="fa fa-hand-o-right mr-1" aria-hidden="true"></i>
          {(type === "ApplicationStatus" || type === "ApplyApplication") && (
            <React.Fragment>
              {/* {Checklist.name}
              <i
                className="fa fa-angle-double-right mr-1 ml-1"
                aria-hidden="true"
              ></i>
              {Checklist.category}
              <i
                className="fa fa-angle-double-right mr-1 ml-1"
                aria-hidden="true"
              ></i> */}
            </React.Fragment>
          )}
          {/* {type === "ApplicationStatus" && (
            <Link
              to={{
                pathname: "/StatusPage",
              }}
            >
              Check Your Application Approval Status
            </Link>
          )}
          {type === "ApplyApplication" && (
            <button
              className="btn btn-link p-0 m-0 mt--1 btn-sm"
              onClick={this.handleRunApplication}
            >
              Apply for New Application
            </button>
          )}
          <i
            className="fa fa-angle-double-right mr-1 ml-1"
            aria-hidden="true"
          ></i> */}
          {appType === "1" && "Step-1 : Complete" + Checklist.workflowTitle}
          {appType === "4" && "Step-4 : Onboarding Status"}
          <i
            className="fa fa-angle-double-right mr-1 ml-1"
            aria-hidden="true"
          ></i>
          {type === "ApplicationStatus" && "Onboarding Application Form"}
          {type === "ApplyApplication" && (
            <button
              className="btn btn-success btn-sm ml-2"
              onClick={this.handleRunApplication}
            >
              Start {usrType === "candidate" ? "Onboarding" : "Enrollment"}
            </button>
          )}
        </span>
        <Progress
          showHide={this.state.showHide}
          handleModalShowHide={this.handleModalShowHide}
          formProcess={this.state.formProcess}
          processId="1"
        ></Progress>
      </li>
    );
  }
}

export default ChecklistItem;
