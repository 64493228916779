import React, { Component, useEffect, useState } from "react";
import config from "../../config.json";
import http from "../../Services/httpService";
import auth, { getCurrentUserDetails } from "../../Services/authService";
import PageHeader from "../Comman/PageHeader";
import CandidateInfo from "../../app/components/CandidateInfo";
import PartnerInfo from "../../app/components/PartnerInfo";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { getAllMonths, getAllYears, getFieldData } from "../../Services/appService";
import GeneratePdf from "../../app/components/Report/GeneratePdf";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getFilterAllCandidates, loadCandidatesByFilter } from "../../app/store/candidate";
import { getMonthlyPayCandidates, loadMonthlyPayCandidates } from "../../app/store/report";
import { getPartnerAllCandidate, loadPartnerAllCandidate } from "../../app/store/partner";

const CustomReport = () => {

  const years = getAllYears();
  const months = getAllMonths();

  const [selectedYear, setSelectedYear] = useState("");
  const [candidateList, setCandidateList] = useState([]);
  const [monthlyRpt, setMonthlyRpt] = useState([]);
  const [allRpts, setAllRpts] = useState([]);
  const [pdfShowHide, setPdfShowHide] = useState(false);
  const [selectedCol, setSelectedCol] = useState([]);
  const [selectedColData, setSelectedColData] = useState([]);
  const [userDetails, setUserDeatils] = useState([]);
  const [candidateObj, setcandidateObj] = useState(null);

  const userDetailsSelector=useSelector(getLoggedUserDetails);
  const filterAllCandidatesSelector=useSelector(getFilterAllCandidates);
  const monthlyPayCandidatesSelector=useSelector(getMonthlyPayCandidates);
  const partnerAllCandidateSelector=useSelector(getPartnerAllCandidate);

  const usrType = localStorage.getItem("userType");

  const currentUser=getCurrentUser();
  const dispatch=useDispatch();

  useEffect(()=>{
    if (!userDetailsSelector)
    dispatch(loadUserDetails(currentUser.id));
  },[]);

  useEffect(() => {
    if (userDetailsSelector) {
      setUserDeatils(userDetailsSelector);
      if (usrType === "candidate")
        getCandidateDetails(currentUser.id);
      else
        dispatch(loadPartnerAllCandidate(userDetailsSelector.partnerid,0))
    }
  }, [userDetailsSelector]);

  useEffect(()=>{
    setCandidateList(partnerAllCandidateSelector);
  },[partnerAllCandidateSelector])



  const getCandidateDetails = (id) => {
    const frmBody = new FormData();
    frmBody.append("id", id);
    frmBody.append("name", "");
    frmBody.append("email", "");
    frmBody.append("entity", "");
    frmBody.append("type", "candidate");
    frmBody.append("filterType", "single");
    dispatch(loadCandidatesByFilter(frmBody))
  };

  useEffect(()=>{
   if (filterAllCandidatesSelector.length > 0) {
      const candidate = filterAllCandidatesSelector[0];

      setcandidateObj(candidate);

      const entityType = getFieldData(
        candidate.performerData,
        "candidateonboardingform",
        "CandidateEntity"
      );
      
      let rptType = "candidate";
      if (entityType === "C2C Basis") rptType = "subcontractor";
      if (userDetails?.partnerType === "Recruiter") rptType = "recruiter";

      dispatch(loadMonthlyPayCandidates(candidate.id,rptType))
     
    }
  },[filterAllCandidatesSelector]);

  useEffect(()=>{
    if(monthlyPayCandidatesSelector.length > 0){

      setAllRpts(monthlyPayCandidatesSelector);

      let filterRpt = monthlyPayCandidatesSelector;
      if (selectedYear !== "")
        filterRpt = filterRpt.filter((x) => x.year === parseInt(selectedYear));

      setMonthlyRpt(filterRpt);
    }
  },[monthlyPayCandidatesSelector])

  const handleCandidateChange = ({ currentTarget }) => {
    setMonthlyRpt([]);
    setAllRpts([]);
    setSelectedYear("");
    if (currentTarget.value !== "")
      getCandidateDetails(currentTarget.value);
  };

  const handleChangeYear = ({ currentTarget }) => {
    if (currentTarget.value !== "") {
      const rtps = allRpts.filter(
        (x) => x.year === parseInt(currentTarget.value)
      );
      setMonthlyRpt(rtps);
      setSelectedYear(currentTarget.value);
    } else {
      setMonthlyRpt(allRpts);
      setSelectedYear("");
    }
  };

  const getTotalRows = () => {
    let hrs = 0;
    let invAmt = 0;
    let payAmt = 0;
    monthlyRpt.map((item) => {
      hrs += item.hours;
      invAmt += item.totalAmount;
      payAmt += item.paidAmount;
    });
    return (
      <tr className="font-weight-bold">
        <td colSpan={3}>Total</td>
        <td>{hrs}</td>
        <td>{parseFloat(invAmt).toFixed(2)}</td>
        <td>{parseFloat(payAmt).toFixed(2)}</td>
      </tr>
    );
  };

  const tmLogRptExportPDF = () => {
    const headers = [
      [
        "S.No.",
        "Year",
        "Month",
        "Total Hours",
        "Invoice Amount ($)",
        "Payment Amount ($)"
      ],
    ];
    let data = [];
    monthlyRpt.map((item,key) =>
      data.push([
        key+1,
        item.year,
        months.filter((x) => x.id === item.month)[0].name,
        item.hours && parseFloat(item.hours).toFixed(2),
        item.totalAmount && parseFloat(item.totalAmount).toFixed(2),
        item.paidAmount && parseFloat(item.paidAmount).toFixed(2)
      ])
    );
    setSelectedCol(headers);
    setSelectedColData(data);
    setPdfShowHide(true);
  };

  const handlepdfShowHide = () => {
    setPdfShowHide(false);
    setSelectedCol([]);
    setSelectedColData([]);
};


  return (
    <React.Fragment>
      <div className="col-md-12 mt-2">
        <ul className="list-group">
          <li className="list-group-item">
            <div className="row">
              <div className="col-md-6">
              <i className="fa fa-square wf-phase-box mr-2" aria-hidden="true"></i>
            <span className="h3">
              Invoice Reports
            </span>
              </div>
              <div className="col-md-6 text-right">
              <ReactHTMLTableToExcel
              id="tmLogTable"
              className="btn btn-sm btn-outline-default fa fa-file-excel-o"
              table="Invoice_Report"
              filename="Invoice_Report"
              sheet="Invoice_Report"
              buttonText=" Export to Excel"
            />
            <button
              type="button"
              className="btn btn-sm btn-outline-dark"
              disabled={monthlyRpt.length === 0}
              onClick={tmLogRptExportPDF}
            >
              <i className="fa fa-print" aria-hidden="true"></i> Generate PDF
            </button>
              </div>
            </div>
            
            
          </li>
          {usrType !== "candidate" && (
            <li className="list-group-item">
              <div className="row">
                <div className="col-md-8">
                  <select
                    name="candidate"
                    id="candidate"
                    className="form-control"
                    onChange={handleCandidateChange}
                  >
                    <option value="">Select Candidate</option>
                    {candidateList.map((item) => (
                      <option value={item.performerid}>
                        {item.performername}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <select
                    name="year"
                    id="year"
                    className="form-control"
                    onChange={handleChangeYear}
                    value={selectedYear}
                  >
                    <option value="">Select Year</option>
                    {years.filter(x=>x.name <= (new Date().getFullYear())).map((item) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </li>
          )}
          <li className="list-group-item">
            <div className="table-responsive">
              <table id="Invoice_Report" className="table align-items-center table-flush table-hover table-striped border">
                <thead className="thead-light">
                  <tr>
                    <th>S.No.</th>
                    <th>Year</th>
                    <th>Month</th>
                    <th>Total Hours</th>
                    <th>Invoice Amount ($)</th>
                    <th>Payment Amount ($)</th>
                  </tr>
                </thead>
                <tbody>
                  {monthlyRpt.map((item, key) => (
                    <tr>
                      <td>{key + 1}</td>
                      <td>{item.year}</td>
                      <td>
                        {months.filter((x) => x.id === item.month)[0].name}
                      </td>
                      <td>{item.hours && parseFloat(item.hours).toFixed(2)}</td>
                      <td>
                        {item.totalAmount && parseFloat(item.totalAmount).toFixed(2)}
                      </td>
                      <td>
                        {item.paidAmount && parseFloat(item.paidAmount).toFixed(2)}
                      </td>
                    </tr>
                  ))}

                  {getTotalRows()}
                </tbody>
              </table>
            </div>
          </li>
        </ul>
      </div>
      <GeneratePdf
        handlepdfShowHide={handlepdfShowHide}
        pdfShowHide={pdfShowHide}
        selectedCol={selectedCol}
        selectedColData={selectedColData}
      ></GeneratePdf>
    </React.Fragment>
  );
}

export default CustomReport;
