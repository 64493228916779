import React, { Component } from "react";

class PhoneInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
    };
  }

  componentDidMount() {}

  formatPhoneNumber(value) {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) return value;

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;

    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  componentWillReceiveProps(props) {
    const { value } = props;
    if (value !== undefined) {
      const formattedPhoneNumber = this.formatPhoneNumber(value);
      this.setState({ inputValue: formattedPhoneNumber });
    }
  }

  handleInput = (e) => {
    const formattedPhoneNumber = this.formatPhoneNumber(e.target.value);
    this.setState({ inputValue: formattedPhoneNumber });
    const unFormattedNumber = formattedPhoneNumber.replace(/[^\d]/g, "");
    this.props.onChange({
      currentTarget: {
        value: unFormattedNumber,
        name: e.target.name,
      },
    });
  };

  render() {
    const { name, label, error, req, value, ...rest } = this.props;
    const formattedPhoneNumber = this.formatPhoneNumber(value);
    return (
      <div className={`form-group ${req && "required"}`}>
        <label className="form-control-label" htmlFor={name}>
          {label}
        </label>
        <input
          {...rest}
          id={name}
          name={name}
          value={formattedPhoneNumber}
          className="form-control"
          placeholder={label}
          onChange={this.handleInput}
        />
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    );
  }
}

export default PhoneInput;
