import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "partners",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        partnerDetails: null,
        allCandidates:[],
        filterCandidates:[],
        docSendStatus:false,
        userList:[],
        candidatesRpt:[],
        bulkInvGen:"",
        partCandidateApps:[],
        candidateAppTasks:[],
        partRoleTypes:[],
        partUserAddedStatus:false,
        candidatesPoOrders:[],
        poOrdersSignStatus:null,
        poOrdersPdf:null,
    },
    reducers: {
        partnersReceived: (partners, action) => {
            partners.list = action.payload;
        },
        partnerUsersReceived: (partners, action) => {
            partners.userList = action.payload;
            partners.partUserAddedStatus = false;
        },
        partnerDetailsReceived: (partners, action) => {
            partners.partnerDetails = action.payload;
        },
        partnerAllCandidateReceived: (partners, action) => {
            partners.allCandidates = action.payload;
            partners.docSendStatus="";
        },
        partnerFilterCandidateReceived: (partners, action) => {
            partners.filterCandidates = action.payload;
            partners.docSendStatus="";
        },
        partnerCandidateDocSent:(partners,action)=>{
            partners.docSendStatus=action.payload;
        },
        partnerCandidatesRptReceived:(partners,action)=>{
            partners.candidatesRpt=action.payload;
        },
        partnerCandidatesInvGenerated: (partners, action) => {
            partners.bulkInvGen = action.payload;
        },
        partCandidateAppsReceived: (partners, action) => {
            const filterApp=action.payload?.filter(x=>x.pmId !== config.WorkflowIds.SupportPmId);
            partners.partCandidateApps = filterApp;
        },
        partCandidateAppTasksReceived:(partners,action)=>{
            partners.candidateAppTasks=action.payload;
        },
        partRoleTypesReceived: (partners, action) => {
            partners.partRoleTypes = action.payload;
        },
        partnerUserAdded: (partners, action) => {
            partners.partUserAddedStatus = action.payload;
        },
        partnerCandidatesPoOrdersReceived: (partners, action) => {
            partners.candidatesPoOrders = action.payload;
        },
        poOrdersSignUpdated: (partners, action) => {
            partners.poOrdersSignStatus = action.payload;
        },
        generatedPoOrderPdfReceived: (partners, action) => {
            partners.poOrdersPdf = action.payload;
        },

    }
});

export const { partnersReceived, partnerDetailsReceived,
    partnerAllCandidateReceived,partnerFilterCandidateReceived,
    partnerCandidateDocSent,partnerUsersReceived,partnerCandidatesRptReceived,
    partnerCandidatesInvGenerated,partCandidateAppsReceived,partCandidateAppTasksReceived,
    partRoleTypesReceived,partnerUserAdded,partnerCandidatesPoOrdersReceived,poOrdersSignUpdated,
    generatedPoOrderPdfReceived } = slice.actions;
export default slice.reducer;

export const loadPartnerDetails = (id) => apiCallBegan({
    url: config.GetPartnerById + id,
    onSuccess: partnerDetailsReceived.type
});

export const loadPartRoleTypes = (name) => apiCallBegan({
    url: `${config.GetCodemanager}${name}`,
    onSuccess: partRoleTypesReceived.type
});

export const loadPartnerAllCandidate = (partId, perfId) => apiCallBegan({
    url: config.GetPartnerUsers + partId + "&perfId=" + perfId,
    onSuccess: partnerAllCandidateReceived.type
});

export const loadPartnerUsers = (id) => apiCallBegan({
    url: config.GetPartnerPerformers + id,
    onSuccess: partnerUsersReceived.type
});

export const addPartnerUser = (data) => apiCallBegan({
    url: config.AddPartnerPerformer,
    method:"post",
    data:data,
    onSuccess: partnerUserAdded.type
});

export const loadPartnerAllCandidateRpt = (year, month, partId, type, canId) => apiCallBegan({
    url: config.GetBulkReportCandidates + year + "&month=" + month + "&partnerId=" 
                                        + partId + "&type=" + type + "&candId=" + canId,
    onSuccess: partnerCandidatesRptReceived.type
});

export const loadPartnerFilterCandidate = (partId, perfId) => apiCallBegan({
    url: config.GetPartnerUsers + partId + "&perfId=" + perfId,
    onSuccess: partnerFilterCandidateReceived.type
});

export const partnerCandidateDocSend = (data) => apiCallBegan({
    url: config.UpdateDocSentStatus,
    method:"post",
    data:data,
    onSuccess: partnerCandidateDocSent.type
});

export const partnerCandidateGenerateBulkInv = (data) => apiCallBegan({
    url: config.GenerateBulkInvoice,
    method:"post",
    data:data,
    onSuccess: partnerCandidatesInvGenerated.type
});

export const loadPartnerCandidateApps = (id) => apiCallBegan({
    url: config.GetPerformerApplication + id,
    onSuccess: partCandidateAppsReceived.type
});

export const loadCandidateAppRuningTasks = (wfId, userId) => apiCallBegan({
    url: config.GetRunningTask + wfId + "&performerId=" + userId,
    onSuccess: partCandidateAppTasksReceived.type
});

export const loadPartCandidatePoOrders = (id, type) => apiCallBegan({
    url: config.GetPurchaseOrders + id + "&type=" + type,
    onSuccess: partnerCandidatesPoOrdersReceived.type
});

export const updatePartPoOrderSign = (data) => apiCallBegan({
    url: config.UpdatePoOrderSign,
    method:"post",
    data:data,
    onSuccess: poOrdersSignUpdated.type
});

export const printGeneratedPoOrders = (data) => apiCallBegan({
    url: config.PrintTemplate,
    method: "post",
    data: data,
    responseType: "blob",
    onSuccess: generatedPoOrderPdfReceived.type
});

export const getPartnerDetails = createSelector(
    state => state.entities.partners,
    partners => partners.partnerDetails
);

export const getPartnerUsers = createSelector(
    state => state.entities.partners,
    partners => partners.userList
);

export const getPartnerAllCandidate = createSelector(
    state => state.entities.partners,
    partners => partners.allCandidates
);

export const getPartnerFilterCandidate = createSelector(
    state => state.entities.partners,
    partners => partners.filterCandidates
);

export const getUpdateDocSentStatus = createSelector(
    state => state.entities.partners,
    partners => partners.docSendStatus
);

export const getPartnerCandidatesReport = createSelector(
    state => state.entities.partners,
    partners => partners.candidatesRpt
);
export const getGenerateBulkInvoiceStatus = createSelector(
    state => state.entities.partners,
    partners => partners.bulkInvGen
);

export const getPartnerCandidateApps = createSelector(
    state => state.entities.partners,
    partners => partners.partCandidateApps
)

export const getPartCandidateAppTasks = createSelector(
    state => state.entities.partners,
    partners => partners.candidateAppTasks
)

export const getPartRoleTypes = createSelector(
    state => state.entities.partners,
    partners => partners.partRoleTypes
);
export const getPartUserAddedStatus = createSelector(
    state => state.entities.partners,
    partners => partners.partUserAddedStatus
);
export const getPartCandidatePoOrders = createSelector(
    state => state.entities.partners,   
    partners => partners.candidatesPoOrders
);
export const getPoOrdersSignStatus = createSelector(
    state => state.entities.partners,   
    partners => partners.poOrdersSignStatus
);
export const getPoOrdersPdf = createSelector(
    state => state.entities.partners,   
    partners => partners.poOrdersPdf
);


