import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../store/auth";
import { useNavigate } from "react-router-dom";
import { getAppTasks, getCandidateApps, loadAppRuningTasks, loadCandidateApps } from "../store/candidate";
import PageHeader from "../../Components/Comman/PageHeader";
import CandidateInfo from "./CandidateInfo";
import PartnerInfo from "./PartnerInfo";

export default function Compliance() {

    const route = [{ name: "Task Management", url: "" }];

    const [appTasklist, setAppTasklist] = useState([]);
    const [phaseName, setPhaseName] = useState("Compliance");

    const candidateAppsSelector = useSelector(getCandidateApps);
    const appTasksSelector = useSelector(getAppTasks);

    const dispatch = useDispatch();
    const currentUser = getCurrentUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (candidateAppsSelector.length > 0) {
            const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
            if (mainWorkflow.length > 0) {
                const wf = mainWorkflow[0]
                dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
            }
        }
    }, [candidateAppsSelector]);

    useEffect(() => {
        if (appTasksSelector.length > 0) {
            const filterTasks = appTasksSelector.filter(x => x.taskType === 1 &&
                x.wiid > 0 &&
                x.wfPhase === phaseName);
            setAppTasklist(filterTasks);
        }
    }, [appTasksSelector])

    useEffect(() => {
        dispatch(loadCandidateApps(currentUser.id))
    }, []);

    const usrType = localStorage.getItem("userType");

    return <React.Fragment>
        <div className="col-md-12 mt-2">
            <div className="card border shadow-none">
                <div className="card-header p-3">
                <i className="fa fa-square wf-phase5-box mr-2" aria-hidden="true"></i>
                    <span className="h3">Compliance Management</span>
                </div>
                <div className="card-body p-3">
                    <div className="table-responsive">
                        <table className="table align-items-center table-flush table-hover table-striped border">
                            <thead className="thead-light">
                                <tr>
                                    <th>S.No.</th>
                                    <th>Form</th>
                                    <th colSpan={2}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {appTasklist.map((item, key) => (
                                    <tr>
                                        <td>{key + 1}</td>
                                        <td>{item.taskName}</td>
                                        <td>
                                            <button className="btn btn-link btn-sm p-0 text-underline">
                                                View
                                            </button>
                                        </td>
                                        <td>
                                            <button className="btn btn-link btn-sm p-0 text-underline">
                                                Acknowledgment
                                            </button>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
    </React.Fragment>
}