import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "workflowEngine",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        ruleAppData: "",
        currentWfTask:"",
        workflowTask:""
    },
    reducers: {
        taskReceived: (workflowEngine, action) => {
            workflowEngine.list.push(action.payload);
        },
        taskDone: (workflowEngine, action) => {
            workflowEngine.workflowTask = action.payload;
        },
        ruleAppDataReceived: (workflowEngine, action) => {
            workflowEngine.ruleAppData = action.payload;
        },
        currentWfTaskReceived: (workflowEngine, action) => {
            workflowEngine.currentWfTask = action.payload;
        },
        workflowTaskReceived: (workflowEngine, action) => {
            workflowEngine.workflowTask = action.payload;
        }
    }
});

export const { taskReceived, taskDone, ruleAppDataReceived,
    currentWfTaskReceived,workflowTaskReceived } = slice.actions;
export default slice.reducer;

export const workflowTaskDone = (data) => apiCallBegan({
    url: config.SetWorkItemToDone,
    method: "post",
    data: data,
    onSuccess: taskDone.type
});

export const executeBprmFormRule = (data) => apiCallBegan({
    url: config.ExcuteFormRule,
    method: "post",
    data: data,
    onSuccess: ruleAppDataReceived.type
});

export const loadCurrentWorkflowTask = (data) => apiCallBegan({
    url: config.GetCurrentWorkflowTask,
    method: "post",
    data: data,
    onSuccess: currentWfTaskReceived.type
});

export const loadWorkflowTask = (wiid) => apiCallBegan({
    url: config.GetWorkflowTask + wiid,
    onSuccess: workflowTaskReceived.type
});

export const getExecuteRuleAppData = createSelector(
    state => state.entities.workflowEngine,
    workflowEngine => workflowEngine.ruleAppData
);

export const getCurrentWfTask = createSelector(
    state => state.entities.workflowEngine,
    workflowEngine => workflowEngine.currentWfTask
);

export const getWorkflowTask = createSelector(
    state => state.entities.workflowEngine,
    workflowEngine => workflowEngine.workflowTask
);
