import React, { useEffect, useState } from "react";
import PageHeader from "../Comman/PageHeader";
import http from "../../Services/httpService";
import auth from "../../Services/authService";
import config from "../../config.json";
import ChecklistItem from "../Comman/ChecklistItem";
import Moment from "react-moment";
import ViewForm from "./ViewForm";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
import { useDispatch, useSelector } from "react-redux";
import { getAppFormLibrary, getCandidateApps, loadAppFormLibrary, loadCandidateApps } from "../../app/store/candidate";
import { getCurrentUser } from "../../app/store/auth";
import ViewFormModal from "../../app/components/ViewFormModal";
import { loadApplication } from "../../app/store/application";
import { getComponentUserStatus, UpdateComponentsUserStatus } from "../../app/store/tools";
//import ViewForm from "../PortalForms/ViewForm";
//import AddPartnerFormLibrary from "./AddPartnerFormLibrary";

const FormLibrary = () => {

  const state = {
    //route: [{ url: "", name: "Home" }],
    appTasklist: [],
    formShowHide: false,
    selectedForm: {},
    workflowTask: {},
    showHide: false,
    selectedApp: {},
  };

  const route = [{ url: "", name: "Home" }];

  const [appFormLibrary, setAppFormLibrary] = useState([]);
  const [workflow, setWorkflow] = useState(null);
  const [wfTask, setWfTask] = useState(null);
  const [viewFormShowHide, setViewFormShowHide] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);

  const candidateAppsSelector = useSelector(getCandidateApps);
  const appFormLibrarySelector = useSelector(getAppFormLibrary);
  const componentUserStatusSelector = useSelector(getComponentUserStatus);

  const dispatch = useDispatch();
  const currentUser = getCurrentUser();

  useEffect(() => {
    dispatch(setCurrentPageTitle("Form"));
    dispatch(setCurrentRoute(route))
  }, []);

  useEffect(() => {
    if (candidateAppsSelector.length === 0)
      dispatch(loadCandidateApps(currentUser.id))
  }, []);

  useEffect(() => {
    if (candidateAppsSelector.length > 0) {
      const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
      if (mainWorkflow.length > 0) {
        const wf = mainWorkflow[0];
        setWorkflow(wf);
        if (appFormLibrarySelector.length === 0)
          dispatch(loadAppFormLibrary(wf.oldWorkflowId, currentUser.id));
      }
    }
  }, [candidateAppsSelector]);

  useEffect(() => {
    if (appFormLibrarySelector.length > 0) {
      const frmLibrary = appFormLibrarySelector
        .filter(x => x.formType !== "ACH Information" && x.formType !== "Insurance Information" && x.sendStatus === true);

      setAppFormLibrary(frmLibrary);

      if (frmLibrary.length > 0) {
        let frmItems = "";
        frmLibrary.map((x, key) => {
          if (key > 0) frmItems += ",";
          frmItems += x.id;
        })

        handleUpdateComponent(frmItems, frmLibrary[0].ciid);
      }

    }
  }, [appFormLibrarySelector]);

  const handleUpdateComponent = (compItems, ciid) => {
    const comp = componentUserStatusSelector.filter(x => x.compId === 22);
    let updCompItems = "";
    if (comp.length > 0) {
      updCompItems = comp[0].compItems;
    }
    const compObj = {
      compName: "Forms",
      performerId: currentUser.id,
      compItems: compItems,
      ciid: ciid,
      wiid: 0,
      iiid: 0,
      compId: 22
    }
    if (compItems !== "" && compItems !== updCompItems) {
      dispatch(UpdateComponentsUserStatus(compObj));
    }
  }

  const handleFormShowHide = (f) => {
    if(f === "save"){
      setViewFormShowHide(false);
      setSelectedForm(null);
      setWfTask(null);
      if (workflow)
        dispatch(loadAppFormLibrary(workflow.oldWorkflowId, currentUser.id));
    }else{
      setViewFormShowHide(!viewFormShowHide);
      if(f?.ciid > 0){
        setSelectedForm(f);
        const task = {ciid: f.ciid, wiid: f.wiid,iiid: f.iiid,};
        setWfTask(task)
      }
      else{
        setSelectedForm(null);
      }
    }
  };

  const handleFormUpdate=()=>{
    // if (workflow)
    //     dispatch(loadAppFormLibrary(workflow.oldWorkflowId, currentUser.id));
    if(selectedForm?.ciid){
      //const task = {ciid: selectedForm.ciid, wiid: selectedForm.wiid,iiid: selectedForm.iiid,};
      //setWfTask(task)
      //dispatch(loadApplication(selectedForm.ciid));
    }
  
  }

  return (
    <React.Fragment>
      <div className="col-md-12 mt-2">
        <div className="card shadow-none border">
          <div className="card-header p-3">
          <i className="fa fa-square wf-phase-box mr-2" aria-hidden="true"></i>
            <span className="h3">
              Forms
            </span>
            <p className="text-red">
              Below is the history of forms received.
            </p>
          </div>
          <div className="card-body p-3">
            <div className="table-responsive">
              <table className="table align-items-center table-flush table-hover table-striped border">
                <thead className="thead-light">
                  <tr>
                    <th>S.No.</th>
                    <th>Date & Time</th>
                    <th>Review Officer</th>
                    <th>View / Edit Forms</th>
                    <th>Remarks</th>
                  </tr>
                </thead>
                <tbody>
                  {appFormLibrary.map((item,key) => (
                    <tr>
                      <td>{key+1}</td>
                      <td>
                        <Moment format="MM-DD-YYYY">
                          {item.createdDateTime}
                        </Moment>
                        <br />
                        <Moment format="h:mm a">
                          {item.createdDateTime}
                        </Moment>
                      </td>
                      <td>
                        {item.performerName}
                        <br />
                        {item.email}
                        <br />
                        {item.performerRole}
                        {item.department}
                        {item.partner}
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-link"
                          onClick={() => handleFormShowHide(item)}
                        >
                          View
                          <span className="badge badge-pill badge-danger position-absolute mt--1">
                            {item.updatedCount}
                          </span>
                        </button>
                      </td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.remarks}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

      <ViewFormModal
        viewFormShowHide={viewFormShowHide}
        handleViewForm={handleFormShowHide}
        workflowTask={wfTask}
        selectedForm={selectedForm}
        handleFormUpdate={handleFormUpdate}
        allowEdit={true}
      ></ViewFormModal>
    </React.Fragment>
  );
}

export default FormLibrary;
