import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "messages",
    initialState: {
        list: [],
        adhocList: [],
        loading: false,
        lastFetch: null,
        addedMessage:false,
        addedNewMessage:false,
        addedAdhocMessage:false,
        msgReadStatus:false,
        msgList:[],
        adhocMsgList:[],
    },
    reducers: {
        appMessagesReceived: (messages, action) => {
            messages.list = action.payload;
            messages.addedMessage = false;
            messages.msgReadStatus = false;
            messages.addedNewMessage = false;
        },
        appAdocMessagesReceived: (messages, action) => {
            messages.adhocList = action.payload;
        },
        appMessageAdded: (messages, action) => {
            messages.addedMessage = action.payload;
        },
        appNewMessageAdded: (messages, action) => {
            messages.addedNewMessage = true;
        },
        appAdhocMessageAdded: (messages, action) => {
            messages.addedAdhocMessage = true;
        },
        appMessageListRecevied: (messages, action) => {
            messages.msgList = action.payload;
            messages.msgReadStatus = false;
            messages.addedNewMessage = false;
        },
        updateMsgListRead: (messages, action) => {
            messages.msgReadStatus = action.payload;
        },
    }
});

export const { appMessagesReceived,appMessageAdded,appAdocMessagesReceived,
    appMessageListRecevied,updateMsgListRead,appAdhocMessageAdded,appNewMessageAdded } = slice.actions;
export default slice.reducer;

export const loadAppMessages = (id) => apiCallBegan({
    url: config.GetCommunication + id,
    onSuccess: appMessagesReceived.type
});

export const loadAppAdhocMessages = (id) => apiCallBegan({
    url: config.GetAdhocMessages + id,
    onSuccess: appAdocMessagesReceived.type
});

export const loadAppMsgList = (id) => apiCallBegan({
    url: config.GetMessages + id,
    onSuccess: appMessageListRecevied.type
});

export const updateMessageRead = (id,userId) => apiCallBegan({
    url: config.UpdateMessageReadStatus + id+"&perfId="+userId,
    method:"patch",
    onSuccess: updateMsgListRead.type
});

export const addAppNewMessage = (data) => apiCallBegan({
    url: config.AddNewMessage,
    method:"post",
    data:data,
    onSuccess: appMessageAdded.type
});

export const addAppMessage = (data) => apiCallBegan({
    url: config.AddMessage,
    method:"post",
    data:data,
    onSuccess: appNewMessageAdded.type
});

export const addAppAdhocNewMessage = (data) => apiCallBegan({
    url: config.AddAdocMessage,
    method:"post",
    data:data,
    onSuccess: appAdhocMessageAdded.type
});

export const getAppMessages = createSelector(
    state => state.entities.messages,
    messages => messages.list
);

export const getAppAdhocMessages = createSelector(
    state => state.entities.messages,
    messages => messages.adhocList
);

export const getAddedMessageStatus = createSelector(
    state => state.entities.messages,
    messages => messages.addedMessage
);

export const getAddedNewMessageStatus = createSelector(
    state => state.entities.messages,
    messages => messages.addedNewMessage
);

export const getAppMsgList = createSelector(
    state => state.entities.messages,
    messages => messages.msgList
);

export const getAppMsgReadStatus = createSelector(
    state => state.entities.messages,
    messages => messages.msgReadStatus
);

