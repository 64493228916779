import React, { useEffect, useState } from "react";
import Joi from "joi-browser"

const useForm = (schema, doSubmit) => {
    
    const [stateData, setStateData] = useState({});
    const [stateErrors,setStateErrors]=useState({});

    const handleChange = ({ currentTarget: input }) => {
        const errors = { ...stateErrors };
        const errorMessage = validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        const data = { ...stateData };
        data[input.name] = input.value;
        setStateData(data);
        setStateErrors(errors);
    };

    const validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        if (!schema[name]) return null;
        const nschema = { [name]: schema[name] };
        const { error } = Joi.validate(obj, nschema);
        // if(!nschema[name]._flags.presence) return null;
        return error ? error.details[0].message : null;
    };

    const validate = () => {
        const options = { abortEarly: false, allowUnknown: true };
        const result = Joi.validate(stateData, schema, options);
        if (!result.error) return null;
        const errors = {};
        for (let item of result.error.details) {
            const reqFlag = schema[item.context.key]?._flags?.presence;
            if (reqFlag === "required") errors[item.path[0]] = item.message;
            //errors[item.path[0]] = item.message;
        }
        if (Object.keys(errors).length > 0) return errors;
        return null;
    };

    const handleSubmit = (btnType) => {
        //e.preventDefault();
        const errors = validate();
        setStateErrors(errors || {});
        if (errors) return;
        doSubmit(btnType);
    };

    return {
        stateData,
        setStateData,
        setStateErrors,
        stateErrors,
        handleChange,
        validate,
        handleSubmit,
    }

}

export default useForm;