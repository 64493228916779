import React from "react";
export default function Multiline(props) {

    const {
        controleId,
        comp,
        stateData: data,
        stateErrors: errors,
        isDisabled,
        handeOnChange,
        getEvaluatedValue,
        encryptionDecryptions,
        handleEncryptionDecryption
    } = props;

    return (
        <div className={`form-group ${comp.isRequired ? "required" : ""}`}>
            <label className="form-control-label" htmlFor={controleId}>
                {comp.label}
            </label>
            <div className="d-flex">
                <textarea
                    className={`form-control ${comp?.cssclass ? "" : comp.cssclass}`}
                    id={controleId}
                    placeholder={comp.label}
                    rows={2}
                    name={controleId}
                    value={comp?.rule ? getEvaluatedValue(comp.rule) : !data[controleId] ? "" : data[controleId]}
                    onChange={handeOnChange}
                    data-encryption={comp.encryptionValidate}
                    disabled={isDisabled}
                    aria-label={comp.label}
                />
                {comp.encryptionValidate && (
                    <button
                        className="btn btn-secondary btn-sm ml-2"
                        onClick={() => handleEncryptionDecryption(controleId)}
                    >
                        {encryptionDecryptions[controleId] === "" ? (
                            <i class="fa fa-eye-slash" aria-hidden="true"></i>
                        ) : (
                            <i class="fa fa-eye" aria-hidden="true"></i>
                        )}
                    </button>
                )}
            </div>
            {errors[controleId] && (
                <div className="alert alert-danger">{errors[controleId]}</div>
            )}
        </div>
    )
}