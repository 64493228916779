import React, { useEffect, useState } from "react"
import Moment from "react-moment"
import { getFieldData } from "../../../Services/appService"
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../store/auth";
import {
    appFormLibraryRecevied,
    getAppDocuments,
    getAppFormLibrary, getAppTasks, getCandidateApps,
    loadAppDocuments,
    loadAppFormLibrary, loadAppRuningTasks, loadCandidateApps
} from "../../store/candidate";
import ViewForm from "../../../Components/ApplicantProcessing/ViewForm";
import AddEditNotes from "../../../Components/ApplicantProcessing/AddEditNotes";
import CandidateInfo from "../CandidateInfo";
import PageHeader from "../../../Components/Comman/PageHeader";
import OnboardingSteps from "./OnboardingSteps";
import PartnerInfo from "../PartnerInfo";
import EnrollmentSteps from "./EnrollmentSteps";
import TimerPopup from "../../../Components/TimerPopup";
import { setCurrentPageTitle, setCurrentRoute } from "../../store/layoutUI";
import UploadDocPopup from "../../../Components/UploadDocPopup";
import ViewFormModal from "../ViewFormModal";
import useDocDownload from "../../hooks/useDocDownload";
import { allowFormLibraryEdit, getFormLibraryEditStatus } from "../../store/forms";
import { getComponentUserStatus, UpdateComponentsUserStatus } from "../../store/tools";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function AchInfo() {

    const route = [
        { name: "Contract Management", url: "" },
        { name: "Enrollment Steps", url: "" },
    ];

    const [appFormLibrary, setAppFormLibrary] = useState([]);
    const [sentDocs, setSentDocs] = useState([]);
    const [wfTask, setWfTask] = useState(null);
    const [workflow, setWorkflow] = useState(null);
    const [formType, setFormType] = useState("Insurance Information");
    const [formShowHide, setFormShowHide] = useState(false);
    const [formObj, setFormObj] = useState(null);
    const [noteShowHide, setNoteShowHide] = useState(false);
    const [timerShowHide, setTimerShowHide] = useState(false);
    const [notesType, setNotesType] = useState("formEditReq");
    const [appTasklist, setAppTasklist] = useState([]);
    const [selectedDocType, setSelectedDocType] = useState("");
    const [docUploadShowHide, setDocUploadShowHide] = useState(false);
    const [reviewerTask, setReviewerTask] = useState(null);
    const [allowEdit, setAllowEdit] = useState(false);

    const candidateAppsSelector = useSelector(getCandidateApps);
    const appTasksSelector = useSelector(getAppTasks);
    const appFormLibrarySelector = useSelector(getAppFormLibrary);
    const appDocumentsSelector = useSelector(getAppDocuments);
    const formLibraryEditStatusSelector = useSelector(getFormLibraryEditStatus);
    const componentUserStatusSelector = useSelector(getComponentUserStatus);

    const [handleDownloadFile] = useDocDownload();

    const dispatch = useDispatch();
    const currentUser = getCurrentUser();

    useEffect(() => {
        dispatch(setCurrentPageTitle("STEP-4 : Insurance Information"));
        dispatch(setCurrentRoute(route))
    }, [])

    useEffect(() => {
        if (candidateAppsSelector.length === 0)
            dispatch(loadCandidateApps(currentUser.id))
    }, []);

    useEffect(() => {
        if (candidateAppsSelector.length > 0) {
            const mainWorkflow = candidateAppsSelector.filter(x => x.workflowType === 1);
            if (mainWorkflow.length > 0) {
                const wf = mainWorkflow[0];
                setWorkflow(wf);
                if (appFormLibrarySelector.length === 0)
                    dispatch(loadAppFormLibrary(wf.oldWorkflowId, currentUser.id));
                if (appTasksSelector.length === 0)
                    dispatch(loadAppRuningTasks(wf.oldWorkflowId, currentUser.id));
                if (appDocumentsSelector.length === 0)
                    dispatch(loadAppDocuments(wf.oldWorkflowId, currentUser.id));
            }
        }
    }, [candidateAppsSelector]);

    useEffect(() => {
        if (appTasksSelector.length > 0) {
            const filterTasks = appTasksSelector.filter(x => x.taskType === 1 && x.wiid > 0);
            if (filterTasks.length > 0) {
                const reviewTasks = filterTasks.filter(x => x.registrationType === "WP");
                if (reviewTasks.length > 0) setReviewerTask(reviewTasks[reviewTasks.length - 1]);
                setAppTasklist(filterTasks);
                setWfTask(filterTasks[0]);
            }
        }
    }, [appTasksSelector]);

    useEffect(() => {
        if (appFormLibrarySelector.length > 0) {
            const frmLibrary = appFormLibrarySelector.filter(x => x.formType === formType);
            setAppFormLibrary(frmLibrary);

            if (frmLibrary.length > 0) {
                let frmItems = "";
                frmLibrary.map((x, key) => {
                    if (key > 0) frmItems += ",";
                    frmItems += x.id;
                });

                handleUpdateComponent(frmItems, frmLibrary[0].ciid);
            }
        }
    }, [appFormLibrarySelector]);

    useEffect(() => {
        if (appDocumentsSelector.length > 0) {
            const appDocs = appDocumentsSelector;
            let sentDocs = appDocs.filter(
                (x) =>
                    x.type === "send" &&
                    x.uploadType !== "Invoice" &&
                    x.docType !== "internal" &&
                    x.docType !== "Messaging"
            );
            setSentDocs(sentDocs);
        }
    }, [appDocumentsSelector])

    const getCIOStatus = (item) => {
        const insuranceDocs = sentDocs.filter(
            (x) => x.docType === `insurance_${item.id}`
        );

        if (insuranceDocs.length === 0) {
            return <button
                className="btn btn-link btn-sm p-0"
                onClick={() =>
                    handleUploadDocModalShowHide(`insurance_${item.id}`)
                }
            >
                Upload
            </button>
        }

        if (insuranceDocs.length > 0) {
            return <>
                {insuranceDocs.map(doc =>
                    <>
                        <button
                            className="btn btn-link btn-sm p-0 text-underline"
                            title="Download Document"
                            onClick={() => handleDownloadFile(doc)
                            }
                        >
                            {doc.documentName}
                        </button>
                        <br />
                        {doc.notes}
                    </>
                )}
            </>
        }
    }

    const handleUploadDocModalShowHide = (type) => {
        if (type === "save" || type === "") {
            setDocUploadShowHide(false);
            setSelectedDocType("");
            if (workflow?.oldWorkflowId)
                dispatch(loadAppDocuments(workflow.oldWorkflowId, currentUser.id));
        } else {
            setDocUploadShowHide(true);
            setSelectedDocType(type);
        }
    };

    const handleFormShowHide = (frm) => {
        if (frm === "save") {
            setFormShowHide(false);
            setFormObj("");
            if (workflow) dispatch(loadAppRuningTasks(workflow.oldWorkflowId, currentUser.id));
        } else {
            setFormShowHide(!formShowHide);
            setFormObj(frm);
            if (frm?.allowEdit === 1) setAllowEdit(true);
        }
    };

    const handleNotesComments = (type, obj) => {
        if (type === "save") {
          setFormObj(null);
          setNoteShowHide(false);
          if (workflow?.oldWorkflowId > 0)
            dispatch(loadAppFormLibrary(workflow.oldWorkflowId, currentUser.id));
        } else {
          setFormObj(obj);
          setNoteShowHide(!noteShowHide);
        }
      };

    const handleTimerModalShowHide = () => {
        setTimerShowHide(!timerShowHide);
    };

    const handleFormUpdate = () => {
        setAllowEdit(false);
        if (formObj?.id > 0)
            dispatch(allowFormLibraryEdit(formObj.id, 0));
    }

    useEffect(() => {
        if (formLibraryEditStatusSelector) {
            if (workflow?.oldWorkflowId > 0)
                dispatch(loadAppFormLibrary(workflow.oldWorkflowId, currentUser.id));
        }
    }, [formLibraryEditStatusSelector])

    const handleUpdateComponent = (compItems, ciid) => {
        const comp = componentUserStatusSelector.filter(x => x.compId === 21);
        let updCompItems = "";
        if (comp.length > 0) {
            updCompItems = comp[0].compItems;
        }
        const compObj = {
            compName: "Insurance",
            performerId: currentUser.id,
            compItems: compItems,
            ciid: ciid,
            wiid: 0,
            iiid: 0,
            compId: 21
        }
        if (compItems !== "" && compItems !== updCompItems) {
            dispatch(UpdateComponentsUserStatus(compObj));
        }
    }

    const getPopover = (notes) => {
        const popover = (
            <Popover id="popover-basic">
                <Popover.Title as="h4">Notes</Popover.Title>
                <Popover.Content>{notes}</Popover.Content>
            </Popover>
        );
        return (
            <OverlayTrigger trigger="click" placement="top" overlay={popover}>
                <button className="btn btn-sm btn-link"> View</button>
            </OverlayTrigger>
        );
    };
    

    return <React.Fragment>

        <div className="col-md-12 form-group mt-2">
            <div className="card shadow-none border">
                <div className="card-header p-3">
                    <div className="row">
                        <div className="col-md-8">
                        <i className="fa fa-square wf-phase2-box mr-2" aria-hidden="true"></i>
                            <span className="h3">
                                Insurance Information
                            </span>
                            <p className="text-red">
                                Click on View/Edit button to complete the Insurance
                                form, and then click on submit button to send the
                                document.
                            </p>
                        </div>
                        <div className="col-md-4 text-right">
                            <button
                                className="btn btn-outline-default btn-sm mt-2"
                                onClick={handleTimerModalShowHide}
                            >
                                <i className="fa fa-exclamation-circle" aria-hidden="true"></i> Expiration Alert
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body p-3">
                    <div className="table-responsive">
                        <table className="table align-items-center table-flush table-hover table-striped border">
                            <thead className="thead-light">
                                <tr>
                                    <th>S.No.</th>
                                    <th>Form Name</th>
                                    <th>Notes</th>
                                    <th>Insurance Information</th>
                                    <th>Last Updated</th>
                                    <th>Action</th>
                                    <th>COI</th>
                                </tr>
                            </thead>
                            <tbody>
                                {appFormLibrary.map((item, key) => (
                                    <tr>
                                        <td>{key + 1}</td>
                                        <td style={{ whiteSpace: "pre-wrap" }}>
                                            {item.formName}
                                        </td>
                                        <td>
                                            {getPopover(item.remarks)}
                                        </td>
                                        <td>
                                            <table>
                                                <tr>
                                                    <th>Type of Insurance</th>
                                                    <th>Expiration Date</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Commercial General Liability
                                                    </td>
                                                    <td>
                                                        {wfTask && getFieldData(
                                                            wfTask.stateData,
                                                            "insurancedetails",
                                                            "Commercial_General_Liability_Expiration"
                                                        ) !== "" && (
                                                                <Moment format="MMM Do YYYY">
                                                                    {getFieldData(
                                                                        wfTask.stateData,
                                                                        "insurancedetails",
                                                                        "Commercial_General_Liability_Expiration"
                                                                    )}
                                                                </Moment>
                                                            )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Automobile Liability</td>
                                                    <td>
                                                        {wfTask && getFieldData(
                                                            wfTask.stateData,
                                                            "insurancedetails",
                                                            "Automobile_Liability_Expiration"
                                                        ) !== "" && (
                                                                <Moment format="MMM Do YYYY">
                                                                    {getFieldData(
                                                                        wfTask.stateData,
                                                                        "insurancedetails",
                                                                        "Automobile_Liability_Expiration"
                                                                    )}
                                                                </Moment>
                                                            )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Umbrella Liability</td>
                                                    <td>
                                                        {wfTask && getFieldData(
                                                            wfTask.stateData,
                                                            "insurancedetails",
                                                            "Umbrella_Liab_Expiration"
                                                        ) !== "" && (
                                                                <Moment format="MMM Do YYYY">
                                                                    {getFieldData(
                                                                        wfTask.stateData,
                                                                        "insurancedetails",
                                                                        "Umbrella_Liab_Expiration"
                                                                    )}
                                                                </Moment>
                                                            )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Workers Compensation and
                                                        Employer's Liability
                                                    </td>
                                                    <td>
                                                        {wfTask && getFieldData(
                                                            wfTask.stateData,
                                                            "insurancedetails",
                                                            "Workers_Compensation_Expiration"
                                                        ) !== "" && (
                                                                <Moment format="MMM Do YYYY">
                                                                    {getFieldData(
                                                                        wfTask.stateData,
                                                                        "insurancedetails",
                                                                        "Workers_Compensation_Expiration"
                                                                    )}
                                                                </Moment>
                                                            )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Cyber and Privacy Security
                                                        Liability
                                                    </td>
                                                    <td>
                                                        {wfTask && getFieldData(
                                                            wfTask.stateData,
                                                            "insurancedetails",
                                                            "Cyber_Privacy_Security_Liab_Expiration"
                                                        ) !== "" && (
                                                                <Moment format="MMM Do YYYY">
                                                                    {getFieldData(
                                                                        wfTask.stateData,
                                                                        "insurancedetails",
                                                                        "Cyber_Privacy_Security_Liab_Expiration"
                                                                    )}
                                                                </Moment>
                                                            )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>E&O Liability</td>
                                                    <td>
                                                        {wfTask && getFieldData(
                                                            wfTask.stateData,
                                                            "insurancedetails",
                                                            "E_and_O_Liab_Expiration"
                                                        ) !== "" && (
                                                                <Moment format="MMM Do YYYY">
                                                                    {getFieldData(
                                                                        wfTask.stateData,
                                                                        "insurancedetails",
                                                                        "E_and_O_Liab_Expiration"
                                                                    )}
                                                                </Moment>
                                                            )}
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td>
                                        {item.lastUpdate && <Moment format="MMM Do YYYY, h:mm a">
                        {item.lastUpdate}
                      </Moment>}
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-link btn-sm p-0"
                                                onClick={() => handleFormShowHide(item)
                                                }
                                            >
                                                {item.allowEdit === 1
                                                    ? "View/Edit"
                                                    : "View"}
                                            </button>
                                            <br />
                                            {item.allowEdit === 0 && (
                                                <button
                                                    className="btn btn-link btn-sm p-0"
                                                    onClick={() => handleNotesComments("",item)}
                                                >
                                                    Request Edit
                                                </button>
                                            )}
                                            {item.allowEdit === 2 && (
                                                <>
                                                    <span>
                                                    Request Sent
                                                    </span>
                                                    <br />
                                                    {item.editRemarks}
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            {getCIOStatus(item)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        {docUploadShowHide &&
            <UploadDocPopup
                uploadDocShowHide={docUploadShowHide}
                handleUploadDocModalShowHide={handleUploadDocModalShowHide}
                runningApplication={[reviewerTask]}
                selectedDocType={selectedDocType}
            ></UploadDocPopup>}

        {formShowHide && <ViewFormModal
            viewFormShowHide={formShowHide}
            handleViewForm={handleFormShowHide}
            workflowTask={wfTask}
            selectedForm={formObj}
            handleFormUpdate={handleFormUpdate}
            allowEdit={allowEdit}
        ></ViewFormModal>}

        {/* {formShowHide && <ViewForm
            formShowHide={formShowHide}
            handleFormShowHide={handleFormShowHide}
            workflowTask={wfTask}
            selectedForm={formObj}
        ></ViewForm>} */}

        {noteShowHide && <AddEditNotes
            noteShowHide={noteShowHide}
            handleNoteShowHide={handleNotesComments}
            notesType={notesType}
            candidateObj={currentUser}
            selectedObj={formObj}
        ></AddEditNotes>}
        {timerShowHide &&
            <TimerPopup
                timerShowHide={timerShowHide}
                handleTimerModalShowHide={handleTimerModalShowHide}
                workflowTask={wfTask}
            ></TimerPopup>}
    </React.Fragment>
}