import { Modal, Button } from "react-bootstrap";
import React, { Component } from "react";
import dict from "../../Services/dictionaryService";

class Dictionary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      definition: [],
      term: "",
    };
  }

  async componentDidMount() {}

  handleOnChange = ({ currentTarget: input }) => {
    this.setState({ term: input.value });
  };

  handleSearchWord = async () => {
    const { term } = this.state;
    this.getDefinition(term);
  };

  getDefinition = async (term) => {
    const { data } = await dict.define(term);
    console.log(data.list);
    this.setState({ definition: data.list });
  };

  handleReset = () => {
    this.setState({ definition: [], term: "" });
  };

  async componentWillReceiveProps(nextProps) {
    //console.log(nextProps);
    const { selectedText } = nextProps;
    //console.log(selectedText);
    if (selectedText !== undefined && selectedText !== "") {
      await this.getDefinition(selectedText);
    }
  }

  handleClose = () => {
    this.props.handledictionaryShowHide();
  };

  handleCloseDictionary=()=>{
    document.body.classList.remove("INDdictionary");
    this.props.handledictionaryShowHide();
  }

  render() {
    const { definition, term } = this.state;
    const {
      dictionaryShowHide,
      handledictionaryShowHide,
      selectedText,
    } = this.props;
    return (
      <Modal
        show={dictionaryShowHide}
        size="lg"
        dialogClassName="modalLeftRight modalRight"
        onHide={this.handleClose}
      >
        <Modal.Header closeButton onClick={handledictionaryShowHide}>
          <Modal.Title>Dictionary</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <div className="row">
            <div className="col-md-12 form-group">
              <input
                type="text"
                className="form-control"
                name="term"
                id="term"
                value={selectedText}
                onChange={this.handleOnChange}
              />
            </div>
            <div className="col-md-12 form-group text-center">
              <button
                className="btn btn-success btn-sm"
                onClick={this.handleSearchWord}
              >
                Search
              </button>
              <button
                className="btn btn-warning btn-sm"
                onClick={this.handleReset}
              >
                Reset
              </button>
              <button className="btn btn-danger btn-sm" onClick={this.handleCloseDictionary}>
                 Close
              </button>
            </div>
            <div className="col-md-12">
              <ul className="list-group">
                {definition.map((item) => (
                  <React.Fragment>
                    {/* <li className="list-group-item">
                      <span className="h4">Defination : </span>
                      {item.definition}
                    </li> */}
                    <li className="list-group-item">
                      <span className="h4">Example : </span>
                      {item.example}
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="danger" onClick={handledictionaryShowHide}>
            <i className="fa fa-times" aria-hidden="true"></i> Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default Dictionary;
