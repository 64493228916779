import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import config from "../config";
import http from "../Services/httpService";
import Moment from "react-moment";

class TimerPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tmrMessages: [],
    };
  }

  componentDidMount() {}

  async componentWillReceiveProps(nextProps) {
    const { workflowTask } = nextProps;
    //console.log(workflowTask);
    if (workflowTask && workflowTask !== "") {
      const { data: tmrMessages } = await http.get(
        config.GetTimerMessages + workflowTask.ciid
      );
      this.setState({ tmrMessages });
    }
  }
  render() {
    const { tmrMessages } = this.state;
    const { timerShowHide, handleTimerModalShowHide } = this.props;
    return (
      <Modal show={timerShowHide} size="xl">
        <Modal.Header closeButton onHide={handleTimerModalShowHide}>
          <Modal.Title>Timer Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-striped align-items-center table-flush border">
                  <thead className="thead-light">
                    <tr>
                      <th>S.No.</th>
                      <th>Date & Time</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tmrMessages.map((item, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>
                          <Moment format="MM Do YYYY, h:mm a">
                            {item.startDateTime}
                          </Moment>
                        </td>
                        <td style={{ whiteSpace: "pre-wrap" }}>
                          {decodeURIComponent(item.message)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={handleTimerModalShowHide}>
          <i className="fa fa-times" aria-hidden="true"></i> Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default TimerPopup;
