import React, { Component } from "react";
import auth from "../../Services/authService";
import http from "../../Services/httpService";
import config from "../../config.json";

class ClientHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      user: {},
      clientDetails: "",
    };
  }

  async componentDidMount() {
    //const { data: clientDetails } = await http.get(config.GetClientDetails);
    // this.setState({ clientDetails });
  }

  render() {
    const { clientDetails } = this.state;
    return (
      <React.Fragment>
        <div
          className="col-md-12"
          style={{
            backgroundColor: "white",
            textAlign: "left",
            //borderBottom: "1px solid #ccc",
            backgroundColor: "white",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <img style={{ height: "62px" }} src={`/assets/Img/logo.png`} />
        </div>
        <div className="col-md-12 d-flex align-items-center justify-content-center border border-left-0 border-right-0">
          <h3 className="text-uppercase mb-0 p-1">
            HUMAN RESOURCES ONBOARDING AND CONTRACT CASE MANAGEMENT SYSTEM
          </h3>
        </div>
      </React.Fragment>
    );
  }
}

export default ClientHeader;
