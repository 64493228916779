import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";

const GeneratePdf = (props) => {

    const { handlepdfShowHide, pdfShowHide, 
        selectedCol, selectedColData } = props;

    const [columns, setColumns] = useState([]);
    const [colData, setColData] = useState([]);

    useEffect(() => {
        if (!pdfShowHide) {
            setColumns([]);
            setColData([]);
        }
    }, [pdfShowHide])

    const handleChkChange = ({ currentTarget }) => {
        const id = currentTarget.name.split("_")[1];
        const col = [...columns];
        const colD = [...colData];

        if (currentTarget.checked) {
            const obj = selectedCol[0][id];
            col.push(obj);
            colD.push(id);
        } else {
            const index = colD.findIndex((x) => x === id);
            col.splice(index, 1);
            colD.splice(index, 1);
        }
        setColumns(col);
        setColData(colD);
    };

    const exportPDF = () => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape
        //const marginLeft = 10;
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = "";
        const headers = [columns];
        const data = [];
        selectedColData.map((item) => {
            let dt = [];
            colData.map((c) => dt.push(item[c]));
            data.push(dt);
        });
        let content = {
            startY: 50,
            head: headers,
            body: data,
        };
        //doc.text(title, marginLeft, 10);
        doc.autoTable(content);
        doc.save(`Report.pdf`);
    };

    return (
        <Modal show={pdfShowHide} size="xl">
            <Modal.Header closeButton onHide={() => handlepdfShowHide("")}>
                <Modal.Title>Generate PDF</Modal.Title>
            </Modal.Header>
            <Modal.Body className="border">
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-bordered">
                            <thead className="thead-light">
                                <tr>
                                    <th>Select</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedCol.length > 0 &&
                                    selectedCol[0].map((c, key) => (
                                        <tr>
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    name={`col_${key}`}
                                                    id={`col_${key}`}
                                                    onChange={handleChkChange}
                                                />
                                            </td>
                                            <td>{c}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-sm btn-outline-danger" onClick={() => handlepdfShowHide("")}>
                    <i className="fa fa-times" aria-hidden="true"></i> Close
                </button>
                <button className="btn btn-sm btn-outline-primary" onClick={exportPDF}>
                    <i className="fa fa-cog" aria-hidden="true"></i> Generate
                </button>
            </Modal.Footer>
        </Modal>
    );
}

export default GeneratePdf;
