import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "reports",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        complianceRpt:[],
        monthlyPayCandidates:[]
    },
    reducers: {
        complianceReportReceived: (reports, action) => {
            reports.complianceRpt=action.payload;
        },
        monthlyPayCandidatesReceived: (reports, action) => {
            reports.monthlyPayCandidates=action.payload;
        },
    }
});

export const { complianceReportReceived,monthlyPayCandidatesReceived } = slice.actions;
export default slice.reducer;

export const loadComplianceReport = (id) => apiCallBegan({
    url: config.GetComplianceReport + id,
    onSuccess: complianceReportReceived.type
});

export const getComplianceReport = createSelector(
    state => state.entities.reports,
    reports => reports.complianceRpt
);

export const loadMonthlyPayCandidates = (id,type) => apiCallBegan({
    url: config.GetCandidatePayReport + id + "&type="+type,
    onSuccess: monthlyPayCandidatesReceived.type
});

export const getMonthlyPayCandidates = createSelector(
    state => state.entities.reports,
    reports => reports.monthlyPayCandidates
);

