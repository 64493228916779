import React from "react";

const CheckBox = (props) => {

    const {
        controleId,
        comp,
        stateData: data,
        stateErrors: errors,
        isDisabled,
        handleChange,
        ddTypeCodMngr
    } = props;

    const handleCheckBoxChecked = ({ target: input }) => {
        if (!input.checked) {
            input.value = "";
        }
        handleChange(input);
    };

    const getCheckboxCodeManager = (id) => {
        if (ddTypeCodMngr) {
            const ddType = ddTypeCodMngr.filter((x) => x.controlId === id);
            if (ddType.length > 0) return ddType[0].optionData;
        }
        return [];
    };

    const chkCodemanager = getCheckboxCodeManager(controleId);

    return <>
        <div className="form-group">
            {chkCodemanager.length > 0 ? (
                chkCodemanager.map((item, key) => (
                    <div className="custom-control custom-checkbox mt-2">
                        <input
                            className={!comp.cssclass ? comp.cssclass + " custom-control-input" : "custom-control-input"}
                            id={controleId + key}
                            type="checkbox"
                            name={controleId + key}
                            value={item.name}
                            onChange={handleCheckBoxChecked}
                            defaultChecked={data[controleId + key] === item.name}
                            disabled={isDisabled}
                            aria-label={item.name}
                        />

                        <label
                            className="custom-control-label pt-1 form-control-label"
                            htmlFor={controleId + key}
                        >
                            {comp.isRequired && (
                                <i
                                    className="fa fa-asterisk"
                                    aria-hidden="true"
                                    style={{
                                        fontSize: "50%",
                                        position: "absolute",
                                        marginLeft: "-10px",
                                        color: "#d00",
                                        fontSize: "8px",
                                    }}
                                ></i>
                            )}{" "}
                            {item.name}
                        </label>
                        {errors[controleId + key] && (
                            <div className="alert alert-danger">
                                {errors[controleId + key]}
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <div className="custom-control custom-checkbox mt-2">
                    <input
                        className={!comp.cssclass? comp.cssclass + " custom-control-input": "custom-control-input"}
                        id={controleId}
                        type="checkbox"
                        name={controleId}
                        value="Yes"
                        onChange={handleCheckBoxChecked}
                        defaultChecked={data[controleId] === "Yes"}
                        disabled={isDisabled}
                        aria-label={comp.label}
                    />

                    <label
                        className="custom-control-label pt-1 form-control-label"
                        htmlFor={controleId}
                    >
                        {comp.isRequired && (
                            <i
                                className="fa fa-asterisk"
                                aria-hidden="true"
                                style={{
                                    fontSize: "50%",
                                    position: "absolute",
                                    marginLeft: "-10px",
                                    color: "#d00",
                                    fontSize: "8px",
                                }}
                            ></i>
                        )}{" "}
                        {comp.label}
                    </label>
                    {errors[controleId] && (
                        <div className="alert alert-danger">{errors[controleId]}</div>
                    )}
                </div>
            )}
        </div>
    </>

}

export default CheckBox;