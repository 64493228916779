import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { getLoggedUserToken, getLoggedUser, getRefreshToken, getCurrentUser } from "../../store/auth";

const RequireAuth = () => {
    const [open, setOpen] = useState(true);
    const token = getCurrentUser();// useSelector(getLoggedUserToken);
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        console.log(token);
        //if (!token) dispatch(getRefreshToken());
    }, [])
    return (
        token ? <>
            <Outlet />
        </> : <Navigate to="/login" state={{ from: location }} replace />);
}

export default RequireAuth;