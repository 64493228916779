import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "documents",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        sendStatus:false,
        esignSendStatus:false,
        deleteStatus:false,
        purgeStatus:false,
        rollbackStatus:false,
        docDownloadData:null,
        reqDocuments:[],
        invDocuments:[],
        reqDocAddedStatus:false,
        signedDocument:null,
        appDocUploadStatus:null,
        poOrderDownloadData:null
    },
    reducers: {
        documentReceived: (documents, action) => {
            documents.list = action.payload;
            documents.sendStatus = false;
            documents.esignSendStatus = false;
            documents.deleteStatus = false;
            documents.purgeStatus = false;
            documents.rollbackStatus = false;
            documents.appDocUploadStatus = null;
        },
        documentSent:(documents, action)=>{
            documents.sendStatus=true;
        },
        documentDeleted:(documents, action)=>{
            documents.deleteStatus=true;
        },
        documentPurged:(documents, action)=>{
            documents.purgeStatus=true;
        },
        documentRollBacked:(documents, action)=>{
            documents.rollbackStatus=true;
        },
        esignDocumentSent:(documents, action)=>{
            documents.esignSendStatus=true;
        },
        reqDocumentReceived: (documents, action) => {
            documents.list = action.payload;
        },
        documentDownloaded:(documents, action)=>{
            documents.docDownloadData=action.payload;
        },
        reqDocumentAdded:(documents, action)=>{
            documents.reqDocAddedStatus=true;
        },
        reqDocumentsReceived:(documents, action)=>{
            documents.reqDocuments=action.payload;
            documents.reqDocAddedStatus=false;
        },
        signedDocumentReceived:(documents, action)=>{
            documents.signedDocument=action.payload;
        },
        appDocumentUploaded: (documents, action) => {
            documents.appDocUploadStatus = action.payload;
        },
        invDocumentsRecevied: (documents, action) => {
            documents.invDocuments = action.payload;
        },
        poOrderDownloadDataRecevied: (documents, action) => {
            documents.poOrderDownloadData = action.payload;
        }
    }
});

export const { documentReceived,documentSent, reqDocumentReceived,
    esignDocumentSent,documentDeleted,documentPurged,documentRollBacked,
    documentDownloaded,reqDocumentsReceived,reqDocumentAdded,signedDocumentReceived,
    appDocumentUploaded,invDocumentsRecevied,poOrderDownloadDataRecevied } = slice.actions;
export default slice.reducer;

export const loadDocuments = (id) => apiCallBegan({
    url: config.GetApplicationDocuments + id,
    onSuccess: documentReceived.type
});

export const loadInvoiceDocuments = (id) => apiCallBegan({
    url: config.GetAppInvoiceDocuments + id,
    onSuccess: invDocumentsRecevied.type
});

export const loadSignedDocument = (id) => apiCallBegan({
    url: config.DownloadSignedDocument + id,
    responseType: "blob",
    onSuccess: signedDocumentReceived.type
});

export const sendDocuments = (data) => apiCallBegan({
    url: config.UpdateDocSentStatus,
    method:"post",
    data:data,
    onSuccess: documentSent.type
});

export const sendeSignDocuments = (data) => apiCallBegan({
    url: config.SendeSignDocument,
    method:"post",
    data:data,
    onSuccess: esignDocumentSent.type
});

export const deleteDocument = (id) => apiCallBegan({
    url: config.DeleteUploadedDocument + id,
    onSuccess: documentDeleted.type
});

export const purgeDocument = (id) => apiCallBegan({
    url: config.PurgeUploadedDocument + id,
    onSuccess: documentPurged.type
});

export const rollbackDocument = (id) => apiCallBegan({
    url: config.RollbackUploadedDocument + id,
    onSuccess: documentRollBacked.type
});

export const loadReqDocuments = (id) => apiCallBegan({
    url: config.GetRequiredDocument + id,
    onSuccess: reqDocumentsReceived.type
});

export const addReqDocuments = (data) => apiCallBegan({
    url: config.AddRequiredDocument,
    method:"post",
    data:data,
    onSuccess: reqDocumentAdded.type
});

export const downloadDocument=(id)=>apiCallBegan({
    url:config.DownloadDocument+id,
    responseType:"blob",
    onSuccess:documentDownloaded.type
})

export const uploadAppDocuments = (data) => apiCallBegan({
    url: config.ApplicantUploadDocument,
    method:"post",
    data:data,
    onSuccess: appDocumentUploaded.type
});

export const loadPoOrderdownloadDocument=(id,type)=>apiCallBegan({
    url:config.GetPoOrderDocument+id+"&type=" + type,
    responseType:"blob",
    onSuccess:poOrderDownloadDataRecevied.type
})

export const getDocuments = createSelector(
    state => state.entities.documents,
    documents => documents.list
);

export const getDocumentsSendStatus = createSelector(
    state => state.entities.documents,
    documents => documents.sendStatus
);
export const getesignDocumentsSendStatus = createSelector(
    state => state.entities.documents,
    documents => documents.esignSendStatus
);

export const getReqDocuments = createSelector(
    state => state.entities.documents,
    documents => documents.reqDocuments
);

export const getReqDocAddedStatus = createSelector(
    state => state.entities.documents,
    documents => documents.reqDocAddedStatus
);

export const getDocumentDeleteStatus = createSelector(
    state => state.entities.documents,
    documents => documents.deleteStatus
);

export const getDocumentPurgeStatus = createSelector(
    state => state.entities.documents,
    documents => documents.purgeStatus
);

export const getDocumentRollbackStatus = createSelector(
    state => state.entities.documents,
    documents => documents.rollbackStatus
);

export const getDownloadedDocumentData = createSelector(
    state => state.entities.documents,
    documents => documents.docDownloadData
);

export const getSignedDocumentData = createSelector(
    state => state.entities.documents,
    documents => documents.signedDocument
);

export const getAppDocUploadStatus = createSelector(
    state => state.entities.documents,
    documents => documents.appDocUploadStatus
);

export const getInvDocuments = createSelector(
    state => state.entities.documents,
    documents => documents.invDocuments
);

export const getPoOrderDocumentData = createSelector(
    state => state.entities.documents,
    documents => documents.poOrderDownloadData
);

