import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "layoutUI",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        pageTitle:"",
        route:[]
    },
    reducers: {
        setCurrentPageTitle: (layoutUI, action) => {
            layoutUI.pageTitle=action.payload;
        },
        setCurrentRoute: (layoutUI, action) => {
            layoutUI.route = action.payload;
        },
        ruleAppDataReceived: (layoutUI, action) => {
            layoutUI.ruleAppData = action.payload;
        }
    }
});

export const { setCurrentPageTitle, setCurrentRoute } = slice.actions;
export default slice.reducer;

export const getPageTitle = createSelector(
    state => state.entities.layoutUI,
    layoutUI => layoutUI.pageTitle
);

export const getPageRoute = createSelector(
    state => state.entities.layoutUI,
    layoutUI => layoutUI.route
);

