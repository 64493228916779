import React, { useEffect, useState } from "react";
import Form from "../Comman/Form";
import ForgotPwd from "./ForgotPwd";
import ForgotUserName from "./ForgotUserName";
import { Link, useLocation, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [validId, setValidId] = useState("");
  const [forgotUser, setForgotUser] = useState(false);
  const [forgotPwd, setForgotPwd] = useState(false);
  const [forgotMain, setForgotMain] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const state = location.state;
    if (state?.validId)
      setValidId(state.validId);
  }, [])

  const handleUserName = () => {
    setForgotUser(true);
    setForgotMain(false);
  };

  const handlePassword = () => {
    setForgotPwd(true);
    setForgotMain(false);
  };

  const handleBackUser = () => {
    setForgotMain(true);
    setForgotUser(false);
  };

  const handlePwdBack = () => {
    setForgotMain(true);
    setForgotPwd(false);
  };

  const handleBackLogin = () => {
    navigate("/login", { state: { validId: validId } });
  }

  let urlData = "";
  return (
    <React.Fragment>
      <div className="col-md-12">
        <div className="row align-items-center" style={{ height: "calc(100vh - 289px)" }}>
          <div className="col-md-3"></div>
          <div className="col-lg-6 ">
            <div
              className="card shadow-none border p-3 "
              style={{ backgroundColor: "#fafafa", marginTop: "30px" }}
            >
              <h1 className="text-black text-uppercase text-center mt-3 mb-0">
                {forgotUser && "Forgot Username?"}
                {forgotPwd && "Forgot Password?"}
                {!forgotPwd && !forgotUser && "Forgot Username / Password?"}
              </h1>
              <div className="card-body p-3">
                {forgotMain && (
                  <div className="row" >
                    <div className="form-group col-md-12">
                      <button
                        //to={`/Login${search}`}
                        // to={{
                        //   pathname: "/Login",
                        //   data: urlData,
                        // }}
                        onClick={handleBackLogin}
                        className="btn btn-link pl-0"
                      >
                        <i
                          className="fa fa-long-arrow-left"
                          aria-hidden="true"
                        ></i>
                        Back
                      </button>
                    </div>
                    <div className="col-md-12 form-group">
                      <button
                        className="btn btn-danger w-100"
                        onClick={handleUserName}
                      >
                        I Forgot My Username
                      </button>
                    </div>
                    <div className="col-md-12 form-group">
                      <button
                        className="btn btn-danger w-100"
                        onClick={handlePassword}
                      >
                        I Forgot My Password
                      </button>
                    </div>
                  </div>
                )}

                {forgotUser && (
                  <ForgotUserName
                    handleBackUser={handleBackUser}
                    forgotType="Username"
                    urlData={urlData}
                  ></ForgotUserName>
                )}
                {forgotPwd && (
                  <ForgotPwd
                    handlePwdBack={handlePwdBack}
                    urlData={urlData}
                  ></ForgotPwd>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-3"></div>
          <div className="col-lg-6 mt-3">
            <div
              className="card shadow-none border"
              style={{ backgroundColor: "#fafafa" }}
            >
              <div className="card-body text-center p-2">
                <p>
                  Customer Support :{" "}
                  <a href="mailto:support@workfinity.com">
                    Support1@workfinity.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
}

export default ForgotPassword;
