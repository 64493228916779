import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";
import { act } from "react";

const slice = createSlice({
    name: "users",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        addedMessage:false, 
        emailConfig:null,
        emailConfigUpdateStatus:false,
        activatedDeactivatedStatus:false,
        accessLogHistory:[],
        mailHistory:[],
        mailSendStatus:false,
        mailDeleteStatus:false,
        mailAddStatus:false,
        mailUpdateStatus:false,
        secQuestions:[],    
        secQuestionUpdatedStatus:null
    },
    reducers: {
        usersReceived:(users,action)=>{
            users.list=action.payload;
            users.addedMessage=false;
        },
        userAdded:(users,action)=>{
            users.addedMessage=true;
        },
        filterUsersReceived: (users, action) => {
            users.filterList = action.payload;
        },
        emailConfigReceived: (users, action) => {
            users.emailConfig = action.payload;
            users.emailConfigUpdateStatus=false;
        },
        emailConfigUpdated: (users, action) => {
            users.emailConfigUpdateStatus = true;
        },
        userActiveDeactiveUpdated:(users,action)=>{
            users.activatedDeactivatedStatus=true;
        },
        userAccessLogHistoryReceived: (users, action) => {
            users.accessLogHistory = action.payload;
            users.activatedDeactivatedStatus = false;
        },
        mailHistoryReceived: (users, action) => {
            users.mailHistory = action.payload;
            users.mailSendStatus = false;
            users.mailDeleteStatus = false;
            users.mailUpdateStatus = false;
        },
        emailMessageSent: (users, action) => {
            users.mailSendStatus = true;
        },
        emailMessageSent: (users, action) => {
            users.mailSendStatus = action.payload;
        },
        emailMessageDeleted: (users, action) => {
            users.mailDeleteStatus = true;
        },
        emailMessageAdded: (users, action) => {
            users.mailAddStatus = action.payload;
        },
        emailMessageUdated: (users, action) => {
            users.mailUpdateStatus = action.payload;
        },
        secQuestionsReceived: (users, action) => {
            users.secQuestions = action.payload;
        },
        secQuestionsUpdated: (users, action) => {
            users.secQuestionUpdatedStatus = action.payload;
        },
    }
});

export const { usersReceived,userAdded,
    filterUsersReceived,emailConfigReceived,emailConfigUpdated,
    userActiveDeactiveUpdated,userAccessLogHistoryReceived,
    mailHistoryReceived,emailMessageSent,emailMessageDeleted,
    emailMessageAdded,emailMessageUdated,secQuestionsReceived,secQuestionsUpdated } = slice.actions;
export default slice.reducer;

export const loadRegisteredUsers = (pageSize, pageIndex, type) => apiCallBegan({
    url: config.GetRegisteredUsers + pageSize + "&pageNumber=" + pageIndex + "&type=" + type,
    onSuccess: usersReceived.type
});

export const loadEmailConfig = () => apiCallBegan({
    url: config.GetEmailSetup,
    onSuccess: emailConfigReceived.type
});
export const loadSecQuestions = (type) => apiCallBegan({
    url: config.GetCodemanager+type,
    onSuccess: secQuestionsReceived.type
});

export const loadAllEmailHistory = (id) => apiCallBegan({
    url: config.GetAllEmails+id,
    onSuccess: mailHistoryReceived.type
});

export const SendEmailMessage = (id) => apiCallBegan({
    url: config.SendMailMessage+id,
    onSuccess: emailMessageSent.type
});

export const DeleteEmailMessage = (id) => apiCallBegan({
    url: config.DeleteMailMessage + id,
    onSuccess: emailMessageDeleted.type
});

export const updateEmailConfig = (data) => apiCallBegan({
    url: config.SaveEmailSetup,
    method:"post",
    data:data,
    onSuccess: emailConfigUpdated.type
});

export const addNewEmailMessage = (data) => apiCallBegan({
    url: config.AddNewEmail,
    method:"post",
    data:data,
    onSuccess: emailMessageAdded.type
});

export const updateEmailMessage = (data) => apiCallBegan({
    url: config.UpdateMailMessage,
    method:"post",
    data:data,
    onSuccess: emailMessageUdated.type
});

export const loadRegisteredUsersByFilter = (data) => apiCallBegan({
    url: config.GetRegisteredUsersByFilter,
    method: "post",
    data: data,
    onSuccess: filterUsersReceived.type
}); 

export const addUserSecurityQuestion = (data) => apiCallBegan({
    url: config.AddSecurityQuestion,
    method: "post",
    data: data,
    onSuccess: secQuestionsUpdated.type
}); 

export const loadUserAccessLogHistory = (id, type) => apiCallBegan({
    url: config.GetAccessRightHistory + id + "&type=" + type,
    onSuccess: userAccessLogHistoryReceived.type
});

export const updateUserActiveDeactive = (data) => apiCallBegan({
    url: config.SetActivateDeactivate,
    method:"post",
    data:data,
    onSuccess: userActiveDeactiveUpdated.type
});

export const getRegisteredUsers = createSelector(
    state => state.entities.users,
    users => users.list
);

export const getFilterAllUsers = createSelector(
    state => state.entities.users,
    users => users.filterList
);

export const getUserAccessHistory = createSelector(
    state => state.entities.users,
    users => users.accessLogHistory
);

export const getActivateDeactivateUpdateStatus = createSelector(
    state => state.entities.users,
    users => users.activatedDeactivatedStatus
);

export const getEmailConfig = createSelector(
    state => state.entities.users,
    users => users.emailConfig
);

export const getEmailConfigUpdateStatus = createSelector(
    state => state.entities.users,
    users => users.emailConfigUpdateStatus
);

export const getAllEmailHistory = createSelector(
    state => state.entities.users,
    users => users.mailHistory
);

export const getSentEmailStatus = createSelector(
    state => state.entities.users,
    users => users.mailSendStatus
);

export const getDeleteEmailStatus = createSelector(
    state => state.entities.users,
    users => users.mailDeleteStatus
);

export const getEmailAddedStatus = createSelector(
    state => state.entities.users,
    users => users.mailAddStatus
);

export const getEmailUpdatedStatus = createSelector(
    state => state.entities.users,
    users => users.mailUpdateStatus
);

export const getSecQuestions = createSelector(
    state => state.entities.users,
    users => users.secQuestions
);
export const getSecQuestionUpdatedStatus = createSelector(
    state => state.entities.users,
    users => users.secQuestionUpdatedStatus
);

