import React, { Component, useEffect, useState } from "react";
import Moment from "react-moment";
import PageHeader from "./Comman/PageHeader";
import config from "../config.json";
import http from "../Services/httpService";
import auth from "../Services/authService";
import PartnerInfo from "../app/components/PartnerInfo";
import CandidateInfo from "../app/components/CandidateInfo";
import { getCurrentUser, getLoggedUserDetails, loadUserDetails } from "../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getComplianceReport, loadComplianceReport } from "../app/store/report";
import useDocDownload from "../app/hooks/useDocDownload";
import { getComponentUserStatus, getToolsCount, UpdateComponentsUserStatus } from "../app/store/tools";

const ComplianceRpt = () => {

  const [complianceData, setComplianceData] = useState([]);
  const [partnerCompliance, setPartnerCompliance] = useState(false);

  const userDetailsSelector = useSelector(getLoggedUserDetails);
  const complianceReportSelector = useSelector(getComplianceReport);
  const componentUserStatusSelector = useSelector(getComponentUserStatus);
    const toolsCountSelector = useSelector(getToolsCount);

  const [handleDownloadFile] = useDocDownload();

  const usrType = localStorage.getItem("userType");

  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    if (usrType !== "candidate") {
      if (!userDetailsSelector)
        dispatch(loadUserDetails(currentUser.id));
      setPartnerCompliance(true);
    }
    else {
      dispatch(loadComplianceReport(currentUser.id));
    }
  }, []);

  useEffect(() => {
    if (userDetailsSelector && partnerCompliance) {
      dispatch(loadComplianceReport(userDetailsSelector.partnerid));
    }
  }, [userDetailsSelector]);

  useEffect(() => {
    if (complianceReportSelector.length > 0) {
      setComplianceData(complianceReportSelector);
      let compItems = "";
      complianceReportSelector.map((x, key) => {
          if (key > 0) compItems += ",";
          compItems += x.id;
      });

      const appObj = toolsCountSelector.filter(x => x.key === 0);
      let appCiid = 0;
      if (appObj.length > 0)
        appCiid = appObj[0].value;

      handleUpdateComponent(compItems, appCiid);
    }
  }, [complianceReportSelector]);

  const handleUpdateComponent = (compItems, ciid) => {
    const comp = componentUserStatusSelector.filter(x => x.compId === 25);
    let updCompItems = "";
    if (comp.length > 0) {
      updCompItems = comp[0].compItems;
    }
    const compObj = {
      compName: "Compliance",
      performerId: currentUser.id,
      compItems: compItems,
      ciid: ciid,
      wiid: 0,
      iiid: 0,
      compId: 25
    }
    if (compItems !== "" && compItems !== updCompItems) {
      dispatch(UpdateComponentsUserStatus(compObj));
    }
  }

  return (
    <React.Fragment>
      <div className="col-md-12 mt-2">
        <ul className="list-group">
          <li className="list-group-item">
          <i className="fa fa-square wf-phase-box mr-2" aria-hidden="true"></i>
            <span className="h3">
              Compliance Documents
            </span>
            <p className="text-red">
              Listed below are the compliance documents sent to you.
            </p>
          </li>
          <li className="list-group-item">
            <div className="table-responsive">
              <table className="table align-items-center table-flush table-hover table-striped border">
                <thead className="thead-light">
                  <tr>
                    <th>S.No.</th>
                    <th>Date & Time Added</th>
                    <th>Year</th>
                    <th>Notes</th>
                    <th>Document Name</th>
                  </tr>
                </thead>
                <tbody>
                  {complianceData.map((item, key) => (
                    <tr>
                      <td>{key + 1}</td>
                      <td>
                        <Moment format="MMM Do YYYY, h:mm a">
                          {item.startDateTime}
                        </Moment>
                      </td>
                      <td>{item.year}</td>
                      <td>{item.notes}</td>
                      <td>
                        {item.complianceDocs.map((doc) => (
                          <button
                            className="btn btn-sm btn-link"
                            onClick={() => handleDownloadFile(doc)}
                          >
                            {doc.documentName}
                          </button>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}

export default ComplianceRpt;
