import React, { Component } from "react";
import DashboardPageHeader from "./DashboardPageHeader";
import http from "../Services/httpService";
import auth from "../Services/authService";
import config from "../config.json";
import LineChart from "./Charts/line";
import BarChart from "./Charts/bar";
import PageHeader from "./Comman/PageHeader";
import ClientHeader from "./Comman/ClientHeader";
import { Link } from "react-router-dom";
import AddSecurityQuestion from "./ApplicantProcessing/AddSecurityQuestion";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: [],
      application: 50,
      users: 53,
      documents: 63,
      closed: 36,
      Application: 32,
      review: 56,
      approved: 96,
      pending: 65,
      withdrawn: 69,
      latestApplication: [],
      applicants: 62,
      fees: 653,
      workflowPerformer: 54,
      partnerPerformer: 63,
      dashboardData: {},
      applicationByMonth: {},
      selectedKey: -1,
      user: {},
      cipReports: [],
      workflowPhases: [],
      withdrawFundData: {},
      fundedData: {},
      securityShowHide: false,
      userDetails: "",
    };
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    const userDetails = await auth.getCurrentUserDetails(user.id);
    if (userDetails.hintQuestion === null) {
      this.setState({ securityShowHide: true, userDetails });
    } else {
      if (userDetails.maxtasks === null) {
        this.setState({ welShowHide: true });
        const { data: result } = await http.get(
          config.UpdateLoginStatus + user.id
        );
      }
    }

    let usrType = localStorage.getItem("userType");
    if (usrType === "PP") {
      usrType = userDetails?.partnerType;
      if (userDetails?.partnerType === "Sub-Contractors")
        usrType = "Subcontractor";
    }
    usrType = usrType.toLocaleLowerCase();

    const { data: dashboardData } = await http.get(
      config.GetPartnerDashboardData +
        userDetails.partnerid +
        "&type=" +
        usrType
    );
    const { data: applicationByMonth } = await http.get(
      config.GetPartnerReceivedAppsByMonth +
        userDetails.partnerid +
        "&type=" +
        usrType
    );

    this.setState({
      dashboardData,
      user,
      applicationByMonth,
    });
  }

  getWfPhasesColor(phase) {
    switch (phase) {
      case "Closed":
        return "btn-default w-75";
      case "Review":
        return "btn-secondary bg-yellow border-0 w-75";
      case "Application":
        return "btn-primary w-75";
      case "Pending":
        return "btn-info w-75";
      case "Approved":
        return "btn-success w-75";
      case "Denied":
        return "btn-danger w-75";
      case "Withdrawn":
        return "btn-warning w-75";
    }
  }

  handleSelect = (e) => {
    this.setState({ selectedKey: e });
  };

  getWfPhasesItem(name) {
    const { latestApplication } = this.state;
    const count = latestApplication.filter(
      (x) => x.workflowPhase === name
    ).length;
    const color = this.getWfPhaseClassName(name);
    return (
      <li className="list-group-item px-0">
        <div className="row align-items-center">
          <div className="col-auto">
            <a href="!#" className={`avatar rounded-circle bg-${color}`}></a>
          </div>
          <div className="col">
            <h5>
              {name} ({count})
            </h5>
            <div className="progress progress-sm mb-0">
              <div
                className={`progress-bar bg-${color}`}
                role="progressbar"
                aria-valuenow={60}
                aria-valuemin={0}
                aria-valuemax={100}
                style={{ width: "60%" }}
              />
            </div>
          </div>
        </div>
      </li>
    );
  }

  getWfPhaseClassName(name) {
    switch (name) {
      case "Application":
        return "primary";
      case "Review":
        return "yellow";
      case "Pending":
        return "info";
      case "Approved":
        return "success";
      case "Denied":
        return "danger";
      case "Withdrawn":
        return "warning";
      case "Closed":
        return "default";
      case "Underwriting Phase":
        return "lighter";
    }
  }

  handleSecurityShowHide = () => {
    this.setState({ securityShowHide: !this.state.securityShowHide });
  };

  render() {
    const { users, documents } = this.state.dashboardData;

    const {
      latestApplication,
      applicationByMonth,
      user,
      workflowPhases: wfPhases,
      dashboardData,
      route,
      securityShowHide,
      userDetails,
    } = this.state;

    const usrType = localStorage.getItem("userType");
    if (usrType === "candidate") return <Link to="/" />;

    return (
      <React.Fragment>
       
        <DashboardPageHeader
          application={latestApplication}
          users={users}
          documents={documents}
          dashboardData={dashboardData}
        ></DashboardPageHeader>
        <div className="container-fluid" style={{ marginTop: "2rem" }}>
          <div className="row mb-4">
            <div className="col-xl-7">
              <div className="card shadow-none border mb-0">
                <div className="card-header bg-transparent">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="text-muted text-uppercase ls-1 mb-1">
                        Overview
                      </h6>
                      <h5 className="h3 text-black mb-0">
                        Candidate Onboarding
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <LineChart
                    graphData={applicationByMonth}
                    label={"Candidates"}
                  ></LineChart>
                </div>
              </div>
            </div>
            <div className="col-xl-5">
              <div className="card shadow-none border mb-0">
                <div className="card-header bg-transparent">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Overview
                      </h6>
                      <h5 className="h3 mb-0">Candidate Onboarding</h5>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <BarChart
                    graphData={applicationByMonth}
                    label={"Candidates"}
                  ></BarChart>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-xl-12 text-center mb-3 mt-3">
              <a href="/" className="btn btn-primary">
                <i className="fa fa-angle-double-right" aria-hidden="true"></i>{" "}
                Continue
              </a>
            </div>
          </div> */}
        </div>
        <AddSecurityQuestion
          securityShowHide={securityShowHide}
          handleSecurityShowHide={this.handleSecurityShowHide}
          userDetails={userDetails}
        ></AddSecurityQuestion>
      </React.Fragment>
    );
  }
}

export default Dashboard;
