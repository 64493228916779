import React, { useEffect, useState } from "react";
import http from "../../Services/httpService";
import config from "../../config.json";
import BprmForm from "./BprmForm";
import SubForm from "./SubForm";
import ApplicationHeader from "./ApplicationHeader";
import Progress from "./Progress";
import Swal from "sweetalert2";
import authService from "../../Services/authService";
import geo from "../../Services/geoService";
import PageHeader from "./PageHeader";
import countryCodes from "../../Services/countryCodes.json";
import WorkflowElement from "../../app/components/WorkflowElement";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentWfTask, getWorkflowTask, loadCurrentWorkflowTask, taskDone } from "../../app/store/WorkflowEngine";
import { useNavigate } from "react-router-dom";
import { loadAppRuningTasks } from "../../app/store/candidate";

const DynamicHtml = (props) => {
  const { handleBack, workItem } = props;

  const usrType = localStorage.getItem("userType");

  const state = {

    application: {},
    aWorkfinityAppEntity: {},
    wfForm: {},
    data: {},
    errors: {},
    encryptionDecryptions: {},
    aWorkfinityApp: {},

    workItem: {},
    showHide: false,
    processId: 0,
    formProcess: [],
    isDisabled: false,
    stateList: [],
    countryList: [],
    stateCountyCity: [],
    documents: [],
    countryCodes: [],
  };

  const [workflowTask, setWorkflowTask] = useState(null);
  const [reqCount, setReqCount] = useState(0);
  const currentWorkflowTaskSelector = useSelector(getCurrentWfTask);
  const workflowTaskSelector = useSelector(getWorkflowTask);

  let appType = "Enrollment Form";
  if (usrType === "candidate")
    appType = "Onboarding Form";

    const dispatch = useDispatch();
    const navigate=useNavigate();

  useEffect(() => {
    if (workItem?.ciid > 0) {
      setWorkflowTask(workItem);
    }
  }, [workItem]);

  useEffect(() => {
    console.log(currentWorkflowTaskSelector)
    if (currentWorkflowTaskSelector?.formId === null && reqCount <= 2) {
      setTimeout(async () => {
        dispatch(loadCurrentWorkflowTask(workItem));
        const rqCount = reqCount + 1;
        setReqCount(rqCount)
      }, 3000);
    }
    else if (currentWorkflowTaskSelector?.wiid > 0) {
      setWorkflowTask(currentWorkflowTaskSelector);
    }
    else if(reqCount >= 3) {
      setWorkflowTask(null);
      dispatch(taskDone(""));
      dispatch(loadAppRuningTasks(workflowTask.chkId, workflowTask.performerId));
      navigate("/Onboarding");
    }
  }, [currentWorkflowTaskSelector]);

  useEffect(() => {
    if (workflowTaskSelector?.id > 0) {
      setWorkflowTask(workflowTaskSelector)
    }
    else if(reqCount <= 2) {
      setTimeout(() => {
        if(workflowTask)
        dispatch(loadCurrentWorkflowTask(workflowTask));
        setReqCount(1);
      }, 3000);
    }
  }, [workflowTaskSelector])

 

  const handleNextTask = (type) => {
    if(type === "submit" || type === "updateAndSubmit" || type === "checkStatus"){
      setWorkflowTask(null);
      dispatch(taskDone(""));
      dispatch(loadAppRuningTasks(workflowTask.chkId, workflowTask.performerId));
      navigate("/Onboarding");
    }
  }

  return (
    <React.Fragment>
      <div className="col-md-12 mt-3 form-group">
        <div className="card border shadow-none">

          <div className="card-body p-3" style={{ backgroundColor: "#f9f9f9" }}>
            <div className="row">
              <div className="col-md-12 text-right mb-2">
                <button className="btn btn-outline-danger btn-sm" onClick={handleBack}>
                <i className="fa fa-times" aria-hidden="true"></i> Close
                </button>
              </div>
              <div className="col-md-12">
                {/* <ApplicationHeader
              workItem={workItem}
              handleHistoryTask={handleNextTask}
            ></ApplicationHeader> */}
                <WorkflowElement wfTask={workflowTask}
                  handleNextTask={handleNextTask}
                  saveBtnProcessStatus={false}
                  actionType=""
                />
              </div>
            </div>

          </div>
        </div>

      </div>
    </React.Fragment>
  );
}

export default DynamicHtml;
