import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "forms",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        addedStatus:false,
        sendStatus:false,
        deleteStatus:false,
        editAllowStatus:false,
        formById:null,
        editReqStatus:false
    },
    reducers: {
        formLibraryReceived:(forms,action)=>{
            forms.list=action.payload;
            forms.addedStatus=false;
            forms.sendStatus=false;
            forms.deleteStatus=false;
            forms.editAllowStatus=false;
        },
        formLibraryAdded:(forms,action)=>{
            forms.addedStatus=action.payload;
        },
        formLibrarySent:(forms,action)=>{
            forms.sendStatus=true;
        },
        formLibraryDeleted:(forms,action)=>{
            forms.deleteStatus=true;
        },
        libraryFormByIdReceived:(forms,action)=>{
            forms.formById=action.payload;
        },
        formLibraryEditAllowed:(forms,action)=>{
            forms.editAllowStatus=true;
        },
        formLibraryEditRequestSent:(forms,action)=>{
            forms.editReqStatus=action.payload;
        },
        
    }
});

export const { formLibraryReceived, formLibraryAdded,
    formLibrarySent, formLibraryDeleted, libraryFormByIdReceived,
    formLibraryEditAllowed,formLibraryEditRequestSent } = slice.actions;

export default slice.reducer;

export const loadAppLibraryForms = (id) => apiCallBegan({
    url: config.GetFormsLibrary + id,
    onSuccess: formLibraryReceived.type
});

export const loadLibraryFormById= (id) => apiCallBegan({
    url: config.GetFormLibraryById + id,
    onSuccess: libraryFormByIdReceived.type
});

export const addFormLibrary = (data) => apiCallBegan({
    url: config.AddFormLibrary,
    method:"post",
    data:data,
    onSuccess: formLibraryAdded.type
});

export const sendFormLibraryToApplicant = (id) => apiCallBegan({
    url: config.SendLibraryForm+id,
    onSuccess: formLibrarySent.type
});

export const deleteFormLibrary = (id) => apiCallBegan({
    url: config.DeleteFormLibrary+id,
    onSuccess: formLibraryDeleted.type
});

export const allowFormLibraryEdit = (id,allow) => apiCallBegan({
    url: config.EditAllowFormLibrary + id + "&allow=" + allow,
    onSuccess: formLibraryEditAllowed.type
});

export const sendEditReqFormLibrary = (id,notes) => apiCallBegan({
    url: config.SendRequestEditFormLibrary + id + "&notes=" + notes,
    onSuccess: formLibraryEditRequestSent.type
});

export const getAppFormLibrary = createSelector(
    state => state.entities.forms,
    forms => forms.list
);

export const getAppFormLibrarySendStatus = createSelector(
    state => state.entities.forms,
    forms => forms.sendStatus
);

export const getAppFormLibraryDeleteStatus = createSelector(
    state => state.entities.forms,
    forms => forms.deleteStatus
);

export const getAppFormLibraryAddedStatus = createSelector(
    state => state.entities.forms,
    forms => forms.addedStatus
);

export const getFormLibraryEditStatus = createSelector(
    state => state.entities.forms,
    forms => forms.editAllowStatus
);

export const getLibraryFormById = createSelector(
    state => state.entities.forms,
    forms => forms.formById
);

export const getFormLibraryEditReqStatus = createSelector(
    state => state.entities.forms,
    forms => forms.editReqStatus
);

