import React, { Component } from "react";
import { Link } from "react-router-dom";
import ApplicationHeader from "./Comman/ApplicationHeader";

class Home extends Component {
  render() {
    return (
      <div className="row" style={{ border: "1px solid #ccc" }}>
        <ApplicationHeader></ApplicationHeader>
        <div className="col-md-12">
          <div className="card border">
            <div className="card-header" style={{backgroundColor:"#FFFDE7"}}>
              <label htmlFor="" className="form-control-label m-0">
                Apply for Application
              </label>
            </div>
            <div className="card-body bg-secondary">
              <ul className="list-group">
                <li className="list-group-item h5">
                <i className="fa fa-hand-o-right mr-2" aria-hidden="true"></i> Registration is must. <Link to="/Registration">Click here</Link> to Register first
                </li>
                <li className="list-group-item h5">
                <i className="fa fa-hand-o-right mr-2" aria-hidden="true"></i> If you are already registered, <Link to="/Login">Click here</Link> to Sign In
                </li>
                <li className="list-group-item h5">
                <i className="fa fa-hand-o-right mr-2" aria-hidden="true"></i> <Link to="/Login">Click here</Link> Click here to Check Status of Your Application
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card border">
            <div className="card-header" style={{backgroundColor:"#FFFDE7"}}>
              <label htmlFor="" className="form-control-label m-0">
                Partner Network – Join Us
              </label>
            </div>
            <div className="card-body bg-secondary">
              <ul className="list-group">
                <li className="list-group-item h5">
                <i className="fa fa-hand-o-right mr-2" aria-hidden="true"></i> To become our partner, please <Link to="/PartnerRegistration">Click here</Link> to Register first
                </li>
                <li className="list-group-item h5">
                <i className="fa fa-hand-o-right mr-2" aria-hidden="true"></i> If you are already registered, <Link to="/Login">Click here</Link> to Sign In
                </li>
                <li className="list-group-item h5">
                <i className="fa fa-hand-o-right mr-2" aria-hidden="true"></i> <Link to="/Login">Click here</Link> to Check Status of Your Application
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card border">
            <div className="card-header text-center" style={{backgroundColor:"#FFFDE7"}}>
              <a href="!#" className="h3 text-blue">
              <i className="fa fa-hand-o-right mr-3" aria-hidden="true"></i> Gateway Access to Partner Network <i className="fa fa-hand-o-left ml-3" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
