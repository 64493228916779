import React, { Component } from "react";
import { Line } from "react-chartjs-2";

export default class LineChart extends Component {
  generateColor() {
    return "#" + Math.random().toString(16).substr(-6);
  }

  render() {
    const { graphData, label } = this.props;
    //console.log(graphData);
    let dtObj = [];
    let dtlbl = [];
    if (graphData.length > 0) {
      graphData.map((item) => {
        dtlbl = item.label;
        const clr = this.generateColor();
        const obj = {
          label: item.type,
          fill: false,
          //lineTension: 0.2,
          backgroundColor: clr, //"rgba(75,192,192,0.4)",
          borderColor: clr,
          //borderCapStyle: "butt",
          // borderDash: [],
          // borderDashOffset: 0.0,
          // borderJoinStyle: "miter",
          // pointBorderColor: "rgba(75,192,192,1)",
          // pointBackgroundColor: "#fff",
          // pointBorderWidth: 5,
          // pointHoverRadius: 10,
          // pointHoverBackgroundColor: "rgba(75,192,192,1)",
          // pointHoverBorderColor: "rgba(220,220,220,1)",
          // pointHoverBorderWidth: 2,
          // pointRadius: 1,
          // pointHitRadius: 10,
          data: item.data,
        };
        dtObj.push(obj);
      });
    }
    const data = {
      labels: dtlbl,
      datasets: dtObj,
    };

    return (
      <div className="chart">
        <Line data={data} options={{ maintainAspectRatio: false }} />
      </div>
    );
  }
}
