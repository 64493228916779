import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "timeLog",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        timeLogDuration:null,
        updateStatus:false,
        timeSheetDelStatus:false,
        tmLogAddedStatus:false
    },
    reducers: {
        timeLogReceived: (timeLog, action) => {
            timeLog.list=action.payload;
            timeLog.timeSheetDelStatus=false;
            timeLog.updateStatus=false;
            timeLog.tmLogAddedStatus=false;
        },
        taskDone: (timeLog, action) => {
            timeLog.loading = false;
        },
        ruleAppDataReceived: (timeLog, action) => {
            timeLog.ruleAppData = action.payload;
        },
        timeLogDurationReceived:(timeLog,action)=>{
            timeLog.timeLogDuration=action.payload;
            timeLog.timeSheetDelStatus=false;
            timeLog.updateStatus=false;
        },
        projectStartDateUpdating:(timeLog,action)=>{
            timeLog.updateStatus=true;
        },
        timeLogTimeSheetDeleted:(timeLog,action)=>{
            timeLog.timeSheetDelStatus=action.payload;
        },
        newTimeLogAdedd:(timeLog,action)=>{
            timeLog.tmLogAddedStatus=action.payload;
        }
    }
});

export const { timeLogReceived, taskDone, 
    ruleAppDataReceived,timeLogDurationReceived,
    projectStartDateUpdating,timeLogTimeSheetDeleted,
    newTimeLogAdedd } = slice.actions;
export default slice.reducer;

export const loadTimeLogs = (id) => apiCallBegan({
    url: config.GetTimeLog + id,
    onSuccess: timeLogReceived.type
});

export const addNewTmLog = (data) => apiCallBegan({
    url: config.AddNewTimeLog,
    method:"post",
    data:data,
    onSuccess: newTimeLogAdedd.type
});

export const loadTimeLogDuration = (id) => apiCallBegan({
    url: config.GetCandidateProjectStartDate + id,
    onSuccess: timeLogDurationReceived.type
});

export const updateProjectStartDate = (id,date) => apiCallBegan({
    url: config.UpdateProjectStartDate + id+"&startDt="+date,
    onSuccess: projectStartDateUpdating.type
});

export const deleteTimeSheetDoc = (id) => apiCallBegan({
    url: config.DeleteTimeLogTimeSheetDoc + id,
    method:"delete",
    onSuccess: timeLogTimeSheetDeleted.type
});

export const getTimeLogs = createSelector(
    state => state.entities.timeLog,
    timeLog => timeLog.list
);

export const getTimeLogDuration = createSelector(
    state => state.entities.timeLog,
    timeLog => timeLog.timeLogDuration
);

export const getProjectDateUpdateStatus = createSelector(
    state => state.entities.timeLog,
    timeLog => timeLog.updateStatus
);

export const getTimeSheetDelStatus = createSelector(
    state => state.entities.timeLog,
    timeLog => timeLog.timeSheetDelStatus
);

export const getTmLogAddedStatus = createSelector(
    state => state.entities.timeLog,
    timeLog => timeLog.tmLogAddedStatus
);

