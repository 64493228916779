import React, { useEffect } from "react"
import PageHeader from "../../Components/Comman/PageHeader";
import OnboardingSteps from "./Onboarding/OnboardingSteps";
import CandidateInfo from "./CandidateInfo";
import PartnerInfo from "./PartnerInfo";
import EnrollmentSteps from "./Onboarding/EnrollmentSteps";
import { useDispatch } from "react-redux";
import { setCurrentPageTitle, setCurrentRoute } from "../store/layoutUI";

export default function LandingPage(props) {
    const usrType = localStorage.getItem("userType");

    const route = [
        { name: "Task Management", url: "" }
    ];
    const route1 = [
        { name: "Contract Management", url: "" }
    ];

    let pageTitle = "";

    const dispatch=useDispatch();

    useEffect(()=>{
        dispatch(setCurrentPageTitle("Home"));
        dispatch(setCurrentRoute(route));
    },[])

    
    return <React.Fragment>
       
        <div className="col-md-12 mt-3">
            <span className="">
                Welcome to IPCS's Human Resources, Onboarding and Contract
                Management Portal!
            </span>
            <br />
            <br />
            {usrType === "candidate" ? (
                <span>
                    Please click on the link above and follow the process for
                    Onboarding with IPCS.
                </span>
            ) : (
                <span>
                    Please click on the links above (Steps 1 to 5) and follow
                    the enrollment process with IPCS.
                </span>
            )}
        </div>
    </React.Fragment>
}