import React, { Component } from "react";
import http from "../../Services/httpService";
import config from "../../config.json";
import auth from "../../Services/authService";
import Moment from "react-moment";
import WelcomePage from "../WelcomePage";
import { Link } from "react-router-dom";
import ApplicationInstructions from "../ApplicationInstructions";

class SubcontractorDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: "",
      welShowHide: false,
      instructionShowHide: false,
    };
  }

  async componentDidMount() {
    const user = auth.getCurrentUser();
    const currentUser = await auth.getCurrentUserDetails(parseInt(user.id));
    if (currentUser.partnerid !== null) {
      const { data: partner } = await http.get(
        config.GetPartnerById + currentUser.partnerid
      );
      this.setState({ partner });
    }
  }

  handleWelShowHide = () => {
    this.setState({ welShowHide: !this.state.welShowHide });
  };

  handleInstructionShowHide = () => {
    this.setState({ instructionShowHide: !this.state.instructionShowHide });
  };

  render() {
    const { partner: item, welShowHide, instructionShowHide } = this.state;
    const usrType = localStorage.getItem("userType");
    const appType = window.location.pathname;
    //console.log(appType);
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <div className="text-right mt--3">
              <button
                className="btn btn-link btn-sm m-0 p-0"
                onClick={this.handleWelShowHide}
              >
                Welcome Letter !
              </button>
            </div>
            <div className="table-responsive border">
              <table className="table align-items-center table-flush table-hover table-striped mb-2">
                <thead className="thead-light-orange">
                  <tr>
                    <th style={{ borderLeft: "5px solid #ffbf00" }}>ID</th>
                    <th>
                      {usrType === "recruiter" ? "Recruiter" : "Subcontractor"}
                    </th>
                    <th>Date & Time</th>
                    <th>Address</th>
                    <th>Key Contact</th>
                    <th>Entity Type</th>
                    {/* <th>Enrollment Status</th> */}
                    <th>Active Users</th>
                  </tr>
                </thead>
                <tbody>
                  {item !== "" && (
                    <tr>
                      <td>{item.fuid}</td>
                      <td>
                        <span className="text-uppercase">{item.label}</span>
                        <br />
                        <span className="text-primary">{item.email}</span>
                        <br />
                        {item.contact1.contactNo &&
                          item.contact1.contactNo.slice(0, 3) +
                            "-" +
                            item.contact1.contactNo.slice(3, 6) +
                            "-" +
                            item.contact1.contactNo.slice(6)}
                      </td>
                      <td>
                        <Moment format="MMM Do YYYY">{item.createdDate}</Moment>
                        <br />
                        <Moment format="h:mm a">{item.createdDate}</Moment>
                      </td>
                      <td>
                        {item.portalUrl} {item.state} <br />
                        {item.city} {item.zip}
                      </td>
                      <td>
                        {item.contact1 !== null && (
                          <React.Fragment>
                            <span className="text-uppercase">
                              {item.contact1.performername}
                            </span>
                            <br />
                            <span className="text-primary">
                              {item.contact1.performeremail}
                            </span>
                            <br />
                            {item.contact1.contactNo &&
                              item.contact1.contactNo.slice(0, 3) +
                                "-" +
                                item.contact1.contactNo.slice(3, 6) +
                                "-" +
                                item.contact1.contactNo.slice(6)}
                          </React.Fragment>
                        )}
                      </td>
                      <td>C2C</td>
                      {/* <td>
                    <button className="btn btn-sm btn-success">Done</button>
                  </td> */}
                      <td>
                        <span
                          className="badge badge-success w-100 rounded-0"
                          style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                            color: "black",
                          }}
                        >
                          {item.activeUsers}
                        </span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {appType !== "/CandidateList/list" &&
              appType !== "/CandidateList/invoice" &&
              appType !== "/ComplianceRpt" &&
              appType !== "/SurveyFeedback" &&
              appType !== "/ScheduleMeeting" &&
              appType !== "/FormLibrary" &&
              appType !== "/SentDocs" &&
              appType !== "/RecvedDocs" &&
              appType !== "/IndividualMsg" &&
              appType !== "/CustomReport" &&
              appType !== "/CandidateWorkingHub" &&
              appType !== "/Dashboard" &&
              appType !== "/CandidateInvoice" && (
                <React.Fragment>
                  <div className="mt-2">
                    <a
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        fontSize: "13px",
                      }}
                      onClick={this.handleInstructionShowHide}
                    >
                      <i
                        className="fa fa-hand-o-right text-red"
                        aria-hidden="true"
                      ></i>{" "}
                      Instructions for completing{" "}
                      {usrType === "candidate" ? "onboarding" : "enrollment"}{" "}
                      process
                    </a>
                  </div>
                  <div className="p-3 border mt-1">
                    <Link
                      to="/Landing/1"
                      className={
                        appType === "/Landing/1"
                          ? "font-weight-bold"
                          : "text-dark"
                      }
                    >
                      Step 1 : Enrollment Form
                      <i
                        className="fa fa-angle-double-right mr-1 ml-1"
                        aria-hidden="true"
                      ></i>
                    </Link>

                    <Link
                      to="/Landing/2"
                      className={
                        appType === "/Landing/2"
                          ? "font-weight-bold"
                          : "text-dark"
                      }
                    >
                      Step 2 : Contract Documents
                      <i
                        className="fa fa-angle-double-right mr-1 ml-1"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    <Link
                      to="/Landing/5"
                      className={
                        appType === "/Landing/5"
                          ? "font-weight-bold"
                          : "text-dark"
                      }
                    >
                      Step 3 : ACH Information
                      <i
                        className="fa fa-angle-double-right mr-1 ml-1"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    <Link
                      to="/Landing/6"
                      className={
                        appType === "/Landing/6"
                          ? "font-weight-bold"
                          : "text-dark"
                      }
                    >
                      Step 4 : Insurance Information
                      <i
                        className="fa fa-angle-double-right mr-1 ml-1"
                        aria-hidden="true"
                      ></i>
                    </Link>
                    <Link
                      to="/Landing/4"
                      className={
                        appType === "/Landing/4"
                          ? "font-weight-bold"
                          : "text-dark"
                      }
                    >
                      Step 5 : Enrollment Status
                    </Link>
                  </div>
                </React.Fragment>
              )}
          </div>
        </div>

        <WelcomePage
          welShowHide={welShowHide}
          handleWelShowHide={this.handleWelShowHide}
        ></WelcomePage>

        <ApplicationInstructions
          instructionShowHide={instructionShowHide}
          handleInstructionShowHide={this.handleInstructionShowHide}
          //entityType={entityType}
        ></ApplicationInstructions>
      </React.Fragment>
    );
  }
}

export default SubcontractorDetails;
