import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getCurrentUser } from "../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getFilterAllCandidates, loadCandidatesByFilter } from "../../app/store/candidate";
import { getFieldData } from "../../Services/appService";

const Help = (props) => {

  const { helpShowHide, handleHelpShowHide, module } = props;
  const usrType = localStorage.getItem("userType");
  const [entityType, setEntityType] = useState(null);

  const filterAllCandidatesSelector = useSelector(getFilterAllCandidates)

  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    const frmBody = new FormData();
    frmBody.append("id", currentUser.id);
    frmBody.append("name", "");
    frmBody.append("email", "");
    frmBody.append("entity", "");
    frmBody.append("type", "candidate");
    frmBody.append("filterType", "single");
    dispatch(loadCandidatesByFilter(frmBody))
  }, []);

  useEffect(() => {
    if (filterAllCandidatesSelector.length > 0) {
      const perfData = filterAllCandidatesSelector[0].performerData;
      const ent = getFieldData(perfData, "candidateonboardingform", "CandidateEntity");
      setEntityType(ent);
    }
  }, [filterAllCandidatesSelector]);

  return (
    <Modal show={helpShowHide} size="xl">
      <Modal.Header closeButton onHide={handleHelpShowHide}>
        <Modal.Title>
          {module === "Support" ? "Add Issue" : "Help"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="border">
        {module === "Support" && <div className="row border bg-white pt-3 pb-3 rounded">
          <div className="col-md-12 form-group">
            <h3>Welcome to our Customer Support Portal!</h3>
          </div>
          <div className="col-md-12 form-group">
            <p>In this portal we offer you an easy way to register any issue you may encounter while working and using
              various functions and features.
            </p>
          </div>
          <div className="col-md-12 form-group">
            <p>Once you register an issue and submit, it will be received by our dedicated and committed customer and
              technical support staff who will review and provide instant fix(s), if required.</p>
          </div>
          <div className="col-md-12 form-group">
            <p>Our customer and technical support staff will also update and notify you of the action taken.</p>
          </div>
          <div className="col-md-12 form-group">
            <h3>Thank you.</h3>
            <h3>WorkFINITY®</h3>
            <h3>
              Customer and Technical Support</h3>
          </div>
        </div>}
        {module !== "Support" &&
          <div className="row">
            <div className="col-md-12 border bg-white pt-3 pb-3 rounded">
              {usrType !== "candidate" && (
                <p className="mb-3">
                  <b>Manage Users</b> : You may add as many users to access the
                  portal.
                </p>
              )}
              {usrType === "candidate" ? (
                <p className="mb-3">
                  <b>Onboarding Steps</b> : Please complete the following{" "}
                  {entityType === "W2 Basis" || entityType === "1099 Basis"
                    ? "six "
                    : "five "}
                  steps for enrollment.
                </p>
              ) : (
                <p className="mb-3">
                  <b>Onboarding Steps</b> : Please complete the following five
                  steps for enrollment.
                </p>
              )}
              {usrType === "candidate" && (
                <>
                  <p className="mb-3">
                    <b>Compliance</b> : In this section you will
                    review the compliance documents.
                  </p>
                  <p className="mb-3">
                    <b>Time Log</b> : In this section enter your daily
                    hours logged on the project.
                  </p>

                </>
              )}
              {usrType !== "candidate" && (
                <>
                  <p className="mb-3">
                    <b>List of Candidate</b> : This list provides the number of
                    candidates engaged on the projects.
                  </p>
                  <p className="mb-3">
                    <b>Invoices and Payments</b> : Generate automatic Invoices
                    based on the Time Logged by the candidates. You are not
                    required to attach time sheets or Upload Invoices. Our
                    system does this automatically. Payments updates will also
                    be seen in this section.
                  </p>
                </>
              )}
              <p className="mb-3">
                <b>Message(s)</b> : Use this feature for to and fro communication.
              </p>
              <p className="mb-3">
                <b>Forms</b> : It contains various forms that you may need as part of engagement on the project.
              </p>
              <p className="mb-3">
                <b>Document History</b> : View history of documents sent and
                received.
              </p>
              {entityType !== "C2C Basis" && (
                <>
                  <p className="mb-3">
                    <b>Compliance</b> : In this section you will
                    receive yearly compliance document (1099-NEC).
                  </p>

                  <p className="mb-3">
                    <b>Reports</b> : View standard reports.
                  </p>
                </>
              )}
              <p className="mb-3">
                <b>Schedule Appointments</b> : Accept request(s) for meettings initiated by IPCS.
              </p>
              <p className="mb-3">
                <b>Surveys</b> : Complete the surveys as and when requested.
              </p>
            </div>
          </div>}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-sm btn-outline-danger"
          onClick={handleHelpShowHide}
        >
          <i class="fa fa-times mr-1" aria-hidden="true"></i> Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default Help;
