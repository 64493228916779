import React, { Component, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Moment from "react-moment";
import config from "../config.json";
import http from "../Services/httpService";
import auth from "../Services/authService";
import MessagingPopup from "./MessagingPopup";
import AddNewMessage from "./AddNewMessage";
import { getFormatedNumber } from "../Services/appService";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../app/store/auth";
import { getAppMessages, loadAppMessages } from "../app/store/candidate";
import { getAlert } from "../Services/appAlerts";

export default function AppMessages(props){
  const { workflowId, currentApp, msgShowHide, handleMsgShowHide } = props;

  const [appMessages, setAppMessage] = useState([]);
  const [addMsgShowHide, setAddMsgShowHide] = useState(false);
  const [msgObj, setMsgObj] = useState("");
  const [wfTask,setWfTask]=useState([currentApp]);
  const [showHide, setShowHide] = useState(false);

  const appMessagesSelector = useSelector(getAppMessages);

  const dispatch=useDispatch();
  const currentUser=getCurrentUser();

  useEffect(() => {
    let userId = currentUser.id;
    if (currentUser.type === "partner") {
      userId = currentUser.linkUser;
    }
    dispatch(loadAppMessages(workflowId, userId));
  }, []);

  useEffect(() => {
    if (appMessagesSelector.length > 0){
      const filterMsg = appMessagesSelector.filter((x) => x.ciid === currentApp.ciid);
      setAppMessage(filterMsg);
    }
  }, [appMessagesSelector]);

  const handleAddNewMessagePopup = (app) => {
    if (app === "save") {
      setAddMsgShowHide(false);
      let userId = currentUser.id;
      if (currentUser.type === "partner") {
        userId = currentUser.linkUser;
      }
      dispatch(loadAppMessages(workflowId, userId));
    }
    else
    {
      setAddMsgShowHide(!addMsgShowHide);
    }
  };

  const handleMessagePopup = (obj) => {
    setShowHide(!showHide);
    setMsgObj(obj);
  };

  const handleSendMessage = () => {
    if (workflowId > 0){
      let userId = currentUser.id;
      if (currentUser.type === "partner") {
        userId = currentUser.linkUser;
      }
      dispatch(loadAppMessages(workflowId, userId));
    }
      
  }

  return (
    <React.Fragment>
      <Modal show={msgShowHide} size="xl">
        <Modal.Header closeButton onHide={handleMsgShowHide}>
          <Modal.Title>Messages</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            <div className="col-md-12 text-right">
              <button
                className="btn btn-sm btn-outline-default mb-2"
                onClick={() => handleAddNewMessagePopup("")}
              >
                <i className="fa fa-plus" aria-hidden="true"></i> Add Message
              </button>
            </div>
            <div className="col-md-12">
              <div className="table-responsive" style={{maxHeight:350,overflow:"auto"}}>
                <table className="table align-items-center table-flush table-hover table-striped border">
                  <thead className="thead-light">
                    <tr>
                      <th>S.No.</th>
                      <th>Date & Time</th>
                      <th>Review Officer</th>
                      <th>Messages</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appMessages.map((item, key) => (
                      <tr>
                        <td>{key + 1}</td>
                        <td>
                          <Moment format="MMM Do YYYY, h:mm a">
                            {item.addedDateTime}
                          </Moment>
                        </td>
                        <td>
                          {item.role === "Super Admin"
                            ? item.sender
                            : item.receiver}
                          <br />
                          {item.role}
                          <br />
                          {item.email}
                          <br />
                          {item.contact && getFormatedNumber(item.contact)}
                        </td>

                        <td>
                          <button
                            className="btn btn-link btn-sm p-0"
                            onClick={() => handleMessagePopup(item)}
                          >
                            View
                            {item.unreadCount > 0 &&
                              <span className="badge badge-pill badge-danger position-absolute mt--1 ml-0">
                                {item.unreadCount}
                              </span>}
                            {item.readCount > 0 && item.unreadCount === 0 &&
                              <span className="badge badge-pill badge-success text-dark position-absolute mt--1 ml-0">
                                {item.readCount}
                              </span>}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={() => handleMsgShowHide("", 0)}>
          <i className="fa fa-times" aria-hidden="true"></i> Close
          </button>
        </Modal.Footer>
      </Modal>
      <AddNewMessage
        addMsgShowHide={addMsgShowHide}
        handleAddMsgModalShowHide={handleAddNewMessagePopup}
        runningApplication={wfTask}
      ></AddNewMessage>
      <MessagingPopup
        msgShowHide={showHide}
        message={msgObj}
        handleMsgModalShowHide={handleMessagePopup}
        handleSendMessage={handleSendMessage}
      ></MessagingPopup>
    </React.Fragment>
  );
}
