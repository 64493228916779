import React from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "../Components/Comman/Form";
import Joi from "joi-browser";
import auth from "../Services/authService";
import Swal from "sweetalert2";
import { getAlert } from "../Services/appAlerts";

class ChangePassword extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
    };
  }

  schema = {
    oldPassword: Joi.string().required().label("Old Password"),
    newPassword: Joi.string().required().label("New Password"),
    //confirmPassword: Joi.string().required().label("Confirm Password"),
    confirmPassword: Joi.any()
      .valid(Joi.ref("newPassword"))
      .required()
      .options({ language: { any: { allowOnly: "must match new password" } } })
      .label("Confirm Password"),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const passResult = this.password_validate(data.confirmPassword);

      if (passResult) {
        const user = auth.getCurrentUser();
        await auth.changePassword(
          data.oldPassword,
          data.confirmPassword,
          user.id
        );
        getAlert("", "Password Changed Successfully", "success");
      } else {
        getAlert(
          "Password Strength",
          "Please enter strong password",
          "error"
        );
      }
    } catch (ex) {
      //console.log(ex);
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.oldPassword = ex.response.data.message;
        this.setState({ errors });
      }
    }
  };

  password_validate(password) {
    var re = {
      capital: /[A-Z]/,
      digit: /[0-9]/,
      except: /[aeiou]/,
      full: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?][A-Za-z0-9]/,
    };
    //console.log("capital", re.capital.test(password));
    //console.log("digit", re.digit.test(password));
    //console.log("except", re.except.test(password));
    //console.log("full", re.full.test(password));
    return (
      re.capital.test(password) &&
      re.digit.test(password) &&
      re.except.test(password) &&
      re.full.test(password)
    );
  }

  componentWillReceiveProps = () => {
    this.setState({ data: {}, errors: {} });
  };

  // getAlert(title, message, type) {
  //   return Swal.fire({
  //     title: title,
  //     text: message,
  //     icon: type,
  //     confirmButtonText: "Close",
  //     confirmButtonColor: "#d33",
  //     onClose: () => {
  //       if (type === "success") {
  //         //window.location = "/AdminLogin";
  //         auth.logout();
  //         window.location = "/Login";
  //       }
  //     },
  //   });
  // }

  render() {
    const { handleModalShowHide, showHide } = this.props;
    return (
      <Modal show={showHide} size="xl" >
        <Modal.Header closeButton onHide={handleModalShowHide}>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border">
          <div className="row">
            <div className="col-md-12 form-group">
              <p className="text-red">
                <i className="fa fa-hand-o-right" aria-hidden="true"></i> New
                password should contain at least 8 characters (and up to 50
                characters) with a mixture of both uppercase and lowercase
                letters. A mixture of letters and numbers inclusion of at least
                one special character, e.g., ! @ # ? [no spaces allowed!]
              </p>
            </div>
            <div className="col-md-12">
              {this.renderInput("oldPassword", "Old Password", "password")}
            </div>
            <div className="col-md-12">
              {this.renderInput("newPassword", "New Password", "password")}
            </div>
            <div className="col-md-12">
              {this.renderInput(
                "confirmPassword",
                "Confirm Password",
                "password"
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-danger" onClick={handleModalShowHide}>
            <i className="fa fa-times"></i> Close
          </button>
          <button
            className="btn btn-sm btn-outline-default"
            disabled={this.validate()}
            onClick={this.handleSubmit}
          >
            <i className="fa fa-check"></i> Save
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ChangePassword;
