import { createSlice, createSelector } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import config from "../../config.json";

const slice = createSlice({
    name: "timeLogInvoice",
    initialState: {
        list: [],
        loading: false,
        lastFetch: null,
        invPdf:null,
        updateStatus:false
    },
    reducers: {
        timeLogInvocieReceived: (timeLogInvoice, action) => {
            timeLogInvoice.list=action.payload;
            timeLogInvoice.invPdf=null;
        },
        generatedInvoiePdfReceived: (timeLogInvoice, action) => {
            timeLogInvoice.invPdf = action.payload;
        },
        timeLogInvoiceUpdated: (timeLogInvoice, action) => {
            timeLogInvoice.updateStatus = action.payload;
        },
    }
});

export const { timeLogInvocieReceived, generatedInvoiePdfReceived,
    timeLogInvoiceUpdated
 } = slice.actions;
export default slice.reducer;

export const loadTimeLogInvoice = (id) => apiCallBegan({
    url: config.GetTimeLogInvoice + id,
    onSuccess: timeLogInvocieReceived.type
});

export const updateTimeLogInvoice = (data) => apiCallBegan({
    url: config.UpdateTimeLogInvoice,
    method: "post",
    data: data,
    onSuccess: timeLogInvoiceUpdated.type
});

export const printGeneratedInvoice = (data) => apiCallBegan({
    url: config.PrintTemplate,
    method: "post",
    data: data,
    responseType: "blob",
    onSuccess: generatedInvoiePdfReceived.type
});

export const getTimeLogInvoice = createSelector(
    state => state.entities.timeLogInvoice,
    timeLogInvoice => timeLogInvoice.list
);

export const getGeneratedInvPdf = createSelector(
    state => state.entities.timeLogInvoice,
    timeLogInvoice => timeLogInvoice.invPdf
);

export const getTimeLogUpdateStatus = createSelector(
    state => state.entities.timeLogInvoice,
    timeLogInvoice => timeLogInvoice.updateStatus
);

