import React, { Component, useEffect, useState } from "react";
import Moment from "react-moment";
import { Popover, Button, OverlayTrigger } from "react-bootstrap";
import http from "../../Services/httpService";
import config from "../../config.json";
import MeetingOptions from "../MeetingOptions";
import auth from "../../Services/authService";
import PageHeader from "../Comman/PageHeader";
import ChecklistItem from "../Comman/ChecklistItem";
import CandidateInfo from "../../app/components/CandidateInfo";
import { getCurrentUser } from "../../app/store/auth";
import { useDispatch, useSelector } from "react-redux";
import { getAppScheduleMeetings, loadSheduleMeeting } from "../../app/store/candidate";
import PartnerInfo from "../../app/components/PartnerInfo";
import { setCurrentPageTitle, setCurrentRoute } from "../../app/store/layoutUI";
import { getComponentUserStatus, UpdateComponentsUserStatus } from "../../app/store/tools";

export default function ScheduleMeeting() {

  const route = [{ name: "Task Management", url: "" }];
  const route1 = [{ name: "Contract Management", url: "" }];

  const usrType = localStorage.getItem("userType");

  const [scheduleMeetings, setScheduleMeetings] = useState([]);
  const [meetingShowHide, setMeetingShowHide] = useState(false);
  const [meetingObj, setMeetingObj] = useState(null);

  const meetingSelector = useSelector(getAppScheduleMeetings);
  const componentUserStatusSelector = useSelector(getComponentUserStatus);

  const currentUser = getCurrentUser();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPageTitle("Schedule Appointments"));
    dispatch(setCurrentRoute(usrType === "candidate" ? route : route1))
  }, [])

  useEffect(() => {
    if (meetingSelector.length === 0)
      dispatch(loadSheduleMeeting(currentUser.id));
  }, []);

  useEffect(() => {
    if (meetingSelector.length > 0) {
      setScheduleMeetings(meetingSelector);
      let schItems = "";
      meetingSelector.map((x, key) => {
        if (key > 0) schItems += ",";
        schItems += x.id;
      })
      handleUpdateComponent(schItems, meetingSelector[0].ciid)
    }
  }, [meetingSelector]);

  const handleUpdateComponent = (compItems, ciid) => {
    const comp = componentUserStatusSelector.filter(x => x.compId === 11);
    let updCompItems = "";
    if (comp.length > 0) {
      updCompItems = comp[0].compItems;
    }
    const compObj = {
      compName: "Schedule Appointment",
      performerId: currentUser.id,
      compItems: compItems,
      ciid: ciid,
      wiid: 0,
      iiid: 0,
      compId: 11
    }
    if (compItems !== "" && compItems !== updCompItems) {
      dispatch(UpdateComponentsUserStatus(compObj));
    }
  }

  const handleMeetingModalShowHide = (meeting) => {
    setMeetingShowHide(!meetingShowHide);
    setMeetingObj(meeting)
    if (meeting === "save") {
      dispatch(loadSheduleMeeting(currentUser.id));
    }
  };

  const getAcceptedMeetingDate = (meetingOptions) => {
    const meeting = meetingOptions.filter((x) => x.status === true);
    if (meeting.length > 0) {
      return (
        <ul className="list-group p-0">
          <li
            className="list-group-item p-2 mb-2"
            style={{ backgroundColor: "#ffecb3", fontWeight: "bold" }}
          >
            <Moment format="MMM Do YYYY">{meeting[0].meetingDate}</Moment>
            &nbsp; {meeting[0].startTime} - {meeting[0].endTime}
          </li>
        </ul>
      );
    }

    return "";
  };

  return (
    <React.Fragment>

      <div className="col-md-12 mt-2">
        <div className="card shadow-none border">
          <div className="card-header p-3">
            <div className="row">
              <div className="col-md-6">
              <i className="fa fa-square wf-phase-box mr-2" aria-hidden="true"></i>
                <span className="h3">
                  Schedule Appointments
                </span>
                <p className="text-red">
                  Appointments (In person, Video/Phone conference calls) as
                  scheduled are shown below.
                </p>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
          <div className="card-body p-3">
            <div className="table-responsive">
              <table className="table align-items-center table-flush table-hover table-striped border">
                <thead className="thead-light">
                  <tr>
                    <th>S.No.</th>
                    <th>Date & Time</th>
                    <th>Meeting Date & Time (Options)</th>
                    <th>Meeting Date & Time (Accepted)</th>
                    <th>Notes</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {scheduleMeetings.map((item, key) => (
                    <tr>
                      <td>{key + 1}</td>
                      <td>
                        <Moment format="MMM Do YYYY">
                          {item.createdDateTime}
                        </Moment>
                        <br />
                        <Moment format="h:mm a">
                          {item.createdDateTime}
                        </Moment>
                      </td>
                      <td>
                        {item.options.map((o) => (
                          <li className="list-group-item p-2">
                            <Moment format="MMM Do YYYY">
                              {o.meetingDate}
                            </Moment>{" "}
                            {o.startTime} - {o.endTime}
                          </li>
                        ))}
                      </td>
                      <td>
                        {getAcceptedMeetingDate(item.options)}
                        {item.meetingProvider?.split(",")
                          .map((m, key) => {
                            return (
                              <>
                                {item.meetingLinks &&
                                  <a
                                    target="_blank"
                                    href={
                                      item.meetingLinks.split(",")[key]
                                    }
                                  >
                                    Join {m === "meet" && "Google Meet "}
                                    {m === "team" && "Microsoft Team "}
                                    {m === "zoom" && "Zoom "}
                                    Meeting
                                  </a>}
                                <br />
                              </>
                            );
                          })}
                      </td>
                      <td style={{ whiteSpace: "pre-wrap" }}>{item.remarks}</td>
                      <td>
                        {item.status ? (
                          "Accepted"
                        ) : (
                          <button
                            className="btn btn-link btn-sm"
                            onClick={() => handleMeetingModalShowHide(item)}
                          >
                            Accept
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
      <MeetingOptions
        meetingShowHide={meetingShowHide}
        handleMeetingModalShowHide={handleMeetingModalShowHide}
        meeting={meetingObj}
      ></MeetingOptions>
    </React.Fragment>
  );
}
