import React from "react";
import PropTypes from "prop-types";

const PageSizeOptions = ({ pageSize, onChange }) => {
  const pageSizes = [2, 5, 10, 25, 50, 100];
  return (
    <div className="card-header p-0 pb-2">
      <div className="form-inline">
        <label className="mr-2">Display Records</label>
        <select
          className="form-control form-control-sm"
          value={pageSize}
          onChange={onChange}
        >
          {pageSizes.map((size) => (
            <option value={size}>{size}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

PageSizeOptions.propTypes = {};

export default PageSizeOptions;
