import React from "react";
import Joi from "joi-browser";
import Form from "../Comman/Form";
import http from "../../Services/httpService";
import config from "../../config.json";

class LoginStep1 extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: {},
      documents: {},
      userTypes: [],
      currentItem: {
        eye: false,
        name: "",
      },
    };
  }

  schema = {
    validId: Joi.string().required().label("Valid ID"),
  };

  doSubmit = async () => {
    const { data, errors } = this.state;
    try{
      const { data: result } = await http.get(
        config.GetUserType + data["validId"]
      );
      data["userType"] = result;
      localStorage.setItem("userType", data["userType"]);
      this.props.handleStep1(data);
    }
    catch(err){
      const res=err.response;
      if(res?.data === "inactive")
        errors["validId"] =
        "Your account has been deactivated, please contact system administrator systemadmin@workfinity.com";
      else  errors["validId"] = "Invalid Id";

      this.setState({ errors });
    }
    
  };

  componentWillReceiveProps = (nextProps) => {
    const { userError } = nextProps;
    if (userError !== undefined) {
      const { errors } = this.state;
      errors["validId"] = userError;
      this.setState({ errors });
    }
  };

  render() {
    const { type } = this.props;
    return (
      <React.Fragment>
        {this.renderInput("validId", "Enter Your Valid ID")}
        <button
          className="btn w-100"
          style={{
            backgroundColor: `${type === "admin" ? "#298607" : "#2b62a1"}`,
            color: "white",
          }}
          disabled={this.validate()}
          onClick={this.handleSubmit}
        >
          NEXT
        </button>
      </React.Fragment>
    );
  }
}

export default LoginStep1;
